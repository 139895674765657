import { FC, useContext, useEffect, useState } from "react";
import { CalendarPlusIcon } from "src/components/icons/calendar-plus-icon";
import Button from "src/components/ui/button";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import InputWithoutLabel from "src/components/ui/input-without-label";
import DropdownWithoutLabel from "src/components/ui/dropdown-without-label";
import TailwindDatePicker from "src/components/ui/date-picker";
import { EventStatus, LIST_PAGE_SIZE } from "src/lib/constants";
import { ClientCostType } from "src/lib/data-types";
import { Dropdown, MenuProps, Space, Spin, Table, Typography } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import {
  capitalizeFirstLetter,
  getContractStatusTextColor,
  getEventStatusColor,
  getEventStatusName,
  makeNumberWithFloatingDigits,
} from "src/lib/utils";
import { useNavigate } from "react-router-dom";
import routes from "../../config/routes";
import { SortIcon } from "src/components/icons/sort-icon";
import { SearchQueryOptions } from "src/api/types";
import GlobalContext from "src/context/global-context";
import { useMutation } from "react-query";
import client from "../../api";
import { UserIcon } from "src/components/icons/user-icon";
import L from "i18n-react";
import MonthPicker from "src/components/ui/month-picker";
import { makeColumnHeader } from "src/components/table-columns/make-column-header";
import moment from "moment";
import { Empty } from "antd";

const ReportClientsPage: FC = () => {
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState<SearchQueryOptions>({
    per_page: LIST_PAGE_SIZE,
    page: 1,
    sort: "cost",
    direction: "desc",
    event_date: moment().format("yyyy-MM"),
  });
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<ClientCostType[]>([]);
  const { language } = useContext(GlobalContext);

  useEffect(() => {
    if (!isMounted) return;

    const delayDebounceFn = setTimeout(() => {
      getList(searchParams);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [
    isMounted,
    searchParams.page,
    searchParams.sort,
    searchParams.direction,
    searchParams.event_date,
  ]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 1124 ? true : false);
  }

  const { mutate: getList, isLoading } = useMutation(client.reports.clients, {
    onSuccess: (data) => {
      setTotal(data.count);
      setDataSource(
        data.results.map((item, index) => ({
          key: index,
          client_name: item.client.name,
          cost: item.cost,
        }))
      );
    },
  });

  const onPageChange = (page: number, pageSize: number) => {
    setSearchParams({ ...searchParams, page });
  };

  const columns: ColumnsType<ClientCostType> = [
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.client")}
        </p>
      ),
      dataIndex: "client_name",
      className: "w-auto",
      key: "client_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          `€.${L.translate("CommonForm.total_cost")}`,
          "cost",
          searchParams,
          setSearchParams
        ),
      dataIndex: "cost",
      className: "w-auto",
      key: "cost",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {makeNumberWithFloatingDigits(value, 2)}
          </p>
        </div>
      ),
    },
  ];

  const mobileColumns: ColumnsType<ClientCostType> = [
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[14px] font-medium">
          {L.translate("Base.info")}
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-col gap-4 items-start justify-between">
            <div className="w-full flex flex-col sm:flex-row gap-4 items-start justify-between">
              <div className="flex flex-row items-start justify-center gap-2">
                <p className="text-secondary text-[14px] font-gilroy font-medium">
                  {`${L.translate("CommonForm.client")}:`}
                </p>
                <p className="text-primary text-[14px] font-gilroy font-medium">
                  {selData.client_name}
                </p>
              </div>
              <div className="flex flex-row items-start justify-center gap-2">
                <p className="text-secondary text-[14px] font-gilroy font-medium">
                  {`€.${L.translate("CommonForm.total_cost")}:`}
                </p>
                <p className="text-primary text-[14px] font-gilroy font-medium">
                  {makeNumberWithFloatingDigits(selData.cost, 2)}
                </p>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="mt-8 w-full relative">
      <div className="flex flex-col items-start justify-center gap-2 md:flex-row md:items-center md:justify-start md:gap-4 mb-2">
        {/*<div className="text-primary text-[16px] font-gilroy-semibold">
          {L.translate("CommonForm.client_statistics_month")}
        </div>*/}
        <MonthPicker
          placeholder={String(L.translate("CommonForm.filter_by_month"))}
          value={searchParams.event_date ?? ""}
          onChange={(strDate?: string) =>
            setSearchParams({
              ...searchParams,
              event_date: strDate ?? "",
              page: 1,
            })
          }
        />
      </div>
      <Table
        locale={{
          emptyText: (
            <Empty
              description={
                <p className="font-gilroy-semibold text-secondary text-[14px]">
                  {L.translate("Base.no_data")}
                </p>
              }
            />
          ),
        }}
        showSorterTooltip={false}
        columns={isMobile ? mobileColumns : columns}
        dataSource={dataSource}
        loading={isLoading}
        pagination={{
          total,
          current: searchParams.page,
          onChange: onPageChange,
          position: ["bottomCenter"],
          defaultPageSize: LIST_PAGE_SIZE,
          showSizeChanger: false,
        }}
      />
    </div>
  );
};

export default ReportClientsPage;
