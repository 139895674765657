import { FC, useContext, useEffect, useState, useRef } from "react";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import L from "i18n-react";
import PaymentBlocks from "src/components/payment/block-info";
import { useMutation } from "react-query";
import client from "../../api";
import GlobalContext from "src/context/global-context";
import { Empty, Table } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { makeColumnHeader } from "src/components/table-columns/make-column-header";
import { InvoiceDataType, PaymentDataType } from "src/lib/data-types";
import { InvoiceStatus, LIST_PAGE_SIZE } from "src/lib/constants";
import { PaymentSearchQueryOptions } from "src/api/types";
import {
  calculateEventDuration,
  capitalizeFirstLetter,
  extractcollabIDs,
  getInvoiceStatusColor,
  getInvoiceStatusName,
  makeFormattedDateString,
  makeNumberWithFloatingDigits,
} from "src/lib/utils";
import { DownloadIcon } from "src/components/icons/download-icon";
import Button from "src/components/ui/button";
import { CreditCardIcon } from "src/components/icons/credit-card-icon";
import moment from "moment";
import MonthPicker from "src/components/ui/month-picker";
import DropdownWithoutLabel from "src/components/ui/dropdown-without-label";
import InputWithoutLabel from "src/components/ui/input-without-label";
import { generatePaymentXML } from "src/lib/generate-xml";
import { ReceiptIcon } from "src/components/icons/receipt-icon";
import { WalletArrowIcon } from "src/components/icons/wallet-arrow-icon";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import PaymentPDFDownload from "src/components/payment/generate-note";
import { useModalAction } from "src/components/modal-views/context";
import { CrossIcon } from "src/components/icons/cross-icon";
import { LeafHeartIcon } from "src/components/icons/leaf-heart-icon";
import { ExcelIcon } from "src/components/icons/excel-icon";
import axios from "axios";
import { ConfigValue } from "src/config";
import { API_ENDPOINTS } from "src/api/endpoints";
import { getAuthToken } from "src/api/token-util";
import JSZip from "jszip";
import { saveAs } from "file-saver"; 
import html2pdf from "html2pdf.js";

const PaymentsPage: FC = () => {
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const pdfExportComponent = useRef<Record<string, PDFExport | null>>({});
  const { openModal } = useModalAction();

  const [searchParams, setSearchParams] = useState<PaymentSearchQueryOptions>({
    per_page: 1000,//LIST_PAGE_SIZE,
    page: 1,
    sort: "last_name",                  // ordina per cognome    
    direction: "asc",                   // dalla a alla z (ordine crescente)
    date: moment().format("YYYY-MM"),   //FILTRA DI DEFAULT PER IL MESE CORRENTE
    event_name: "",
    collab_name: "",
    status: -1,
  });
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [totalData, setTotalData] = useState<PaymentDataType[]>([]);
  const [dataSource, setDataSource] = useState<PaymentDataType[]>([]);
  const [currentStatus, setCurrentStatus] = useState({
    total_payment: 0,
    paid_payment: 0,
    pending_payment: 0,
  });

  const { setAlertText, setAlertTitle, setIsAlertOpened, geoPosition } =
    useContext(GlobalContext);
  const [selectedItems, setSelectedItems] = useState<Record<string, Record<string, number[]>>>({});

  const [isExporting, setExporting] = useState(false);

  useEffect(() => {
    if (!isMounted) return;

    const delayDebounceFn = setTimeout(() => {
      getStatus();
      getList(searchParams);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [
    isMounted,
    searchParams.page,
    searchParams.sort,
    searchParams.direction,
    searchParams.date,
    searchParams.event_name,
    searchParams.collab_name,
    searchParams.status,
  ]);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 720 ? true : false);
  }

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  const onPageChange = (page: number, pageSize: number) => {
    setSearchParams({ ...searchParams, page });
  };

  const { mutate: getStatus, isLoading: isLoadingStatus } = useMutation(
    client.payments.status,
    {
      onSuccess: (data) => {
        setCurrentStatus(data.status);
      },
    }
  );

  const { mutate: markAsPaid, isLoading: isMarkingPaid } = useMutation(
    client.payments.markAsPaid,
    {
      onSuccess: (data) => {
        //setSelectedPayments([]);
        //setSelectedItems({});
        getList(searchParams);
        getStatus();
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(
            String(L.translate("Errors.failed_mark_payment_as_paid"))
          );
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: getList, isLoading } = useMutation(client.payments.list, {
    onSuccess: (data) => {
      setTotal(data.count);
      setDataSource(
        data.results.map((item, index) => ({
          key: index,
          id: item.id,
          collab_name: `${capitalizeFirstLetter(
            item.collaborator.last_name ?? ""
          )} ${capitalizeFirstLetter(item.collaborator.first_name ?? "")}`,
          first_name:  `${capitalizeFirstLetter(item.collaborator.first_name ?? "")}`,
          last_name:  `${capitalizeFirstLetter(item.collaborator.last_name ?? "")}`,
          collab_info: item.collaborator.info,
          event_name: item.event.name,
          event_start_date: item.event.start_date,
          event_end_date: item.event.end_date,
          amount: item.amount,
          dates: item.dates,
          reimburse: item.reimburse,
          total_amount:
            (Number(item.amount) ?? 0) + (Number(item.reimburse) ?? 0),
          //(Number(item.amount) ?? 0) * 0.8 + (Number(item.reimburse) ?? 0),
          date: item.date,
          due_date: item.due_date,
          status: item.status,
          created_at: item.created_at,
          updated_at: item.updated_at,
        }))
      );
    },
    onError: (error: any) => {
      setAlertTitle("Alert.error");
      if (error.code === "ERR_NETWORK") {
        setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
      } else {
        setAlertText(String(L.translate("Errors.failed_load_payments")));
      }
      setIsAlertOpened(true);
    },
  });

  useEffect(() => {
    const newSource = dataSource.filter((item) => {
      const nFoundIdx = totalData.findIndex((subItem) => subItem.id == item.id);

      if (nFoundIdx >= 0) return false;
      return true;
    });

    setTotalData([...totalData, ...newSource]);
  }, [dataSource]);

  const { mutate: archivePayment, isLoading: isArchiving } = useMutation(
    client.payments.archive,
    {
      onSuccess: (data) => {
        getList(searchParams);
        getStatus();
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_archive_payment")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: deletePayment, isLoading: isDeleting } = useMutation(
    client.payments.delete,
    {
      onSuccess: (data) => {
        getList(searchParams);
        getStatus();
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_delete_payment")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onMarkAsPaid = () => {
    markAsPaid({ payment_ids: extractcollabIDs(selectedItems) });
  };

  const onDownloadPDFs = () => {
    setTimeout(() => onProcessDownloadPDF(), 300);
    // setSelectedItems({});
  };

  const onExport = async () => {
    setExporting(true);

    try {
      const response = await axios.post(
        `${ConfigValue.PUBLIC_REST_API_ENDPOINT}/${API_ENDPOINTS.PAYMENT_EXPORT_EXCEL}`,
        {
          payment_ids: extractcollabIDs(selectedItems),
        },
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${getAuthToken() ?? ""}`,
          },
        }
      );

      setExporting(false);

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "payments.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();

      //setSelectedItems({}); //rimosso il reset di selectedItems
    } catch (error) {
      setExporting(false);

      console.error("Error downloading EXCEL file:", error);
      setAlertTitle("Alert.error");
      setAlertText(String(L.translate("Errors.error_download_excel")));
      setIsAlertOpened(true);
    }
  };

  const onDelete = (nPaymentID: number) => {
    openModal("CONFIRM", { payload: nPaymentID, callback: onConfirmDelete });
  };

  const onConfirmDelete = (nPaymentID: number) => {
    deletePayment(nPaymentID);
  };

  const onArchive = (nPaymentID: number) => {
    openModal("CONFIRM", { payload: nPaymentID, callback: onConfirmArchive });
  };

  const onConfirmArchive = (nPaymentID: number) => {
    archivePayment(nPaymentID);
  };

  /*
  const onDownloadPDF = (nPaymentID: number) => {
    const selectedItems = dataSource.filter((item) => item.id == nPaymentID);
    if (selectedItems.length == 0) return;

    setSelectedPayment(selectedItems[0]);
  };  

  useEffect(() => {
    if (!selectedPayment) return;

    setTimeout(() => onProcessDownloadPDF(), 300);
  }, [selectedPayment]);
  */

  const onProcessDownloadPDF = async() => {
  //   const zip = new JSZip();
  //   const pdfPromises = [];

  //   // Iterate over all refs and generate PDFs
  //   for (const [collabName, domElement] of Object.entries(pdfExportComponent.current)) {
  //     if (domElement) {
  //       // Generate PDF blob for each DOM element
  //       const pdfPromise = savePDF(domElement, { paperSize: "A4" }).then((pdfBlob) => {
  //         return { blob: pdfBlob, name: `${collabName}.pdf` };
  //       });
  //       pdfPromises.push(pdfPromise);
  //     }
  //   }

  //   // Wait for all PDFs to be generated
  //   const pdfFiles = await Promise.all(pdfPromises);

  //   // Add each PDF Blob to the zip file
  //   pdfFiles.forEach((file:any) => {
  //     zip.file(file.name, file.blob);
  //   });

  //   // Generate the zip file and trigger the download
  //   zip.generateAsync({ type: "blob" }).then((content) => {
  //     saveAs(content, "collaborator-pdfs.zip");
  //   });

  //   setSelectedItems({}); // Reset state
  // };

    // console.log(pdfExportComponent.current);
    // if (pdfExportComponent.current) {
    //   // Iterate over all PDFExport components stored in the object
    //   for (const pdfExport of Object.values(pdfExportComponent.current)) {
    //     if (pdfExport) {
    //       await pdfExport.save(); // Trigger the save for each valid reference
    //     }
    //   }
  
    //   setSelectedItems({}); // Reset after processing
    // }
     // State to manage the loader

    const zip = new JSZip(); // Initialize the ZIP archive
    const pdfFiles: { name: string; blob: Blob }[] = []; // Temporary store for PDFs
  
    console.log(pdfExportComponent.current);
  
    if (pdfExportComponent.current) {
      setLoading(true); // Show loader
  
      for (const [collabName, pdfExport] of Object.entries(pdfExportComponent.current)) {
        if (pdfExport) {
          console.log(`Generating PDF for ${collabName}`);
  
          // Select the DOM element using the data-collab-name attribute
          const element = document.querySelector(`[data-collab-name="${collabName}"]`);
  
          if (element) {
            try {
              // Generate PDF using html2pdf.js with higher quality settings
              const pdfBlob = await html2pdf()
                .set({
                  filename: `${collabName}.pdf`,
                  jsPDF: { unit: "pt", format: "a4", precision: 16, compression: "NONE" },
                  html2canvas: {
                    scale: 3,
                    useCORS: true,
                  },
                })
                .from(element)
                .outputPdf("blob");
  
              if (pdfBlob) {
                pdfFiles.push({ name: `${collabName}.pdf`, blob: pdfBlob });
                console.log(`Successfully generated PDF for ${collabName}`);
              } else {
                console.error(`Failed to generate PDF for ${collabName}`);
              }
            } catch (error) {
              console.error(`Error generating PDF for ${collabName}:`, error);
            }
          } else {
            console.error(`Element for ${collabName} not found`);
          }
        }
      }
  
      if (pdfFiles.length > 0) {
        // Add all PDFs to the ZIP archive
        pdfFiles.forEach(({ name, blob }) => {
          zip.file(name, blob);
          console.log(`Added ${name} to ZIP`);
        });
  
        // Generate and download the ZIP file
        zip.generateAsync({ type: "blob" }).then((zipBlob) => {
          saveAs(zipBlob, "Payment-Collabrators.zip");
          console.log("ZIP file generated and downloaded");
          setLoading(false); // Hide loader after completion
        });
      } else {
        console.error("No PDFs were generated, ZIP file is empty.");
        setLoading(false); // Hide loader if no files were generated
      }
  
      //setSelectedItems({}); // Clear selected items after processing
    } else {
      console.error("pdfExportComponent.current is empty or undefined");
      setLoading(false); // Hide loader if no elements are found
    }
  };

  const onDownloadXML = () => {
    const list = totalData.filter((item) =>
      extractcollabIDs(selectedItems).includes(item.id)
    );
   
    const groupedList = list.reduce<PaymentDataType[]>((acc, item) => {
      // Check if the collab_name already exists in the accumulator
      const existingCollab = acc.find(collab => collab.collab_name === item.collab_name);
    
      if (existingCollab) {
        // If collab_name exists, sum the total_amount
        existingCollab.total_amount += item.total_amount;
        // Optionally, you can merge other details if needed, for example, adding the events to an array
        existingCollab.dates = [...new Set([...existingCollab.dates, ...item.dates])];
      } else {
        // If collab_name does not exist, create a new entry
        acc.push({ ...item });
      }
    
      return acc;
    }, []);
    const xmlContent = generatePaymentXML(groupedList);
    const blob = new Blob([xmlContent], { type: "application/xml" });

    // Step 3: Create a link element
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${moment().format("DDMMYY-HH.mm")}-payment.xml`; // Specify the file name

    // Append to the body
    document.body.appendChild(link);
    link.click(); // Trigger the download
    document.body.removeChild(link); // Clean up

    //setSelectedItems({});    //rimosso il reset di selected items
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
  
    const tempKeys = newSelectedRowKeys.map((item) => Number(item));
  
    // Group items by collab_name
    const groupedByCollabName = tempKeys.reduce((acc, item) => {
      const { collab_name, id } = dataSource[item];
      if (!acc[collab_name]) {
        acc[collab_name] = [];
      }
      acc[collab_name].push(id);
      return acc;
    }, {} as Record<string, number[]>);
  
    const currentSelectedItems = { ...selectedItems };
    currentSelectedItems[searchParams.page] = groupedByCollabName;
    setSelectedItems(currentSelectedItems);
  };
  
  const getSelectedRowKeys = () => {
    // Flatten selected items for the current page to match the table row indices
    const selectedIds = selectedItems[searchParams.page] ?? {};
    const rowKeys = Object.values(selectedIds)
      .flat()
      .map((id) =>
        dataSource.findIndex((eachDatasource) => eachDatasource.id === id)
      );
    return rowKeys;
  };
  
  const rowSelection = {
    selectedRowKeys: getSelectedRowKeys(),
    onChange: onSelectChange,
  };

  // const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
  //   console.log("selectedRowKeys changed: ", newSelectedRowKeys);
  //   const tempKeys = newSelectedRowKeys.map((item) => Number(item));

  //   const selPaymentIDs = tempKeys.map((item) => dataSource[item].id);
  //   let currentSelectedItems = { ...selectedItems };
  //   currentSelectedItems[searchParams.page] = selPaymentIDs;
  //   setSelectedItems(currentSelectedItems);

  //   //setSelectedPayments(tempKeys.map((item) => dataSource[item].id));
  // };

  // const getSelectedRowKeys = () => {
  //   const rowKeys = (selectedItems[searchParams.page] ?? []).map((item) =>
  //     dataSource.findIndex((eachDatasource) => eachDatasource.id == item)
  //   );
  //   return rowKeys;
  // };

  // const rowSelection = {
  //   selectedRowKeys: getSelectedRowKeys(),
  //   onChange: onSelectChange,
  // };

  const columns: ColumnsType<PaymentDataType> = [
   {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.last_name")),
          "last_name",
          searchParams,
          setSearchParams
        ),
      dataIndex: "last_name",
      className: "w-auto",
      key: "last_name",
      sorter: true,   // abilitato l'ordinamento
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.first_name")),
          "first_name",
          searchParams,
          setSearchParams
        ),
      dataIndex: "first_name",
      className: "w-auto",
      key: "first_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[11px]">
            {makeNumberWithFloatingDigits(value, 2)}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.event_name")),
          "event_name",
          searchParams,
          setSearchParams
        ),
      dataIndex: "event_name",
      className: "w-auto",
      key: "event_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[11px]">
            {makeNumberWithFloatingDigits(value, 2)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {`€.${L.translate("CommonForm.amount")}`}
        </p>
      ),
      dataIndex: "total_amount",
      className: "w-auto",
      key: "total_amount",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {makeNumberWithFloatingDigits(value, 2)}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.date")),
          "date",
          searchParams,
          setSearchParams
        ),
      dataIndex: "date",
      className: "w-[120px]",
      key: "date",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value ? makeFormattedDateString(value) : "-"}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.status")}
        </p>
      ),
      dataIndex: "status",
      className: "w-[120px]",
      key: "status",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p
            className="font-gilroy text-white text-[13px] sm:text-[14px] rounded-[8px] px-2"
            style={{ backgroundColor: getInvoiceStatusColor(value) }}
          >
            {L.translate(getInvoiceStatusName(value))}
          </p>
        </div>
      ),
    },
    /*
    {
      title: () => <p className="hidden" />,
      dataIndex: "action",
      className: "w-[50px]",
      render: (_, record: { key: React.Key; id: number }) => (
        <div className="flex flex-row items-center justify-center gap-2">
          <Button
            variant="icon"
            className="!text-white hover:bg-red-400 bg-purple text-[14px] font-gilroy p-2 rounded-[12px]"
            onClick={(e) => {
              e.stopPropagation();
              onDownloadPDF(record.id);
            }}
          >
            <WalletArrowIcon className="text-white w-4 h-4" />
          </Button>
        </div>
      ),
    },*/
    {
      title: () => <p className="hidden" />,
      dataIndex: "action",
      className: "w-[50px]",
      render: (_, record: { key: React.Key; id: number }) => (
        <div className="flex flex-row items-center justify-center gap-2">
          <Button
            variant="icon"
            className="bg-error !text-white hover:!bg-red-400 text-[14px] font-gilroy p-2 rounded-[12px] group relative cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(record.id);
            }}
          >
            <CrossIcon className="text-white w-4 h-4" />
            <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
              {L.translate("Base.delete")}
            </span>
          </Button>
          <Button
            variant="icon"
            className="!text-white hover:bg-purple bg-[#8d257c] text-[14px] font-gilroy p-2 rounded-[12px] group relative cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              onArchive(record.id);
            }}
          >
            <LeafHeartIcon className="text-white w-4 h-4" />
            <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
              {L.translate("Base.archive")}
            </span>
          </Button>
        </div>
      ),
    },
  ];

  const mobileColumns: ColumnsType<PaymentDataType> = [
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[14px] font-medium">
          {L.translate("Base.info")}
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-col gap-4 items-start justify-between">
            <div className="w-full flex flex-col gap-4 items-start justify-between">
              <div className="flex flex-row items-center justify-center gap-2">
                <p className="text-secondary text-[14px] font-gilroy font-medium">
                  {`${L.translate("CommonForm.last_name")}:`}
                </p>
                <p className="text-primary text-[12px] font-gilroy font-medium">
                  {selData.last_name}
                </p>
              </div>
              <div className="flex flex-row items-start justify-center gap-2">
                <p className="text-secondary text-[14px] font-gilroy font-medium">
                  {`${L.translate("CommonForm.first_name")}:`}
                </p>
                <p className="text-primary text-[14px] font-gilroy font-medium">
                  {selData.first_name}
                </p>
              </div>
              <div className="flex flex-row items-center justify-center gap-2">
                <p className="text-secondary text-[14px] font-gilroy font-medium">
                  {`${L.translate("CommonForm.event_name")}:`}
                </p>
                <p className="text-primary text-[12px] font-gilroy font-medium">
                  {selData.event_name}
                </p>
              </div>
              <div className="flex flex-row items-start justify-center gap-2">
                <p className="text-secondary text-[14px] font-gilroy font-medium">
                  {`€.${L.translate("CommonForm.amount")}:`}
                </p>
                <p className="text-primary text-[14px] font-gilroy font-medium">
                  {makeNumberWithFloatingDigits(selData.total_amount, 2)}
                </p>
              </div>
              <div className="flex flex-row items-start justify-center gap-2">
                <p className="text-secondary text-[14px] font-gilroy font-medium">
                  {`${L.translate("CommonForm.date")}:`}
                </p>
                <p className="text-primary text-[14px] font-gilroy font-medium">
                  {selData.date ? makeFormattedDateString(selData.date) : "-"}
                </p>
              </div>
              <div className="flex flex-row items-start justify-center gap-2">
                <p className="text-secondary text-[14px] font-gilroy font-medium">
                  {`${L.translate("CommonForm.status")}:`}
                </p>
                <div className="flex flex-row gap-2 items-center">
                  <p
                    className="font-gilroy text-white text-[14px] rounded-[8px] px-2"
                    style={{
                      backgroundColor: getInvoiceStatusColor(selData.status),
                    }}
                  >
                    {L.translate(getInvoiceStatusName(selData.status))}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row items-start justify-center gap-2">
              <Button
                variant="text"
                className="text-[14px] font-gilroy !text-white w-full h-[44px] rounded-[8px] hover:bg-red-400 bg-error"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(selData.id);
                }}
              >
                <CrossIcon className="text-white w-4 h-4" />
                {L.translate("Base.delete")}
              </Button>
              <Button
                variant="icon"
                className="text-[14px] font-gilroy px-8 !text-white w-full h-[44px] rounded-[8px] hover:bg-purple bg-[#8d257c]"
                onClick={(e) => {
                  e.stopPropagation();
                  onArchive(selData.id);
                }}
              >
                <LeafHeartIcon className="text-white w-4 h-4" />
                <span className="text-[14px] font-gilroy uppercase">
                  {L.translate("Base.archive")}
                </span>
              </Button>
            </div>
            {/*
              <div className="flex flex-row items-start justify-center gap-2">
                <Button
                  variant="text"
                  className="text-[14px] font-gilroy px-8 !text-white h-[44px] rounded-[8px] hover:bg-red-400 bg-purple"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDownloadPDF(selData.id);
                  }}
                >
                  <WalletArrowIcon className="w-[16px] h-[16px] text-white" />
                  <span className="text-[14px] font-gilroy uppercase">
                    {L.translate("CommonForm.download_note")}
                  </span>
                </Button>
              </div>
            */}
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full h-full bg-whole-gray">
      <div className="bg-white p-4 rounded-[8px] mb-4">
        <div className="text-primary text-[24px] font-gilroy-semibold">
          {L.translate("Sidebar.payments")}
        </div>
        <div className="mt-4">
          <div className="mt-8 flex flex-col items-center justify-between bg-white">
            <PaymentBlocks
              total_payment={currentStatus.total_payment}
              pending_payment={currentStatus.pending_payment}
              paid_payment={currentStatus.paid_payment}
            />
          </div>
        </div>
        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full gap-4">
          <InputWithoutLabel
            placeholder={String(L.translate("CommonForm.event_name"))}
            className="w-full"
            value={searchParams.event_name}
            showClose={searchParams.event_name!.length > 0}
            clickedClose={() =>
              setSearchParams({ ...searchParams, event_name: "", page: 1 })
            }
            onChange={(e) =>
              setSearchParams({
                ...searchParams,
                event_name: e.target.value,
                page: 1,
              })
            }
          />
          <InputWithoutLabel
            placeholder={String(L.translate("CommonForm.collaborator"))}
            className="w-full"
            showClose={searchParams.collab_name!.length > 0}
            clickedClose={() =>
              setSearchParams({ ...searchParams, collab_name: "", page: 1 })
            }
            value={searchParams.collab_name}
            onChange={(e) =>
              setSearchParams({
                ...searchParams,
                collab_name: e.target.value,
                page: 1,
              })
            }
          />
          <MonthPicker
            placeholder={String(L.translate("CommonForm.filter_by_month"))}
            value={searchParams.date ?? ""}
            onChange={(strDate?: string) =>
              setSearchParams({ ...searchParams, date: strDate ?? "", page: 1 })
            }
          />
          <DropdownWithoutLabel
            placeholder={String(L.translate("CommonForm.status"))}
            className="w-full"
            data={[
              { id: -1, name: String(L.translate("CommonForm.status")) },
              ...InvoiceStatus.filter(
                (item) => item.name != "Base.overdue"
              ).map((item) => ({
                ...item,
                name: String(L.translate(item.name)),
              })),
            ]}
            onChange={(e: number) =>
              setSearchParams({ ...searchParams, status: e, page: 1 })
            }
          />
        </div>
        <div className="mt-8 relative w-full">
          <div className="flex flex-col sm:flex-row justify-end items-center gap-4">
            <Button
              variant="solid"
              className={`${
                extractcollabIDs(selectedItems).length == 0
                  ? "bg-[#BCBEAD]"
                  : "bg-purple"
              } rounded-[8px] text-[12px] font-gilroy leading-[24px] text-white hover:!bg-red-400 w-full sm:w-auto`}
              onClick={onExport}
              disabled={extractcollabIDs(selectedItems).length == 0}
              isLoading={isExporting}
            >
              <ExcelIcon className="w-[16px] h-[16px] text-white" />
              <span className="text-[14px] font-gilroy uppercase">
                {L.translate("Base.export")}
              </span>
            </Button>

            <Button
              variant="solid"
              className={`${
                extractcollabIDs(selectedItems).length == 0
                  ? "bg-[#BCBEAD]"
                  : "bg-success"
              } rounded-[8px] text-[12px] font-gilroy leading-[24px] text-white hover:bg-success-100 w-full sm:w-auto`}
              onClick={onMarkAsPaid}
              disabled={extractcollabIDs(selectedItems).length == 0}
              isLoading={isMarkingPaid}
            >
              <CreditCardIcon className="w-[16px] h-[16px] text-white" />
              <span className="text-[14px] font-gilroy uppercase">
                {L.translate("CommonForm.mark_as_paid")}
              </span>
            </Button>
            <Button
              variant="solid"
              className={`${
                extractcollabIDs(selectedItems).length == 0
                  ? "bg-[#BCBEAD]"
                  : "bg-warning"
              } rounded-[8px] text-[12px] font-gilroy leading-[24px] text-white hover:bg-warning-100 w-full sm:w-auto`}
              onClick={onDownloadXML}
              disabled={extractcollabIDs(selectedItems).length == 0}
            >
              <DownloadIcon className="w-[16px] h-[16px] text-white" />
              <span className="text-[14px] font-gilroy uppercase">
                {L.translate("CommonForm.download_xml")}
              </span>
            </Button>
            {loading && (
              <div
                className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50"
                style={{ pointerEvents: "none" }}
              >
                <div className="w-12 h-12 border-4 border-gray-300 border-t-gray-100 rounded-full animate-spin"></div>
                <p className="text-white mt-4 text-lg font-semibold">Processing your PDFs...</p>
              </div>
            )}
            <Button
              variant="solid"
              className={`${
                extractcollabIDs(selectedItems).length == 0
                  ? "bg-[#BCBEAD]"
                  : "bg-purple"
              } rounded-[8px] text-[12px] font-gilroy leading-[24px] text-white hover:bg-red-400 w-full sm:w-auto ${
                loading ? "cursor-not-allowed opacity-50" : ""
              }`}
              onClick={onDownloadPDFs}
              disabled={loading}
              // disabled={extractcollabIDs(selectedItems).length == 0}
            >
              <CreditCardIcon className="w-[16px] h-[16px] text-white" />
              <span className="text-[14px] font-gilroy uppercase">
                {L.translate("CommonForm.download_note")}
              </span>
            </Button>
          </div>
          <Table
            locale={{
              emptyText: (
                <Empty
                  description={
                    <p className="font-gilroy-semibold text-secondary text-[14px]">
                      {L.translate("Base.no_data")}
                    </p>
                  }
                />
              ),
            }}
            rowSelection={rowSelection}
            showSorterTooltip={false}
            columns={isMobile ? mobileColumns : columns}
            dataSource={dataSource}
            loading={isLoading || isArchiving || isDeleting}
            pagination={{
              total,
              current: searchParams.page,
              onChange: onPageChange,
              position: ["bottomCenter"],
              defaultPageSize: 10000,//LIST_PAGE_SIZE,
              showSizeChanger: false,
            }}
          />
        </div>
      </div>
      {/* <div style={{ position: "fixed", left: "-10000px" }}>
        {extractIDs(selectedItems).map((eachPaymentID, index) => {
          const paymentFullInfos = totalData.filter(
            (item) => eachPaymentID == item.id
          );

          if (paymentFullInfos.length > 0) {
            return (
              <PDFExport
                key={`pdf-export-${index}`}
                ref={(el) => (pdfExportComponent.current[index] = el!)}
                fileName={`${paymentFullInfos[0].collab_name}.pdf`}
                forcePageBreak=".page-break"
                paperSize="A4"
              >
                <PaymentPDFDownload data={paymentFullInfos[0]} />
              </PDFExport>
            );
          }
        })}
      </div> */}
      {/* <div style={{ position: "fixed", left: "-10000px" }}>
        {Object.entries(selectedItems).flatMap(([page, collabGroups]) =>
          Object.entries(collabGroups).map(([collabName, paymentIDs], index) => {
            // Filter the full payment info for the current collab_name
            const paymentFullInfos = totalData.filter((item) =>
              paymentIDs.includes(item.id)
            );
            console.log(paymentFullInfos, "info")
            if (paymentFullInfos.length > 0) {
              console.log("Exporting data item:", paymentFullInfos);
              return (
                <PDFExport
                  key={`pdf-export-${page}-${index}`}
                  ref={(el) => (pdfExportComponent.current[index] = el!)}
                  fileName={`${collabName}.pdf`}
                  forcePageBreak=".page-break"
                  paperSize="A4"
                >
                  <PaymentPDFDownload key={index} data={paymentFullInfos} />

                </PDFExport>
              );
            }

            return null;
          })
        )}
      </div> */}
      <div style={{ position: "fixed", left: "-10000px" }}>
        {Object.entries(selectedItems).flatMap(([page, collabGroups]) =>
          Object.entries(collabGroups).map(([collabName, paymentIDs], index) => {
            // Filter the full payment info for the current collab_name
            const paymentFullInfos = totalData.filter((item) =>
              paymentIDs.includes(item.id)
            );
            console.log(paymentFullInfos, "info");
            if (paymentFullInfos.length > 0) {
              console.log("Preparing PDF data:", collabName);
              return (
              <div key={`container-${page}-${collabName}`} data-collab-name={collabName}>
                  <PDFExport
                    key={`pdf-export-${page}-${collabName}`}
                    ref={(el) => {
                      if (el) pdfExportComponent.current[collabName] = el; // Ensure a unique index
                    }}
                    fileName={`${collabName}.pdf`}
                    forcePageBreak=".page-break"
                    paperSize="A4"
                  >
                    <PaymentPDFDownload key={index} data={paymentFullInfos} />
                  </PDFExport>
              </div>  
              );
            }
            return null; // Safeguard if no items match
          })
        )}
        {/* <button
          onClick={async () => {
            for (let index = 0; index < pdfExportComponent.current.length; index++) {
              const pdfExport = pdfExportComponent.current[index];
              if (pdfExport) {
                console.log(`Downloading PDF for index ${index}`);
                await pdfExport.save(); // Sequentially trigger the PDF download
              }
            }
          }}
        >
          Download All PDFs
        </button> */}
      </div>
    </div>
  );
};

export default PaymentsPage;
