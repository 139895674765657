import { FC, useContext, useEffect, useState, useRef } from "react";
import Button from "src/components/ui/button";
import InputWithoutLabel from "src/components/ui/input-without-label";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { useModalAction } from "src/components/modal-views/context";
import Zoom from "react-medium-image-zoom";
import UserPlaceholder from "../../assets/images/placeholder.png";
import useAuth from "src/hooks/use-auth";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "react-query";
import client from "../../api";
import {
  formatFileSize,
  getErrorMessage,
  isValidEmail,
  makeFormattedDateString,
} from "../../lib/utils";
import GlobalContext from "src/context/global-context";
import {
  Provinces,
  LanguageLevels,
  Positions,
  HairColors,
  ShoeSizes,
  Positions_IT,
  LanguageLevels_IT,
  HairColors_IT,
  EyeColors,
  EyeColors_IT,
} from "src/lib/constants";
import { CollaboratorProfile, Photo } from "src/api/types";
import { SpinnerIcon } from "src/components/icons/spinner-icon";
import AddressInput from "src/components/ui/address-input";
import CheckboxOptions from "src/components/ui/checkbox-options";
import TailwindDatePicker from "src/components/ui/date-picker";
import { ReactPhone } from "src/components/ui/phone-input";
import classNames from "classnames";
import SelectInput from "src/components/ui/select-input";
import DropdownWithoutLabel from "src/components/ui/dropdown-without-label";
import PositionSelect from "src/components/ui/position-select";
import { PencilIcon } from "src/components/icons/pencil-icon";
import ImageWithLoading from "src/components/ui/image-with-loading";
import { setCollaboratorProfileFilledFlag } from "src/api/token-util";
import L from "i18n-react";
import InputNoSpaceWithoutLabel from "src/components/ui/input-nospace-without-label";
import { shakeElement } from "src/lib/shake-element";
import { CheckboxIcon } from "src/components/icons/checkbox-icon";
import { SquareIcon } from "src/components/icons/square-icon";
import routes from "src/config/routes";
import { CrossIcon } from "src/components/icons/cross-icon";
import { calcolaCodiceFiscale } from "src/lib/calculate-tax-id";
import moment from "moment";
import InputName from "src/components/ui/input-name";
import InputPhoneNumber from "src/components/ui/input-phone-number";
import { set } from "lodash";

const CollaboratorProfilePage: FC = () => {
  const { getCurrentUser, setCurrentUser } = useAuth();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const phoneRef = useRef<HTMLDivElement>(null);
  const [phoneDivWidth, setPhoneDivWidth] = useState(0);

  const [privacyChecked, setPrivacyChecked] = useState(false);
  const shakeRef = useRef<HTMLDivElement>(null);

  const [profileInfo, setProfileInfo] = useState<CollaboratorProfile | null>(
    null
  );

  const [second_name, setSecondName] = useState("");
  const [french, setFrench] = useState<number | undefined>(undefined);
  const [german, setGerman] = useState<number | undefined>(undefined);
  const [spanish, setSpanish] = useState<number | undefined>(undefined);
  const [otherLang, setOtherLang] = useState("");
  const [provinces, setProvinces] = useState("");

  const [originalVideoFileName1, setOriginalVideoFileName1] = useState("");
  const [originalVideoFileName2, setOriginalVideoFileName2] = useState("");

  const videoRef1 = useRef<HTMLInputElement>(null);
  const [videoFile1, setVideoFile1] = useState<File | null>(null);
  const [videoPreview1, setVideoPreview1] = useState<string | null>(null);

  const videoRef2 = useRef<HTMLInputElement>(null);
  const [videoFile2, setVideoFile2] = useState<File | null>(null);
  const [videoPreview2, setVideoPreview2] = useState<string | null>(null);

  const cvRef = useRef<HTMLInputElement>(null);
  const [cvFile, setCVFile] = useState<File | null>(null);
  const [cvPreview, setCVPreview] = useState<string | null>(null);

  const mainPhotoRef = useRef<HTMLInputElement>(null);
  const [mainPhotoFile, setMainPhotoFile] = useState<File | null>(null);
  const [mainPhotoPreview, setMainPhotoPreview] = useState<any | null>(null);

  const photoRef2 = useRef<HTMLInputElement>(null);
  const [photoFile2, setPhotoFile2] = useState<File | null>(null);
  const [photoPreview2, setPhotoPreview2] = useState<any | null>(null);

  const photoRef3 = useRef<HTMLInputElement>(null);
  const [photoFile3, setPhotoFile3] = useState<File | null>(null);
  const [photoPreview3, setPhotoPreview3] = useState<any | null>(null);

  const photoRef4 = useRef<HTMLInputElement>(null);
  const [photoFile4, setPhotoFile4] = useState<File | null>(null);
  const [photoPreview4, setPhotoPreview4] = useState<any | null>(null);

  const photoRef5 = useRef<HTMLInputElement>(null);
  const [photoFile5, setPhotoFile5] = useState<File | null>(null);
  const [photoPreview5, setPhotoPreview5] = useState<any | null>(null);

  const photoRef6 = useRef<HTMLInputElement>(null);
  const [photoFile6, setPhotoFile6] = useState<File | null>(null);
  const [photoPreview6, setPhotoPreview6] = useState<any | null>(null);

  const photoRef7 = useRef<HTMLInputElement>(null);
  const [photoFile7, setPhotoFile7] = useState<File | null>(null);
  const [photoPreview7, setPhotoPreview7] = useState<any | null>(null);

  const photoRef8 = useRef<HTMLInputElement>(null);
  const [photoFile8, setPhotoFile8] = useState<File | null>(null);
  const [photoPreview8, setPhotoPreview8] = useState<any | null>(null);

  const photoRef9 = useRef<HTMLInputElement>(null);
  const [photoFile9, setPhotoFile9] = useState<File | null>(null);
  const [photoPreview9, setPhotoPreview9] = useState<any | null>(null);

  const [items, setItems] = useState([
    `${getCurrentUser().id}_photo_1`,
    `${getCurrentUser().id}_photo_2`,
    `${getCurrentUser().id}_photo_3`,
    `${getCurrentUser().id}_photo_4`,
    `${getCurrentUser().id}_photo_5`,
    `${getCurrentUser().id}_photo_6`,
    `${getCurrentUser().id}_photo_7`,
    `${getCurrentUser().id}_photo_8`,
  ]);

  const schema = yup
    .object({
      first_name: yup
        .string()
        .required(String(L.translate("ValidationErrors.first_name_required"))),
      last_name: yup
        .string()
        .required(String(L.translate("ValidationErrors.last_name_required"))),
      sex: yup
        .number()
        .required(String(L.translate("ValidationErrors.sex_required"))),
      live_aboard: yup
        .number()
        .required(String(L.translate("ValidationErrors.live_aboard_required"))),
      email: yup
        .string()
        .required(String(L.translate("ValidationErrors.email_required")))
        .matches(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          String(L.translate("ValidationErrors.invalid_email"))
        ),
      confirm_email: yup
        .string()
        .required(String(L.translate("ValidationErrors.confirm_email")))
        .matches(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          String(L.translate("ValidationErrors.invalid_email"))
        )
        .oneOf(
          [yup.ref("email")],
          String(L.translate("ValidationErrors.mails_not_match"))
        ),
      instagram: yup
        .string()
        .required(String(L.translate("ValidationErrors.instagram_required"))),
      tax_residence_outside_italiy: yup
        .number()
        .required(String(L.translate("ValidationErrors.tax_residence_outside_italiy_required"))),
        tax_id_code: yup
        .string()
        .when('tax_residence_outside_italiy', (tax_residence_outside_italiy, schema) => {
          return tax_residence_outside_italiy[0] === 1
            ? schema.required(String(L.translate("ValidationErrors.tax_id_code_required")))
            : schema;
        }),
      telephone_number: yup
        .string()
        .required(String(L.translate("ValidationErrors.telephone_number_required"))),
      id_passport_number: yup
        .string()
        .required(String(L.translate("ValidationErrors.id_passport_number_required"))),
      foreign_name: yup
        .string()
        .required(String(L.translate("ValidationErrors.foreign_name_required"))),
      born_aboard: yup
        .number()
        .required(String(L.translate("ValidationErrors.born_aboard_required"))),
      birth_place: yup
        .string()
        .required(String(L.translate("ValidationErrors.birth_place_required"))),
      birth_county: yup
        .string()
        .required(String(L.translate("ValidationErrors.birth_county_required"))),
      date_of_birth: yup
        .string()
        .required(String(L.translate("ValidationErrors.date_of_birth_required"))),
      home_address: yup
        .string()
        .required(String(L.translate("ValidationErrors.home_address_required"))),
      residence_domicile: yup
        .number()
        .required(String(L.translate("ValidationErrors.residence_domicile_required"))),
      residence_address: yup
        .string()
        .required(String(L.translate("ValidationErrors.residence_address_required"))),
      have_vat_number: yup
        .number()
        .required(String(L.translate("ValidationErrors.have_vat_number_required"))),
      vat_number: yup
        .string()
        .required(String(L.translate("ValidationErrors.vat_number_required"))),
      IBAN: yup
        .string()
        .required(String(L.translate("ValidationErrors.IBAN_required"))),
      current_account_holder: yup
        .string()
        .required(String(L.translate("ValidationErrors.current_account_holder_required"))),
      height: yup
        .string()
        .matches(
          /^\d{3}$/,
          String(L.translate("ValidationErrors.just_integer"))
        )
        .required(String(L.translate("ValidationErrors.height_required"))),
      italian_size: yup
        .number()
        .required(String(L.translate("ValidationErrors.italian_size_required"))),
      shoe_size: yup
        .string()
        .required(String(L.translate("ValidationErrors.shoe_size_required"))),
      hair_color: yup
        .number()
        .required(String(L.translate("ValidationErrors.hair_color_required"))),
      eye_color: yup
        .number()
        .required(String(L.translate("ValidationErrors.eye_color_required"))),
      have_tatoo: yup
        .number()
        .required(String(L.translate("ValidationErrors.have_tatoo_required"))),
      have_piercing: yup
        .number()
        .required(String(L.translate("ValidationErrors.have_piercing_required"))),
      have_driving_license: yup
        .number()
        .required(String(L.translate("ValidationErrors.have_driving_license_required"))),
      have_car: yup
        .number()
        .required(String(L.translate("ValidationErrors.have_car_required"))),
      have_HACCP_certificate: yup
        .number()
        .required(String(L.translate("ValidationErrors.have_HACCP_certificate_required"))),
      wear_eyeglasses: yup
        .number()
        .required(String(L.translate("ValidationErrors.wear_eyeglasses_required"))),
      italian: yup
        .number()
        .required(String(L.translate("ValidationErrors.italian_required"))),
      english: yup
        .number()
        .required(String(L.translate("ValidationErrors.english_required"))),
      positions: yup
        .string()
        .required(String(L.translate("ValidationErrors.positions_required"))),
      cv: yup
        .boolean()
        .required(String(L.translate("ValidationErrors.cv_required"))),
      main_photo: yup
        .boolean()
        .required(String(L.translate("ValidationErrors.main_photo_required"))),
      photo2: yup
        .boolean()
        .required(String(L.translate("ValidationErrors.photo_required"))),
      photo3: yup
        .boolean()
        .required(String(L.translate("ValidationErrors.photo_required"))),
      photo4: yup
        .boolean()
        .required(String(L.translate("ValidationErrors.photo_required"))),
      photo5: yup
        .boolean()
        .required(String(L.translate("ValidationErrors.photo_required"))),
    })
    .required();
  type FormData = yup.InferType<typeof schema>;
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    watch,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: getCurrentUser().first_name ?? "",
      last_name: getCurrentUser().last_name ?? "",
      email: getCurrentUser().email ?? "",
      confirm_email: getCurrentUser().email ?? "",
      foreign_name: "none",
      vat_number: "none",
      home_address: "none",
      residence_address: "none",
    },
  });

  const first_name = watch("first_name");
  const last_name = watch("last_name");
  const sex = watch("sex");
  const dob_date = watch("date_of_birth");
  const originalDate = new Date(dob_date);

  // Convert the date to "DD-MM-YYYY" format
  const dob = `${String(originalDate.getDate()).padStart(2, '0')}-${String(originalDate.getMonth() + 1).padStart(2, '0')}-${originalDate.getFullYear()}`;

  const placeBirth = watch("birth_place");

  const live_aboard = watch("live_aboard");
  const home_address = watch("home_address");
  const residence_domicile = watch("residence_domicile");
  const have_vat_number = watch("have_vat_number");
  const vat_number = watch("vat_number");
  const foreign_name = watch("foreign_name");
  const residence_address = watch("residence_address");

  const main_photo_loaded = watch("main_photo");
  const photo2_loaded = watch("photo2");
  const photo3_loaded = watch("photo3");
  const photo4_loaded = watch("photo4");
  const photo5_loaded = watch("photo5");
  const cv_loaded = watch("cv");

  const isMounted = useIsMounted();

  const {
    setAlertText,
    setAlertTitle,
    setIsAlertOpened,
    setCollaboratorProfileFilled,
    language,
    geoPosition,
  } = useContext(GlobalContext);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  const calculateTaxID = () => {
    const strTaxID = calcolaCodiceFiscale(
      first_name.replace(/ /g, "").toUpperCase(),
      last_name.replace(/ /g, "").toUpperCase(),
      sex == 0 ? "M" : "F",
      dob,
      placeBirth.trim().toUpperCase()
    );

    setValue("tax_id_code", strTaxID, { shouldValidate: true });
  };

  useEffect(() => {
    if (!isMounted) return;

    if (live_aboard == 1) {
      setValue("foreign_name", "none", {
        shouldValidate: true,
      });
    } else {
      (foreign_name.length == 0 || foreign_name == "none") &&
        setValue("foreign_name", "", {
          shouldValidate: true,
        });
    }
  }, [isMounted, live_aboard]);

  useEffect(() => {
    if (!isMounted) return;

    if (have_vat_number == 1) {
      setValue("vat_number", "none", {
        shouldValidate: true,
      });
    } else {
      (vat_number.length == 0 || vat_number == "none") &&
        setValue("vat_number", "", {
          shouldValidate: true,
        });
    }
  }, [isMounted, have_vat_number]);

  useEffect(() => {
    if (!isMounted) return;

    if (residence_domicile == 0) {
      setValue("residence_address", home_address ?? "", {
        shouldValidate: true,
      });
    } else {
      if (residence_address) {
        (residence_address.length == 0 || residence_address == "none") &&
          setValue("residence_address", "", {
            shouldValidate: true,
          });
      } else {
        setValue("residence_address", "", {
          shouldValidate: true,
        });
      }
    }
  }, [isMounted, residence_domicile]);

  useEffect(() => {
    if (!isMounted) return;

    getInfo();
    getCollaboratorProfile(getCurrentUser().id);
  }, [isMounted]);

  useEffect(() => {
    const width = phoneRef.current ? phoneRef.current.offsetWidth : 0;
    setPhoneDivWidth(width);
  }, [phoneRef.current]);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
  }

  const { mutate: getInfo, isLoading } = useMutation(client.users.me, {
    onSuccess: (data) => {
      setCurrentUser(data.user, getCurrentUser().password);
    },
  });

  const { mutate: getCollaboratorProfile, isLoading: isGettingProfile } =
    useMutation(client.collaborators.getProfile, {
      onSuccess: (data) => {
        setCollaboratorProfileFilled(true);
        setCollaboratorProfileFilledFlag(true);

        setProfileInfo(data.collaborator);

        setOriginalVideoFileName1(data.collaborator.info.video1 ?? "");
        setOriginalVideoFileName2(data.collaborator.info.video2 ?? "");

        data.collaborator.info.second_name &&
          setSecondName(data.collaborator.info.second_name);
        data.collaborator.info.french &&
          setFrench(data.collaborator.info.french);
        data.collaborator.info.german &&
          setGerman(data.collaborator.info.german);
        data.collaborator.info.spanish &&
          setSpanish(data.collaborator.info.spanish);
        data.collaborator.info.otherLang &&
          setOtherLang(data.collaborator.info.otherLang);
        data.collaborator.info.provinces &&
          setProvinces(data.collaborator.info.provinces);

        setValue("sex", data.collaborator.info.sex, { shouldValidate: true });
        setValue("live_aboard", data.collaborator.info.live_aboard, {
          shouldValidate: true,
        });
        setValue(
          "foreign_name",
          data.collaborator.info.foreign_name ?? "none",
          {
            shouldValidate: true,
          }
        );
        setValue("email", data.collaborator.info.email, {
          shouldValidate: true,
        });
        setValue("confirm_email", data.collaborator.info.confirm_email, {
          shouldValidate: true,
        });
        setValue("instagram", data.collaborator.info.instagram, {
          shouldValidate: true,
        });
        setValue(
          "tax_residence_outside_italiy",
          data.collaborator.info.tax_residence_outside_italiy,
          {
            shouldValidate: true,
          }
        );
        setValue("tax_id_code", data.collaborator.info.tax_id_code, {
          shouldValidate: true,
        });
        setValue("telephone_number", data.collaborator.info.telephone_number, {
          shouldValidate: true,
        });
        setValue(
          "id_passport_number",
          data.collaborator.info.id_passport_number ?? "",
          {
            shouldValidate: true,
          }
        );
        setValue("born_aboard", data.collaborator.info.born_aboard, {
          shouldValidate: true,
        });
        setValue("birth_place", data.collaborator.info.birth_place, {
          shouldValidate: true,
        });
        setValue("birth_county", data.collaborator.info.birth_county, {
          shouldValidate: true,
        });
        setValue("date_of_birth", data.collaborator.info.date_of_birth, {
          shouldValidate: true,
        });
        setValue("home_address", data.collaborator.info.home_address, {
          shouldValidate: true,
        });
        setValue(
          "residence_domicile",
          data.collaborator.info.residence_domicile,
          {
            shouldValidate: true,
          }
        );
        setValue(
          "residence_address",
          data.collaborator.info.residence_address,
          {
            shouldValidate: true,
          }
        );
        setValue("have_vat_number", data.collaborator.info.have_vat_number, {
          shouldValidate: true,
        });
        setValue("vat_number", data.collaborator.info.vat_number, {
          shouldValidate: true,
        });
        setValue("IBAN", data.collaborator.info.IBAN, {
          shouldValidate: true,
        });
        setValue(
          "current_account_holder",
          data.collaborator.info.current_account_holder,
          {
            shouldValidate: true,
          }
        );
        setValue("height", data.collaborator.info.height, {
          shouldValidate: true,
        });
        setValue("italian_size", data.collaborator.info.italian_size, {
          shouldValidate: true,
        });
        setValue("shoe_size", data.collaborator.info.shoe_size, {
          shouldValidate: true,
        });
        setValue("hair_color", data.collaborator.info.hair_color, {
          shouldValidate: true,
        });
        setValue("eye_color", data.collaborator.info.eye_color ?? -1, {
          shouldValidate: true,
        });
        setValue("have_tatoo", data.collaborator.info.have_tatoo ?? -1, {
          shouldValidate: true,
        });
        setValue("have_piercing", data.collaborator.info.have_piercing ?? -1, {
          shouldValidate: true,
        });
        setValue(
          "have_driving_license",
          data.collaborator.info.have_driving_license,
          {
            shouldValidate: true,
          }
        );
        setValue("have_car", data.collaborator.info.have_car, {
          shouldValidate: true,
        });
        setValue(
          "have_HACCP_certificate",
          data.collaborator.info.have_HACCP_certificate,
          {
            shouldValidate: true,
          }
        );
        setValue("wear_eyeglasses", data.collaborator.info.wear_eyeglasses, {
          shouldValidate: true,
        });
        setValue("italian", data.collaborator.info.italian, {
          shouldValidate: true,
        });
        setValue("english", data.collaborator.info.english, {
          shouldValidate: true,
        });
        setValue("positions", data.collaborator.info.positions, {
          shouldValidate: true,
        });

        setValue("cv", true, { shouldValidate: true });
        setValue("main_photo", true, { shouldValidate: true });
        setValue("photo2", true, { shouldValidate: true });
        setValue("photo3", true, { shouldValidate: true });
        setValue("photo4", true, { shouldValidate: true });
        setValue("photo5", true, { shouldValidate: true });
      },
      onError: (error: any) => {
        setCollaboratorProfileFilled(false);
        setCollaboratorProfileFilledFlag(false);
      },
    });

  const onAddVideo1 = () => {
    videoRef1.current?.click();
  };
  const handleVideoChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles?.length == 0) {
      return;
    }

    setVideoFile1(selectedFiles![0]);
    setVideoPreview1(selectedFiles![0].name);
  };

  const onDeleteVideo1 = () => {
    setVideoFile1(null);
    setVideoPreview1(null);
    if (videoRef1.current) {
      videoRef1.current.value = "";
    }
  };

  const onAddVideo2 = () => {
    videoRef2.current?.click();
  };
  const handleVideoChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles?.length == 0) {
      return;
    }

    setVideoFile2(selectedFiles![0]);
    setVideoPreview2(selectedFiles![0].name);
  };

  const onDeleteVideo2 = () => {
    setVideoFile2(null);
    setVideoPreview2(null);
    if (videoRef2.current) {
      videoRef2.current.value = "";
    }
  };

  const onAddCV = () => {
    cvRef.current?.click();
  };
  const handleCVChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles?.length == 0) {
      return;
    }

    setCVFile(selectedFiles![0]);
    setCVPreview(selectedFiles![0].name);
    setValue("cv", true, { shouldValidate: true });
  };

  const onDeleteCV = () => {
    setCVFile(null);
    setCVPreview(null);
    setValue("cv", false, { shouldValidate: true });
    if (cvRef.current) {
      cvRef.current.value = "";
    }
  };

  const onAddMainPhoto = () => {
    mainPhotoRef.current?.click();
  };
  const handleMainPhotoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFiles = event.target.files;
    if (selectedFiles?.length == 0) {
      return;
    }

    setMainPhotoFile(selectedFiles![0]);

    const url = URL.createObjectURL(selectedFiles![0]);
    setMainPhotoPreview(url);

    setValue("main_photo", true, { shouldValidate: true });
  };

  const onAddPhoto2 = () => {
    photoRef2.current?.click();
  };
  const handlePhotoChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles?.length == 0) {
      return;
    }

    setPhotoFile2(selectedFiles![0]);

    const url = URL.createObjectURL(selectedFiles![0]);
    setPhotoPreview2(url);

    setValue("photo2", true, { shouldValidate: true });
  };

  const onAddPhoto3 = () => {
    photoRef3.current?.click();
  };
  const handlePhotoChange3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles?.length == 0) {
      return;
    }

    setPhotoFile3(selectedFiles![0]);

    const url = URL.createObjectURL(selectedFiles![0]);
    setPhotoPreview3(url);

    setValue("photo3", true, { shouldValidate: true });
  };

  const onAddPhoto4 = () => {
    photoRef4.current?.click();
  };
  const handlePhotoChange4 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles?.length == 0) {
      return;
    }

    setPhotoFile4(selectedFiles![0]);

    const url = URL.createObjectURL(selectedFiles![0]);
    setPhotoPreview4(url);

    setValue("photo4", true, { shouldValidate: true });
  };

  const onAddPhoto5 = () => {
    photoRef5.current?.click();
  };
  const handlePhotoChange5 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles?.length == 0) {
      return;
    }

    setPhotoFile5(selectedFiles![0]);

    const url = URL.createObjectURL(selectedFiles![0]);
    setPhotoPreview5(url);

    setValue("photo5", true, { shouldValidate: true });
  };

  const onDeletePhoto6 = () => {
    setPhotoFile6(null);
    setPhotoPreview6(null);
  };
  const onAddPhoto6 = () => {
    photoRef6.current?.click();
  };
  const handlePhotoChange6 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles?.length == 0) {
      return;
    }

    setPhotoFile6(selectedFiles![0]);

    const url = URL.createObjectURL(selectedFiles![0]);
    setPhotoPreview6(url);
  };

  const onDeletePhoto7 = () => {
    setPhotoFile7(null);
    setPhotoPreview7(null);
  };
  const onAddPhoto7 = () => {
    photoRef7.current?.click();
  };
  const handlePhotoChange7 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles?.length == 0) {
      return;
    }

    setPhotoFile7(selectedFiles![0]);

    const url = URL.createObjectURL(selectedFiles![0]);
    setPhotoPreview7(url);
  };

  const onDeletePhoto8 = () => {
    setPhotoFile8(null);
    setPhotoPreview8(null);
  };
  const onAddPhoto8 = () => {
    photoRef8.current?.click();
  };
  const handlePhotoChange8 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles?.length == 0) {
      return;
    }

    setPhotoFile8(selectedFiles![0]);

    const url = URL.createObjectURL(selectedFiles![0]);
    setPhotoPreview8(url);
  };

  const onDeletePhoto9 = () => {
    setPhotoFile9(null);
    setPhotoPreview9(null);
  };
  const onAddPhoto9 = () => {
    photoRef9.current?.click();
  };
  const handlePhotoChange9 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles?.length == 0) {
      return;
    }

    setPhotoFile9(selectedFiles![0]);

    const url = URL.createObjectURL(selectedFiles![0]);
    setPhotoPreview9(url);
  };

  const { mutate: updateProfile, isLoading: isUpdatingProfile } = useMutation(
    client.collaborators.updateCurrentProfile,
    {
      onSuccess: (data) => {
        setAlertTitle("Alert.sucess");
        setAlertText("Success.updated_profile");
        setIsAlertOpened(true);

        setCurrentUser(data.user, getCurrentUser().password);
        setProfileInfo(data.collaborator);
        setCollaboratorProfileFilled(true);
        setCollaboratorProfileFilledFlag(true);

        getCollaboratorProfile(getCurrentUser().id);
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            if (
              error.response.data &&
              error.response.data.message === "same_email_exists"
            ) {
              setAlertText(String(L.translate("Errors.email_taken")));
            } else {
              setAlertText(
                getErrorMessage(
                  error.response.data.message ??
                    String(L.translate("Errors.failed_update_profile"))
                )
              );
            }
          } else {
            setAlertText(String(L.translate("Errors.failed_update_profile")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onUpdateInfo = (data: FormData) => {
    if (!privacyChecked) {
      shakeElement(shakeRef);
      return;
    }

    const finalData = {
      ...data,
      ...(second_name.length > 0 && { second_name: second_name }),
      ...(french && { french: french }),
      ...(german && { german: german }),
      ...(spanish && { spanish: spanish }),
      ...(otherLang.length > 0 && { otherLang: otherLang }),
      ...(provinces.length > 0 && { provinces: provinces }),
    };

    const requestData = {
      user_id: getCurrentUser().id,
      email: data.email,
      info: JSON.stringify(finalData),
      cv: cvFile,
      cv_extension: cvFile ? cvFile?.name.split(".").at(-1) ?? "" : "",
      main_photo: mainPhotoFile,
      photo2: photoFile2,
      photo3: photoFile3,
      photo4: photoFile4,
      photo5: photoFile5,
      photo6: photoFile6,
      photo7: photoFile7,
      photo8: photoFile8,
      photo9: photoFile9,
      original_video_filename1: originalVideoFileName1,
      video1: videoFile1,
      video_extension1: videoFile1
        ? videoFile1?.name.split(".").at(-1) ?? ""
        : "",
      original_video_filename2: originalVideoFileName2,
      video2: videoFile2,
      video_extension2: videoFile2
        ? videoFile2?.name.split(".").at(-1) ?? ""
        : "",
    };

    updateProfile(requestData);
  };

  const makePhoneInput = () => {
    return (
      <div className="flex flex-row justify-between gap-4">
        <div className="flex flex-col w-full" ref={phoneRef}>
          <span className="block cursor-pointer pb-2 font-normal text-secondary font-gilroy text-[14px]">
            <span className="text-[14px] text-error font-gilroy">* </span>
            {String(L.translate("CollaboratorProfile.telephone_number"))}
          </span>

          <Controller
            name="telephone_number"
            control={control}
            render={({ field }) => (
              <ReactPhone
                dropdownStyle={{ width: phoneDivWidth }}
                inputClass={classNames(
                  "!w-full !border h-[44px] !bg-gray-modern-100 !border-gray-modern-100 !ring-[0.5px] !ring-light-500 !focus:ring-[0.5px] !rounded-[8px] text-[14px] font-gilroy",
                  errors.telephone_number
                    ? "focus:!border-error focus:!ring-error"
                    : "focus:!border-active focus:!ring-active"
                )}
                onlyCountries={["it"]}
                country="it"
                {...field}
              />
            )}
          />

          {errors.telephone_number?.message && (
            <span
              role="alert"
              className="block pt-2 text-[12px] text-error font-gilroy"
            >
              {errors.telephone_number?.message}
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="w-full h-full bg-whole-gray relative ">
      <div className="bg-white p-4 rounded-[8px]">
        <div className="text-primary text-[24px] font-gilroy-semibold">
          {L.translate("CommonForm.edit_profile")}
          <div className="flex flex-col items-center justify-center gap-4 w-full">
            <label className="mt-4 text-[14px] font-gilroy font-medium text-secondary">
              {L.translate("CommonForm.profile_picture")}
            </label>
            <div className="flex items-center justify-center relative border-2 border-active rounded-full">
              <Zoom classDialog="custom-zoom">
                <ImageWithLoading
                  isRoundedFull={true}
                  src={
                    mainPhotoPreview
                      ? mainPhotoPreview
                      : profileInfo?.main_photo
                      ? profileInfo.main_photo
                      : UserPlaceholder
                  }
                  className="w-40 h-40 object-cover cursor-pointer rounded-full"
                />
              </Zoom>
              <input
                ref={mainPhotoRef}
                className="hidden"
                type="file"
                onChange={handleMainPhotoChange}
                accept="image/png, image/jpeg"
              />
              <Button
                variant="icon"
                className="w-[32px] h-[32px] bg-active hover:bg-main rounded-full absolute top-[8px] right-[8px]"
                onClick={onAddMainPhoto}
              >
                <PencilIcon className="w-4 h-4 text-white" />
              </Button>
            </div>
            {!main_photo_loaded && (
              <span
                role="alert"
                className="block text-[12px] text-error font-gilroy"
              >
                {`* ${L.translate("ValidationErrors.field_required")}`}
              </span>
            )}

            <div className="w-full flex flex-col items-start justify-between gap-2 sm:gap-4">
              <div className="flex flex-col sm:flex-row items-center justify-between gap-4 w-full mt-4">
                <Controller
                  name="first_name"
                  control={control}
                  render={({ field }) => (
                    <InputName
                      label={String(L.translate("CommonForm.first_name"))}
                      required
                      className="w-full"
                      onlyAlphaAvailable
                      {...register("first_name")}
                      error={
                        errors.first_name &&
                        (errors.first_name.message as string)
                      }
                      {...field}
                    />
                  )}
                />
                <InputName
                  label={String(L.translate("CommonForm.second_name"))}
                  className="w-full"
                  value={second_name}
                  onlyAlphaAvailable
                  onChange={(e: string) => setSecondName(e)}
                />
                <Controller
                  name="last_name"
                  control={control}
                  render={({ field }) => (
                    <InputName
                      label={String(L.translate("CommonForm.surname"))}
                      required
                      onlyAlphaAvailable
                      className="w-full"
                      {...register("last_name")}
                      error={
                        errors.last_name && (errors.last_name.message as string)
                      }
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col sm:flex-row items-start justify-center gap-4 sm:gap-16 mt-4">
                <Controller
                  name="sex"
                  control={control}
                  render={({ field }) => (
                    <CheckboxOptions
                      label={String(L.translate("CollaboratorProfile.sex"))}
                      required
                      options={[
                        String(L.translate("Base.male")),
                        String(L.translate("Base.female")),
                      ]}
                      error={errors.sex && (errors.sex.message as string)}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="live_aboard"
                  control={control}
                  render={({ field }) => (
                    <CheckboxOptions
                      label={String(
                        L.translate("CollaboratorProfile.live_aboard")
                      )}
                      required
                      options={[
                        String(L.translate("Base.yes")),
                        String(L.translate("Base.no")),
                      ]}
                      error={
                        errors.live_aboard &&
                        (errors.live_aboard.message as string)
                      }
                      {...field}
                    />
                  )}
                />
                {live_aboard == 0 && (
                  <InputWithoutLabel
                    label={String(
                      L.translate("CollaboratorProfile.foreign_name")
                    )}
                    required
                    {...register("foreign_name")}
                    error={
                      errors.foreign_name &&
                      (errors.foreign_name.message as string)
                    }
                  />
                )}
              </div>
              <div className="flex flex-col sm:flex-row items-start justify-center gap-4 mt-4 w-full">
                <InputWithoutLabel
                  label={String(L.translate("CommonForm.email"))}
                  required
                  className="w-full"
                  showInfo
                  info={String(
                    L.translate(
                      "CollaboratorProfile.receive_employment_contract"
                    )
                  )}
                  {...register("email")}
                  error={errors.email && (errors.email.message as string)}
                />
                <InputWithoutLabel
                  label={String(L.translate("CommonForm.confirm_email"))}
                  required
                  className="w-full"
                  {...register("confirm_email")}
                  error={
                    errors.confirm_email &&
                    (errors.confirm_email.message as string)
                  }
                />
                <InputWithoutLabel
                  label={String(L.translate("CollaboratorProfile.instagram"))}
                  required
                  className="w-full"
                  showInfo
                  info={String(
                    L.translate("CollaboratorProfile.write_username")
                  )}
                  {...register("instagram")}
                  error={
                    errors.instagram && (errors.instagram.message as string)
                  }
                />
              </div>
              <div className="flex flex-col sm:flex-row items-start justify-center gap-4 sm:gap-16 mt-4">
                <Controller
                  name="tax_residence_outside_italiy"
                  control={control}
                  render={({ field }) => (
                    <CheckboxOptions
                      label={String(
                        L.translate(
                          "CollaboratorProfile.tax_residence_outside_italiy"
                        )
                      )}
                      required
                      options={[
                        String(L.translate("Base.yes")),
                        String(L.translate("Base.no")),
                      ]}
                      error={
                        errors.tax_residence_outside_italiy &&
                        (errors.tax_residence_outside_italiy.message as string)
                      }
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col md:flex-row items-start gap-4 md:gap-8 mt-4 w-full">
                {/*makePhoneInput()*/}
                <Controller
                  name="telephone_number"
                  control={control}
                  render={({ field }) => (
                    <InputPhoneNumber
                      label={String(
                        L.translate("CollaboratorProfile.telephone_number")
                      )}
                      required
                      className="w-auto"
                      error={
                        errors.telephone_number &&
                        (errors.telephone_number.message as string)
                      }
                      {...field}
                    />
                  )}
                />
                <InputWithoutLabel
                  label={String(
                    L.translate("CollaboratorProfile.id_passport_number")
                  )}
                  required
                  className="w-auto"
                  {...register("id_passport_number")}
                  error={
                    errors.id_passport_number &&
                    (errors.id_passport_number.message as string)
                  }
                />
              </div>
              <div className="flex flex-col md:flex-row items-start gap-4 mt-4">
                <Controller
                  name="born_aboard"
                  control={control}
                  render={({ field }) => (
                    <CheckboxOptions
                      label={String(
                        L.translate("CollaboratorProfile.born_aboard")
                      )}
                      required
                      options={[
                        String(L.translate("Base.yes")),
                        String(L.translate("Base.no")),
                      ]}
                      error={
                        errors.born_aboard &&
                        (errors.born_aboard.message as string)
                      }
                      {...field}
                    />
                  )}
                />
                <InputWithoutLabel
                  label={String(L.translate("CollaboratorProfile.birth_place"))}
                  required
                  {...register("birth_place")}
                  error={
                    errors.birth_place && (errors.birth_place.message as string)
                  }
                />
                <InputWithoutLabel
                  label={String(
                    L.translate("CollaboratorProfile.birth_county")
                  )}
                  required
                  {...register("birth_county")}
                  error={
                    errors.birth_county &&
                    (errors.birth_county.message as string)
                  }
                />
                <Controller
                  name="date_of_birth"
                  control={control}
                  render={({ field }) => (
                    <TailwindDatePicker
                      label={String(
                        L.translate("CollaboratorProfile.date_of_birth")
                      )}
                      required
                      displayFormat="DD/MM/YYYY"
                      placeholder="DD/MM/YYYY"
                      className="z-30"
                      error={
                        errors.date_of_birth &&
                        (errors.date_of_birth.message as string)
                      }
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="flex flex-row items-start gap-4 mt-4 w-full">
                <InputWithoutLabel
                  label={String(L.translate("CollaboratorProfile.tax_id_code"))}
                  required={watch('tax_residence_outside_italiy') === 1}
                  className="w-full sm:w-[320px]"
                  allCaps
                  autoComplete= "new-password" 
                  {...register("tax_id_code")}
                  error={
                    errors.tax_id_code && (errors.tax_id_code.message as string)
                  }
                  showInfo
                  info={String(
                    L.translate("CollaboratorProfile.verify_tax_id")
                  )}
                />
                <Button
                  variant="validate"
                  className="mt-[29px] rounded-[10px] font-gilroy text-[14px] font-bold leading-[24px] h-[44px]"
                  onClick={calculateTaxID}
                  disabled={
                    !(
                      (first_name ?? "").length > 0 &&
                      (last_name ?? "").length > 0 &&
                      (sex ?? -1) >= 0 &&
                      (dob ?? "") &&
                      (placeBirth ?? "").length > 0
                    )
                  }
                >
                  {L.translate("CollaboratorProfile.calculate_tax")}
                </Button>
              </div>
              <div className="flex flex-col md:flex-row items-start gap-8 md:gap-4 mt-4 w-full">
                <div className="w-full md:mt-[82px]">
                  <Controller
                    name="home_address"
                    control={control}
                    render={({ field }) => (
                      <AddressInput
                        label={String(
                          L.translate("CollaboratorProfile.home_address")
                        )}
                        className="w-full"
                        required
                        error={
                          errors.home_address &&
                          (errors.home_address.message as string)
                        }
                        autoComplete= "new-password" //dubbio
                        {...field}
                      />
                    )}
                  />
              </div>
              </div>
                <div className="flex flex-col w-full gap-4 md:gap-8">
                  <Controller
                    name="residence_domicile"
                    control={control}
                    render={({ field }) => (
                      <CheckboxOptions
                        label={String(
                          L.translate("CollaboratorProfile.residence_domicile")
                        )}
                        required
                        className={
                          home_address
                            ? "pointer-events-auto"
                            : "pointer-events-none"
                        }
                        options={[
                          String(L.translate("Base.yes")),
                          String(L.translate("Base.no")),
                        ]}
                        error={
                          errors.residence_domicile &&
                          (errors.residence_domicile.message as string)
                        }
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="residence_address"
                    control={control}
                    render={({ field }) => (
                      <AddressInput
                        label={String(
                          L.translate("CollaboratorProfile.residence_address")
                        )}
                        className={`w-full ${
                          residence_domicile == 0 && "pointer-events-none"
                        }`}
                        required
                        error={
                          errors.residence_address &&
                          (errors.residence_address.message as string)
                        } 
                        autoComplete= "new-password" //dubbio
                        {...field}
                      />
                    )}
                  />
                </div>
              <div className="flex flex-col md:flex-row items-start gap-4 md:gap-16 mt-4">
                <Controller
                  name="have_vat_number"
                  control={control}
                  render={({ field }) => (
                    <CheckboxOptions
                      label={String(
                        L.translate("CollaboratorProfile.have_vat_number")
                      )}
                      required
                      options={[
                        String(L.translate("Base.yes")),
                        String(L.translate("Base.no")),
                      ]}
                      error={
                        errors.have_vat_number &&
                        (errors.have_vat_number.message as string)
                      }
                      {...field}
                    />
                  )}
                />
                {have_vat_number == 0 && (
                  <InputWithoutLabel
                    label={String(L.translate("CommonForm.vat_number"))}
                    required
                    {...register("vat_number")}
                    error={
                      errors.vat_number && (errors.vat_number.message as string)
                    }
                  />
                )}
              </div>
              <div className="flex flex-col sm:flex-row items-start justify-center gap-4 mt-4 w-full">
                <Controller
                  name="IBAN"
                  control={control}
                  render={({ field }) => (
                    <InputNoSpaceWithoutLabel
                      label={String(L.translate("CollaboratorProfile.IBAN"))}
                      required
                      className="w-full"
                      showInfo
                      allCaps
                      info={String(
                        L.translate(
                          "CollaboratorProfile.without_spaces_all_caps"
                        )
                      )}
                      {...register("IBAN")}
                      error={errors.IBAN && (errors.IBAN.message as string)}
                      {...field}
                    />
                  )}
                />
                <InputWithoutLabel
                  label={String(
                    L.translate("CollaboratorProfile.current_account_holder")
                  )}
                  required
                  className="w-full"
                  {...register("current_account_holder")}
                  error={
                    errors.current_account_holder &&
                    (errors.current_account_holder.message as string)
                  }
                />
              </div>
              <div className="flex flex-col md:flex-row items-start justify-center gap-4 mt-4 w-full">
                <InputWithoutLabel
                  label={String(L.translate("CollaboratorProfile.height"))}
                  placeholder={String(
                    L.translate("CollaboratorProfile.ex_172")
                  )}
                  required
                  type="text"
                  min={0}
                  max={220}
                  step={1}
                  className="w-full"
                  showInfo
                  info={String(L.translate("CollaboratorProfile.real"))}
                  {...register("height")}
                  error={errors.height && (errors.height.message as string)}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <Controller
                  name="italian_size"
                  control={control}
                  render={({ field }) => (
                    <DropdownWithoutLabel
                      className="w-full"
                      label={String(
                        L.translate("CollaboratorProfile.italian_size")
                      )}
                      required
                      showInfo
                      info={String(L.translate("CollaboratorProfile.real"))}
                      data={ShoeSizes}
                      error={
                        errors.italian_size &&
                        (errors.italian_size.message as string)
                      }
                      {...field}
                    />
                  )}
                />
                <InputWithoutLabel
                  label={String(L.translate("CollaboratorProfile.shoe_size"))}
                  placeholder={String(L.translate("CollaboratorProfile.ex_38"))}
                  required
                  type="number"
                  className="w-full"
                  {...register("shoe_size")}
                  error={
                    errors.shoe_size && (errors.shoe_size.message as string)
                  }
                />
                <Controller
                  name="hair_color"
                  control={control}
                  render={({ field }) => (
                    <DropdownWithoutLabel
                      className="w-full"
                      label={String(
                        L.translate("CollaboratorProfile.hair_color")
                      )}
                      required
                      data={language == "EN" ? HairColors : HairColors_IT}
                      error={
                        errors.hair_color &&
                        (errors.hair_color.message as string)
                      }
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="eye_color"
                  control={control}
                  render={({ field }) => (
                    <DropdownWithoutLabel
                      className="w-full"
                      label={String(
                        L.translate("CollaboratorProfile.eye_color")
                      )}
                      required
                      data={language == "EN" ? EyeColors : EyeColors_IT}
                      error={
                        errors.eye_color && (errors.eye_color.message as string)
                      }
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col sm:flex-row items-start gap-4 md:gap-16 mt-4">
                <Controller
                  name="have_tatoo"
                  control={control}
                  render={({ field }) => (
                    <CheckboxOptions
                      label={String(L.translate("CollaboratorProfile.tatoos"))}
                      required
                      options={[
                        String(L.translate("Base.yes")),
                        String(L.translate("Base.no")),
                      ]}
                      error={
                        errors.have_tatoo &&
                        (errors.have_tatoo.message as string)
                      }
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="have_piercing"
                  control={control}
                  render={({ field }) => (
                    <CheckboxOptions
                      label={String(
                        L.translate("CollaboratorProfile.piercings")
                      )}
                      required
                      options={[
                        String(L.translate("Base.yes")),
                        String(L.translate("Base.no")),
                      ]}
                      error={
                        errors.have_piercing &&
                        (errors.have_piercing.message as string)
                      }
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col md:flex-row items-start gap-4 md:gap-16 mt-4">
                <div className="flex flex-col items-start gap-4 sm:gap-8">
                  <Controller
                    name="have_driving_license"
                    control={control}
                    render={({ field }) => (
                      <CheckboxOptions
                        label={String(
                          L.translate(
                            "CollaboratorProfile.have_driving_license"
                          )
                        )}
                        required
                        options={[
                          String(L.translate("Base.yes")),
                          String(L.translate("Base.no")),
                        ]}
                        error={
                          errors.have_driving_license &&
                          (errors.have_driving_license.message as string)
                        }
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="have_HACCP_certificate"
                    control={control}
                    render={({ field }) => (
                      <CheckboxOptions
                        label={String(
                          L.translate(
                            "CollaboratorProfile.have_HACCP_certificate"
                          )
                        )}
                        required
                        options={[
                          String(L.translate("Base.yes")),
                          String(L.translate("Base.no")),
                        ]}
                        error={
                          errors.have_HACCP_certificate &&
                          (errors.have_HACCP_certificate.message as string)
                        }
                        {...field}
                      />
                    )}
                  />
                </div>
                <div className="flex flex-col items-start gap-4 sm:gap-8">
                  <Controller
                    name="have_car"
                    control={control}
                    render={({ field }) => (
                      <CheckboxOptions
                        label={String(
                          L.translate("CollaboratorProfile.have_car")
                        )}
                        required
                        options={[
                          String(L.translate("Base.yes")),
                          String(L.translate("Base.no")),
                        ]}
                        error={
                          errors.have_car && (errors.have_car.message as string)
                        }
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="wear_eyeglasses"
                    control={control}
                    render={({ field }) => (
                      <CheckboxOptions
                        label={String(
                          L.translate("CollaboratorProfile.wear_eyeglasses")
                        )}
                        required
                        options={[
                          String(L.translate("Base.yes")),
                          String(L.translate("Base.no")),
                        ]}
                        error={
                          errors.wear_eyeglasses &&
                          (errors.wear_eyeglasses.message as string)
                        }
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
              <span className="text-primary text-[16px] font-gilroy-bold mt-8">
                {`${L.translate("CollaboratorProfile.known_languages")}:`}
              </span>
              <div className="flex flex-col sm:flex-row items-start gap-4 mt-4 w-full">
                <Controller
                  name="italian"
                  control={control}
                  render={({ field }) => (
                    <DropdownWithoutLabel
                      className="w-full"
                      label={String(L.translate("CollaboratorProfile.italian"))}
                      required
                      data={
                        language == "EN" ? LanguageLevels : LanguageLevels_IT
                      }
                      error={
                        errors.italian && (errors.italian.message as string)
                      }
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="english"
                  control={control}
                  render={({ field }) => (
                    <DropdownWithoutLabel
                      className="w-full"
                      label={String(L.translate("CollaboratorProfile.english"))}
                      required
                      data={
                        language == "EN" ? LanguageLevels : LanguageLevels_IT
                      }
                      error={
                        errors.english && (errors.english.message as string)
                      }
                      {...field}
                    />
                  )}
                />
                <DropdownWithoutLabel
                  className="w-full"
                  label={String(L.translate("CollaboratorProfile.french"))}
                  data={language == "EN" ? LanguageLevels : LanguageLevels_IT}
                  value={french}
                  onChange={(selVal: number) => setFrench(selVal)}
                />
              </div>
              <div className="flex flex-col sm:flex-row items-start gap-4 mt-4 w-full">
                <DropdownWithoutLabel
                  className="w-full"
                  label={String(L.translate("CollaboratorProfile.german"))}
                  data={language == "EN" ? LanguageLevels : LanguageLevels_IT}
                  value={german}
                  onChange={(selVal: number) => setGerman(selVal)}
                />
                <DropdownWithoutLabel
                  className="w-full"
                  label={String(L.translate("CollaboratorProfile.spanish"))}
                  data={language == "EN" ? LanguageLevels : LanguageLevels_IT}
                  value={spanish}
                  onChange={(selVal: number) => setSpanish(selVal)}
                />
                <InputWithoutLabel
                  label={String(L.translate("CollaboratorProfile.other"))}
                  className="w-full"
                  showInfo
                  value={otherLang}
                  onChange={(e) => setOtherLang(e.target.value)}
                  info={String(
                    L.translate("CollaboratorProfile.specify_language_level")
                  )}
                />
              </div>
              <span className="text-primary text-[16px] font-gilroy-bold mt-8">
                {`${L.translate(
                  "CollaboratorProfile.available_work_province"
                )}:`}
              </span>
              <div className="flex flex-col sm:flex-row items-start gap-4 w-full">
                <SelectInput
                  placeholder=""
                  className="w-full"
                  showInfo
                  maxCount={5}
                  value={provinces}
                  onChange={(selVal: string) => setProvinces(selVal)}
                  info={String(
                    L.translate("CollaboratorProfile.select_max_cities")
                  )}
                  options={Provinces}
                />
              </div>
              <span className="text-primary text-[16px] font-gilroy-bold mt-8">
                {L.translate("CollaboratorProfile.avalable_as")}
                <span className="text-[14px] text-error font-gilroy"> * </span>
              </span>
              <div className="flex flex-col sm:flex-row items-start gap-4 w-full">
                <Controller
                  name="positions"
                  control={control}
                  render={({ field }) => (
                    <PositionSelect
                      className="w-full"
                      options={language == "EN" ? Positions : Positions_IT}
                      error={
                        errors.positions && (errors.positions.message as string)
                      }
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 bg-white p-4 rounded-[8px] mb-4">
        <div className="flex flex-col items-start justify-start gap-4">
          <div className="flex flex-row items-center gap-8">
            <div className="text-primary text-[24px] font-gilroy-semibold">
              {`${L.translate("CollaboratorProfile.video")} 1`}
            </div>
            {profileInfo?.video1 && (
              <a
                href={profileInfo!.video1}
                target="_blank"
                className="underline text-secondary hover:text-active"
              >
                {L.translate("CollaboratorProfile.view_current_one")}
              </a>
            )}
          </div>
          <Button
            variant="text"
            className="h-[44px] bg-active hover:bg-blue-dark-400 text-white rounded-[8px] px-20"
            onClick={onAddVideo1}
          >
            {videoFile1 ? L.translate("CollaboratorProfile.update") : L.translate("CollaboratorProfile.add")}
          </Button>
          {videoFile1 && (
            <label className="text-[14px] text-secondary font-gilroy">
              {`${videoFile1.name} (${formatFileSize(videoFile1.size)})`}
            </label>
          )}
          <input
            ref={videoRef1}
            className="hidden"
            type="file"
            onChange={handleVideoChange1}
            accept="video/mp4, video/x-msvideo, video/x-matroska, video/quicktime, video/x-ms-wmv"
          />
          {videoFile1 && (
            <div className="flex flex-row gap-4 mt-2">
              <Button
                variant="icon"
                className="w-[32px] h-[32px] bg-error hover:bg-red-400 rounded-full"
                onClick={onDeleteVideo1}
              >
                <CrossIcon className="w-4 h-4 text-white" />
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className="mt-4 bg-white p-4 rounded-[8px] mb-4">
        <div className="flex flex-col items-start justify-start gap-4">
          <div className="flex flex-row items-center gap-8">
            <div className="text-primary text-[24px] font-gilroy-semibold">
              {`${L.translate("CollaboratorProfile.video")} 2`}
            </div>
            {profileInfo?.video2 && (
              <a
                href={profileInfo!.video2}
                target="_blank"
                className="underline text-secondary hover:text-active"
              >
                {L.translate("CollaboratorProfile.view_current_one")}
              </a>
            )}
          </div>
          <Button
            variant="text"
            className="h-[44px] bg-active hover:bg-blue-dark-400 text-white rounded-[8px] px-20"
            onClick={onAddVideo2}
          >
            {videoFile2 ? L.translate("CollaboratorProfile.update") : L.translate("CollaboratorProfile.add")}
          </Button>
          {videoFile2 && (
            <label className="text-[14px] text-secondary font-gilroy">
              {`${videoFile2.name} (${formatFileSize(videoFile2.size)})`}
            </label>
          )}
          <input
            ref={videoRef2}
            className="hidden"
            type="file"
            onChange={handleVideoChange2}
            accept="video/mp4, video/x-msvideo, video/x-matroska, video/quicktime, video/x-ms-wmv"
          />
          {videoFile2 && (
            <div className="flex flex-row gap-4 mt-2">
              <Button
                variant="icon"
                className="w-[32px] h-[32px] bg-error hover:bg-red-400 rounded-full"
                onClick={onDeleteVideo2}
              >
                <CrossIcon className="w-4 h-4 text-white" />
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className="mt-4 bg-white p-4 rounded-[8px] mb-4">
        <div className="flex flex-col items-start justify-start gap-4">
          <div className="flex flex-row items-center gap-8">
            <div className="text-primary text-[24px] font-gilroy-semibold">
              {L.translate("CollaboratorProfile.cv")}
            </div>
            {profileInfo?.cv && (
              <a
                href={profileInfo!.cv}
                target="_blank"
                className="underline text-secondary hover:text-active"
              >
                {L.translate("CollaboratorProfile.view_current_one")}
              </a>
            )}
          </div>
          <Button
            variant="text"
            className="h-[44px] bg-active hover:bg-blue-dark-400 text-white rounded-[8px] px-20"
            onClick={onAddCV}
          >
            {cvFile ? L.translate("CollaboratorProfile.update") : L.translate("CollaboratorProfile.add")}
          </Button>
          {cvFile && (
            <label className="text-[14px] text-secondary font-gilroy">
              {`${cvFile.name} (${formatFileSize(cvFile.size)})`}
            </label>
          )}
          <input
            ref={cvRef}
            className="hidden"
            type="file"
            onChange={handleCVChange}
            accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/pdf"
          />
          {!cv_loaded && (
            <span
              role="alert"
              className="block text-[12px] text-error font-gilroy"
            >
              {`* ${L.translate("ValidationErrors.field_required")}`}
            </span>
          )}
          {cvFile && (
            <div className="flex flex-row gap-4 mt-2">
              <Button
                variant="icon"
                className="w-[32px] h-[32px] bg-error hover:bg-red-400 rounded-full"
                onClick={onDeleteCV}
              >
                <CrossIcon className="w-4 h-4 text-white" />
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className="mt-4 bg-white p-4 rounded-[8px] mb-4">
        <div className="flex flex-row items-center justify-start gap-4">
          <div className="text-primary text-[24px] font-gilroy-semibold">
            {L.translate("CollaboratorProfile.photos")}
          </div> 
        </div>
        <div>
          <label className="text-[14px] text-error font-gilroy">{L.translate("CollaboratorProfile.info_photos")}</label>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8 mt-4">
          <div className="flex flex-col">
            <div
              key={`photo_2`}
              className="relative flex items-center justify-center aspect-square bg-gray-modern-100 rounded-[8px] border-gray-modern-400 border-2"
            >
              <Zoom classDialog="custom-zoom">
                <ImageWithLoading
                  src={
                    photoPreview2
                      ? photoPreview2
                      : profileInfo?.photo2
                      ? profileInfo.photo2
                      : UserPlaceholder
                  }
                  className="object-contain w-full aspect-square"
                />
              </Zoom>
              <input
                ref={photoRef2}
                className="hidden"
                type="file"
                onChange={handlePhotoChange2}
                accept="image/png, image/jpeg"
              />
              <Button
                variant="icon"
                className="w-[32px] h-[32px] bg-active hover:bg-main rounded-full absolute top-[-16px] right-[-16px]"
                onClick={onAddPhoto2}
              >
                <PencilIcon className="w-4 h-4 text-white" />
              </Button>
            </div>
            {!photo2_loaded && (
              <label
                role="alert"
                className="text-[12px] text-error font-gilroy"
              >
                {`* ${L.translate("ValidationErrors.field_required")}`}
              </label>
            )}
          </div>
          <div className="flex flex-col">
            <div
              key={`photo_3`}
              className="relative flex items-center justify-center aspect-square bg-gray-modern-100 rounded-[8px] border-gray-modern-400 border-2"
            >
              <Zoom classDialog="custom-zoom">
                <ImageWithLoading
                  src={
                    photoPreview3
                      ? photoPreview3
                      : profileInfo?.photo3
                      ? profileInfo.photo3
                      : UserPlaceholder
                  }
                  className="object-contain w-full aspect-square"
                />
              </Zoom>
              <input
                ref={photoRef3}
                className="hidden"
                type="file"
                onChange={handlePhotoChange3}
                accept="image/png, image/jpeg"
              />
              <Button
                variant="icon"
                className="w-[32px] h-[32px] bg-active hover:bg-main rounded-full absolute top-[-16px] right-[-16px]"
                onClick={onAddPhoto3}
              >
                <PencilIcon className="w-4 h-4 text-white" />
              </Button>
            </div>
            {!photo3_loaded && (
              <label
                role="alert"
                className="text-[12px] text-error font-gilroy"
              >
                {`* ${L.translate("ValidationErrors.field_required")}`}
              </label>
            )}
          </div>
          <div className="flex flex-col">
            <div
              key={`photo_4`}
              className="relative flex items-center justify-center aspect-square bg-gray-modern-100 rounded-[8px] border-gray-modern-400 border-2"
            >
              <Zoom classDialog="custom-zoom">
                <ImageWithLoading
                  src={
                    photoPreview4
                      ? photoPreview4
                      : profileInfo?.photo4
                      ? profileInfo.photo4
                      : UserPlaceholder
                  }
                  className="object-contain w-full aspect-square"
                />
              </Zoom>
              <input
                ref={photoRef4}
                className="hidden"
                type="file"
                onChange={handlePhotoChange4}
                accept="image/png, image/jpeg"
              />
              <Button
                variant="icon"
                className="w-[32px] h-[32px] bg-active hover:bg-main rounded-full absolute top-[-16px] right-[-16px]"
                onClick={onAddPhoto4}
              >
                <PencilIcon className="w-4 h-4 text-white" />
              </Button>
            </div>
            {!photo4_loaded && (
              <label
                role="alert"
                className="text-[12px] text-error font-gilroy"
              >
                {`* ${L.translate("ValidationErrors.field_required")}`}
              </label>
            )}
          </div>
          <div className="flex flex-col">
            <div
              key={`photo_5`}
              className="relative flex items-center justify-center aspect-square bg-gray-modern-100 rounded-[8px] border-gray-modern-400 border-2"
            >
              <Zoom classDialog="custom-zoom">
                <ImageWithLoading
                  src={
                    photoPreview5
                      ? photoPreview5
                      : profileInfo?.photo5
                      ? profileInfo.photo5
                      : UserPlaceholder
                  }
                  className="object-contain w-full aspect-square"
                />
              </Zoom>
              <input
                ref={photoRef5}
                className="hidden"
                type="file"
                onChange={handlePhotoChange5}
                accept="image/png, image/jpeg"
              />
              <Button
                variant="icon"
                className="w-[32px] h-[32px] bg-active hover:bg-main rounded-full absolute top-[-16px] right-[-16px]"
                onClick={onAddPhoto5}
              >
                <PencilIcon className="w-4 h-4 text-white" />
              </Button>
            </div>
            {!photo5_loaded && (
              <label
                role="alert"
                className="text-[12px] text-error font-gilroy"
              >
                {`* ${L.translate("ValidationErrors.field_required")}`}
              </label>
            )}
          </div>
          <div
            key={`photo_6`}
            className="relative flex items-center justify-center aspect-square bg-gray-modern-100 rounded-[8px] border-gray-modern-400 border-2"
          >
            <Zoom classDialog="custom-zoom">
              <ImageWithLoading
                src={
                  photoPreview6
                    ? photoPreview6
                    : profileInfo?.photo6
                    ? profileInfo.photo6
                    : UserPlaceholder
                }
                className="object-contain w-full aspect-square"
              />
            </Zoom>
            <input
              ref={photoRef6}
              className="hidden"
              type="file"
              onChange={handlePhotoChange6}
              accept="image/png, image/jpeg"
            />
            <Button
              variant="icon"
              className="w-[32px] h-[32px] bg-active hover:bg-main rounded-full absolute top-[-16px] right-[-16px]"
              onClick={onAddPhoto6}
            >
              <PencilIcon className="w-4 h-4 text-white" />
            </Button>
            <Button
              variant="icon"
              className="w-[32px] h-[32px] bg-error hover:bg-red-400 rounded-full absolute top-[-16px] left-[-16px]"
              onClick={onDeletePhoto6}
            >
              <CrossIcon className="w-4 h-4 text-white" />
            </Button>
          </div>
          <div
            key={`photo_7`}
            className="relative flex items-center justify-center aspect-square bg-gray-modern-100 rounded-[8px] border-gray-modern-400 border-2"
          >
            <Zoom classDialog="custom-zoom">
              <ImageWithLoading
                src={
                  photoPreview7
                    ? photoPreview7
                    : profileInfo?.photo7
                    ? profileInfo.photo7
                    : UserPlaceholder
                }
                className="object-contain w-full aspect-square"
              />
            </Zoom>
            <input
              ref={photoRef7}
              className="hidden"
              type="file"
              onChange={handlePhotoChange7}
              accept="image/png, image/jpeg"
            />
            <Button
              variant="icon"
              className="w-[32px] h-[32px] bg-active hover:bg-main rounded-full absolute top-[-16px] right-[-16px]"
              onClick={onAddPhoto7}
            >
              <PencilIcon className="w-4 h-4 text-white" />
            </Button>
            <Button
              variant="icon"
              className="w-[32px] h-[32px] bg-error hover:bg-red-400 rounded-full absolute top-[-16px] left-[-16px]"
              onClick={onDeletePhoto7}
            >
              <CrossIcon className="w-4 h-4 text-white" />
            </Button>
          </div>
          <div
            key={`photo_8`}
            className="relative flex items-center justify-center aspect-square bg-gray-modern-100 rounded-[8px] border-gray-modern-400 border-2"
          >
            <Zoom classDialog="custom-zoom">
              <ImageWithLoading
                src={
                  photoPreview8
                    ? photoPreview8
                    : profileInfo?.photo8
                    ? profileInfo.photo8
                    : UserPlaceholder
                }
                className="object-contain w-full aspect-square"
              />
            </Zoom>
            <input
              ref={photoRef8}
              className="hidden"
              type="file"
              onChange={handlePhotoChange8}
              accept="image/png, image/jpeg"
            />
            <Button
              variant="icon"
              className="w-[32px] h-[32px] bg-active hover:bg-main rounded-full absolute top-[-16px] right-[-16px]"
              onClick={onAddPhoto8}
            >
              <PencilIcon className="w-4 h-4 text-white" />
            </Button>
            <Button
              variant="icon"
              className="w-[32px] h-[32px] bg-error hover:bg-red-400 rounded-full absolute top-[-16px] left-[-16px]"
              onClick={onDeletePhoto8}
            >
              <CrossIcon className="w-4 h-4 text-white" />
            </Button>
          </div>
          <div
            key={`photo_9`}
            className="relative flex items-center justify-center aspect-square bg-gray-modern-100 rounded-[8px] border-gray-modern-400 border-2"
          >
            <Zoom classDialog="custom-zoom">
              <ImageWithLoading
                src={
                  photoPreview9
                    ? photoPreview9
                    : profileInfo?.photo9
                    ? profileInfo.photo9
                    : UserPlaceholder
                }
                className="object-contain w-full aspect-square"
              />
            </Zoom>
            <input
              ref={photoRef9}
              className="hidden"
              type="file"
              onChange={handlePhotoChange9}
              accept="image/png, image/jpeg"
            />
            <Button
              variant="icon"
              className="w-[32px] h-[32px] bg-active hover:bg-main rounded-full absolute top-[-16px] right-[-16px]"
              onClick={onAddPhoto9}
            >
              <PencilIcon className="w-4 h-4 text-white" />
            </Button>
            <Button
              variant="icon"
              className="w-[32px] h-[32px] bg-error hover:bg-red-400 rounded-full absolute top-[-16px] left-[-16px]"
              onClick={onDeletePhoto9}
            >
              <CrossIcon className="w-4 h-4 text-white" />
            </Button>
          </div>
        </div>
        <div
          className="flex flex-row items-center justify-start gap-2 mt-12"
          onClick={() => setPrivacyChecked(!privacyChecked)}
          ref={shakeRef}
        >
          {privacyChecked ? (
            <CheckboxIcon className="w-[16px] h-[16px] text-primary" />
          ) : (
            <SquareIcon className="w-[16px] h-[16px] text-primary" />
          )}
          <label className="text-[14px] font-gilroy text-primary">
            {L.translate("CollaboratorProfile.have_read_privacy_1")}
            <span>
              <a
                href="https://www.withyouagency.it/privacy"
                target="_blank"
                className="underline hover:text-active"
              >
                {L.translate("CollaboratorProfile.have_read_privacy_2")}
              </a>
            </span>
            {L.translate("CollaboratorProfile.have_read_privacy_3")}
            <span className="text-[14px] text-error font-gilroy"> * </span>
          </label>
        </div>
        <div className="flex flex-row items-center justify-start gap-2 mt-8">
          <label className="text-[14px] font-gilroy-bold-italic text-error">
            {L.translate("CollaboratorProfile.confirmed_all_data")}
          </label>
        </div>

        <div className="error-summary mt-4 mb-4 p-4 rounded">
          {Object.keys(errors).length > 0 && (
            <ul>
              {Object.values(errors).map((error, index) => (
                <li key={index} className="text-red-500">
                  {error.message}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="flex flex-row items-center justify-center gap-2 mt-8 mb-8 w-full sm:w-[50%]">
          <Button
            variant="validate"
            type="submit"
            className={`${
              isValid ? "bg-active" : "bg-[#BCBEAD]"
            } rounded-[10px] font-gilroy text-[14px] font-bold leading-[24px] h-[44px] w-full sm:!px-12`}
            onClick={handleSubmit(onUpdateInfo)}
            disabled={!isValid}
          >
            {L.translate("Base.submit")}
          </Button>
        </div>
      </div>

      {isGettingProfile && (
        <div className="fixed inset-0 z-50 backdrop-blur-[20px] w-[100vw] h-[100vh] flex items-center justify-center">
          <SpinnerIcon className="h-auto w-8 animate-spin text-active" />
        </div>
      )}

      {isUpdatingProfile && (
        <div className="fixed inset-0 z-50 backdrop-blur-[20px] w-[100vw] h-[100vh] flex items-center justify-center gap-4">
          <SpinnerIcon className="h-auto w-8 animate-spin text-active" />
          <span className="font-gilroy text-[20px] text-active">
            {L.translate("Base.updating")}
          </span>
        </div>
      )}
    </div>
  );
};

export default CollaboratorProfilePage;
