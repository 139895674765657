import moment from "moment";

export const calcolaCodiceFiscale = (
  nome: string,
  cognome: string,
  sesso: string,
  dataDiNascita: string,
  localita: string
) => {
  const consonanti_nome: string[] = [];
  const vocali_nome: string[] = [];
  const consonanti_cognome: string[] = [];
  const vocali_cognome: string[] = [];

  var femmina = sesso == "M" ? false : true;
  var maschio = sesso == "M" ? true : false;

  let consonanti: string[] | undefined;
  let vocali: string[] | undefined;

  let consonanti_prese_nome: string[] | undefined;
  let consonanti_prese_cognome: string[] | undefined;

  let n_cons: number = 0;

  //const data: Date = new Date(dataDiNascita);
  const dataItems = dataDiNascita.split("-");
  const anno: string = dataItems[2].substring(2, 4); //data.getFullYear().toString().substr(-2);
  let mese: number = Number(dataItems[1]); //data.getMonth() + 1;
  let giorno: number = Number(dataItems[0]); //data.getDate() + 1;
  console.log("data format", dataDiNascita, anno, mese, giorno);

  const cod_mese: string[] = [
    "A",
    "B",
    "C",
    "D",
    "G",
    "H",
    "L",
    "M",
    "P",
    "R",
    "S",
    "T",
  ];

  function GetCF(Cod: string): string {
    let CF_MOD: string;
    let CF_MOD_ORA: string = "";
    for (let i: number = 0; i <= Cod.length; ++i) {
      CF_MOD = Cod.charAt(i);
      if (CF_MOD === ",") {
        continue;
      } else {
        CF_MOD_ORA += Cod.charAt(i);
      }
    }
    return CF_MOD_ORA;
  }

  function getValueForChar(char: string): number {
    switch (char) {
      case "0":
      case "A":
        return 1;
      case "1":
      case "B":
        return 0;
      case "2":
      case "C":
        return 5;
      case "3":
      case "D":
        return 7;
      case "4":
      case "E":
        return 9;
      case "5":
      case "F":
        return 13;
      case "6":
      case "G":
        return 15;
      case "7":
      case "H":
        return 17;
      case "8":
      case "I":
        return 19;
      case "9":
      case "J":
        return 21;
      case "K":
        return 2;
      case "L":
        return 4;
      case "M":
        return 18;
      case "N":
        return 20;
      case "O":
        return 11;
      case "P":
        return 3;
      case "Q":
        return 6;
      case "R":
        return 8;
      case "S":
        return 12;
      case "T":
        return 14;
      case "U":
        return 16;
      case "V":
        return 10;
      case "W":
        return 22;
      case "X":
        return 25;
      case "Y":
        return 24;
      case "Z":
        return 23;
      default:
        return 0;
    }
  }

  function getValueForCharOther(char: string): number {
    switch (char) {
      case "0":
      case "A":
        return 0;
        break;
      case "1":
      case "B":
        return 1;
        break;
      case "2":
      case "C":
        return 2;
        break;
      case "3":
      case "D":
        return 3;
        break;
      case "4":
      case "E":
        return 4;
        break;
      case "5":
      case "F":
        return 5;
        break;
      case "6":
      case "G":
        return 6;
        break;
      case "7":
      case "H":
        return 7;
        break;
      case "8":
      case "I":
        return 8;
        break;
      case "9":
      case "J":
        return 9;
        break;
      case "K":
        return 10;
        break;
      case "L":
        return 11;
        break;
      case "M":
        return 12;
        break;
      case "N":
        return 13;
        break;
      case "O":
        return 14;
        break;
      case "P":
        return 15;
        break;
      case "Q":
        return 16;
        break;
      case "R":
        return 17;
        break;
      case "S":
        return 18;
        break;
      case "T":
        return 19;
        break;
      case "U":
        return 20;
        break;
      case "V":
        return 21;
        break;
      case "W":
        return 22;
        break;
      case "X":
        return 23;
        break;
      case "Y":
        return 24;
        break;
      case "Z":
        return 25;
        break;
      default:
        return 0;
    }
  }

  if (nome.length < 3) {
    nome += "x";
  } else {
    for (let i: number = 0; i <= nome.length; i++) {
      if (nome.charAt(i).match(/[aeiouAEIOU]/)) {
        vocali_nome[i] = nome.charAt(i).toUpperCase();
        vocali = vocali_nome.filter(Boolean);
      } else if (nome.charAt(i).match(/[/',/"]/)) {
        continue;
      } else {
        consonanti_nome[i] = nome.charAt(i).toUpperCase();
        consonanti = consonanti_nome.filter(Boolean);
        if (consonanti_nome[i] !== "") {
          n_cons++;
        }
      }
    }
  }

  if (consonanti && consonanti.length >= 4) {
    consonanti_prese_nome = [consonanti[0], consonanti[2], consonanti[3]];
  } else if (consonanti && consonanti.length === 1) {
    consonanti_prese_nome = [consonanti[0], vocali![0], vocali![1]];
  } else if (consonanti && consonanti.length < 3) {
    consonanti_prese_nome = [consonanti[0], consonanti[1], vocali![0]];
  } else if (consonanti && consonanti.length === 3) {
    consonanti_prese_nome = [consonanti[0], consonanti[1], consonanti[2]];
  }

  if (cognome.length < 3) {
    cognome += "x";
  } else {
    for (let i: number = 0; i <= cognome.length; i++) {
      if (cognome.charAt(i).match(/[aeiouAEIOU]/)) {
        vocali_cognome[i] = cognome.charAt(i).toUpperCase();
        vocali = vocali_cognome.filter(Boolean);
      } else if (cognome.charAt(i).match(/[/',/"]/)) {
        continue;
      } else {
        consonanti_cognome[i] = cognome.charAt(i).toUpperCase();
        consonanti = consonanti_cognome.filter(Boolean);
        if (consonanti_cognome[i] !== "") {
          n_cons++;
        }
      }
    }
  }

  if (consonanti && consonanti.length < 3) {
    consonanti_prese_cognome = [consonanti[0], consonanti[1], vocali![0]];
  } else {
    consonanti_prese_cognome = [consonanti![0], consonanti![1], consonanti![2]];
  }

  if (consonanti && consonanti.length === 1) {
    consonanti_prese_cognome = [consonanti[0], vocali![0], vocali![1]];
  }

  let meseCod: string = "";
  console.log(mese, cod_mese);
  switch (mese) {
    case 1:
      meseCod = cod_mese[0];
      break;
    case 2:
      meseCod = cod_mese[1];
      break;
    case 3:
      meseCod = cod_mese[2];
      break;
    case 4:
      meseCod = cod_mese[3];
      break;
    case 5:
      meseCod = cod_mese[4];
      break;
    case 6:
      meseCod = cod_mese[5];
      break;
    case 7:
      meseCod = cod_mese[6];
      break;
    case 8:
      meseCod = cod_mese[7];
      break;
    case 9:
      meseCod = cod_mese[8];
      break;
    case 10:
      meseCod = cod_mese[9];
      break;
    case 11:
      meseCod = cod_mese[10];
      break;
    case 12:
      meseCod = cod_mese[11];
      break;
  }

  const giornoCF = giorno < 10 ? `0${giorno}` : `${giorno}`;

  switch (giorno) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
    case 17:
    case 18:
    case 19:
    case 20:
    case 21:
    case 22:
    case 23:
    case 24:
    case 25:
    case 26:
    case 27:
    case 28:
    case 29:
    case 30:
    case 31:
      if (femmina) {
        giorno += 40;
      } // ALTRIMENTI MASCHIO
      else if (maschio) {
        giorno = giorno;
      }
      break;
  }

  const cod_citta: string[] = [
    "A001",
    "A004",
    "A005",
    "A006",
    "A007",
    "A008",
    "A010",
    "A012",
    "A013",
    "A014",
    "A015",
    "A016",
    "A017",
    "A018",
    "A019",
    "A020",
    "A023",
    "A024",
    "A025",
    "A026",
    "A027",
    "A029",
    "A028",
    "A031",
    "A032",
    "A033",
    "A034",
    "A035",
    "A039",
    "A038",
    "A040",
    "A041",
    "A042",
    "A043",
    "A044",
    "A045",
    "A050",
    "A048",
    "A051",
    "A047",
    "A049",
    "M211",
    "A052",
    "A053",
    "A054",
    "A055",
    "A056",
    "A057",
    "A058",
    "A059",
    "A060",
    "A061",
    "A062",
    "A064",
    "A065",
    "A067",
    "A068",
    "A069",
    "A070",
    "A071",
    "A072",
    "A074",
    "H848",
    "A075",
    "A076",
    "A077",
    "A080",
    "A082",
    "A083",
    "A084",
    "A085",
    "A087",
    "A088",
    "A089",
    "A091",
    "A092",
    "A093",
    "A096",
    "A097",
    "A098",
    "A100",
    "A102",
    "A103",
    "A101",
    "A105",
    "A106",
    "A107",
    "A109",
    "A110",
    "A111",
    "A112",
    "A113",
    "A116",
    "A115",
    "A117",
    "A118",
    "A119",
    "A120",
    "A121",
    "A122",
    "A123",
    "A124",
    "A125",
    "A126",
    "A127",
    "A128",
    "A131",
    "A132",
    "A129",
    "A130",
    "A134",
    "A137",
    "A135",
    "A138",
    "A139",
    "A143",
    "A145",
    "A146",
    "A149",
    "A150",
    "A153",
    "A154",
    "A155",
    "A158",
    "A157",
    "A159",
    "A160",
    "A161",
    "A162",
    "A163",
    "A164",
    "A166",
    "A165",
    "A167",
    "A171",
    "A172",
    "A173",
    "A175",
    "A176",
    "A177",
    "A178",
    "A179",
    "A180",
    "A182",
    "A183",
    "A181",
    "A184",
    "A185",
    "A186",
    "A187",
    "A188",
    "A189",
    "A191",
    "A192",
    "A193",
    "A194",
    "A201",
    "A195",
    "A196",
    "A197",
    "A198",
    "A199",
    "A200",
    "A202",
    "A203",
    "A204",
    "A206",
    "A205",
    "A207",
    "A208",
    "A210",
    "A211",
    "A214",
    "A216",
    "A217",
    "A218",
    "A220",
    "A221",
    "A222",
    "A223",
    "A224",
    "A225",
    "A226",
    "A228",
    "A229",
    "A227",
    "A230",
    "A231",
    "A233",
    "A234",
    "A235",
    "A236",
    "A237",
    "A238",
    "A239",
    "A240",
    "A241",
    "A242",
    "A243",
    "A244",
    "A246",
    "A245",
    "A249",
    "A251",
    "A252",
    "A253",
    "A254",
    "A255",
    "A256",
    "A257",
    "A258",
    "A259",
    "A260",
    "A261",
    "A262",
    "A263",
    "A264",
    "A265",
    "A267",
    "A268",
    "A269",
    "A270",
    "A271",
    "A272",
    "A274",
    "A273",
    "A275",
    "A278",
    "A280",
    "A281",
    "A282",
    "A283",
    "A284",
    "A285",
    "A286",
    "A287",
    "A288",
    "A290",
    "A291",
    "A292",
    "A293",
    "A294",
    "A295",
    "A297",
    "A296",
    "A299",
    "A301",
    "A302",
    "A303",
    "A304",
    "A306",
    "A305",
    "A309",
    "A312",
    "A313",
    "A314",
    "A315",
    "A317",
    "A318",
    "A319",
    "A320",
    "A321",
    "A323",
    "A324",
    "A325",
    "A326",
    "A327",
    "A328",
    "A329",
    "A330",
    "A333",
    "A332",
    "A334",
    "A335",
    "A337",
    "A338",
    "A339",
    "A340",
    "A341",
    "A343",
    "A344",
    "A346",
    "A347",
    "A348",
    "A350",
    "A351",
    "A352",
    "A354",
    "A357",
    "A358",
    "A359",
    "A360",
    "A363",
    "A365",
    "A366",
    "A367",
    "A369",
    "A370",
    "A371",
    "A372",
    "A373",
    "A374",
    "A375",
    "A376",
    "A377",
    "A379",
    "A380",
    "M213",
    "A382",
    "A383",
    "A385",
    "A386",
    "A387",
    "A388",
    "A389",
    "A390",
    "A391",
    "A392",
    "A393",
    "A394",
    "A396",
    "A397",
    "A398",
    "A399",
    "A400",
    "A401",
    "A402",
    "A403",
    "A405",
    "A407",
    "A409",
    "A412",
    "A413",
    "A414",
    "A415",
    "A418",
    "A419",
    "A424",
    "A421",
    "A422",
    "A425",
    "A427",
    "A429",
    "A430",
    "A431",
    "A432",
    "A433",
    "A434",
    "A435",
    "A437",
    "A436",
    "A438",
    "A439",
    "A441",
    "A443",
    "A444",
    "A445",
    "A446",
    "A447",
    "A448",
    "A449",
    "A451",
    "A452",
    "A453",
    "A440",
    "A454",
    "A455",
    "A456",
    "A458",
    "A459",
    "A460",
    "A461",
    "A462",
    "A463",
    "A464",
    "A465",
    "A467",
    "A466",
    "A470",
    "A471",
    "A473",
    "A474",
    "A475",
    "A476",
    "A477",
    "A478",
    "A479",
    "A480",
    "A481",
    "A482",
    "A484",
    "A485",
    "A486",
    "A487",
    "A488",
    "A489",
    "A490",
    "A491",
    "A492",
    "A493",
    "A494",
    "A495",
    "A496",
    "A497",
    "A499",
    "A501",
    "A502",
    "A503",
    "A506",
    "A507",
    "A508",
    "A509",
    "A511",
    "A512",
    "A514",
    "A515",
    "A516",
    "A517",
    "A518",
    "A519",
    "M258",
    "A520",
    "A521",
    "A522",
    "A523",
    "A094",
    "A108",
    "A525",
    "A526",
    "A527",
    "A530",
    "A529",
    "A528",
    "A531",
    "A532",
    "A533",
    "A534",
    "A535",
    "A536",
    "M214",
    "A537",
    "A540",
    "A538",
    "A539",
    "A541",
    "A542",
    "A544",
    "A546",
    "A547",
    "A552",
    "A551",
    "A550",
    "A553",
    "A555",
    "A557",
    "A560",
    "A564",
    "A565",
    "A567",
    "A568",
    "A566",
    "A570",
    "A572",
    "A574",
    "A573",
    "A569",
    "A571",
    "A575",
    "A576",
    "A577",
    "A578",
    "A579",
    "A580",
    "A581",
    "A584",
    "A586",
    "A587",
    "A588",
    "A590",
    "A589",
    "A591",
    "A592",
    "A593",
    "A594",
    "A597",
    "A599",
    "A600",
    "A601",
    "A603",
    "A604",
    "A605",
    "A606",
    "A607",
    "A610",
    "A612",
    "A613",
    "A614",
    "A615",
    "A616",
    "A617",
    "A618",
    "A619",
    "A621",
    "A625",
    "A626",
    "A628",
    "A627",
    "A629",
    "A630",
    "A631",
    "A632",
    "A633",
    "A634",
    "A635",
    "A637",
    "A638",
    "A639",
    "A640",
    "A643",
    "A645",
    "A646",
    "A647",
    "A650",
    "A651",
    "A652",
    "A653",
    "A655",
    "A656",
    "A657",
    "A658",
    "A660",
    "A661",
    "A662",
    "A663",
    "A664",
    "A665",
    "A666",
    "A667",
    "A668",
    "A669",
    "A670",
    "A671",
    "A673",
    "A674",
    "A676",
    "A677",
    "A678",
    "A681",
    "A683",
    "A684",
    "A686",
    "A687",
    "A689",
    "A690",
    "A691",
    "A692",
    "A694",
    "A696",
    "A697",
    "A698",
    "A699",
    "A700",
    "A702",
    "A703",
    "A706",
    "A704",
    "A707",
    "A708",
    "A709",
    "A710",
    "A711",
    "A712",
    "A713",
    "A714",
    "A716",
    "A717",
    "A718",
    "A719",
    "A721",
    "A722",
    "A725",
    "A726",
    "A728",
    "A729",
    "A730",
    "A731",
    "A732",
    "A733",
    "A734",
    "A735",
    "A736",
    "A737",
    "A740",
    "A739",
    "A738",
    "A741",
    "A742",
    "A743",
    "A744",
    "A745",
    "A746",
    "A747",
    "A749",
    "A750",
    "A751",
    "A752",
    "M294",
    "A755",
    "A756",
    "A757",
    "A759",
    "A762",
    "A763",
    "A761",
    "A765",
    "A764",
    "A760",
    "A766",
    "A768",
    "A772",
    "A774",
    "A773",
    "A769",
    "A770",
    "A776",
    "A777",
    "A778",
    "A779",
    "A780",
    "A781",
    "A782",
    "A783",
    "A784",
    "A785",
    "A786",
    "A787",
    "A788",
    "A789",
    "A791",
    "A792",
    "A793",
    "A794",
    "A795",
    "A796",
    "A798",
    "A799",
    "A801",
    "A802",
    "A804",
    "A805",
    "A806",
    "A808",
    "A809",
    "A810",
    "A811",
    "A812",
    "A813",
    "A816",
    "A817",
    "A815",
    "A818",
    "A819",
    "A820",
    "A821",
    "A823",
    "A825",
    "A826",
    "A827",
    "A831",
    "A832",
    "A834",
    "A835",
    "A836",
    "A837",
    "A839",
    "A841",
    "A842",
    "A843",
    "A844",
    "A845",
    "A846",
    "A847",
    "A848",
    "A849",
    "A850",
    "A851",
    "A852",
    "A853",
    "A854",
    "A855",
    "A856",
    "A859",
    "A861",
    "A863",
    "A864",
    "A866",
    "A870",
    "A872",
    "A874",
    "A876",
    "A877",
    "A878",
    "A880",
    "A881",
    "A882",
    "A883",
    "A884",
    "A885",
    "A887",
    "A889",
    "A891",
    "A892",
    "A893",
    "A894",
    "A895",
    "A896",
    "A897",
    "A898",
    "A901",
    "A902",
    "A903",
    "A857",
    "A904",
    "A905",
    "M268",
    "A906",
    "A909",
    "A910",
    "A911",
    "A912",
    "A914",
    "A916",
    "A918",
    "A919",
    "A920",
    "A922",
    "A925",
    "A929",
    "A930",
    "A931",
    "A932",
    "A933",
    "A937",
    "A940",
    "A941",
    "A944",
    "A945",
    "A946",
    "A947",
    "A948",
    "A949",
    "A950",
    "A952",
    "A953",
    "A954",
    "A955",
    "A956",
    "A957",
    "A958",
    "A959",
    "A960",
    "A961",
    "A963",
    "A962",
    "A964",
    "A965",
    "A967",
    "A968",
    "A970",
    "A971",
    "A972",
    "A973",
    "A975",
    "A976",
    "A977",
    "A978",
    "A979",
    "A981",
    "A982",
    "A983",
    "A984",
    "A986",
    "A987",
    "A988",
    "A989",
    "A990",
    "A991",
    "A993",
    "A998",
    "A992",
    "A995",
    "A999",
    "B001",
    "B002",
    "B003",
    "B005",
    "B007",
    "B009",
    "B010",
    "B026",
    "B028",
    "B033",
    "B035",
    "B017",
    "B036",
    "B037",
    "B038",
    "B043",
    "B044",
    "B042",
    "B006",
    "A996",
    "B046",
    "B011",
    "B015",
    "B013",
    "B016",
    "B018",
    "B019",
    "B020",
    "B021",
    "B024",
    "B025",
    "B029",
    "B030",
    "B031",
    "B008",
    "B040",
    "B041",
    "B048",
    "B049",
    "B051",
    "B054",
    "B055",
    "B056",
    "B057",
    "B058",
    "B061",
    "B062",
    "B063",
    "B064",
    "B067",
    "B068",
    "B069",
    "B070",
    "B073",
    "B071",
    "B075",
    "B076",
    "B077",
    "B078",
    "B079",
    "B080",
    "B081",
    "B082",
    "B083",
    "B084",
    "B085",
    "B086",
    "B088",
    "B091",
    "B094",
    "B097",
    "B099",
    "B098",
    "B100",
    "B101",
    "B102",
    "A720",
    "B104",
    "B105",
    "B106",
    "B107",
    "B109",
    "B110",
    "B111",
    "B112",
    "B114",
    "B115",
    "B116",
    "B117",
    "B118",
    "B120",
    "B121",
    "B123",
    "B124",
    "B126",
    "B128",
    "B131",
    "B132",
    "B134",
    "B135",
    "B136",
    "B137",
    "B138",
    "B140",
    "B141",
    "B142",
    "B143",
    "B144",
    "B145",
    "B149",
    "B150",
    "B152",
    "B153",
    "B154",
    "B156",
    "B157",
    "B158",
    "B159",
    "B160",
    "B161",
    "B162",
    "B165",
    "B166",
    "B167",
    "B169",
    "B171",
    "B172",
    "B173",
    "B175",
    "B176",
    "B178",
    "B179",
    "B180",
    "B181",
    "B182",
    "B183",
    "B184",
    "B185",
    "B187",
    "B188",
    "B191",
    "B192",
    "B193",
    "B194",
    "B195",
    "B196",
    "B197",
    "B198",
    "B200",
    "B201",
    "B202",
    "B203",
    "B204",
    "B205",
    "B207",
    "B209",
    "B212",
    "B213",
    "B214",
    "B215",
    "B216",
    "B217",
    "B218",
    "B219",
    "B220",
    "B221",
    "B223",
    "B225",
    "B227",
    "B228",
    "B229",
    "B230",
    "B232",
    "B234",
    "B235",
    "B236",
    "B237",
    "B238",
    "B239",
    "B240",
    "B242",
    "B243",
    "B246",
    "B247",
    "B248",
    "B249",
    "B250",
    "B251",
    "B255",
    "B256",
    "B258",
    "B259",
    "B261",
    "B262",
    "B264",
    "B265",
    "B266",
    "B267",
    "B269",
    "B270",
    "B272",
    "B274",
    "B275",
    "B276",
    "B278",
    "B279",
    "B280",
    "B281",
    "B282",
    "B283",
    "B284",
    "B285",
    "B286",
    "B287",
    "B288",
    "B289",
    "B292",
    "B293",
    "B294",
    "B295",
    "B296",
    "B297",
    "B300",
    "B301",
    "B302",
    "B303",
    "B304",
    "B305",
    "B306",
    "B309",
    "B320",
    "B311",
    "B313",
    "B314",
    "B315",
    "B319",
    "B326",
    "B328",
    "B332",
    "B335",
    "B345",
    "B346",
    "B347",
    "B349",
    "B350",
    "B351",
    "B352",
    "B354",
    "B355",
    "B358",
    "B357",
    "B359",
    "B360",
    "B361",
    "B362",
    "B364",
    "B365",
    "B366",
    "B367",
    "B368",
    "B369",
    "B371",
    "B374",
    "B375",
    "B376",
    "B377",
    "B378",
    "B379",
    "B380",
    "B381",
    "B382",
    "B383",
    "B384",
    "B385",
    "B386",
    "B388",
    "B389",
    "B390",
    "B391",
    "B392",
    "B393",
    "B394",
    "B395",
    "B396",
    "B397",
    "B398",
    "B399",
    "B400",
    "B402",
    "B403",
    "B404",
    "B405",
    "B406",
    "B408",
    "B410",
    "B409",
    "B413",
    "B415",
    "B416",
    "B417",
    "B418",
    "B419",
    "B423",
    "B424",
    "B425",
    "B426",
    "B427",
    "B428",
    "B429",
    "B430",
    "B431",
    "B432",
    "B433",
    "B434",
    "B435",
    "B436",
    "B437",
    "B439",
    "B440",
    "B441",
    "B442",
    "B443",
    "B444",
    "B446",
    "B445",
    "B447",
    "B448",
    "B450",
    "B452",
    "B453",
    "B455",
    "B456",
    "B457",
    "B460",
    "B461",
    "B462",
    "B463",
    "B465",
    "B467",
    "B468",
    "B469",
    "B471",
    "B472",
    "B470",
    "B473",
    "B474",
    "B476",
    "B477",
    "B479",
    "B481",
    "B482",
    "B483",
    "B484",
    "B485",
    "B486",
    "B489",
    "B490",
    "B492",
    "B493",
    "B496",
    "B497",
    "B498",
    "B499",
    "B500",
    "B501",
    "B502",
    "B504",
    "B505",
    "B507",
    "B506",
    "B508",
    "B511",
    "B509",
    "B512",
    "B513",
    "B514",
    "B515",
    "B516",
    "B526",
    "B529",
    "B538",
    "B553",
    "B564",
    "B570",
    "B519",
    "B520",
    "B521",
    "B522",
    "B524",
    "B525",
    "B527",
    "B528",
    "B530",
    "B531",
    "B533",
    "B532",
    "B534",
    "B535",
    "B536",
    "B537",
    "B539",
    "B541",
    "B543",
    "B542",
    "B544",
    "B545",
    "B546",
    "B547",
    "B549",
    "B550",
    "B551",
    "B554",
    "B555",
    "B556",
    "B557",
    "B559",
    "B562",
    "B561",
    "B563",
    "B565",
    "B566",
    "B567",
    "B569",
    "B572",
    "B577",
    "B573",
    "B574",
    "B576",
    "B578",
    "B579",
    "B580",
    "B581",
    "B582",
    "B584",
    "B586",
    "B588",
    "B587",
    "B589",
    "B590",
    "B591",
    "B592",
    "B593",
    "B594",
    "B597",
    "B598",
    "B599",
    "B602",
    "B603",
    "B604",
    "B605",
    "B606",
    "B607",
    "B608",
    "B609",
    "B610",
    "B613",
    "B612",
    "B615",
    "B616",
    "B617",
    "B618",
    "B619",
    "B620",
    "B621",
    "C669",
    "B624",
    "B626",
    "B627",
    "B628",
    "B631",
    "B629",
    "B630",
    "B633",
    "B634",
    "B635",
    "B636",
    "B637",
    "B639",
    "B640",
    "B641",
    "B642",
    "B643",
    "B644",
    "B645",
    "B646",
    "B647",
    "B648",
    "B649",
    "B650",
    "B651",
    "B653",
    "B655",
    "B656",
    "B658",
    "B660",
    "B661",
    "B664",
    "B666",
    "B663",
    "B667",
    "B669",
    "B670",
    "B671",
    "B672",
    "B674",
    "B675",
    "B676",
    "B677",
    "B679",
    "B680",
    "B678",
    "B681",
    "B682",
    "B684",
    "B685",
    "B686",
    "B688",
    "B687",
    "B690",
    "B691",
    "B692",
    "B693",
    "B694",
    "B696",
    "B695",
    "B697",
    "B698",
    "B701",
    "B703",
    "B704",
    "B705",
    "B706",
    "B707",
    "B708",
    "B710",
    "B709",
    "B711",
    "B712",
    "B715",
    "B716",
    "B718",
    "B717",
    "B719",
    "B720",
    "B722",
    "B723",
    "B724",
    "B725",
    "B726",
    "B727",
    "B729",
    "B730",
    "B731",
    "B732",
    "B733",
    "B734",
    "B735",
    "B741",
    "B740",
    "B739",
    "B736",
    "B742",
    "B743",
    "B744",
    "B745",
    "B748",
    "B749",
    "B752",
    "B754",
    "B755",
    "M285",
    "B756",
    "B758",
    "B759",
    "B760",
    "B762",
    "B763",
    "B765",
    "B766",
    "B767",
    "B768",
    "B769",
    "B771",
    "B772",
    "B774",
    "B776",
    "B777",
    "B778",
    "B779",
    "B780",
    "B781",
    "B782",
    "B783",
    "B784",
    "B785",
    "B787",
    "B788",
    "B789",
    "B790",
    "B791",
    "B792",
    "B794",
    "B795",
    "B796",
    "B798",
    "B801",
    "B802",
    "B803",
    "B804",
    "B805",
    "B807",
    "B808",
    "B809",
    "B810",
    "B812",
    "B813",
    "B814",
    "B816",
    "B817",
    "B818",
    "B819",
    "B820",
    "B822",
    "B823",
    "B825",
    "B827",
    "B828",
    "B826",
    "B829",
    "B830",
    "B832",
    "B835",
    "B836",
    "B838",
    "B839",
    "B840",
    "B841",
    "B842",
    "B844",
    "B845",
    "B846",
    "B847",
    "B848",
    "B850",
    "B851",
    "B853",
    "B854",
    "B856",
    "B857",
    "B858",
    "B859",
    "B860",
    "B870",
    "B872",
    "B895",
    "B861",
    "B862",
    "B864",
    "B865",
    "B866",
    "B867",
    "B868",
    "B869",
    "B871",
    "B873",
    "B876",
    "B881",
    "B877",
    "B875",
    "B878",
    "B885",
    "B879",
    "B880",
    "B882",
    "B883",
    "B886",
    "B889",
    "B890",
    "B887",
    "B888",
    "B891",
    "B892",
    "B893",
    "B894",
    "B896",
    "B897",
    "B898",
    "B899",
    "B900",
    "B901",
    "B902",
    "B905",
    "B904",
    "B907",
    "B910",
    "B911",
    "B912",
    "B916",
    "B917",
    "B918",
    "B919",
    "B920",
    "B921",
    "B922",
    "B923",
    "B924",
    "B925",
    "B928",
    "B927",
    "B929",
    "B932",
    "M260",
    "B933",
    "B934",
    "B935",
    "B936",
    "B937",
    "B938",
    "B940",
    "B939",
    "B941",
    "B942",
    "B943",
    "B945",
    "B946",
    "B947",
    "B948",
    "B949",
    "A559",
    "B950",
    "B953",
    "B954",
    "B955",
    "B956",
    "B959",
    "B961",
    "B958",
    "B960",
    "B963",
    "B965",
    "B966",
    "B967",
    "B971",
    "B974",
    "B977",
    "B978",
    "B980",
    "B979",
    "B982",
    "B983",
    "B984",
    "B985",
    "B988",
    "B987",
    "B989",
    "B990",
    "B991",
    "A472",
    "B993",
    "B994",
    "B996",
    "C002",
    "C003",
    "B998",
    "B997",
    "C004",
    "C005",
    "B999",
    "C006",
    "C007",
    "C014",
    "C020",
    "C024",
    "C022",
    "C027",
    "C030",
    "C033",
    "C034",
    "C037",
    "C038",
    "C041",
    "C044",
    "C045",
    "C046",
    "C049",
    "C047",
    "C048",
    "C050",
    "C052",
    "C053",
    "C055",
    "C056",
    "C058",
    "C064",
    "C065",
    "B494",
    "C040",
    "C071",
    "C183",
    "C075",
    "C076",
    "C078",
    "C082",
    "C083",
    "C085",
    "C086",
    "B969",
    "C090",
    "C091",
    "C093",
    "C094",
    "C096",
    "C097",
    "C098",
    "C102",
    "C114",
    "C115",
    "C116",
    "C117",
    "C118",
    "C121",
    "C203",
    "C204",
    "C208",
    "C211",
    "C252",
    "C253",
    "C255",
    "C259",
    "C261",
    "C262",
    "C263",
    "C266",
    "C265",
    "C270",
    "C268",
    "C269",
    "C289",
    "C110",
    "C291",
    "C057",
    "C059",
    "C060",
    "C062",
    "C063",
    "C066",
    "C067",
    "C069",
    "C072",
    "C073",
    "C074",
    "C080",
    "C081",
    "C089",
    "C100",
    "C101",
    "C103",
    "C104",
    "C105",
    "C112",
    "C113",
    "C107",
    "C106",
    "C111",
    "C119",
    "C120",
    "C122",
    "C123",
    "C125",
    "C126",
    "C127",
    "C128",
    "C130",
    "C129",
    "C133",
    "C134",
    "C135",
    "C136",
    "C137",
    "C139",
    "C140",
    "C142",
    "C141",
    "C143",
    "C145",
    "C146",
    "C147",
    "C148",
    "C149",
    "C153",
    "C152",
    "C154",
    "C155",
    "C156",
    "C157",
    "C158",
    "C160",
    "C161",
    "C162",
    "C166",
    "C165",
    "C167",
    "C169",
    "C079",
    "C172",
    "C174",
    "C173",
    "C175",
    "C176",
    "C177",
    "B312",
    "C184",
    "C185",
    "C178",
    "C186",
    "A300",
    "C187",
    "C188",
    "C190",
    "C191",
    "C194",
    "C189",
    "C195",
    "C198",
    "C199",
    "C201",
    "C202",
    "C051",
    "C205",
    "C206",
    "C207",
    "C197",
    "C209",
    "C210",
    "C213",
    "C215",
    "C217",
    "C218",
    "C219",
    "C216",
    "C226",
    "C227",
    "C228",
    "C229",
    "C220",
    "C230",
    "C231",
    "C225",
    "C222",
    "C214",
    "C235",
    "C224",
    "C236",
    "C237",
    "C232",
    "C240",
    "C241",
    "C223",
    "C242",
    "C243",
    "C244",
    "C245",
    "C246",
    "C247",
    "C248",
    "C250",
    "C251",
    "C254",
    "C267",
    "C271",
    "C272",
    "C273",
    "B968",
    "C274",
    "C275",
    "C181",
    "C278",
    "C276",
    "C279",
    "C280",
    "B129",
    "C200",
    "C284",
    "C283",
    "C286",
    "C287",
    "C288",
    "C290",
    "C292",
    "C293",
    "M288",
    "C318",
    "C319",
    "C308",
    "C302",
    "C301",
    "C304",
    "C296",
    "C306",
    "C309",
    "C310",
    "C312",
    "C303",
    "C297",
    "C299",
    "C313",
    "C314",
    "C315",
    "C298",
    "C316",
    "C300",
    "C317",
    "C307",
    "C321",
    "C322",
    "C323",
    "C325",
    "C324",
    "C327",
    "C329",
    "C330",
    "C331",
    "C332",
    "C334",
    "C335",
    "C336",
    "C337",
    "M261",
    "C338",
    "C339",
    "C340",
    "C341",
    "C108",
    "C343",
    "C344",
    "C345",
    "C346",
    "C347",
    "C348",
    "C349",
    "C351",
    "C352",
    "C353",
    "C354",
    "C357",
    "C356",
    "C285",
    "C359",
    "C361",
    "C360",
    "C362",
    "C363",
    "C364",
    "C365",
    "C367",
    "C369",
    "C370",
    "C372",
    "C374",
    "C375",
    "C376",
    "C377",
    "M308",
    "C378",
    "C380",
    "C381",
    "C382",
    "C383",
    "C384",
    "C385",
    "C387",
    "C389",
    "C390",
    "C392",
    "C393",
    "C394",
    "C395",
    "C396",
    "C398",
    "C400",
    "C404",
    "C405",
    "C406",
    "C407",
    "C409",
    "C408",
    "C412",
    "C410",
    "C413",
    "C414",
    "C415",
    "C417",
    "C418",
    "C420",
    "C421",
    "C422",
    "C424",
    "C426",
    "C428",
    "C429",
    "C430",
    "C435",
    "C432",
    "C436",
    "C437",
    "C438",
    "C439",
    "C444",
    "C441",
    "C440",
    "C443",
    "C442",
    "C446",
    "C447",
    "C449",
    "C448",
    "C450",
    "M262",
    "C452",
    "C453",
    "C456",
    "C457",
    "C458",
    "C459",
    "C461",
    "C463",
    "C467",
    "C466",
    "C469",
    "C470",
    "C472",
    "C471",
    "C474",
    "C476",
    "C478",
    "C479",
    "C480",
    "C481",
    "C483",
    "C482",
    "C484",
    "C485",
    "C486",
    "C487",
    "C488",
    "C489",
    "C492",
    "C493",
    "C494",
    "C495",
    "C496",
    "C498",
    "C500",
    "C501",
    "C497",
    "C502",
    "C503",
    "C504",
    "C505",
    "C506",
    "C508",
    "C509",
    "C510",
    "C511",
    "C512",
    "C513",
    "C514",
    "C515",
    "C516",
    "A022",
    "C517",
    "C520",
    "C521",
    "C523",
    "C526",
    "C528",
    "C524",
    "C527",
    "C507",
    "C529",
    "C518",
    "C525",
    "C530",
    "C531",
    "C532",
    "C536",
    "C534",
    "C537",
    "C533",
    "C538",
    "C539",
    "C540",
    "C541",
    "C542",
    "C543",
    "C544",
    "C545",
    "C547",
    "C548",
    "C549",
    "C550",
    "C551",
    "C552",
    "C553",
    "C554",
    "C555",
    "C556",
    "C557",
    "C558",
    "C559",
    "C560",
    "C561",
    "C563",
    "C564",
    "C565",
    "C566",
    "C567",
    "C568",
    "C569",
    "C573",
    "C574",
    "C576",
    "C578",
    "C577",
    "C580",
    "C581",
    "C582",
    "C583",
    "C584",
    "C585",
    "C587",
    "C588",
    "C589",
    "C591",
    "C593",
    "C594",
    "C595",
    "B491",
    "C596",
    "B540",
    "C598",
    "C294",
    "C599",
    "C600",
    "C604",
    "C605",
    "C606",
    "C608",
    "C609",
    "C610",
    "C612",
    "C613",
    "C614",
    "C615",
    "C616",
    "C618",
    "C619",
    "C620",
    "C621",
    "C623",
    "C624",
    "C625",
    "C627",
    "C630",
    "C628",
    "C629",
    "C631",
    "C632",
    "C633",
    "C634",
    "C635",
    "C637",
    "C638",
    "C639",
    "C640",
    "C641",
    "C648",
    "C649",
    "C650",
    "C651",
    "C652",
    "C653",
    "C655",
    "C654",
    "C656",
    "C657",
    "C658",
    "C659",
    "C660",
    "C661",
    "C662",
    "C663",
    "C665",
    "M272",
    "C668",
    "C672",
    "C673",
    "C674",
    "C675",
    "C676",
    "C677",
    "C678",
    "C679",
    "C680",
    "C681",
    "C684",
    "C685",
    "C686",
    "C689",
    "C691",
    "C694",
    "C695",
    "C696",
    "C697",
    "C699",
    "C700",
    "C701",
    "C702",
    "C703",
    "C704",
    "C705",
    "C707",
    "C708",
    "C709",
    "C710",
    "C711",
    "C712",
    "C714",
    "C713",
    "C715",
    "C716",
    "C718",
    "C719",
    "C722",
    "C723",
    "C724",
    "C725",
    "C726",
    "C727",
    "C728",
    "C729",
    "C730",
    "C732",
    "C733",
    "C734",
    "C735",
    "C738",
    "C739",
    "C740",
    "C741",
    "C742",
    "C744",
    "C745",
    "C750",
    "C743",
    "C746",
    "C747",
    "C749",
    "C751",
    "C752",
    "C754",
    "C755",
    "C756",
    "C757",
    "C758",
    "C759",
    "C760",
    "C763",
    "C765",
    "C766",
    "C764",
    "C768",
    "C769",
    "C770",
    "C771",
    "C773",
    "C778",
    "C779",
    "C780",
    "C781",
    "C777",
    "C774",
    "C776",
    "C782",
    "C783",
    "C784",
    "C785",
    "C787",
    "C790",
    "C791",
    "C792",
    "C793",
    "C794",
    "C795",
    "C796",
    "C797",
    "C800",
    "C801",
    "C803",
    "C804",
    "C806",
    "C807",
    "C810",
    "C811",
    "C812",
    "C813",
    "C814",
    "C815",
    "C816",
    "C817",
    "C818",
    "C819",
    "C820",
    "C821",
    "C823",
    "C824",
    "C826",
    "C829",
    "C830",
    "C835",
    "C836",
    "C838",
    "C839",
    "C840",
    "C841",
    "C844",
    "C845",
    "C851",
    "C854",
    "C857",
    "C847",
    "C870",
    "C846",
    "C872",
    "C848",
    "C850",
    "C852",
    "C853",
    "C311",
    "C855",
    "C856",
    "C858",
    "C859",
    "C860",
    "C862",
    "C864",
    "C865",
    "C866",
    "C867",
    "C868",
    "C869",
    "C871",
    "C875",
    "C876",
    "C878",
    "C877",
    "C880",
    "C879",
    "C882",
    "C883",
    "C884",
    "C885",
    "C886",
    "C888",
    "C890",
    "C893",
    "C894",
    "C895",
    "C897",
    "C900",
    "C901",
    "C902",
    "C903",
    "C904",
    "C905",
    "C908",
    "C910",
    "C911",
    "C912",
    "C914",
    "C917",
    "C918",
    "C920",
    "C922",
    "C925",
    "C926",
    "C927",
    "C928",
    "C929",
    "C930",
    "C931",
    "C933",
    "C934",
    "C937",
    "C935",
    "C938",
    "C941",
    "C940",
    "C939",
    "C943",
    "C944",
    "C946",
    "C948",
    "C949",
    "C950",
    "C951",
    "C952",
    "C953",
    "C954",
    "C955",
    "C956",
    "C957",
    "C958",
    "C959",
    "C960",
    "C962",
    "C963",
    "C964",
    "C965",
    "C968",
    "C969",
    "C971",
    "C972",
    "C973",
    "C974",
    "C975",
    "C976",
    "C977",
    "C978",
    "C979",
    "C980",
    "C982",
    "C983",
    "C984",
    "C986",
    "C987",
    "C988",
    "C990",
    "C991",
    "C992",
    "C993",
    "C994",
    "C996",
    "C995",
    "C998",
    "C999",
    "D003",
    "D004",
    "D005",
    "D006",
    "D007",
    "D008",
    "D009",
    "D011",
    "D010",
    "D013",
    "D014",
    "D015",
    "D016",
    "D017",
    "D018",
    "D019",
    "B799",
    "D020",
    "D021",
    "D022",
    "D026",
    "D027",
    "D028",
    "D029",
    "D030",
    "D037",
    "D038",
    "D040",
    "D041",
    "D042",
    "D043",
    "D044",
    "D045",
    "D046",
    "D048",
    "D049",
    "D050",
    "D051",
    "D052",
    "D054",
    "D056",
    "D057",
    "D058",
    "D068",
    "D061",
    "D062",
    "D064",
    "D065",
    "D066",
    "D067",
    "D072",
    "A266",
    "D075",
    "D076",
    "D077",
    "D078",
    "D079",
    "D081",
    "D082",
    "D085",
    "D086",
    "D087",
    "D088",
    "D089",
    "D093",
    "D092",
    "D094",
    "D095",
    "D096",
    "D099",
    "D100",
    "D101",
    "D109",
    "D110",
    "D105",
    "D111",
    "D112",
    "D103",
    "D102",
    "D117",
    "D107",
    "D108",
    "D113",
    "D114",
    "D118",
    "D119",
    "D120",
    "D121",
    "D123",
    "D124",
    "D012",
    "D126",
    "D127",
    "D128",
    "D131",
    "D132",
    "D133",
    "D134",
    "D136",
    "D137",
    "D139",
    "D141",
    "D142",
    "D143",
    "D144",
    "D145",
    "D147",
    "D149",
    "D150",
    "D151",
    "D154",
    "D156",
    "D157",
    "D158",
    "D159",
    "D160",
    "D161",
    "D162",
    "D165",
    "D166",
    "D168",
    "D170",
    "D171",
    "D172",
    "D175",
    "C670",
    "D177",
    "D179",
    "D180",
    "D181",
    "D182",
    "D184",
    "D185",
    "D122",
    "D186",
    "D187",
    "D188",
    "D189",
    "D192",
    "D194",
    "D195",
    "D196",
    "D197",
    "D198",
    "D199",
    "D200",
    "D201",
    "D202",
    "D203",
    "D204",
    "D205",
    "D206",
    "D207",
    "D208",
    "D209",
    "D210",
    "D211",
    "B824",
    "D214",
    "D216",
    "D217",
    "D218",
    "D219",
    "D221",
    "D222",
    "D223",
    "D225",
    "D226",
    "D227",
    "D228",
    "D229",
    "D231",
    "D230",
    "D232",
    "D233",
    "D234",
    "D235",
    "D236",
    "D237",
    "D238",
    "D239",
    "D243",
    "D244",
    "D245",
    "D246",
    "D247",
    "D248",
    "D250",
    "D251",
    "D253",
    "D255",
    "D256",
    "D257",
    "D258",
    "D259",
    "D260",
    "D261",
    "D264",
    "D265",
    "D266",
    "D267",
    "D268",
    "D269",
    "D270",
    "D271",
    "D272",
    "D273",
    "D277",
    "D278",
    "D279",
    "D280",
    "D281",
    "D284",
    "D286",
    "D287",
    "D289",
    "D293",
    "D296",
    "D291",
    "D297",
    "D298",
    "D299",
    "D300",
    "D302",
    "D303",
    "D304",
    "D305",
    "D309",
    "D310",
    "D311",
    "D312",
    "D314",
    "D315",
    "D316",
    "D317",
    "D318",
    "D319",
    "D321",
    "D323",
    "D325",
    "D327",
    "D328",
    "D329",
    "D330",
    "D331",
    "D332",
    "D333",
    "D334",
    "D336",
    "D339",
    "D341",
    "D338",
    "D344",
    "D345",
    "D346",
    "D347",
    "D348",
    "D349",
    "D350",
    "D351",
    "D352",
    "D355",
    "D356",
    "D357",
    "D358",
    "D360",
    "D361",
    "D364",
    "D365",
    "D366",
    "D367",
    "D369",
    "D370",
    "D371",
    "D372",
    "D373",
    "D374",
    "D376",
    "D377",
    "M300",
    "D379",
    "D380",
    "D383",
    "D384",
    "D385",
    "D386",
    "C772",
    "D388",
    "D390",
    "D391",
    "D392",
    "D394",
    "D395",
    "D398",
    "D399",
    "D401",
    "D402",
    "D403",
    "D406",
    "D407",
    "D408",
    "C342",
    "D410",
    "D411",
    "D412",
    "D414",
    "D415",
    "D416",
    "D419",
    "D420",
    "D421",
    "D422",
    "H243",
    "D423",
    "D424",
    "D426",
    "M292",
    "D428",
    "D429",
    "D430",
    "D431",
    "D434",
    "D436",
    "D440",
    "D441",
    "D442",
    "D443",
    "D444",
    "D445",
    "D433",
    "D447",
    "D449",
    "D450",
    "D451",
    "D452",
    "D453",
    "D454",
    "D455",
    "D457",
    "D456",
    "D458",
    "D459",
    "D461",
    "D462",
    "D463",
    "D465",
    "D464",
    "D467",
    "D468",
    "D469",
    "D470",
    "D471",
    "D473",
    "D472",
    "D474",
    "D475",
    "D476",
    "D477",
    "D480",
    "D481",
    "D482",
    "D483",
    "D484",
    "D486",
    "D487",
    "D488",
    "D489",
    "D494",
    "D490",
    "D493",
    "D492",
    "D491",
    "D495",
    "D496",
    "D497",
    "D499",
    "D501",
    "D502",
    "D503",
    "D506",
    "D505",
    "D504",
    "D508",
    "D509",
    "D510",
    "D511",
    "D512",
    "D514",
    "D516",
    "D518",
    "D520",
    "D523",
    "D524",
    "D526",
    "D527",
    "D528",
    "D529",
    "D530",
    "D531",
    "D532",
    "D537",
    "D538",
    "D539",
    "D540",
    "D541",
    "D542",
    "D543",
    "D544",
    "D545",
    "D547",
    "D548",
    "D549",
    "D550",
    "D551",
    "D552",
    "D554",
    "D555",
    "D557",
    "D560",
    "D562",
    "D561",
    "D564",
    "D565",
    "D567",
    "D568",
    "D569",
    "D570",
    "B034",
    "D571",
    "D572",
    "D573",
    "D574",
    "D575",
    "D576",
    "D578",
    "D577",
    "D579",
    "D583",
    "D582",
    "D586",
    "D587",
    "D588",
    "D589",
    "D590",
    "D591",
    "D592",
    "D593",
    "D594",
    "D595",
    "D596",
    "D597",
    "D599",
    "D600",
    "D604",
    "D605",
    "D606",
    "D608",
    "D607",
    "D609",
    "D611",
    "D612",
    "D613",
    "D614",
    "D615",
    "A310",
    "D617",
    "D619",
    "D621",
    "D622",
    "D624",
    "D623",
    "D627",
    "M297",
    "D628",
    "D629",
    "D630",
    "D631",
    "D634",
    "D635",
    "D636",
    "D637",
    "D638",
    "D639",
    "D640",
    "D641",
    "D643",
    "D644",
    "D645",
    "D646",
    "D649",
    "D650",
    "D651",
    "D652",
    "D653",
    "D654",
    "D655",
    "D656",
    "D660",
    "D661",
    "D662",
    "D663",
    "D665",
    "D666",
    "D667",
    "D670",
    "D671",
    "D668",
    "D672",
    "D673",
    "D674",
    "D675",
    "D676",
    "D677",
    "D678",
    "D679",
    "D680",
    "M309",
    "D681",
    "D682",
    "D683",
    "D684",
    "D685",
    "D686",
    "D688",
    "D689",
    "D691",
    "D693",
    "D694",
    "D695",
    "D696",
    "D697",
    "D700",
    "D701",
    "D702",
    "D704",
    "D703",
    "D705",
    "D706",
    "D707",
    "D708",
    "D709",
    "D710",
    "D711",
    "D712",
    "D713",
    "D714",
    "D715",
    "D718",
    "D719",
    "D720",
    "D725",
    "D726",
    "D728",
    "D727",
    "D730",
    "D731",
    "D732",
    "D733",
    "D734",
    "D735",
    "D736",
    "D738",
    "D740",
    "D741",
    "D737",
    "D742",
    "D745",
    "D744",
    "D748",
    "D749",
    "D750",
    "D751",
    "D752",
    "D559",
    "D754",
    "D755",
    "D756",
    "D757",
    "D758",
    "D763",
    "D762",
    "D759",
    "D760",
    "D765",
    "D761",
    "D766",
    "D764",
    "D767",
    "D768",
    "D769",
    "D770",
    "D771",
    "D773",
    "D774",
    "D775",
    "D776",
    "D777",
    "D780",
    "D781",
    "D782",
    "D783",
    "D785",
    "D784",
    "D788",
    "D787",
    "D789",
    "D790",
    "D791",
    "D793",
    "D794",
    "D796",
    "D797",
    "D798",
    "D799",
    "D802",
    "D803",
    "D804",
    "D805",
    "D807",
    "D808",
    "D810",
    "D811",
    "D812",
    "D813",
    "D814",
    "D815",
    "D817",
    "D818",
    "D819",
    "D821",
    "D823",
    "D824",
    "D825",
    "D826",
    "D827",
    "D828",
    "D829",
    "D830",
    "D832",
    "D834",
    "D835",
    "D836",
    "D839",
    "D841",
    "D842",
    "D843",
    "D844",
    "D845",
    "D847",
    "D848",
    "D850",
    "D849",
    "D851",
    "D852",
    "D853",
    "D854",
    "D855",
    "D856",
    "D858",
    "D859",
    "D860",
    "D861",
    "D862",
    "D863",
    "D864",
    "D865",
    "D867",
    "D868",
    "D869",
    "D870",
    "D872",
    "D871",
    "D873",
    "D874",
    "D875",
    "D876",
    "D878",
    "D879",
    "D881",
    "D882",
    "D883",
    "D884",
    "D885",
    "D886",
    "D888",
    "D889",
    "D890",
    "D891",
    "D892",
    "D894",
    "D895",
    "D896",
    "D897",
    "D898",
    "D899",
    "D901",
    "D902",
    "D903",
    "D905",
    "D906",
    "D907",
    "D909",
    "D910",
    "D911",
    "D912",
    "D913",
    "D915",
    "D917",
    "D918",
    "D920",
    "D921",
    "D923",
    "D924",
    "D925",
    "D926",
    "D927",
    "D928",
    "D930",
    "D931",
    "D932",
    "D933",
    "D934",
    "D935",
    "D937",
    "D938",
    "D940",
    "D941",
    "D942",
    "D943",
    "D944",
    "D945",
    "D946",
    "D947",
    "D948",
    "D949",
    "D951",
    "D952",
    "D956",
    "D957",
    "D958",
    "D959",
    "D960",
    "D961",
    "D962",
    "D963",
    "D964",
    "D965",
    "D966",
    "D967",
    "D968",
    "D969",
    "D970",
    "D971",
    "D972",
    "D973",
    "D974",
    "D975",
    "D977",
    "D978",
    "D980",
    "D981",
    "D982",
    "D983",
    "D984",
    "D986",
    "D987",
    "D988",
    "D990",
    "D991",
    "D993",
    "D994",
    "D995",
    "D996",
    "D997",
    "D998",
    "D999",
    "E001",
    "E003",
    "E004",
    "E006",
    "E007",
    "E008",
    "E009",
    "E010",
    "E012",
    "E011",
    "E013",
    "E014",
    "E015",
    "E016",
    "E017",
    "E019",
    "E020",
    "E021",
    "E022",
    "E023",
    "E024",
    "E025",
    "E026",
    "E027",
    "E028",
    "E029",
    "E030",
    "E031",
    "E033",
    "E034",
    "E036",
    "E037",
    "E040",
    "E038",
    "E039",
    "E041",
    "E044",
    "E043",
    "E045",
    "E047",
    "E048",
    "E049",
    "E050",
    "E051",
    "E052",
    "E053",
    "E054",
    "E055",
    "E057",
    "E056",
    "E058",
    "E059",
    "E060",
    "E061",
    "E062",
    "E063",
    "E064",
    "E065",
    "E066",
    "E067",
    "E068",
    "E069",
    "E071",
    "E072",
    "E074",
    "E078",
    "E079",
    "E081",
    "M274",
    "E082",
    "E083",
    "E084",
    "E086",
    "E087",
    "E085",
    "D585",
    "E088",
    "E089",
    "E090",
    "E091",
    "E092",
    "E093",
    "E094",
    "E096",
    "E098",
    "E101",
    "E102",
    "E100",
    "E103",
    "E104",
    "E106",
    "E107",
    "E109",
    "E111",
    "E113",
    "E114",
    "E115",
    "E116",
    "E118",
    "E120",
    "E122",
    "E124",
    "E125",
    "E126",
    "E127",
    "E128",
    "E130",
    "E131",
    "E132",
    "E133",
    "E134",
    "E135",
    "E136",
    "E138",
    "E139",
    "E141",
    "E142",
    "E143",
    "E144",
    "E145",
    "E146",
    "E147",
    "E148",
    "E149",
    "E150",
    "E151",
    "E152",
    "E153",
    "E154",
    "E156",
    "E155",
    "E158",
    "E159",
    "E160",
    "E161",
    "E163",
    "E164",
    "E165",
    "E167",
    "E168",
    "E169",
    "E170",
    "E171",
    "E172",
    "E173",
    "E177",
    "E178",
    "E179",
    "E180",
    "E182",
    "E184",
    "E185",
    "E187",
    "E188",
    "E189",
    "E191",
    "E192",
    "E193",
    "E195",
    "E196",
    "E199",
    "E200",
    "E201",
    "E202",
    "E203",
    "E204",
    "E205",
    "E206",
    "E207",
    "E208",
    "E209",
    "E210",
    "E212",
    "E213",
    "E214",
    "E215",
    "E216",
    "E217",
    "E219",
    "E221",
    "E222",
    "E223",
    "E224",
    "E226",
    "E227",
    "E228",
    "E229",
    "E230",
    "E232",
    "E233",
    "E234",
    "E235",
    "E236",
    "E240",
    "E237",
    "E238",
    "E239",
    "E241",
    "E245",
    "E246",
    "E242",
    "E249",
    "E243",
    "E244",
    "E248",
    "E250",
    "E251",
    "E252",
    "E253",
    "E255",
    "E256",
    "E258",
    "E259",
    "E261",
    "E263",
    "E264",
    "E266",
    "E269",
    "E270",
    "E271",
    "E272",
    "E273",
    "E280",
    "E281",
    "E282",
    "E283",
    "E284",
    "E285",
    "E287",
    "E288",
    "E289",
    "E290",
    "E291",
    "E292",
    "E296",
    "E295",
    "E297",
    "E299",
    "E301",
    "E304",
    "E305",
    "E306",
    "E307",
    "E308",
    "E309",
    "E310",
    "E311",
    "E313",
    "E314",
    "E317",
    "E321",
    "E323",
    "E325",
    "E326",
    "E327",
    "E328",
    "E329",
    "E330",
    "E332",
    "E333",
    "E334",
    "E335",
    "E336",
    "E337",
    "E338",
    "E341",
    "E348",
    "E343",
    "E340",
    "E351",
    "E349",
    "E350",
    "E339",
    "E353",
    "E356",
    "E358",
    "E360",
    "E354",
    "E345",
    "E346",
    "E363",
    "E364",
    "E365",
    "E366",
    "E367",
    "E368",
    "E369",
    "E370",
    "E371",
    "E373",
    "E374",
    "E375",
    "E376",
    "E377",
    "E378",
    "E379",
    "E380",
    "E274",
    "E381",
    "E382",
    "E386",
    "E387",
    "E388",
    "C388",
    "E320",
    "E389",
    "E390",
    "E391",
    "E394",
    "E423",
    "E425",
    "A308",
    "E430",
    "E458",
    "E463",
    "E470",
    "E491",
    "E490",
    "E392",
    "E393",
    "E395",
    "E396",
    "E397",
    "E398",
    "E400",
    "M212",
    "E401",
    "E402",
    "E403",
    "E405",
    "E406",
    "E407",
    "E409",
    "E410",
    "E412",
    "E414",
    "E415",
    "E416",
    "E417",
    "E419",
    "E420",
    "E421",
    "E413",
    "E422",
    "E424",
    "E426",
    "E428",
    "M208",
    "E429",
    "E431",
    "E432",
    "E433",
    "E434",
    "E435",
    "E436",
    "E437",
    "E438",
    "E439",
    "E441",
    "C767",
    "E443",
    "E444",
    "E445",
    "E447",
    "E448",
    "E450",
    "A345",
    "E451",
    "E452",
    "E454",
    "E455",
    "M207",
    "E456",
    "E464",
    "E457",
    "E459",
    "E461",
    "E462",
    "E465",
    "E466",
    "E467",
    "E468",
    "E469",
    "E471",
    "E472",
    "E473",
    "E474",
    "E475",
    "E476",
    "E480",
    "E479",
    "E481",
    "E482",
    "E483",
    "E484",
    "E485",
    "E486",
    "E487",
    "E488",
    "E489",
    "E492",
    "E493",
    "E494",
    "E496",
    "E497",
    "E498",
    "E500",
    "E502",
    "E504",
    "E506",
    "E505",
    "E507",
    "E509",
    "E510",
    "E512",
    "E514",
    "E515",
    "E517",
    "E518",
    "E519",
    "E520",
    "E522",
    "E523",
    "E524",
    "E525",
    "E526",
    "E527",
    "E528",
    "E530",
    "E531",
    "C562",
    "E532",
    "E535",
    "E536",
    "E537",
    "E538",
    "E540",
    "E539",
    "E541",
    "E542",
    "E543",
    "E544",
    "E546",
    "E547",
    "E549",
    "E550",
    "E551",
    "E552",
    "E553",
    "E554",
    "E555",
    "E557",
    "E558",
    "E559",
    "E560",
    "E562",
    "E563",
    "E564",
    "E565",
    "E566",
    "E569",
    "E570",
    "E571",
    "E573",
    "E574",
    "E576",
    "E578",
    "E581",
    "E583",
    "E584",
    "E585",
    "E586",
    "E587",
    "E588",
    "E589",
    "E590",
    "E591",
    "E592",
    "E593",
    "E594",
    "E597",
    "E596",
    "E599",
    "E600",
    "E602",
    "E605",
    "E606",
    "E607",
    "E608",
    "E610",
    "E611",
    "E613",
    "E614",
    "E615",
    "E617",
    "E620",
    "E621",
    "E622",
    "E623",
    "E624",
    "E625",
    "E626",
    "E627",
    "E629",
    "E630",
    "A771",
    "E632",
    "E633",
    "E635",
    "E639",
    "E638",
    "E640",
    "E644",
    "E645",
    "D976",
    "E646",
    "E647",
    "E648",
    "E651",
    "E649",
    "E652",
    "E654",
    "E655",
    "M275",
    "E656",
    "E658",
    "E659",
    "E660",
    "E661",
    "E662",
    "E664",
    "E665",
    "E666",
    "E667",
    "E668",
    "E669",
    "E671",
    "E672",
    "E673",
    "E674",
    "E675",
    "E677",
    "E678",
    "E679",
    "E681",
    "E682",
    "E683",
    "E684",
    "E685",
    "E687",
    "E688",
    "E689",
    "E690",
    "E691",
    "E692",
    "E693",
    "E694",
    "E695",
    "E698",
    "E700",
    "E704",
    "E705",
    "E706",
    "E707",
    "E709",
    "E708",
    "E711",
    "E712",
    "E713",
    "E715",
    "E714",
    "E716",
    "E718",
    "E719",
    "E722",
    "E723",
    "E724",
    "E726",
    "E727",
    "E729",
    "E730",
    "E731",
    "E734",
    "E735",
    "E736",
    "E737",
    "E738",
    "E742",
    "E743",
    "B387",
    "E745",
    "E746",
    "E747",
    "E748",
    "E749",
    "E750",
    "E751",
    "E752",
    "E753",
    "E754",
    "E757",
    "E758",
    "E759",
    "E760",
    "E761",
    "E762",
    "E763",
    "E764",
    "E767",
    "E769",
    "E770",
    "E772",
    "E773",
    "E775",
    "E777",
    "E778",
    "E780",
    "E779",
    "E782",
    "E783",
    "E784",
    "E785",
    "E786",
    "E787",
    "E788",
    "E789",
    "E790",
    "E791",
    "E342",
    "E793",
    "E794",
    "E795",
    "E798",
    "E799",
    "E800",
    "E801",
    "E803",
    "E804",
    "E805",
    "E806",
    "E809",
    "E808",
    "E811",
    "E807",
    "E810",
    "E813",
    "E812",
    "E814",
    "E815",
    "E816",
    "E817",
    "E818",
    "E819",
    "E821",
    "E820",
    "E825",
    "E829",
    "E830",
    "E834",
    "E835",
    "E836",
    "E837",
    "E838",
    "E839",
    "E840",
    "E841",
    "E842",
    "E833",
    "E843",
    "E844",
    "E847",
    "E848",
    "E850",
    "E851",
    "E852",
    "E853",
    "E854",
    "E855",
    "E856",
    "E858",
    "E859",
    "E860",
    "E862",
    "E863",
    "E864",
    "E865",
    "E866",
    "E868",
    "E869",
    "E870",
    "E872",
    "E873",
    "E874",
    "E875",
    "E876",
    "E877",
    "E878",
    "B632",
    "E879",
    "E880",
    "E882",
    "E883",
    "E884",
    "E885",
    "E887",
    "E888",
    "M283",
    "E889",
    "E891",
    "E892",
    "E893",
    "E894",
    "E896",
    "E897",
    "E899",
    "E900",
    "E901",
    "E902",
    "E903",
    "E904",
    "E906",
    "E911",
    "E908",
    "E910",
    "E914",
    "E915",
    "E905",
    "E907",
    "E912",
    "E917",
    "E919",
    "E921",
    "E922",
    "E923",
    "E924",
    "E925",
    "E927",
    "E928",
    "E929",
    "E930",
    "E931",
    "E932",
    "E933",
    "E934",
    "E936",
    "E938",
    "E939",
    "E940",
    "E941",
    "E944",
    "E945",
    "E946",
    "E947",
    "E949",
    "E951",
    "E952",
    "E953",
    "E954",
    "E955",
    "E956",
    "E957",
    "E958",
    "E959",
    "E960",
    "E961",
    "E962",
    "E963",
    "E965",
    "E967",
    "E968",
    "E970",
    "E971",
    "E972",
    "E973",
    "E974",
    "E975",
    "E976",
    "E977",
    "E978",
    "E979",
    "E980",
    "E981",
    "E982",
    "E983",
    "E984",
    "E986",
    "E987",
    "E988",
    "E989",
    "E990",
    "E991",
    "E992",
    "E993",
    "E994",
    "E995",
    "B689",
    "E999",
    "E998",
    "E997",
    "F001",
    "F002",
    "M270",
    "F003",
    "F004",
    "F005",
    "F006",
    "F007",
    "F009",
    "F010",
    "F011",
    "F012",
    "F013",
    "F016",
    "F015",
    "F017",
    "F019",
    "F020",
    "F023",
    "F022",
    "M289",
    "F025",
    "F021",
    "F026",
    "F029",
    "F030",
    "F032",
    "F024",
    "F027",
    "F028",
    "F033",
    "F035",
    "F037",
    "F041",
    "F042",
    "F044",
    "F045",
    "F046",
    "F047",
    "F048",
    "F050",
    "F051",
    "F052",
    "F053",
    "F054",
    "F055",
    "F058",
    "F059",
    "F061",
    "F063",
    "F064",
    "F065",
    "F066",
    "M271",
    "F067",
    "F068",
    "F070",
    "F074",
    "F073",
    "F078",
    "F080",
    "F081",
    "F082",
    "F083",
    "F084",
    "F085",
    "F086",
    "F087",
    "F088",
    "F089",
    "F091",
    "F092",
    "F093",
    "F094",
    "F095",
    "F096",
    "F097",
    "F098",
    "F100",
    "F101",
    "F102",
    "F104",
    "F105",
    "F106",
    "F107",
    "F108",
    "F109",
    "F111",
    "F112",
    "F110",
    "F113",
    "F114",
    "F115",
    "F117",
    "F118",
    "F119",
    "F120",
    "F121",
    "F122",
    "F123",
    "F125",
    "F126",
    "F127",
    "F130",
    "F131",
    "F132",
    "F133",
    "F134",
    "F135",
    "F136",
    "F138",
    "F139",
    "F140",
    "F141",
    "F144",
    "F145",
    "F146",
    "F147",
    "F148",
    "F149",
    "F151",
    "F152",
    "F153",
    "F154",
    "F155",
    "F156",
    "F157",
    "F158",
    "F161",
    "F162",
    "F164",
    "F165",
    "F168",
    "F170",
    "F167",
    "F171",
    "F172",
    "F173",
    "F174",
    "F175",
    "F176",
    "F181",
    "F182",
    "F183",
    "F184",
    "F186",
    "F187",
    "F188",
    "F189",
    "F190",
    "F191",
    "F192",
    "F193",
    "F194",
    "F196",
    "F198",
    "F199",
    "F200",
    "F201",
    "F202",
    "F203",
    "F205",
    "F206",
    "E618",
    "F207",
    "F208",
    "F209",
    "F210",
    "F213",
    "F214",
    "F216",
    "F217",
    "F218",
    "F219",
    "F221",
    "F220",
    "F223",
    "F224",
    "F225",
    "F226",
    "F229",
    "F230",
    "F231",
    "F235",
    "F232",
    "F233",
    "F238",
    "F239",
    "F240",
    "F241",
    "F242",
    "F244",
    "F243",
    "F246",
    "F247",
    "F248",
    "F249",
    "F250",
    "F251",
    "F254",
    "F256",
    "F257",
    "F258",
    "F259",
    "F261",
    "F262",
    "F263",
    "F265",
    "F266",
    "F267",
    "F268",
    "F269",
    "F270",
    "F272",
    "F274",
    "F275",
    "F277",
    "F276",
    "F278",
    "F279",
    "F280",
    "F281",
    "F283",
    "F284",
    "M255",
    "F286",
    "F287",
    "F288",
    "F290",
    "F293",
    "F294",
    "F295",
    "F297",
    "F301",
    "F304",
    "F305",
    "F306",
    "F307",
    "F308",
    "F309",
    "F310",
    "F311",
    "F312",
    "F315",
    "F313",
    "F316",
    "F317",
    "F318",
    "F319",
    "F320",
    "F322",
    "F323",
    "F324",
    "F325",
    "F327",
    "F326",
    "F329",
    "F328",
    "F330",
    "F332",
    "F333",
    "F335",
    "F336",
    "D553",
    "F337",
    "F338",
    "F340",
    "F341",
    "F342",
    "F343",
    "F346",
    "F347",
    "F348",
    "F351",
    "F352",
    "F354",
    "F355",
    "F356",
    "F358",
    "F359",
    "F360",
    "F361",
    "F363",
    "F364",
    "F365",
    "F368",
    "F369",
    "F370",
    "F371",
    "F372",
    "F373",
    "F374",
    "F375",
    "F376",
    "F377",
    "F378",
    "F379",
    "F380",
    "F381",
    "F382",
    "F383",
    "F384",
    "F385",
    "F386",
    "F387",
    "F390",
    "F391",
    "F392",
    "F393",
    "F394",
    "F395",
    "F396",
    "F397",
    "F398",
    "F400",
    "F399",
    "F402",
    "F403",
    "F404",
    "F405",
    "F408",
    "F409",
    "F407",
    "F410",
    "F411",
    "F414",
    "F415",
    "F419",
    "F420",
    "F406",
    "F417",
    "F416",
    "F422",
    "F423",
    "F424",
    "F426",
    "F427",
    "F428",
    "F429",
    "F430",
    "F432",
    "F433",
    "F437",
    "F456",
    "F460",
    "F467",
    "F476",
    "F434",
    "F486",
    "F488",
    "F517",
    "F524",
    "F532",
    "F561",
    "F589",
    "F590",
    "F599",
    "F600",
    "F603",
    "F616",
    "F618",
    "F620",
    "F619",
    "F621",
    "F622",
    "F626",
    "F627",
    "F628",
    "F634",
    "F629",
    "F631",
    "F653",
    "F664",
    "F665",
    "F440",
    "F441",
    "D746",
    "B268",
    "F442",
    "F443",
    "F445",
    "F446",
    "F450",
    "F448",
    "F449",
    "F452",
    "F453",
    "F454",
    "F455",
    "F457",
    "A561",
    "F458",
    "F461",
    "F462",
    "F463",
    "F464",
    "F465",
    "F469",
    "F468",
    "F473",
    "F474",
    "F475",
    "F477",
    "F478",
    "F479",
    "F480",
    "F481",
    "F482",
    "F483",
    "F484",
    "F487",
    "F489",
    "F491",
    "F492",
    "F493",
    "F494",
    "F495",
    "F496",
    "F497",
    "F498",
    "F499",
    "F500",
    "F502",
    "F501",
    "F503",
    "F504",
    "F507",
    "F508",
    "F506",
    "F509",
    "F510",
    "F511",
    "F512",
    "F513",
    "F514",
    "F515",
    "F516",
    "F518",
    "F519",
    "F520",
    "F522",
    "F523",
    "F526",
    "F527",
    "F528",
    "F529",
    "F531",
    "F533",
    "F534",
    "F535",
    "F536",
    "F538",
    "F540",
    "F543",
    "F542",
    "F541",
    "F544",
    "F545",
    "F546",
    "F547",
    "F548",
    "F549",
    "F550",
    "F551",
    "F552",
    "F555",
    "F553",
    "F556",
    "F558",
    "F559",
    "F560",
    "F562",
    "F563",
    "F564",
    "F565",
    "F566",
    "F568",
    "F569",
    "F570",
    "F572",
    "F573",
    "F574",
    "F576",
    "F579",
    "F580",
    "F578",
    "F582",
    "F586",
    "F587",
    "F591",
    "F592",
    "F593",
    "F594",
    "F595",
    "F596",
    "F597",
    "F598",
    "F601",
    "F605",
    "F604",
    "F606",
    "F609",
    "F610",
    "F607",
    "F608",
    "F611",
    "F612",
    "F614",
    "F623",
    "F625",
    "F636",
    "F637",
    "F638",
    "F639",
    "F640",
    "F641",
    "F642",
    "F644",
    "F646",
    "F648",
    "F651",
    "F654",
    "F655",
    "F656",
    "F657",
    "F659",
    "F660",
    "F661",
    "F662",
    "F666",
    "F667",
    "F668",
    "F672",
    "F671",
    "F670",
    "F674",
    "F675",
    "F669",
    "F471",
    "F676",
    "F677",
    "M302",
    "F679",
    "F680",
    "F367",
    "F681",
    "F682",
    "F685",
    "F687",
    "F686",
    "F688",
    "F690",
    "F689",
    "F692",
    "F693",
    "F694",
    "F696",
    "F697",
    "F698",
    "F701",
    "F703",
    "F704",
    "F705",
    "F706",
    "F708",
    "F707",
    "F709",
    "F710",
    "F711",
    "F712",
    "F713",
    "F716",
    "F715",
    "F717",
    "F718",
    "F720",
    "F721",
    "F722",
    "F723",
    "F724",
    "F725",
    "F726",
    "F727",
    "F728",
    "F729",
    "F730",
    "F731",
    "D033",
    "F732",
    "F733",
    "F734",
    "F735",
    "F736",
    "F737",
    "F738",
    "F739",
    "F740",
    "F743",
    "F744",
    "F745",
    "F747",
    "F746",
    "F748",
    "F749",
    "F750",
    "F751",
    "F754",
    "F756",
    "F758",
    "F760",
    "F761",
    "F762",
    "F764",
    "F765",
    "F766",
    "F767",
    "F768",
    "M304",
    "F771",
    "F772",
    "F773",
    "F774",
    "F770",
    "F777",
    "F779",
    "F780",
    "F781",
    "F783",
    "F775",
    "F776",
    "B012",
    "F784",
    "F785",
    "F786",
    "F788",
    "F789",
    "F791",
    "F793",
    "F795",
    "F797",
    "F798",
    "F799",
    "F801",
    "F802",
    "F806",
    "F808",
    "F809",
    "F811",
    "F813",
    "F814",
    "F815",
    "F816",
    "F817",
    "F818",
    "F820",
    "F822",
    "F826",
    "F828",
    "F829",
    "F830",
    "F832",
    "F833",
    "F835",
    "F836",
    "F837",
    "F838",
    "F839",
    "F840",
    "F841",
    "F842",
    "F843",
    "F844",
    "F845",
    "F846",
    "F847",
    "F848",
    "F849",
    "F851",
    "F853",
    "F852",
    "F856",
    "F857",
    "F858",
    "F859",
    "F861",
    "F862",
    "F863",
    "F864",
    "F865",
    "F866",
    "F867",
    "F868",
    "F870",
    "F871",
    "F872",
    "F874",
    "F876",
    "F877",
    "F878",
    "F880",
    "F881",
    "F882",
    "F883",
    "F884",
    "F885",
    "F886",
    "F887",
    "F889",
    "F890",
    "F891",
    "F892",
    "F893",
    "F894",
    "F895",
    "F898",
    "F899",
    "F900",
    "F901",
    "F902",
    "F904",
    "F906",
    "F907",
    "F908",
    "F912",
    "F913",
    "F910",
    "F911",
    "F914",
    "F915",
    "F916",
    "F917",
    "F918",
    "F920",
    "F921",
    "F922",
    "F923",
    "F924",
    "F925",
    "F926",
    "F927",
    "F929",
    "F930",
    "F931",
    "F932",
    "F933",
    "F934",
    "F935",
    "F937",
    "F939",
    "F942",
    "F943",
    "F949",
    "F944",
    "F950",
    "A942",
    "F137",
    "F947",
    "F948",
    "F952",
    "F951",
    "F956",
    "F955",
    "F957",
    "F958",
    "F960",
    "F961",
    "F963",
    "F962",
    "F964",
    "F966",
    "F965",
    "F967",
    "F968",
    "F970",
    "F972",
    "F975",
    "F974",
    "F976",
    "F977",
    "F978",
    "F979",
    "F980",
    "F981",
    "F982",
    "F983",
    "F985",
    "F986",
    "F987",
    "F988",
    "F989",
    "F990",
    "F991",
    "F992",
    "F993",
    "F994",
    "F995",
    "F996",
    "F997",
    "F998",
    "F999",
    "G001",
    "G002",
    "G003",
    "G004",
    "G005",
    "G006",
    "G007",
    "G008",
    "G009",
    "G010",
    "G011",
    "G015",
    "G016",
    "G018",
    "G019",
    "G020",
    "G021",
    "G022",
    "G023",
    "G025",
    "G026",
    "G028",
    "G030",
    "G031",
    "G032",
    "G034",
    "G036",
    "G039",
    "G040",
    "G037",
    "G041",
    "G042",
    "G043",
    "G044",
    "G045",
    "G046",
    "G047",
    "G049",
    "G048",
    "G050",
    "G054",
    "G056",
    "G058",
    "G061",
    "G062",
    "G063",
    "G064",
    "G065",
    "G066",
    "G068",
    "G070",
    "G071",
    "G074",
    "G075",
    "G076",
    "G078",
    "G079",
    "G080",
    "G081",
    "G082",
    "G083",
    "G084",
    "G086",
    "G087",
    "G088",
    "G089",
    "G090",
    "D522",
    "M266",
    "G093",
    "G095",
    "G097",
    "G098",
    "G102",
    "G103",
    "G105",
    "G108",
    "G109",
    "G107",
    "G110",
    "G111",
    "G113",
    "G114",
    "G115",
    "G116",
    "G117",
    "G118",
    "G119",
    "G120",
    "G121",
    "G122",
    "G123",
    "G124",
    "G125",
    "G126",
    "G128",
    "G129",
    "G130",
    "G131",
    "G134",
    "G133",
    "G135",
    "G136",
    "G137",
    "G139",
    "G140",
    "G141",
    "G142",
    "G143",
    "G144",
    "G145",
    "G146",
    "G147",
    "G148",
    "B595",
    "G149",
    "G150",
    "G151",
    "G152",
    "G153",
    "G154",
    "G155",
    "G156",
    "G157",
    "G158",
    "G159",
    "G160",
    "E529",
    "G161",
    "G163",
    "G164",
    "G168",
    "G165",
    "G167",
    "G166",
    "G169",
    "G170",
    "G171",
    "G173",
    "G178",
    "G179",
    "G181",
    "G182",
    "G183",
    "G184",
    "G185",
    "G186",
    "F401",
    "F581",
    "G187",
    "G188",
    "G189",
    "G191",
    "G192",
    "G190",
    "G193",
    "G194",
    "G195",
    "G196",
    "G197",
    "G198",
    "G199",
    "G200",
    "G201",
    "G012",
    "G202",
    "G203",
    "G205",
    "G204",
    "G206",
    "G207",
    "G209",
    "G208",
    "G210",
    "G211",
    "G212",
    "G213",
    "G214",
    "G215",
    "G218",
    "G221",
    "G220",
    "G217",
    "G222",
    "G224",
    "G225",
    "M301",
    "G226",
    "G227",
    "G228",
    "G229",
    "G230",
    "G232",
    "G233",
    "G234",
    "G237",
    "G238",
    "G240",
    "G241",
    "G242",
    "G243",
    "G247",
    "G248",
    "G249",
    "G250",
    "G251",
    "G252",
    "G253",
    "G254",
    "G255",
    "G257",
    "G258",
    "G259",
    "G263",
    "G262",
    "G260",
    "G261",
    "G267",
    "G268",
    "G264",
    "G266",
    "G270",
    "G271",
    "G272",
    "G273",
    "G274",
    "G275",
    "G276",
    "G277",
    "G278",
    "G280",
    "G281",
    "G283",
    "G282",
    "G284",
    "G285",
    "G286",
    "G288",
    "G289",
    "G290",
    "G291",
    "G293",
    "G294",
    "G292",
    "G295",
    "G297",
    "G296",
    "G298",
    "G300",
    "G302",
    "G303",
    "G304",
    "G305",
    "G306",
    "G307",
    "G308",
    "G311",
    "G312",
    "G315",
    "G316",
    "G317",
    "G318",
    "G320",
    "G323",
    "G324",
    "G325",
    "G327",
    "G328",
    "G329",
    "G330",
    "G331",
    "G333",
    "G334",
    "G335",
    "G336",
    "G337",
    "G338",
    "G339",
    "G340",
    "G342",
    "G344",
    "G346",
    "G347",
    "G348",
    "G349",
    "G350",
    "G352",
    "G353",
    "G354",
    "G358",
    "G359",
    "G361",
    "G362",
    "G364",
    "G365",
    "G367",
    "G368",
    "M269",
    "G371",
    "G372",
    "G370",
    "G374",
    "G376",
    "G377",
    "G378",
    "G379",
    "G381",
    "G382",
    "G384",
    "G385",
    "G386",
    "G387",
    "G388",
    "G389",
    "G392",
    "G391",
    "G393",
    "G394",
    "G395",
    "G396",
    "G397",
    "G398",
    "G399",
    "G400",
    "G402",
    "G403",
    "G404",
    "G406",
    "G408",
    "G410",
    "G411",
    "G412",
    "G415",
    "G416",
    "G417",
    "G418",
    "G419",
    "G420",
    "G421",
    "G424",
    "G426",
    "G427",
    "G428",
    "G429",
    "G430",
    "G432",
    "G436",
    "G437",
    "G433",
    "G434",
    "G435",
    "G438",
    "G439",
    "G441",
    "G442",
    "G443",
    "G444",
    "G445",
    "G446",
    "G447",
    "G448",
    "G449",
    "G450",
    "G451",
    "G452",
    "G453",
    "G454",
    "G455",
    "G456",
    "G457",
    "G458",
    "G459",
    "G461",
    "C013",
    "G463",
    "G462",
    "G465",
    "G469",
    "G471",
    "G474",
    "G475",
    "G476",
    "G477",
    "G478",
    "G479",
    "G480",
    "G481",
    "G482",
    "G483",
    "G484",
    "G485",
    "G486",
    "G487",
    "G488",
    "G489",
    "G491",
    "G492",
    "G494",
    "G493",
    "G495",
    "G496",
    "G497",
    "G498",
    "G499",
    "G500",
    "G502",
    "G504",
    "G505",
    "G506",
    "G508",
    "G509",
    "G510",
    "G511",
    "G513",
    "G512",
    "G514",
    "G515",
    "G516",
    "G517",
    "G518",
    "M281",
    "G519",
    "G520",
    "G521",
    "G522",
    "G523",
    "G524",
    "G525",
    "G526",
    "G528",
    "G529",
    "G532",
    "G535",
    "G534",
    "G536",
    "G537",
    "G538",
    "G546",
    "G552",
    "G542",
    "G543",
    "G541",
    "G547",
    "G549",
    "G551",
    "G553",
    "G555",
    "G556",
    "G557",
    "G558",
    "G559",
    "G560",
    "G561",
    "G564",
    "G565",
    "G568",
    "D546",
    "G570",
    "G571",
    "G572",
    "G574",
    "G575",
    "G576",
    "G577",
    "G582",
    "G580",
    "G579",
    "G583",
    "G587",
    "G588",
    "G589",
    "G590",
    "G591",
    "G592",
    "G593",
    "G594",
    "G597",
    "G596",
    "G598",
    "G600",
    "G601",
    "G602",
    "G603",
    "G612",
    "G605",
    "G619",
    "G606",
    "G607",
    "G608",
    "G609",
    "G610",
    "G611",
    "G613",
    "G615",
    "G616",
    "G618",
    "G620",
    "G604",
    "G621",
    "G622",
    "G623",
    "G624",
    "G625",
    "G626",
    "G627",
    "G628",
    "G629",
    "G630",
    "G631",
    "G636",
    "G635",
    "G638",
    "G639",
    "G641",
    "G642",
    "G643",
    "G633",
    "G644",
    "G645",
    "G632",
    "G647",
    "G634",
    "G096",
    "G648",
    "G646",
    "G650",
    "G651",
    "G653",
    "G656",
    "G657",
    "G658",
    "G637",
    "G649",
    "G659",
    "G660",
    "G662",
    "G661",
    "G663",
    "G664",
    "G665",
    "G666",
    "G669",
    "G670",
    "G671",
    "G672",
    "G673",
    "G674",
    "F831",
    "G676",
    "G677",
    "G678",
    "G680",
    "G681",
    "G682",
    "G683",
    "G684",
    "G685",
    "G686",
    "G687",
    "G688",
    "G690",
    "G691",
    "G692",
    "G693",
    "G694",
    "G695",
    "G696",
    "G697",
    "G699",
    "G702",
    "G703",
    "G705",
    "M291",
    "G707",
    "G710",
    "G704",
    "G712",
    "G713",
    "G715",
    "G716",
    "G717",
    "G718",
    "G719",
    "G720",
    "G721",
    "G722",
    "G724",
    "G726",
    "G727",
    "G728",
    "G729",
    "G733",
    "G734",
    "G735",
    "G299",
    "G737",
    "G740",
    "G741",
    "G742",
    "G743",
    "G746",
    "G747",
    "G749",
    "G751",
    "G752",
    "G754",
    "G755",
    "G756",
    "G757",
    "G761",
    "G763",
    "G764",
    "G765",
    "G766",
    "G768",
    "G753",
    "G770",
    "G771",
    "D566",
    "B317",
    "G758",
    "G760",
    "G762",
    "G767",
    "G769",
    "G431",
    "G772",
    "G773",
    "G774",
    "G775",
    "G776",
    "G777",
    "G779",
    "G780",
    "G782",
    "G783",
    "G784",
    "G785",
    "G786",
    "G787",
    "G789",
    "G790",
    "G791",
    "G792",
    "G793",
    "G794",
    "G795",
    "F567",
    "G796",
    "G797",
    "G798",
    "G799",
    "G800",
    "G801",
    "G802",
    "G803",
    "G804",
    "G805",
    "G806",
    "G807",
    "G808",
    "G809",
    "G811",
    "G812",
    "G813",
    "G814",
    "G815",
    "G816",
    "G817",
    "G818",
    "G820",
    "G821",
    "G822",
    "G823",
    "G826",
    "G825",
    "G545",
    "G827",
    "G833",
    "G842",
    "G844",
    "G846",
    "G830",
    "G829",
    "G847",
    "B662",
    "G851",
    "F941",
    "G855",
    "G856",
    "G831",
    "G834",
    "G836",
    "G837",
    "G838",
    "G839",
    "G840",
    "G843",
    "G848",
    "G849",
    "G850",
    "G852",
    "G853",
    "G858",
    "G859",
    "G860",
    "G861",
    "G862",
    "G864",
    "G865",
    "G866",
    "G867",
    "G869",
    "G870",
    "G854",
    "G871",
    "G873",
    "G872",
    "G874",
    "G875",
    "G877",
    "G878",
    "G879",
    "G881",
    "G882",
    "G886",
    "G888",
    "G889",
    "G890",
    "G891",
    "A558",
    "G894",
    "G895",
    "G900",
    "G902",
    "G903",
    "G904",
    "G905",
    "E680",
    "G906",
    "M263",
    "F299",
    "G917",
    "G919",
    "G920",
    "G921",
    "G923",
    "G924",
    "G907",
    "G926",
    "G909",
    "G910",
    "G912",
    "G913",
    "G914",
    "G916",
    "M257",
    "G922",
    "G925",
    "G927",
    "G929",
    "G931",
    "G932",
    "G933",
    "G934",
    "G935",
    "G936",
    "G937",
    "G939",
    "G940",
    "G942",
    "F632",
    "G943",
    "G944",
    "G945",
    "G947",
    "G949",
    "G950",
    "G951",
    "B914",
    "G953",
    "G954",
    "G955",
    "G960",
    "G959",
    "G957",
    "G961",
    "G962",
    "G963",
    "G964",
    "G966",
    "G965",
    "G968",
    "G969",
    "G970",
    "G973",
    "G975",
    "G976",
    "G977",
    "G978",
    "G979",
    "G980",
    "G981",
    "G982",
    "G985",
    "G986",
    "G987",
    "G988",
    "G989",
    "G993",
    "G992",
    "G994",
    "G990",
    "G991",
    "G995",
    "G997",
    "G999",
    "H004",
    "H002",
    "H001",
    "H007",
    "H006",
    "H008",
    "H010",
    "G974",
    "H011",
    "H014",
    "H015",
    "H017",
    "H018",
    "H019",
    "H020",
    "H021",
    "H022",
    "H026",
    "H027",
    "H028",
    "H029",
    "H030",
    "H033",
    "H034",
    "H036",
    "H037",
    "H038",
    "H039",
    "H040",
    "H042",
    "H043",
    "H045",
    "H046",
    "H047",
    "H048",
    "H050",
    "H052",
    "H055",
    "H056",
    "H057",
    "H059",
    "H062",
    "H061",
    "H063",
    "H068",
    "H069",
    "M279",
    "G698",
    "H070",
    "H071",
    "H072",
    "H073",
    "H074",
    "H076",
    "H078",
    "H077",
    "H081",
    "H083",
    "H085",
    "H086",
    "H087",
    "H088",
    "H089",
    "H090",
    "H091",
    "H092",
    "H094",
    "H095",
    "H096",
    "H097",
    "H098",
    "H100",
    "H101",
    "H102",
    "H103",
    "H104",
    "H106",
    "H107",
    "H108",
    "H109",
    "H110",
    "H114",
    "H117",
    "H118",
    "H119",
    "H120",
    "H121",
    "H122",
    "H124",
    "H126",
    "H127",
    "H128",
    "H129",
    "H130",
    "H131",
    "H132",
    "H134",
    "H140",
    "H143",
    "H145",
    "H146",
    "H147",
    "H148",
    "H150",
    "H151",
    "H152",
    "H153",
    "H154",
    "H156",
    "H157",
    "H159",
    "M287",
    "H161",
    "H162",
    "H163",
    "H166",
    "H168",
    "G654",
    "H171",
    "H173",
    "H174",
    "H175",
    "H176",
    "H177",
    "H180",
    "H182",
    "H183",
    "H184",
    "H185",
    "H186",
    "H187",
    "H188",
    "H189",
    "H192",
    "H194",
    "H195",
    "H196",
    "H198",
    "H199",
    "H200",
    "H202",
    "H203",
    "H204",
    "H205",
    "H206",
    "H207",
    "H210",
    "H211",
    "H212",
    "H213",
    "H214",
    "H216",
    "H218",
    "H219",
    "H220",
    "H221",
    "H222",
    "H224",
    "H223",
    "H225",
    "H227",
    "H228",
    "H229",
    "H230",
    "H233",
    "H235",
    "H236",
    "H238",
    "H240",
    "H242",
    "H244",
    "H245",
    "H246",
    "H247",
    "H248",
    "H250",
    "H253",
    "H254",
    "H255",
    "H256",
    "H257",
    "H258",
    "H262",
    "H263",
    "H264",
    "H265",
    "H266",
    "H267",
    "H268",
    "H269",
    "H270",
    "H271",
    "H272",
    "H273",
    "H274",
    "H275",
    "H276",
    "H277",
    "H280",
    "H281",
    "H282",
    "H284",
    "H285",
    "H288",
    "H287",
    "H286",
    "H289",
    "H291",
    "H292",
    "H293",
    "H294",
    "H299",
    "H305",
    "H297",
    "H298",
    "H300",
    "H301",
    "H302",
    "H303",
    "H304",
    "H307",
    "H308",
    "H320",
    "H311",
    "H312",
    "H313",
    "H314",
    "H315",
    "H316",
    "H319",
    "H321",
    "H322",
    "H323",
    "H324",
    "H325",
    "H326",
    "H330",
    "H331",
    "H328",
    "H337",
    "H329",
    "H333",
    "H334",
    "H335",
    "H327",
    "H336",
    "H338",
    "H340",
    "H341",
    "H342",
    "H343",
    "H344",
    "H346",
    "H347",
    "H348",
    "H350",
    "H352",
    "H353",
    "H354",
    "H355",
    "H356",
    "H357",
    "H359",
    "H360",
    "H361",
    "H362",
    "H363",
    "H365",
    "H366",
    "H367",
    "G223",
    "H369",
    "H371",
    "H372",
    "H375",
    "H373",
    "H376",
    "H377",
    "H378",
    "H386",
    "H387",
    "H391",
    "H392",
    "H395",
    "H396",
    "H398",
    "H399",
    "H400",
    "H401",
    "H402",
    "H403",
    "H404",
    "H414",
    "H416",
    "H421",
    "H429",
    "H379",
    "H432",
    "H437",
    "H438",
    "H439",
    "H440",
    "H441",
    "H446",
    "H450",
    "H382",
    "H383",
    "H384",
    "H385",
    "H389",
    "H393",
    "H394",
    "H405",
    "H390",
    "H408",
    "H406",
    "H407",
    "H409",
    "H410",
    "H411",
    "H412",
    "H413",
    "H417",
    "H418",
    "H420",
    "H422",
    "H423",
    "H424",
    "H425",
    "H426",
    "H427",
    "H428",
    "H431",
    "H433",
    "H434",
    "H436",
    "H442",
    "H443",
    "H444",
    "H445",
    "H447",
    "H448",
    "H449",
    "H380",
    "H451",
    "H452",
    "H453",
    "H454",
    "H456",
    "H455",
    "H458",
    "H462",
    "H461",
    "H459",
    "H465",
    "H460",
    "H466",
    "H467",
    "H468",
    "H470",
    "H472",
    "H473",
    "H474",
    "H475",
    "H477",
    "H478",
    "H480",
    "H479",
    "H481",
    "H484",
    "H485",
    "H486",
    "H488",
    "H489",
    "H490",
    "H491",
    "H492",
    "H493",
    "H494",
    "H495",
    "H497",
    "H498",
    "H500",
    "H501",
    "H503",
    "H502",
    "H505",
    "H506",
    "H507",
    "H508",
    "H511",
    "H512",
    "H509",
    "H514",
    "H516",
    "H517",
    "H518",
    "H519",
    "H521",
    "H522",
    "H523",
    "H525",
    "H527",
    "H528",
    "H529",
    "H531",
    "H532",
    "H533",
    "H534",
    "H540",
    "H538",
    "H537",
    "H539",
    "H536",
    "H535",
    "H541",
    "H542",
    "H544",
    "H545",
    "H546",
    "H547",
    "H549",
    "M303",
    "H552",
    "H553",
    "H554",
    "H556",
    "H558",
    "H559",
    "H560",
    "H561",
    "H562",
    "H564",
    "H565",
    "H566",
    "H572",
    "F585",
    "H568",
    "H570",
    "H569",
    "H573",
    "H574",
    "H575",
    "H577",
    "H578",
    "H579",
    "H580",
    "H581",
    "H583",
    "H584",
    "H585",
    "H588",
    "H589",
    "H590",
    "H591",
    "H592",
    "H593",
    "H594",
    "H555",
    "H596",
    "H364",
    "H598",
    "H599",
    "H601",
    "H602",
    "H604",
    "H606",
    "H607",
    "H608",
    "H610",
    "H609",
    "H612",
    "H614",
    "H615",
    "H618",
    "H620",
    "H621",
    "H622",
    "H623",
    "H625",
    "H627",
    "H628",
    "H629",
    "H630",
    "H631",
    "H632",
    "H633",
    "H634",
    "H635",
    "F271",
    "H637",
    "H639",
    "H641",
    "H642",
    "H643",
    "H644",
    "H165",
    "H646",
    "H645",
    "H647",
    "H648",
    "H650",
    "H652",
    "H654",
    "H655",
    "H657",
    "H658",
    "H659",
    "H661",
    "H662",
    "H665",
    "H666",
    "H669",
    "H670",
    "H671",
    "H672",
    "H673",
    "H674",
    "H675",
    "H676",
    "H682",
    "H681",
    "H678",
    "H679",
    "H683",
    "H677",
    "H687",
    "H688",
    "H689",
    "H690",
    "H691",
    "H684",
    "F810",
    "H693",
    "H694",
    "H695",
    "H699",
    "H704",
    "H700",
    "H686",
    "H702",
    "H701",
    "H703",
    "H705",
    "H706",
    "H707",
    "H708",
    "H710",
    "H713",
    "H714",
    "H715",
    "H716",
    "H717",
    "H719",
    "H720",
    "H721",
    "H723",
    "H724",
    "H725",
    "H726",
    "H727",
    "H729",
    "H731",
    "H732",
    "H734",
    "H733",
    "H735",
    "H736",
    "H738",
    "H739",
    "H743",
    "H744",
    "H745",
    "H746",
    "H749",
    "H013",
    "H752",
    "H753",
    "H754",
    "H755",
    "H756",
    "H763",
    "H764",
    "H760",
    "H765",
    "H766",
    "H767",
    "H768",
    "H770",
    "H772",
    "H769",
    "H773",
    "H771",
    "H774",
    "G566",
    "H775",
    "H777",
    "H780",
    "H781",
    "H778",
    "H779",
    "H782",
    "H783",
    "H784",
    "H785",
    "H786",
    "H787",
    "H789",
    "H790",
    "H791",
    "M264",
    "H792",
    "M295",
    "H793",
    "H794",
    "H795",
    "H796",
    "H797",
    "H798",
    "H800",
    "H799",
    "H801",
    "H803",
    "H804",
    "H802",
    "H805",
    "H806",
    "H808",
    "H807",
    "H809",
    "H810",
    "H814",
    "H811",
    "H812",
    "H816",
    "H815",
    "H818",
    "H819",
    "H820",
    "H823",
    "H822",
    "H826",
    "H825",
    "H827",
    "H824",
    "D324",
    "H830",
    "H831",
    "H834",
    "H836",
    "H838",
    "H833",
    "H835",
    "M277",
    "H839",
    "H840",
    "H841",
    "H842",
    "H843",
    "H844",
    "H845",
    "H846",
    "H847",
    "H850",
    "H856",
    "H857",
    "H858",
    "H859",
    "H860",
    "H862",
    "H863",
    "H861",
    "H865",
    "H867",
    "H870",
    "H868",
    "B952",
    "H873",
    "H875",
    "H876",
    "H892",
    "H880",
    "H881",
    "H890",
    "H894",
    "H891",
    "H893",
    "H885",
    "H883",
    "H895",
    "H886",
    "H896",
    "H897",
    "H882",
    "H898",
    "H888",
    "H878",
    "H889",
    "H887",
    "H899",
    "H884",
    "H900",
    "H907",
    "H906",
    "H910",
    "H911",
    "H912",
    "H903",
    "H914",
    "H916",
    "H918",
    "H919",
    "H920",
    "H921",
    "G467",
    "H917",
    "H922",
    "H923",
    "H924",
    "H926",
    "G287",
    "D690",
    "H901",
    "H928",
    "H929",
    "H930",
    "A562",
    "H933",
    "H931",
    "H935",
    "H936",
    "H937",
    "H942",
    "H940",
    "H941",
    "H943",
    "H939",
    "H938",
    "H945",
    "H949",
    "H951",
    "H952",
    "H953",
    "H955",
    "H959",
    "H957",
    "H961",
    "H962",
    "H956",
    "H966",
    "H958",
    "H964",
    "H967",
    "H969",
    "H970",
    "H971",
    "H973",
    "H976",
    "H977",
    "H975",
    "H978",
    "H979",
    "H980",
    "H981",
    "H982",
    "H984",
    "F043",
    "H985",
    "H986",
    "H999",
    "H987",
    "I003",
    "H997",
    "H994",
    "I005",
    "I007",
    "H992",
    "I008",
    "H996",
    "H988",
    "H989",
    "H990",
    "I011",
    "I012",
    "I002",
    "I014",
    "H991",
    "I016",
    "I018",
    "I017",
    "I019",
    "I023",
    "I024",
    "I025",
    "I028",
    "I031",
    "H712",
    "I029",
    "I032",
    "I026",
    "I027",
    "I030",
    "I040",
    "I042",
    "I035",
    "I034",
    "I037",
    "I045",
    "I046",
    "I047",
    "I049",
    "I052",
    "I051",
    "I060",
    "I061",
    "I058",
    "I057",
    "I056",
    "I062",
    "A368",
    "I063",
    "G383",
    "I065",
    "I066",
    "G407",
    "B906",
    "I073",
    "I074",
    "B310",
    "I072",
    "I071",
    "I076",
    "I079",
    "I082",
    "I084",
    "I085",
    "I086",
    "I093",
    "I092",
    "I089",
    "I095",
    "I096",
    "I098",
    "I088",
    "I102",
    "I103",
    "I105",
    "I108",
    "I109",
    "I110",
    "G788",
    "I107",
    "I114",
    "I115",
    "I113",
    "I116",
    "I117",
    "I090",
    "I119",
    "I120",
    "I121",
    "I125",
    "I123",
    "I124",
    "I122",
    "I126",
    "I128",
    "I130",
    "I129",
    "I131",
    "I132",
    "I133",
    "I135",
    "I136",
    "I137",
    "I138",
    "I139",
    "I140",
    "I142",
    "I143",
    "I147",
    "I144",
    "I145",
    "I148",
    "I151",
    "I150",
    "I152",
    "I154",
    "I153",
    "I157",
    "I156",
    "I158",
    "I162",
    "I163",
    "I164",
    "I165",
    "I166",
    "I261",
    "I328",
    "I329",
    "I347",
    "I376",
    "I377",
    "I381",
    "I382",
    "I384",
    "I390",
    "I388",
    "I389",
    "I391",
    "I402",
    "I403",
    "I404",
    "I394",
    "I396",
    "I392",
    "I405",
    "I401",
    "I407",
    "I400",
    "I393",
    "I408",
    "I409",
    "I414",
    "I412",
    "I415",
    "I416",
    "I417",
    "H757",
    "H821",
    "H829",
    "H851",
    "H852",
    "H855",
    "H872",
    "H877",
    "H944",
    "H974",
    "I048",
    "I053",
    "I054",
    "I059",
    "I155",
    "I168",
    "I171",
    "I170",
    "I169",
    "I172",
    "I176",
    "I175",
    "I174",
    "I173",
    "I178",
    "I179",
    "I181",
    "I177",
    "I183",
    "I184",
    "I185",
    "I187",
    "I188",
    "I203",
    "I205",
    "I206",
    "I207",
    "I217",
    "I220",
    "I221",
    "I219",
    "I226",
    "I224",
    "I230",
    "I225",
    "I232",
    "I233",
    "I234",
    "M284",
    "C717",
    "I238",
    "I237",
    "I240",
    "I242",
    "I243",
    "I244",
    "M273",
    "I247",
    "I248",
    "I249",
    "I251",
    "I253",
    "I254",
    "I255",
    "I291",
    "I301",
    "I308",
    "I310",
    "I309",
    "I311",
    "I312",
    "I314",
    "I316",
    "I315",
    "I182",
    "I189",
    "I191",
    "I197",
    "I198",
    "I192",
    "I199",
    "I193",
    "I201",
    "I190",
    "I202",
    "I196",
    "I208",
    "I209",
    "I210",
    "I213",
    "I214",
    "I215",
    "I216",
    "I258",
    "I259",
    "I256",
    "I262",
    "I263",
    "I266",
    "I265",
    "I264",
    "I271",
    "I277",
    "I278",
    "I280",
    "I279",
    "I273",
    "I281",
    "I282",
    "I283",
    "I275",
    "I285",
    "I286",
    "I287",
    "I288",
    "I289",
    "I274",
    "I276",
    "I290",
    "I284",
    "M209",
    "I292",
    "I293",
    "I294",
    "I296",
    "I300",
    "M276",
    "I302",
    "I305",
    "I304",
    "F557",
    "I306",
    "I307",
    "I326",
    "I318",
    "I317",
    "I319",
    "B466",
    "I320",
    "I321",
    "I324",
    "I327",
    "I330",
    "I332",
    "I333",
    "I335",
    "I336",
    "I337",
    "I339",
    "I341",
    "I342",
    "I344",
    "I365",
    "I367",
    "I368",
    "I357",
    "C919",
    "I370",
    "I363",
    "I359",
    "I360",
    "I371",
    "I362",
    "I356",
    "I372",
    "I361",
    "I373",
    "I346",
    "I260",
    "I348",
    "I349",
    "I350",
    "I351",
    "I352",
    "I353",
    "I354",
    "I374",
    "I375",
    "I410",
    "I411",
    "I418",
    "I420",
    "I421",
    "I422",
    "I423",
    "I424",
    "I425",
    "I426",
    "I428",
    "I429",
    "I430",
    "I431",
    "I432",
    "I433",
    "I434",
    "I435",
    "I436",
    "I437",
    "I438",
    "I439",
    "I441",
    "I442",
    "I443",
    "I444",
    "I445",
    "I447",
    "I448",
    "I449",
    "I451",
    "I452",
    "I453",
    "I454",
    "I455",
    "I457",
    "G972",
    "I459",
    "I460",
    "I461",
    "I462",
    "I463",
    "G614",
    "I464",
    "I465",
    "I466",
    "I467",
    "I468",
    "I469",
    "I470",
    "I471",
    "I473",
    "I472",
    "I474",
    "I475",
    "I476",
    "I477",
    "I478",
    "I479",
    "H730",
    "I480",
    "I482",
    "I483",
    "I484",
    "I486",
    "I485",
    "I487",
    "I489",
    "I490",
    "I492",
    "I493",
    "I494",
    "I496",
    "B962",
    "I497",
    "I498",
    "I499",
    "I501",
    "I503",
    "I504",
    "M256",
    "I506",
    "I507",
    "I510",
    "I511",
    "I512",
    "I514",
    "I519",
    "I520",
    "I522",
    "I523",
    "I526",
    "I527",
    "I529",
    "I530",
    "I531",
    "I532",
    "I533",
    "I534",
    "I535",
    "I536",
    "D290",
    "I537",
    "I538",
    "I539",
    "I540",
    "I541",
    "I543",
    "I544",
    "I545",
    "I546",
    "I548",
    "I549",
    "I551",
    "I553",
    "I554",
    "I555",
    "I556",
    "I558",
    "I559",
    "I561",
    "I562",
    "I563",
    "I564",
    "I565",
    "I566",
    "I567",
    "I569",
    "I570",
    "I571",
    "I573",
    "I576",
    "I577",
    "I578",
    "I580",
    "I581",
    "I582",
    "I585",
    "I588",
    "I589",
    "I590",
    "I593",
    "I592",
    "I594",
    "I591",
    "I595",
    "I596",
    "I597",
    "I598",
    "I599",
    "I600",
    "I601",
    "I602",
    "I604",
    "I603",
    "I605",
    "I606",
    "I607",
    "I608",
    "I609",
    "I610",
    "I611",
    "I612",
    "I613",
    "I614",
    "I615",
    "I618",
    "I619",
    "I621",
    "I622",
    "I624",
    "I625",
    "I626",
    "I627",
    "I628",
    "I629",
    "I630",
    "I631",
    "I632",
    "I634",
    "I635",
    "I636",
    "I637",
    "I642",
    "I643",
    "I650",
    "I640",
    "I639",
    "I653",
    "I654",
    "I641",
    "I644",
    "I646",
    "I645",
    "I647",
    "F357",
    "I648",
    "I649",
    "I651",
    "I652",
    "I655",
    "I656",
    "I662",
    "I661",
    "I659",
    "I660",
    "I657",
    "I663",
    "I666",
    "I667",
    "I668",
    "I669",
    "I670",
    "I671",
    "C070",
    "I676",
    "I677",
    "I678",
    "I679",
    "E070",
    "I681",
    "I687",
    "I686",
    "I688",
    "I682",
    "I683",
    "I684",
    "I690",
    "I689",
    "I693",
    "I692",
    "I695",
    "I696",
    "I697",
    "I698",
    "I700",
    "I701",
    "I699",
    "I703",
    "I702",
    "I704",
    "I705",
    "I706",
    "I707",
    "I709",
    "I711",
    "I712",
    "I714",
    "I715",
    "I716",
    "I717",
    "I718",
    "I720",
    "I721",
    "M253",
    "I723",
    "I724",
    "I725",
    "I726",
    "I727",
    "I728",
    "I729",
    "I730",
    "F116",
    "I732",
    "I734",
    "I735",
    "I737",
    "I736",
    "I738",
    "I739",
    "I741",
    "I742",
    "I743",
    "I744",
    "I745",
    "I747",
    "A468",
    "I748",
    "I749",
    "I750",
    "I751",
    "I752",
    "I753",
    "I754",
    "I756",
    "I757",
    "I633",
    "I758",
    "I759",
    "I760",
    "I761",
    "I763",
    "I765",
    "E265",
    "I767",
    "I771",
    "I772",
    "I774",
    "I775",
    "I777",
    "I778",
    "I779",
    "I780",
    "I781",
    "I782",
    "I783",
    "I785",
    "I786",
    "I787",
    "I790",
    "I791",
    "I792",
    "I793",
    "I794",
    "I796",
    "I797",
    "I798",
    "I799",
    "I800",
    "I801",
    "I802",
    "I803",
    "I805",
    "I808",
    "I809",
    "I812",
    "I813",
    "I815",
    "I817",
    "I819",
    "I820",
    "I821",
    "I822",
    "I823",
    "I824",
    "I825",
    "I826",
    "I827",
    "I828",
    "I829",
    "I830",
    "I831",
    "I832",
    "I835",
    "I838",
    "I839",
    "I840",
    "I841",
    "I844",
    "I843",
    "I845",
    "I847",
    "I848",
    "I849",
    "I850",
    "I851",
    "I852",
    "I853",
    "I854",
    "I855",
    "I856",
    "I857",
    "I858",
    "I860",
    "I861",
    "I862",
    "I863",
    "I864",
    "I865",
    "I866",
    "I867",
    "I868",
    "I869",
    "I871",
    "I872",
    "I873",
    "I874",
    "I875",
    "I876",
    "I877",
    "I878",
    "I879",
    "I673",
    "I880",
    "I881",
    "I884",
    "I885",
    "I886",
    "I887",
    "I888",
    "I889",
    "I891",
    "I892",
    "I893",
    "I894",
    "I895",
    "I896",
    "I898",
    "I899",
    "I901",
    "I902",
    "I903",
    "I904",
    "I905",
    "I906",
    "I907",
    "I908",
    "I909",
    "I910",
    "I911",
    "I912",
    "I914",
    "I916",
    "I917",
    "I919",
    "I921",
    "I922",
    "I923",
    "I924",
    "I925",
    "I926",
    "I927",
    "I928",
    "I929",
    "I930",
    "I932",
    "I935",
    "I936",
    "I937",
    "I938",
    "I939",
    "M298",
    "I941",
    "I942",
    "I943",
    "I945",
    "I946",
    "G887",
    "I947",
    "I948",
    "I949",
    "I950",
    "I951",
    "I952",
    "I953",
    "I954",
    "I955",
    "I956",
    "I959",
    "M290",
    "I960",
    "I962",
    "I963",
    "I964",
    "I965",
    "I968",
    "I969",
    "I970",
    "I973",
    "I974",
    "I975",
    "I976",
    "I977",
    "I978",
    "I979",
    "I980",
    "I981",
    "I982",
    "I984",
    "I985",
    "I986",
    "I990",
    "B014",
    "I991",
    "I992",
    "I993",
    "I994",
    "I995",
    "I996",
    "I997",
    "I998",
    "I804",
    "L002",
    "L003",
    "L004",
    "L006",
    "L007",
    "L008",
    "L009",
    "L010",
    "L011",
    "L013",
    "L014",
    "L015",
    "L016",
    "L017",
    "L018",
    "L019",
    "L020",
    "L022",
    "L023",
    "L024",
    "L025",
    "L026",
    "L027",
    "L030",
    "L032",
    "L033",
    "G736",
    "L034",
    "L035",
    "L036",
    "L037",
    "L038",
    "L039",
    "L040",
    "L042",
    "L044",
    "L046",
    "L047",
    "L048",
    "L049",
    "L050",
    "D024",
    "L055",
    "L056",
    "L057",
    "L058",
    "L059",
    "L060",
    "L061",
    "L062",
    "L063",
    "L064",
    "L065",
    "L066",
    "L067",
    "F260",
    "L069",
    "L070",
    "L071",
    "L073",
    "C698",
    "L074",
    "L075",
    "L078",
    "L081",
    "L082",
    "L083",
    "D292",
    "L084",
    "L085",
    "L086",
    "L087",
    "L088",
    "L089",
    "L090",
    "L093",
    "L094",
    "L096",
    "L097",
    "L100",
    "L101",
    "L102",
    "L103",
    "L104",
    "L105",
    "L106",
    "E548",
    "M282",
    "L107",
    "L108",
    "L109",
    "M210",
    "L111",
    "L112",
    "L113",
    "L115",
    "L116",
    "L117",
    "L118",
    "L120",
    "L121",
    "L122",
    "L124",
    "L125",
    "L126",
    "L127",
    "L123",
    "L131",
    "L132",
    "L134",
    "L136",
    "L137",
    "L138",
    "L139",
    "L140",
    "L142",
    "L143",
    "L144",
    "L145",
    "L146",
    "L147",
    "L149",
    "L150",
    "L152",
    "L153",
    "L154",
    "L155",
    "L156",
    "L157",
    "L158",
    "L160",
    "L162",
    "L163",
    "L164",
    "L165",
    "L166",
    "L167",
    "L168",
    "L169",
    "L172",
    "L173",
    "L174",
    "L175",
    "L176",
    "L177",
    "L178",
    "L180",
    "L181",
    "L182",
    "L183",
    "L184",
    "L185",
    "L186",
    "L187",
    "L188",
    "L189",
    "L190",
    "L191",
    "L192",
    "L193",
    "L194",
    "L195",
    "L197",
    "L199",
    "L200",
    "L201",
    "L202",
    "L203",
    "L204",
    "D717",
    "L205",
    "L206",
    "L207",
    "L210",
    "L211",
    "L212",
    "L213",
    "L214",
    "L215",
    "L216",
    "L217",
    "L219",
    "L218",
    "L220",
    "L221",
    "L223",
    "L224",
    "L225",
    "L227",
    "L228",
    "L229",
    "L230",
    "L231",
    "L233",
    "L235",
    "L237",
    "L238",
    "L239",
    "L245",
    "L250",
    "L251",
    "L252",
    "L243",
    "L247",
    "L256",
    "L257",
    "L262",
    "L263",
    "L258",
    "L265",
    "L259",
    "L267",
    "L240",
    "L244",
    "L269",
    "L272",
    "L241",
    "L274",
    "L276",
    "L277",
    "L278",
    "L279",
    "L280",
    "L246",
    "L248",
    "L253",
    "L254",
    "L270",
    "L271",
    "L273",
    "M286",
    "L281",
    "L282",
    "L285",
    "L284",
    "L287",
    "L297",
    "L286",
    "I550",
    "L290",
    "L294",
    "L296",
    "L293",
    "L291",
    "L295",
    "L292",
    "L298",
    "L299",
    "L301",
    "L303",
    "L302",
    "A355",
    "L304",
    "L305",
    "L306",
    "L307",
    "L308",
    "L309",
    "L312",
    "L314",
    "L316",
    "L315",
    "L317",
    "L319",
    "L321",
    "L322",
    "L323",
    "L324",
    "L325",
    "L326",
    "L327",
    "L328",
    "L329",
    "L330",
    "L331",
    "L332",
    "L333",
    "L334",
    "L335",
    "L336",
    "L337",
    "L338",
    "I236",
    "L339",
    "L342",
    "L345",
    "L346",
    "L340",
    "L347",
    "L348",
    "L349",
    "L353",
    "L354",
    "M280",
    "L355",
    "L356",
    "L357",
    "L359",
    "L361",
    "L363",
    "L364",
    "L366",
    "L367",
    "L368",
    "L369",
    "L371",
    "L372",
    "L375",
    "L377",
    "L378",
    "L379",
    "L380",
    "L381",
    "L382",
    "L383",
    "L384",
    "L385",
    "L386",
    "L388",
    "L389",
    "L390",
    "L392",
    "L393",
    "L396",
    "L397",
    "L398",
    "L399",
    "L400",
    "L402",
    "L401",
    "L403",
    "L404",
    "L407",
    "L406",
    "L408",
    "L409",
    "L411",
    "L410",
    "L413",
    "L414",
    "L415",
    "L416",
    "L418",
    "L419",
    "L420",
    "L421",
    "L422",
    "L423",
    "L424",
    "L425",
    "L426",
    "L427",
    "L428",
    "B915",
    "L429",
    "L430",
    "L431",
    "L432",
    "L433",
    "L434",
    "L435",
    "L436",
    "L437",
    "L438",
    "L439",
    "L440",
    "L444",
    "L445",
    "L447",
    "L448",
    "L449",
    "L450",
    "A705",
    "L451",
    "L452",
    "L453",
    "L454",
    "L455",
    "L457",
    "L458",
    "L459",
    "L460",
    "L461",
    "L462",
    "L463",
    "L464",
    "L466",
    "G507",
    "L469",
    "L470",
    "L471",
    "L472",
    "L473",
    "L474",
    "L475",
    "L477",
    "L478",
    "L310",
    "C789",
    "L480",
    "L482",
    "L483",
    "L484",
    "L485",
    "L487",
    "L488",
    "L489",
    "L490",
    "D786",
    "L492",
    "L494",
    "L496",
    "L497",
    "L498",
    "L499",
    "L500",
    "L501",
    "L502",
    "L503",
    "L505",
    "L506",
    "L507",
    "L508",
    "L509",
    "L511",
    "L512",
    "L513",
    "L514",
    "L515",
    "L516",
    "L517",
    "L519",
    "L521",
    "L522",
    "L524",
    "L525",
    "L526",
    "L527",
    "L528",
    "L533",
    "L529",
    "L532",
    "L531",
    "L537",
    "L535",
    "L538",
    "L539",
    "L540",
    "M265",
    "L555",
    "L562",
    "L564",
    "L638",
    "H259",
    "L544",
    "L545",
    "L546",
    "L547",
    "L550",
    "L551",
    "L552",
    "L554",
    "L556",
    "G319",
    "L557",
    "L558",
    "L561",
    "L563",
    "L565",
    "L566",
    "L568",
    "L567",
    "L569",
    "L570",
    "L571",
    "L572",
    "L573",
    "L574",
    "L575",
    "L576",
    "L577",
    "L578",
    "L579",
    "L580",
    "L581",
    "L582",
    "L583",
    "L584",
    "L586",
    "L588",
    "L589",
    "L594",
    "L595",
    "L597",
    "G540",
    "L590",
    "L601",
    "L591",
    "L593",
    "L606",
    "L617",
    "L620",
    "L596",
    "L598",
    "L599",
    "L603",
    "L604",
    "I322",
    "L607",
    "L609",
    "L605",
    "L611",
    "L612",
    "L613",
    "L614",
    "L616",
    "L623",
    "L624",
    "L625",
    "L626",
    "L628",
    "L627",
    "L629",
    "L631",
    "L633",
    "L634",
    "L636",
    "L639",
    "L640",
    "L641",
    "L642",
    "L643",
    "L644",
    "B510",
    "L647",
    "L649",
    "D513",
    "C936",
    "L650",
    "L651",
    "L653",
    "L655",
    "L654",
    "L656",
    "L657",
    "L658",
    "L659",
    "L468",
    "L660",
    "L664",
    "L665",
    "L666",
    "L667",
    "L668",
    "L669",
    "L670",
    "L671",
    "L672",
    "L673",
    "L675",
    "L676",
    "L677",
    "L678",
    "L680",
    "L682",
    "L681",
    "L685",
    "L686",
    "L687",
    "L689",
    "L690",
    "L691",
    "L692",
    "A701",
    "L693",
    "E372",
    "L696",
    "L697",
    "L698",
    "L699",
    "L700",
    "L702",
    "L704",
    "L703",
    "L705",
    "L706",
    "L707",
    "L709",
    "L710",
    "L711",
    "L712",
    "L713",
    "L715",
    "L716",
    "L719",
    "L720",
    "L723",
    "L722",
    "L724",
    "L725",
    "L727",
    "L728",
    "L729",
    "L726",
    "L730",
    "L731",
    "L733",
    "L734",
    "L735",
    "L736",
    "L737",
    "L738",
    "L739",
    "L741",
    "L740",
    "L742",
    "L743",
    "L745",
    "L744",
    "L746",
    "L747",
    "L748",
    "L749",
    "L750",
    "L751",
    "L752",
    "L753",
    "L755",
    "L756",
    "L758",
    "L762",
    "L763",
    "L764",
    "L765",
    "L768",
    "L769",
    "L771",
    "L772",
    "L773",
    "L774",
    "L775",
    "L776",
    "L777",
    "L778",
    "L779",
    "L780",
    "L781",
    "D193",
    "L783",
    "C282",
    "L784",
    "L785",
    "L788",
    "L787",
    "L792",
    "L795",
    "L797",
    "L798",
    "L799",
    "L800",
    "L801",
    "L802",
    "L804",
    "L805",
    "L806",
    "L807",
    "L808",
    "L809",
    "L810",
    "L811",
    "L812",
    "L813",
    "L814",
    "L815",
    "L817",
    "L816",
    "L821",
    "L819",
    "L820",
    "L823",
    "L826",
    "L827",
    "L828",
    "L829",
    "L830",
    "L831",
    "L833",
    "L834",
    "F537",
    "L835",
    "L836",
    "L837",
    "L838",
    "L840",
    "L548",
    "L842",
    "L845",
    "L843",
    "L841",
    "L846",
    "L847",
    "L850",
    "L851",
    "M259",
    "L854",
    "L856",
    "L857",
    "L858",
    "L859",
    "L860",
    "L864",
    "L866",
    "L865",
    "L868",
    "L869",
    "L872",
    "L873",
    "L874",
    "L876",
    "L878",
    "L880",
    "L879",
    "L881",
    "L882",
    "L883",
    "L885",
    "L886",
    "L887",
    "L888",
    "L889",
    "L890",
    "L893",
    "L903",
    "L892",
    "L894",
    "L896",
    "L897",
    "L898",
    "L899",
    "L900",
    "L904",
    "L910",
    "L912",
    "L913",
    "L917",
    "L919",
    "L920",
    "L922",
    "L926",
    "L928",
    "L929",
    "A215",
    "L933",
    "L934",
    "D801",
    "L907",
    "L936",
    "L908",
    "L938",
    "L937",
    "L943",
    "L956",
    "L957",
    "A081",
    "L844",
    "L969",
    "F804",
    "M006",
    "M018",
    "H913",
    "I118",
    "M019",
    "L905",
    "M021",
    "M022",
    "M023",
    "I298",
    "L909",
    "I364",
    "A609",
    "M034",
    "L915",
    "L916",
    "L923",
    "L924",
    "L931",
    "L939",
    "L906",
    "L942",
    "L945",
    "L949",
    "L946",
    "L947",
    "L948",
    "L944",
    "L950",
    "L951",
    "L952",
    "L953",
    "L958",
    "L959",
    "L961",
    "L963",
    "L964",
    "L965",
    "L966",
    "L967",
    "L968",
    "L970",
    "L971",
    "L978",
    "L982",
    "L975",
    "L983",
    "L984",
    "L973",
    "L985",
    "L977",
    "L979",
    "L988",
    "L974",
    "L972",
    "L989",
    "L990",
    "L980",
    "L991",
    "L986",
    "L987",
    "L992",
    "L994",
    "L995",
    "M278",
    "B903",
    "L998",
    "L999",
    "M007",
    "M013",
    "M014",
    "M015",
    "M002",
    "M003",
    "M004",
    "G309",
    "M009",
    "M011",
    "M016",
    "M017",
    "B738",
    "M025",
    "M026",
    "M027",
    "M028",
    "M030",
    "M031",
    "M032",
    "L981",
    "M043",
    "M041",
    "M042",
    "M044",
    "M045",
    "M048",
    "M050",
    "M052",
    "M053",
    "M055",
    "M057",
    "M058",
    "M059",
    "M060",
    "M062",
    "M063",
    "M065",
    "M067",
    "H123",
    "M069",
    "M070",
    "M071",
    "M072",
    "M073",
    "M077",
    "M078",
    "M079",
    "M080",
    "M081",
    "M082",
    "M083",
    "M085",
    "M086",
    "M088",
    "M089",
    "M090",
    "M091",
    "M093",
    "M092",
    "M094",
    "M096",
    "M095",
    "M098",
    "M100",
    "M101",
    "M102",
    "M103",
    "M104",
    "M105",
    "M106",
    "M108",
    "M109",
    "M110",
    "M111",
    "M113",
    "M115",
    "M116",
    "M118",
    "M119",
    "M120",
    "M121",
    "M122",
    "M125",
    "M123",
    "M124",
    "M126",
    "M127",
    "M131",
    "M130",
    "M132",
    "M133",
    "M136",
    "M138",
    "M139",
    "M140",
    "M141",
    "M142",
    "M143",
    "M144",
    "M145",
    "M147",
    "M267",
    "M150",
    "M152",
    "M153",
    "M156",
    "M158",
    "M160",
    "M161",
    "M162",
    "M163",
    "M165",
    "M166",
    "M167",
    "M168",
    "M169",
    "M170",
    "M171",
    "M172",
    "M173",
    "L848",
    "M174",
    "M176",
    "M177",
    "M178",
    "M179",
    "M180",
    "M182",
    "M183",
    "M184",
    "M185",
    "I345",
    "M187",
    "M188",
    "M189",
    "M190",
    "M194",
    "M196",
    "M197",
    "M198",
    "M199",
    "M200",
    "M201",
    "M202",
    "M203",
    "M204",
  ];
  //document.write(cod_citta.length);
  const provincia: string[] = [
    "ABANO TERME",
    "ABBADIA CERRETO",
    "ABBADIA LARIANA",
    "ABBADIA SAN SALVATORE",
    "ABBASANTA",
    "ABBATEGGIO",
    "ABBIATEGRASSO",
    "ABETONE",
    "ABRIOLA",
    "ACATE",
    "ACCADIA",
    "ACCEGLIO",
    "ACCETTURA",
    "ACCIANO",
    "ACCUMOLI",
    "ACERENZA",
    "ACERNO",
    "ACERRA",
    "ACI BONACCORSI",
    "ACI CASTELLO",
    "ACI CATENA",
    "ACI SANT'ANTONIO",
    "ACIREALE",
    "ACQUACANINA",
    "ACQUAFONDATA",
    "ACQUAFORMOSA",
    "ACQUAFREDDA",
    "ACQUALAGNA",
    "ACQUANEGRA CREMONESE",
    "ACQUANEGRA SUL CHIESE",
    "ACQUAPENDENTE",
    "ACQUAPPESA",
    "ACQUARICA DEL CAPO",
    "ACQUARO",
    "ACQUASANTA TERME",
    "ACQUASPARTA",
    "ACQUAVIVA COLLECROCE",
    "ACQUAVIVA DELLE FONTI",
    "ACQUAVIVA D'ISERNIA",
    "ACQUAVIVA PICENA",
    "ACQUAVIVA PLATANI",
    "ACQUEDOLCI",
    "ACQUI TERME",
    "ACRI",
    "ACUTO",
    "ADELFIA",
    "ADRANO",
    "ADRARA SAN MARTINO",
    "ADRARA SAN ROCCO",
    "ADRIA",
    "ADRO",
    "AFFI",
    "AFFILE",
    "AFRAGOLA",
    "AFRICO",
    "AGAZZANO",
    "AGEROLA",
    "AGGIUS",
    "AGIRA",
    "AGLIANA",
    "AGLIANO TERME",
    "AGLIE'",
    "AGLIENTU",
    "AGNA",
    "AGNADELLO",
    "AGNANA CALABRA",
    "AGNONE",
    "AGNOSINE",
    "AGORDO",
    "AGOSTA",
    "AGRA",
    "AGRATE BRIANZA",
    "AGRATE CONTURBIA",
    "AGRIGENTO",
    "AGROPOLI",
    "AGUGLIANO",
    "AGUGLIARO",
    "AICURZIO",
    "AIDOMAGGIORE",
    "AIDONE",
    "AIELLI",
    "AIELLO CALABRO",
    "AIELLO DEL FRIULI",
    "AIELLO DEL SABATO",
    "AIETA",
    "AILANO",
    "AILOCHE",
    "AIRASCA",
    "AIROLA",
    "AIROLE",
    "AIRUNO",
    "AISONE",
    "ALA",
    "ALA' DEI SARDI",
    "ALA DI STURA",
    "ALAGNA",
    "ALAGNA VALSESIA",
    "ALANNO",
    "ALANO DI PIAVE",
    "ALASSIO",
    "ALATRI",
    "ALBA",
    "ALBA ADRIATICA",
    "ALBAGIARA",
    "ALBAIRATE",
    "ALBANELLA",
    "ALBANO DI LUCANIA",
    "ALBANO LAZIALE",
    "ALBANO SANT'ALESSANDRO",
    "ALBANO VERCELLESE",
    "ALBAREDO ARNABOLDI",
    "ALBAREDO D'ADIGE",
    "ALBAREDO PER SAN MARCO",
    "ALBARETO",
    "ALBARETTO DELLA TORRE",
    "ALBAVILLA",
    "ALBENGA",
    "ALBERA LIGURE",
    "ALBEROBELLO",
    "ALBERONA",
    "ALBESE CON CASSANO",
    "ALBETTONE",
    "ALBI",
    "ALBIANO",
    "ALBIANO D'IVREA",
    "ALBIATE",
    "ALBIDONA",
    "ALBIGNASEGO",
    "ALBINEA",
    "ALBINO",
    "ALBIOLO",
    "ALBISOLA SUPERIORE",
    "ALBISSOLA MARINA",
    "ALBIZZATE",
    "ALBONESE",
    "ALBOSAGGIA",
    "ALBUGNANO",
    "ALBUZZANO",
    "ALCAMO",
    "ALCARA LI FUSI",
    "ALDENO",
    "ALDINO .ALDEIN.",
    "ALES",
    "ALESSANDRIA",
    "ALESSANDRIA DEL CARRETTO",
    "ALESSANDRIA DELLA ROCCA",
    "ALESSANO",
    "ALEZIO",
    "ALFANO",
    "ALFEDENA",
    "ALFIANELLO",
    "ALFIANO NATTA",
    "ALFONSINE",
    "ALGHERO",
    "ALGUA",
    "ALI'",
    "ALI' TERME",
    "ALIA",
    "ALIANO",
    "ALICE BEL COLLE",
    "ALICE CASTELLO",
    "ALICE SUPERIORE",
    "ALIFE",
    "ALIMENA",
    "ALIMINUSA",
    "ALLAI",
    "ALLEGHE",
    "ALLEIN",
    "ALLERONA",
    "ALLISTE",
    "ALLUMIERE",
    "ALLUVIONI CAMBIO'",
    "ALME'",
    "ALMENNO SAN BARTOLOMEO",
    "ALMENNO SAN SALVATORE",
    "ALMESE",
    "ALONTE",
    "ALPETTE",
    "ALPIGNANO",
    "ALSENO",
    "ALSERIO",
    "ALTAMURA",
    "ALTARE",
    "ALTAVILLA IRPINA",
    "ALTAVILLA MILICIA",
    "ALTAVILLA MONFERRATO",
    "ALTAVILLA SILENTINA",
    "ALTAVILLA VICENTINA",
    "ALTIDONA",
    "ALTILIA",
    "ALTINO",
    "ALTISSIMO",
    "ALTIVOLE",
    "ALTO",
    "ALTOFONTE",
    "ALTOMONTE",
    "ALTOPASCIO",
    "ALVIANO",
    "ALVIGNANO",
    "ALVITO",
    "ALZANO LOMBARDO",
    "ALZANO SCRIVIA",
    "ALZATE BRIANZA",
    "AMALFI",
    "AMANDOLA",
    "AMANTEA",
    "AMARO",
    "AMARONI",
    "AMASENO",
    "AMATO",
    "AMATRICE",
    "AMBIVERE",
    "AMBLAR",
    "AMEGLIA",
    "AMELIA",
    "AMENDOLARA",
    "AMENO",
    "AMOROSI",
    "AMPEZZO",
    "ANACAPRI",
    "ANAGNI",
    "ANCARANO",
    "ANCONA",
    "ANDALI",
    "ANDALO",
    "ANDALO VALTELLINO",
    "ANDEZENO",
    "ANDORA",
    "ANDORNO MICCA",
    "ANDRANO",
    "ANDRATE",
    "ANDREIS",
    "ANDRETTA",
    "ANDRIA",
    "ANDRIANO .ANDRIAN.",
    "ANELA",
    "ANFO",
    "ANGERA",
    "ANGHIARI",
    "ANGIARI",
    "ANGOLO TERME",
    "ANGRI",
    "ANGROGNA",
    "ANGUILLARA SABAZIA",
    "ANGUILLARA VENETA",
    "ANNICCO",
    "ANNONE DI BRIANZA",
    "ANNONE VENETO",
    "ANOIA",
    "ANTEGNATE",
    "ANTERIVO .ALTREI.",
    "ANTEY-SAINT-ANDRE'",
    "ANTICOLI CORRADO",
    "ANTIGNANO",
    "ANTILLO",
    "ANTONIMINA",
    "ANTRODOCO",
    "ANTRONA SCHIERANCO",
    "ANVERSA DEGLI ABRUZZI",
    "ANZANO DEL PARCO",
    "ANZANO DI PUGLIA",
    "ANZI",
    "ANZIO",
    "ANZOLA DELL'EMILIA",
    "ANZOLA D'OSSOLA",
    "AOSTA",
    "APECCHIO",
    "APICE",
    "APIRO",
    "APOLLOSA",
    "APPIANO GENTILE",
    "APPIANO SULLA STRADA DEL VINO .EPPAN AN DER.",
    "APPIGNANO",
    "APPIGNANO DEL TRONTO",
    "APRICA",
    "APRICALE",
    "APRICENA",
    "APRIGLIANO",
    "APRILIA",
    "AQUARA",
    "AQUILA D'ARROSCIA",
    "AQUILEIA",
    "AQUILONIA",
    "AQUINO",
    "ARADEO",
    "ARAGONA",
    "ARAMENGO",
    "ARBA",
    "ARBOREA",
    "ARBORIO",
    "ARBUS",
    "ARCADE",
    "ARCE",
    "ARCENE",
    "ARCEVIA",
    "ARCHI",
    "ARCIDOSSO",
    "ARCINAZZO ROMANO",
    "ARCISATE",
    "ARCO",
    "ARCOLA",
    "ARCOLE",
    "ARCONATE",
    "ARCORE",
    "ARCUGNANO",
    "ARDARA",
    "ARDAULI",
    "ARDEA",
    "ARDENNO",
    "ARDESIO",
    "ARDORE",
    "ARENA",
    "ARENA PO",
    "ARENZANO",
    "ARESE",
    "AREZZO",
    "ARGEGNO",
    "ARGELATO",
    "ARGENTA",
    "ARGENTERA",
    "ARGUELLO",
    "ARGUSTO",
    "ARI",
    "ARIANO IRPINO",
    "ARIANO NEL POLESINE",
    "ARICCIA",
    "ARIELLI",
    "ARIENZO",
    "ARIGNANO",
    "ARITZO",
    "ARIZZANO",
    "ARLENA DI CASTRO",
    "ARLUNO",
    "ARMENO",
    "ARMENTO",
    "ARMO",
    "ARMUNGIA",
    "ARNAD",
    "ARNARA",
    "ARNASCO",
    "ARNESANO",
    "AROLA",
    "ARONA",
    "AROSIO",
    "ARPAIA",
    "ARPAISE",
    "ARPINO",
    "ARQUA' PETRARCA",
    "ARQUA' POLESINE",
    "ARQUATA DEL TRONTO",
    "ARQUATA SCRIVIA",
    "ARRE",
    "ARRONE",
    "ARSAGO SEPRIO",
    "ARSIE'",
    "ARSIERO",
    "ARSITA",
    "ARSOLI",
    "ARTA TERME",
    "ARTEGNA",
    "ARTENA",
    "ARTOGNE",
    "ARVIER",
    "ARZACHENA",
    "ARZAGO D'ADDA",
    "ARZANA",
    "ARZANO",
    "ARZENE",
    "ARZERGRANDE",
    "ARZIGNANO",
    "ASCEA",
    "ASCIANO",
    "ASCOLI PICENO",
    "ASCOLI SATRIANO",
    "ASCREA",
    "ASIAGO",
    "ASIGLIANO VENETO",
    "ASIGLIANO VERCELLESE",
    "ASOLA",
    "ASOLO",
    "ASSAGO",
    "ASSEMINI",
    "ASSISI",
    "ASSO",
    "ASSOLO",
    "ASSORO",
    "ASTI",
    "ASUNI",
    "ATELETA",
    "ATELLA",
    "ATENA LUCANA",
    "ATESSA",
    "ATINA",
    "ATRANI",
    "ATRI",
    "ATRIPALDA",
    "ATTIGLIANO",
    "ATTIMIS",
    "ATZARA",
    "AUDITORE",
    "AUGUSTA",
    "AULETTA",
    "AULLA",
    "AURANO",
    "AURIGO",
    "AURONZO DI CADORE",
    "AUSONIA",
    "AUSTIS",
    "AVEGNO",
    "AVELENGO .HAFLING.",
    "AVELLA",
    "AVELLINO",
    "AVERARA",
    "AVERSA",
    "AVETRANA",
    "AVEZZANO",
    "AVIANO",
    "AVIATICO",
    "AVIGLIANA",
    "AVIGLIANO",
    "AVIGLIANO UMBRO",
    "AVIO",
    "AVISE",
    "AVOLA",
    "AVOLASCA",
    "AYAS",
    "AYMAVILLES",
    "AZEGLIO",
    "AZZANELLO",
    "AZZANO D'ASTI",
    "AZZANO DECIMO",
    "AZZANO MELLA",
    "AZZANO SAN PAOLO",
    "AZZATE",
    "AZZIO",
    "AZZONE",
    "BACENO",
    "BACOLI",
    "BADALUCCO",
    "BADESI",
    "BADIA .ABTEI.",
    "BADIA CALAVENA",
    "BADIA PAVESE",
    "BADIA POLESINE",
    "BADIA TEDALDA",
    "BADOLATO",
    "BAGALADI",
    "BAGHERIA",
    "BAGNACAVALLO",
    "BAGNARA CALABRA",
    "BAGNARA DI ROMAGNA",
    "BAGNARIA",
    "BAGNARIA ARSA",
    "BAGNASCO",
    "BAGNATICA",
    "BAGNI DI LUCCA",
    "BAGNO A RIPOLI",
    "BAGNO DI ROMAGNA",
    "BAGNOLI DEL TRIGNO",
    "BAGNOLI DI SOPRA",
    "BAGNOLI IRPINO",
    "BAGNOLO CREMASCO",
    "BAGNOLO DEL SALENTO",
    "BAGNOLO DI PO",
    "BAGNOLO IN PIANO",
    "BAGNOLO MELLA",
    "BAGNOLO PIEMONTE",
    "BAGNOLO SAN VITO",
    "BAGNONE",
    "BAGNOREGIO",
    "BAGOLINO",
    "BAIA E LATINA",
    "BAIANO",
    "BAIARDO",
    "BAIRO",
    "BAISO",
    "BALANGERO",
    "BALDICHIERI D'ASTI",
    "BALDISSERO CANAVESE",
    "BALDISSERO D'ALBA",
    "BALDISSERO TORINESE",
    "BALESTRATE",
    "BALESTRINO",
    "BALLABIO",
    "BALLAO",
    "BALME",
    "BALMUCCIA",
    "BALOCCO",
    "BALSORANO",
    "BALVANO",
    "BALZOLA",
    "BANARI",
    "BANCHETTE",
    "BANNIO ANZINO",
    "BANZI",
    "BAONE",
    "BARADILI",
    "BARAGIANO",
    "BARANELLO",
    "BARANO D'ISCHIA",
    "BARANZATE",
    "BARASSO",
    "BARATILI SAN PIETRO",
    "BARBANIA",
    "BARBARA",
    "BARBARANO ROMANO",
    "BARBARANO VICENTINO",
    "BARBARESCO",
    "BARBARIGA",
    "BARBATA",
    "BARBERINO DI MUGELLO",
    "BARBERINO VAL D'ELSA",
    "BARBIANELLO",
    "BARBIANO .BARBIAN.",
    "BARBONA",
    "BARCELLONA POZZO DI GOTTO",
    "BARCHI",
    "BARCIS",
    "BARD",
    "BARDELLO",
    "BARDI",
    "BARDINETO",
    "BARDOLINO",
    "BARDONECCHIA",
    "BAREGGIO",
    "BARENGO",
    "BARESSA",
    "BARETE",
    "BARGA",
    "BARGAGLI",
    "BARGE",
    "BARGHE",
    "BARI",
    "BARI SARDO",
    "BARIANO",
    "BARICELLA",
    "BARILE",
    "BARISCIANO",
    "BARLASSINA",
    "BARLETTA",
    "BARNI",
    "BAROLO",
    "BARONE CANAVESE",
    "BARONISSI",
    "BARRAFRANCA",
    "BARRALI",
    "BARREA",
    "BARUMINI",
    "BARZAGO",
    "BARZANA",
    "BARZANO'",
    "BARZIO",
    "BASALUZZO",
    "BASCAPE'",
    "BASCHI",
    "BASCIANO",
    "BASELGA DI PINE'",
    "BASELICE",
    "BASIANO",
    "BASICO'",
    "BASIGLIO",
    "BASILIANO",
    "BASSANO BRESCIANO",
    "BASSANO DEL GRAPPA",
    "BASSANO IN TEVERINA",
    "BASSANO ROMANO",
    "BASSIANO",
    "BASSIGNANA",
    "BASTIA MONDOVI'",
    "BASTIA UMBRA",
    "BASTIDA DE' DOSSI",
    "BASTIDA PANCARANA",
    "BASTIGLIA",
    "BATTAGLIA TERME",
    "BATTIFOLLO",
    "BATTIPAGLIA",
    "BATTUDA",
    "BAUCINA",
    "BAULADU",
    "BAUNEI",
    "BAVENO",
    "BAZZANO",
    "BEDERO VALCUVIA",
    "BEDIZZOLE",
    "BEDOLLO",
    "BEDONIA",
    "BEDULITA",
    "BEE",
    "BEINASCO",
    "BEINETTE",
    "BELCASTRO",
    "BELFIORE",
    "BELFORTE ALL'ISAURO",
    "BELFORTE DEL CHIENTI",
    "BELFORTE MONFERRATO",
    "BELGIOIOSO",
    "BELGIRATE",
    "BELLA",
    "BELLAGIO",
    "BELLANO",
    "BELLANTE",
    "BELLARIA-IGEA MARINA",
    "BELLEGRA",
    "BELLINO",
    "BELLINZAGO LOMBARDO",
    "BELLINZAGO NOVARESE",
    "BELLIZZI",
    "BELLONA",
    "BELLOSGUARDO",
    "BELLUNO",
    "BELLUSCO",
    "BELMONTE CALABRO",
    "BELMONTE CASTELLO",
    "BELMONTE DEL SANNIO",
    "BELMONTE IN SABINA",
    "BELMONTE MEZZAGNO",
    "BELMONTE PICENO",
    "BELPASSO",
    "BELSITO",
    "BELVEDERE DI SPINELLO",
    "BELVEDERE LANGHE",
    "BELVEDERE MARITTIMO",
    "BELVEDERE OSTRENSE",
    "BELVEGLIO",
    "BELVI",
    "BEMA",
    "BENE LARIO",
    "BENE VAGIENNA",
    "BENESTARE",
    "BENETUTTI",
    "BENEVELLO",
    "BENEVENTO",
    "BENNA",
    "BENTIVOGLIO",
    "BERBENNO",
    "BERBENNO DI VALTELLINA",
    "BERCETO",
    "BERCHIDDA",
    "BEREGAZZO CON FIGLIARO",
    "BEREGUARDO",
    "BERGAMASCO",
    "BERGAMO",
    "BERGANTINO",
    "BERGEGGI",
    "BERGOLO",
    "BERLINGO",
    "BERNALDA",
    "BERNAREGGIO",
    "BERNATE TICINO",
    "BERNEZZO",
    "BERRA",
    "BERSONE",
    "BERTINORO",
    "BERTIOLO",
    "BERTONICO",
    "BERZANO DI SAN PIETRO",
    "BERZANO DI TORTONA",
    "BERZO DEMO",
    "BERZO INFERIORE",
    "BERZO SAN FERMO",
    "BESANA IN BRIANZA",
    "BESANO",
    "BESATE",
    "BESENELLO",
    "BESENZONE",
    "BESNATE",
    "BESOZZO",
    "BESSUDE",
    "BETTOLA",
    "BETTONA",
    "BEURA-CARDEZZA",
    "BEVAGNA",
    "BEVERINO",
    "BEVILACQUA",
    "BEZZECCA",
    "BIANCAVILLA",
    "BIANCHI",
    "BIANCO",
    "BIANDRATE",
    "BIANDRONNO",
    "BIANZANO",
    "BIANZE'",
    "BIANZONE",
    "BIASSONO",
    "BIBBIANO",
    "BIBBIENA",
    "BIBBONA",
    "BIBIANA",
    "BICCARI",
    "BICINICCO",
    "BIDONI'",
    "BIELLA",
    "BIENNO",
    "BIENO",
    "BIENTINA",
    "BIGARELLO",
    "BINAGO",
    "BINASCO",
    "BINETTO",
    "BIOGLIO",
    "BIONAZ",
    "BIONE",
    "BIRORI",
    "BISACCIA",
    "BISACQUINO",
    "BISCEGLIE",
    "BISEGNA",
    "BISENTI",
    "BISIGNANO",
    "BISTAGNO",
    "BISUSCHIO",
    "BITETTO",
    "BITONTO",
    "BITRITTO",
    "BITTI",
    "BIVONA",
    "BIVONGI",
    "BIZZARONE",
    "BLEGGIO INFERIORE",
    "BLEGGIO SUPERIORE",
    "BLELLO",
    "BLERA",
    "BLESSAGNO",
    "BLEVIO",
    "BLUFI",
    "BOARA PISANI",
    "BOBBIO",
    "BOBBIO PELLICE",
    "BOCA",
    "BOCCHIGLIERO",
    "BOCCIOLETO",
    "BOCENAGO",
    "BODIO LOMNAGO",
    "BOFFALORA D'ADDA",
    "BOFFALORA SOPRA TICINO",
    "BOGLIASCO",
    "BOGNANCO",
    "BOGOGNO",
    "BOIANO",
    "BOISSANO",
    "BOLANO",
    "BOLBENO",
    "BOLGARE",
    "BOLLATE",
    "BOLLENGO",
    "BOLOGNA",
    "BOLOGNANO",
    "BOLOGNETTA",
    "BOLOGNOLA",
    "BOLOTANA",
    "BOLSENA",
    "BOLTIERE",
    "BOLZANO .BOZEN.",
    "BOLZANO NOVARESE",
    "BOLZANO VICENTINO",
    "BOMARZO",
    "BOMBA",
    "BOMPENSIERE",
    "BOMPIETRO",
    "BOMPORTO",
    "BONARCADO",
    "BONASSOLA",
    "BONATE SOPRA",
    "BONATE SOTTO",
    "BONAVIGO",
    "BONDENO",
    "BONDO",
    "BONDONE",
    "BONEA",
    "BONEFRO",
    "BONEMERSE",
    "BONIFATI",
    "BONITO",
    "BONNANARO",
    "BONO",
    "BONORVA",
    "BONVICINO",
    "BORBONA",
    "BORCA DI CADORE",
    "BORDANO",
    "BORDIGHERA",
    "BORDOLANO",
    "BORE",
    "BORETTO",
    "BORGARELLO",
    "BORGARO TORINESE",
    "BORGETTO",
    "BORGHETTO D'ARROSCIA",
    "BORGHETTO DI BORBERA",
    "BORGHETTO DI VARA",
    "BORGHETTO LODIGIANO",
    "BORGHETTO SANTO SPIRITO",
    "BORGHI",
    "BORGIA",
    "BORGIALLO",
    "BORGIO VEREZZI",
    "BORGO A MOZZANO",
    "BORGO D'ALE",
    "BORGO DI TERZO",
    "BORGO PACE",
    "BORGO PRIOLO",
    "BORGO SAN DALMAZZO",
    "BORGO SAN GIACOMO",
    "BORGO SAN GIOVANNI",
    "BORGO SAN LORENZO",
    "BORGO SAN MARTINO",
    "BORGO SAN SIRO",
    "BORGO TICINO",
    "BORGO TOSSIGNANO",
    "BORGO VAL DI TARO",
    "BORGO VALSUGANA",
    "BORGO VELINO",
    "BORGO VERCELLI",
    "BORGOFORTE",
    "BORGOFRANCO D'IVREA",
    "BORGOFRANCO SUL PO",
    "BORGOLAVEZZARO",
    "BORGOMALE",
    "BORGOMANERO",
    "BORGOMARO",
    "BORGOMASINO",
    "BORGONE SUSA",
    "BORGONOVO VAL TIDONE",
    "BORGORATTO ALESSANDRINO",
    "BORGORATTO MORMOROLO",
    "BORGORICCO",
    "BORGOROSE",
    "BORGOSATOLLO",
    "BORGOSESIA",
    "BORMIDA",
    "BORMIO",
    "BORNASCO",
    "BORNO",
    "BORONEDDU",
    "BORORE",
    "BORRELLO",
    "BORRIANA",
    "BORSO DEL GRAPPA",
    "BORTIGALI",
    "BORTIGIADAS",
    "BORUTTA",
    "BORZONASCA",
    "BOSA",
    "BOSARO",
    "BOSCHI SANT'ANNA",
    "BOSCO CHIESANUOVA",
    "BOSCO MARENGO",
    "BOSCONERO",
    "BOSCOREALE",
    "BOSCOTRECASE",
    "BOSENTINO",
    "BOSIA",
    "BOSIO",
    "BOSISIO PARINI",
    "BOSNASCO",
    "BOSSICO",
    "BOSSOLASCO",
    "BOTRICELLO",
    "BOTRUGNO",
    "BOTTANUCO",
    "BOTTICINO",
    "BOTTIDDA",
    "BOVA",
    "BOVA MARINA",
    "BOVALINO",
    "BOVEGNO",
    "BOVES",
    "BOVEZZO",
    "BOVILLE ERNICA",
    "BOVINO",
    "BOVISIO-MASCIAGO",
    "BOVOLENTA",
    "BOVOLONE",
    "BOZZOLE",
    "BOZZOLO",
    "BRA",
    "BRACCA",
    "BRACCIANO",
    "BRACIGLIANO",
    "BRAIES .PRAGS.",
    "BRALLO DI PREGOLA",
    "BRANCALEONE",
    "BRANDICO",
    "BRANDIZZO",
    "BRANZI",
    "BRAONE",
    "BREBBIA",
    "BREDA DI PIAVE",
    "BREGANO",
    "BREGANZE",
    "BREGNANO",
    "BREGUZZO",
    "BREIA",
    "BREMBATE",
    "BREMBATE DI SOPRA",
    "BREMBILLA",
    "BREMBIO",
    "BREME",
    "BRENDOLA",
    "BRENNA",
    "BRENNERO .BRENNER.",
    "BRENO",
    "BRENTA",
    "BRENTINO BELLUNO",
    "BRENTONICO",
    "BRENZONE",
    "BRESCELLO",
    "BRESCIA",
    "BRESIMO",
    "BRESSANA BOTTARONE",
    "BRESSANONE .BRIXEN.",
    "BRESSANVIDO",
    "BRESSO",
    "BREZ",
    "BREZZO DI BEDERO",
    "BRIAGLIA",
    "BRIATICO",
    "BRICHERASIO",
    "BRIENNO",
    "BRIENZA",
    "BRIGA ALTA",
    "BRIGA NOVARESE",
    "BRIGNANO GERA D'ADDA",
    "BRIGNANO-FRASCATA",
    "BRINDISI",
    "BRINDISI MONTAGNA",
    "BRINZIO",
    "BRIONA",
    "BRIONE",
    "BRIONE",
    "BRIOSCO",
    "BRISIGHELLA",
    "BRISSAGO-VALTRAVAGLIA",
    "BRISSOGNE",
    "BRITTOLI",
    "BRIVIO",
    "BROCCOSTELLA",
    "BROGLIANO",
    "BROGNATURO",
    "BROLO",
    "BRONDELLO",
    "BRONI",
    "BRONTE",
    "BRONZOLO .BRANZOLL.",
    "BROSSASCO",
    "BROSSO",
    "BROVELLO-CARPUGNINO",
    "BROZOLO",
    "BRUGHERIO",
    "BRUGINE",
    "BRUGNATO",
    "BRUGNERA",
    "BRUINO",
    "BRUMANO",
    "BRUNATE",
    "BRUNELLO",
    "BRUNICO .BRUNECK.",
    "BRUNO",
    "BRUSAPORTO",
    "BRUSASCO",
    "BRUSCIANO",
    "BRUSIMPIANO",
    "BRUSNENGO",
    "BRUSSON",
    "BRUZOLO",
    "BRUZZANO ZEFFIRIO",
    "BUBBIANO",
    "BUBBIO",
    "BUCCHERI",
    "BUCCHIANICO",
    "BUCCIANO",
    "BUCCINASCO",
    "BUCCINO",
    "BUCINE",
    "BUDDUSO'",
    "BUDOIA",
    "BUDONI",
    "BUDRIO",
    "BUGGERRU",
    "BUGGIANO",
    "BUGLIO IN MONTE",
    "BUGNARA",
    "BUGUGGIATE",
    "BUJA",
    "BULCIAGO",
    "BULGAROGRASSO",
    "BULTEI",
    "BULZI",
    "BUONABITACOLO",
    "BUONALBERGO",
    "BUONCONVENTO",
    "BUONVICINO",
    "BURAGO DI MOLGORA",
    "BURCEI",
    "BURGIO",
    "BURGOS",
    "BURIASCO",
    "BUROLO",
    "BURONZO",
    "BUSACHI",
    "BUSALLA",
    "BUSANA",
    "BUSANO",
    "BUSCA",
    "BUSCATE",
    "BUSCEMI",
    "BUSETO PALIZZOLO",
    "BUSNAGO",
    "BUSSERO",
    "BUSSETO",
    "BUSSI SUL TIRINO",
    "BUSSO",
    "BUSSOLENGO",
    "BUSSOLENO",
    "BUSTO ARSIZIO",
    "BUSTO GAROLFO",
    "BUTERA",
    "BUTI",
    "BUTTAPIETRA",
    "BUTTIGLIERA ALTA",
    "BUTTIGLIERA D'ASTI",
    "BUTTRIO",
    "CA' D'ANDREA",
    "CABELLA LIGURE",
    "CABIATE",
    "CABRAS",
    "CACCAMO",
    "CACCURI",
    "CADEGLIANO-VICONAGO",
    "CADELBOSCO DI SOPRA",
    "CADEO",
    "CADERZONE",
    "CADONEGHE",
    "CADORAGO",
    "CADREZZATE",
    "CAERANO DI SAN MARCO",
    "CAFASSE",
    "CAGGIANO",
    "CAGLI",
    "CAGLIARI",
    "CAGLIO",
    "CAGNANO AMITERNO",
    "CAGNANO VARANO",
    "CAGNO",
    "CAGNO'",
    "CAIANELLO",
    "CAIAZZO",
    "CAINES .KUENS.",
    "CAINO",
    "CAIOLO",
    "CAIRANO",
    "CAIRATE",
    "CAIRO MONTENOTTE",
    "CAIVANO",
    "CALABRITTO",
    "CALALZO DI CADORE",
    "CALAMANDRANA",
    "CALAMONACI",
    "CALANGIANUS",
    "CALANNA",
    "CALASCA-CASTIGLIONE",
    "CALASCIBETTA",
    "CALASCIO",
    "CALASETTA",
    "CALATABIANO",
    "CALATAFIMI",
    "CALAVINO",
    "CALCATA",
    "CALCERANICA AL LAGO",
    "CALCI",
    "CALCIANO",
    "CALCINAIA",
    "CALCINATE",
    "CALCINATO",
    "CALCIO",
    "CALCO",
    "CALDARO SULLA STRADA DEL VINO .KALTERN AN DE.",
    "CALDAROLA",
    "CALDERARA DI RENO",
    "CALDES",
    "CALDIERO",
    "CALDOGNO",
    "CALDONAZZO",
    "CALENDASCO",
    "CALENZANO",
    "CALESTANO",
    "CALICE AL CORNOVIGLIO",
    "CALICE LIGURE",
    "CALIMERA",
    "CALITRI",
    "CALIZZANO",
    "CALLABIANA",
    "CALLIANO",
    "CALLIANO",
    "CALOLZIOCORTE",
    "CALOPEZZATI",
    "CALOSSO",
    "CALOVETO",
    "CALTABELLOTTA",
    "CALTAGIRONE",
    "CALTANISSETTA",
    "CALTAVUTURO",
    "CALTIGNAGA",
    "CALTO",
    "CALTRANO",
    "CALUSCO D'ADDA",
    "CALUSO",
    "CALVAGESE DELLA RIVIERA",
    "CALVANICO",
    "CALVATONE",
    "CALVELLO",
    "CALVENE",
    "CALVENZANO",
    "CALVERA",
    "CALVI",
    "CALVI DELL'UMBRIA",
    "CALVI RISORTA",
    "CALVIGNANO",
    "CALVIGNASCO",
    "CALVISANO",
    "CALVIZZANO",
    "CAMAGNA MONFERRATO",
    "CAMAIORE",
    "CAMAIRAGO",
    "CAMANDONA",
    "CAMASTRA",
    "CAMBIAGO",
    "CAMBIANO",
    "CAMBIASCA",
    "CAMBURZANO",
    "CAMERANA",
    "CAMERANO",
    "CAMERANO CASASCO",
    "CAMERATA CORNELLO",
    "CAMERATA NUOVA",
    "CAMERATA PICENA",
    "CAMERI",
    "CAMERINO",
    "CAMEROTA",
    "CAMIGLIANO",
    "CAMINATA",
    "CAMINI",
    "CAMINO",
    "CAMINO AL TAGLIAMENTO",
    "CAMISANO",
    "CAMISANO VICENTINO",
    "CAMMARATA",
    "CAMO",
    "CAMOGLI",
    "CAMPAGNA",
    "CAMPAGNA LUPIA",
    "CAMPAGNANO DI ROMA",
    "CAMPAGNATICO",
    "CAMPAGNOLA CREMASCA",
    "CAMPAGNOLA EMILIA",
    "CAMPANA",
    "CAMPARADA",
    "CAMPEGINE",
    "CAMPELLO SUL CLITUNNO",
    "CAMPERTOGNO",
    "CAMPI BISENZIO",
    "CAMPI SALENTINA",
    "CAMPIGLIA CERVO",
    "CAMPIGLIA DEI BERICI",
    "CAMPIGLIA MARITTIMA",
    "CAMPIGLIONE FENILE",
    "CAMPIONE D'ITALIA",
    "CAMPITELLO DI FASSA",
    "CAMPLI",
    "CAMPO CALABRO",
    "CAMPO DI GIOVE",
    "CAMPO DI TRENS .FREIENFELD.",
    "CAMPO LIGURE",
    "CAMPO NELL'ELBA",
    "CAMPO SAN MARTINO",
    "CAMPO TURES .SAND IN TAUFERS.",
    "CAMPOBASSO",
    "CAMPOBELLO DI LICATA",
    "CAMPOBELLO DI MAZARA",
    "CAMPOCHIARO",
    "CAMPODARSEGO",
    "CAMPODENNO",
    "CAMPODIMELE",
    "CAMPODIPIETRA",
    "CAMPODOLCINO",
    "CAMPODORO",
    "CAMPOFELICE DI FITALIA",
    "CAMPOFELICE DI ROCCELLA",
    "CAMPOFILONE",
    "CAMPOFIORITO",
    "CAMPOFORMIDO",
    "CAMPOFRANCO",
    "CAMPOGALLIANO",
    "CAMPOLATTARO",
    "CAMPOLI APPENNINO",
    "CAMPOLI DEL MONTE TABURNO",
    "CAMPOLIETO",
    "CAMPOLONGO AL TORRE",
    "CAMPOLONGO MAGGIORE",
    "CAMPOLONGO SUL BRENTA",
    "CAMPOMAGGIORE",
    "CAMPOMARINO",
    "CAMPOMORONE",
    "CAMPONOGARA",
    "CAMPORA",
    "CAMPOREALE",
    "CAMPORGIANO",
    "CAMPOROSSO",
    "CAMPOROTONDO DI FIASTRONE",
    "CAMPOROTONDO ETNEO",
    "CAMPOSAMPIERO",
    "CAMPOSANO",
    "CAMPOSANTO",
    "CAMPOSPINOSO",
    "CAMPOTOSTO",
    "CAMUGNANO",
    "CANAL SAN BOVO",
    "CANALE",
    "CANALE D'AGORDO",
    "CANALE MONTERANO",
    "CANARO",
    "CANAZEI",
    "CANCELLARA",
    "CANCELLO ED ARNONE",
    "CANDA",
    "CANDELA",
    "CANDELO",
    "CANDIA CANAVESE",
    "CANDIA LOMELLINA",
    "CANDIANA",
    "CANDIDA",
    "CANDIDONI",
    "CANDIOLO",
    "CANEGRATE",
    "CANELLI",
    "CANEPINA",
    "CANEVA",
    "CANEVINO",
    "CANICATTI'",
    "CANICATTINI BAGNI",
    "CANINO",
    "CANISCHIO",
    "CANISTRO",
    "CANNA",
    "CANNALONGA",
    "CANNARA",
    "CANNERO RIVIERA",
    "CANNETO PAVESE",
    "CANNETO SULL'OGLIO",
    "CANNOBIO",
    "CANNOLE",
    "CANOLO",
    "CANONICA D'ADDA",
    "CANOSA DI PUGLIA",
    "CANOSA SANNITA",
    "CANOSIO",
    "CANOSSA",
    "CANSANO",
    "CANTAGALLO",
    "CANTALICE",
    "CANTALUPA",
    "CANTALUPO IN SABINA",
    "CANTALUPO LIGURE",
    "CANTALUPO NEL SANNIO",
    "CANTARANA",
    "CANTELLO",
    "CANTERANO",
    "CANTIANO",
    "CANTOIRA",
    "CANTU'",
    "CANZANO",
    "CANZO",
    "CAORLE",
    "CAORSO",
    "CAPACCIO",
    "CAPACI",
    "CAPALBIO",
    "CAPANNOLI",
    "CAPANNORI",
    "CAPENA",
    "CAPERGNANICA",
    "CAPESTRANO",
    "CAPIAGO INTIMIANO",
    "CAPISTRANO",
    "CAPISTRELLO",
    "CAPITIGNANO",
    "CAPIZZI",
    "CAPIZZONE",
    "CAPO DI PONTE",
    "CAPO D'ORLANDO",
    "CAPODIMONTE",
    "CAPODRISE",
    "CAPOLIVERI",
    "CAPOLONA",
    "CAPONAGO",
    "CAPORCIANO",
    "CAPOSELE",
    "CAPOTERRA",
    "CAPOVALLE",
    "CAPPADOCIA",
    "CAPPELLA CANTONE",
    "CAPPELLA DE' PICENARDI",
    "CAPPELLA MAGGIORE",
    "CAPPELLE SUL TAVO",
    "CAPRACOTTA",
    "CAPRAIA E LIMITE",
    "CAPRAIA ISOLA",
    "CAPRALBA",
    "CAPRANICA",
    "CAPRANICA PRENESTINA",
    "CAPRARICA DI LECCE",
    "CAPRAROLA",
    "CAPRAUNA",
    "CAPRESE MICHELANGELO",
    "CAPREZZO",
    "CAPRI",
    "CAPRI LEONE",
    "CAPRIANA",
    "CAPRIANO DEL COLLE",
    "CAPRIATA D'ORBA",
    "CAPRIATE SAN GERVASIO",
    "CAPRIATI A VOLTURNO",
    "CAPRIE",
    "CAPRIGLIA IRPINA",
    "CAPRIGLIO",
    "CAPRILE",
    "CAPRINO BERGAMASCO",
    "CAPRINO VERONESE",
    "CAPRIOLO",
    "CAPRIVA DEL FRIULI",
    "CAPUA",
    "CAPURSO",
    "CARAFFA DEL BIANCO",
    "CARAFFA DI CATANZARO",
    "CARAGLIO",
    "CARAMAGNA PIEMONTE",
    "CARAMANICO TERME",
    "CARANO",
    "CARAPELLE",
    "CARAPELLE CALVISIO",
    "CARASCO",
    "CARASSAI",
    "CARATE BRIANZA",
    "CARATE URIO",
    "CARAVAGGIO",
    "CARAVATE",
    "CARAVINO",
    "CARAVONICA",
    "CARBOGNANO",
    "CARBONARA AL TICINO",
    "CARBONARA DI NOLA",
    "CARBONARA DI PO",
    "CARBONARA SCRIVIA",
    "CARBONATE",
    "CARBONE",
    "CARBONERA",
    "CARBONIA",
    "CARCARE",
    "CARCERI",
    "CARCOFORO",
    "CARDANO AL CAMPO",
    "CARDE'",
    "CARDEDU",
    "CARDETO",
    "CARDINALE",
    "CARDITO",
    "CAREGGINE",
    "CAREMA",
    "CARENNO",
    "CARENTINO",
    "CARERI",
    "CARESANA",
    "CARESANABLOT",
    "CAREZZANO",
    "CARFIZZI",
    "CARGEGHE",
    "CARIATI",
    "CARIFE",
    "CARIGNANO",
    "CARIMATE",
    "CARINARO",
    "CARINI",
    "CARINOLA",
    "CARISIO",
    "CARISOLO",
    "CARLANTINO",
    "CARLAZZO",
    "CARLENTINI",
    "CARLINO",
    "CARLOFORTE",
    "CARLOPOLI",
    "CARMAGNOLA",
    "CARMIANO",
    "CARMIGNANO",
    "CARMIGNANO DI BRENTA",
    "CARNAGO",
    "CARNATE",
    "CAROBBIO DEGLI ANGELI",
    "CAROLEI",
    "CARONA",
    "CARONIA",
    "CARONNO PERTUSELLA",
    "CARONNO VARESINO",
    "CAROSINO",
    "CAROVIGNO",
    "CAROVILLI",
    "CARPANETO PIACENTINO",
    "CARPANZANO",
    "CARPASIO",
    "CARPEGNA",
    "CARPENEDOLO",
    "CARPENETO",
    "CARPI",
    "CARPIANO",
    "CARPIGNANO SALENTINO",
    "CARPIGNANO SESIA",
    "CARPINETI",
    "CARPINETO DELLA NORA",
    "CARPINETO ROMANO",
    "CARPINETO SINELLO",
    "CARPINO",
    "CARPINONE",
    "CARRARA",
    "CARRE'",
    "CARREGA LIGURE",
    "CARRO",
    "CARRODANO",
    "CARROSIO",
    "CARRU'",
    "CARSOLI",
    "CARTIGLIANO",
    "CARTIGNANO",
    "CARTOCETO",
    "CARTOSIO",
    "CARTURA",
    "CARUGATE",
    "CARUGO",
    "CARUNCHIO",
    "CARVICO",
    "CARZANO",
    "CASABONA",
    "CASACALENDA",
    "CASACANDITELLA",
    "CASAGIOVE",
    "CASAL CERMELLI",
    "CASAL DI PRINCIPE",
    "CASAL VELINO",
    "CASALANGUIDA",
    "CASALATTICO",
    "CASALBELTRAME",
    "CASALBORDINO",
    "CASALBORE",
    "CASALBORGONE",
    "CASALBUONO",
    "CASALBUTTANO ED UNITI",
    "CASALCIPRANO",
    "CASALDUNI",
    "CASALE CORTE CERRO",
    "CASALE CREMASCO-VIDOLASCO",
    "CASALE DI SCODOSIA",
    "CASALE LITTA",
    "CASALE MARITTIMO",
    "CASALE MONFERRATO",
    "CASALE SUL SILE",
    "CASALECCHIO DI RENO",
    "CASALEGGIO BOIRO",
    "CASALEGGIO NOVARA",
    "CASALEONE",
    "CASALETTO CEREDANO",
    "CASALETTO DI SOPRA",
    "CASALETTO LODIGIANO",
    "CASALETTO SPARTANO",
    "CASALETTO VAPRIO",
    "CASALFIUMANESE",
    "CASALGRANDE",
    "CASALGRASSO",
    "CASALINCONTRADA",
    "CASALINO",
    "CASALMAGGIORE",
    "CASALMAIOCCO",
    "CASALMORANO",
    "CASALMORO",
    "CASALNOCETO",
    "CASALNUOVO DI NAPOLI",
    "CASALNUOVO MONTEROTARO",
    "CASALOLDO",
    "CASALPUSTERLENGO",
    "CASALROMANO",
    "CASALSERUGO",
    "CASALUCE",
    "CASALVECCHIO DI PUGLIA",
    "CASALVECCHIO SICULO",
    "CASALVIERI",
    "CASALVOLONE",
    "CASALZUIGNO",
    "CASAMARCIANO",
    "CASAMASSIMA",
    "CASAMICCIOLA TERME",
    "CASANDRINO",
    "CASANOVA ELVO",
    "CASANOVA LERRONE",
    "CASANOVA LONATI",
    "CASAPE",
    "CASAPESENNA",
    "CASAPINTA",
    "CASAPROTA",
    "CASAPULLA",
    "CASARANO",
    "CASARGO",
    "CASARILE",
    "CASARSA DELLA DELIZIA",
    "CASARZA LIGURE",
    "CASASCO",
    "CASASCO D'INTELVI",
    "CASATENOVO",
    "CASATISMA",
    "CASAVATORE",
    "CASAZZA",
    "CASCIA",
    "CASCIAGO",
    "CASCIANA TERME",
    "CASCINA",
    "CASCINETTE D'IVREA",
    "CASEI GEROLA",
    "CASELETTE",
    "CASELLA",
    "CASELLE IN PITTARI",
    "CASELLE LANDI",
    "CASELLE LURANI",
    "CASELLE TORINESE",
    "CASERTA",
    "CASIER",
    "CASIGNANA",
    "CASINA",
    "CASIRATE D'ADDA",
    "CASLINO D'ERBA",
    "CASNATE CON BERNATE",
    "CASNIGO",
    "CASOLA DI NAPOLI",
    "CASOLA IN LUNIGIANA",
    "CASOLA VALSENIO",
    "CASOLE BRUZIO",
    "CASOLE D'ELSA",
    "CASOLI",
    "CASORATE PRIMO",
    "CASORATE SEMPIONE",
    "CASOREZZO",
    "CASORIA",
    "CASORZO",
    "CASPERIA",
    "CASPOGGIO",
    "CASSACCO",
    "CASSAGO BRIANZA",
    "CASSANO ALLO IONIO",
    "CASSANO D'ADDA",
    "CASSANO DELLE MURGE",
    "CASSANO IRPINO",
    "CASSANO MAGNAGO",
    "CASSANO SPINOLA",
    "CASSANO VALCUVIA",
    "CASSARO",
    "CASSIGLIO",
    "CASSINA DE' PECCHI",
    "CASSINA RIZZARDI",
    "CASSINA VALSASSINA",
    "CASSINASCO",
    "CASSINE",
    "CASSINELLE",
    "CASSINETTA DI LUGAGNANO",
    "CASSINO",
    "CASSOLA",
    "CASSOLNOVO",
    "CASTAGNARO",
    "CASTAGNETO CARDUCCI",
    "CASTAGNETO PO",
    "CASTAGNITO",
    "CASTAGNOLE DELLE LANZE",
    "CASTAGNOLE MONFERRATO",
    "CASTAGNOLE PIEMONTE",
    "CASTANA",
    "CASTANO PRIMO",
    "CASTEGGIO",
    "CASTEGNATO",
    "CASTEGNERO",
    "CASTEL BARONIA",
    "CASTEL BOGLIONE",
    "CASTEL BOLOGNESE",
    "CASTEL CAMPAGNANO",
    "CASTEL CASTAGNA",
    "CASTEL COLONNA",
    "CASTEL CONDINO",
    "CASTEL D'AIANO",
    "CASTEL D'ARIO",
    "CASTEL D'AZZANO",
    "CASTEL DEL GIUDICE",
    "CASTEL DEL MONTE",
    "CASTEL DEL PIANO",
    "CASTEL DEL RIO",
    "CASTEL DI CASIO",
    "CASTEL DI IERI",
    "CASTEL DI IUDICA",
    "CASTEL DI LAMA",
    "CASTEL DI LUCIO",
    "CASTEL DI SANGRO",
    "CASTEL DI SASSO",
    "CASTEL DI TORA",
    "CASTEL FOCOGNANO",
    "CASTEL FRENTANO",
    "CASTEL GABBIANO",
    "CASTEL GANDOLFO",
    "CASTEL GIORGIO",
    "CASTEL GOFFREDO",
    "CASTEL GUELFO DI BOLOGNA",
    "CASTEL MADAMA",
    "CASTEL MAGGIORE",
    "CASTEL MELLA",
    "CASTEL MORRONE",
    "CASTEL RITALDI",
    "CASTEL ROCCHERO",
    "CASTEL ROZZONE",
    "CASTEL SAN GIORGIO",
    "CASTEL SAN GIOVANNI",
    "CASTEL SAN LORENZO",
    "CASTEL SAN NICCOLO'",
    "CASTEL SAN PIETRO ROMANO",
    "CASTEL SAN PIETRO TERME",
    "CASTEL SAN VINCENZO",
    "CASTEL SANT'ANGELO",
    "CASTEL SANT'ELIA",
    "CASTEL VISCARDO",
    "CASTEL VITTORIO",
    "CASTEL VOLTURNO",
    "CASTELBALDO",
    "CASTELBELFORTE",
    "CASTELBELLINO",
    "CASTELBELLO CIARDES .KASTELBELL TSCHARS.",
    "CASTELBIANCO",
    "CASTELBOTTACCIO",
    "CASTELBUONO",
    "CASTELCIVITA",
    "CASTELCOVATI",
    "CASTELCUCCO",
    "CASTELDACCIA",
    "CASTELDELCI",
    "CASTELDELFINO",
    "CASTELDIDONE",
    "CASTELFIDARDO",
    "CASTELFIORENTINO",
    "CASTELFONDO",
    "CASTELFORTE",
    "CASTELFRANCI",
    "CASTELFRANCO DI SOPRA",
    "CASTELFRANCO DI SOTTO",
    "CASTELFRANCO EMILIA",
    "CASTELFRANCO IN MISCANO",
    "CASTELFRANCO VENETO",
    "CASTELGOMBERTO",
    "CASTELGRANDE",
    "CASTELGUGLIELMO",
    "CASTELGUIDONE",
    "CASTELLABATE",
    "CASTELLAFIUME",
    "CASTELL'ALFERO",
    "CASTELLALTO",
    "CASTELLAMMARE DEL GOLFO",
    "CASTELLAMMARE DI STABIA",
    "CASTELLAMONTE",
    "CASTELLANA GROTTE",
    "CASTELLANA SICULA",
    "CASTELLANETA",
    "CASTELLANIA",
    "CASTELLANZA",
    "CASTELLAR",
    "CASTELLAR GUIDOBONO",
    "CASTELLARANO",
    "CASTELLARO",
    "CASTELL'ARQUATO",
    "CASTELLAVAZZO",
    "CASTELL'AZZARA",
    "CASTELLAZZO BORMIDA",
    "CASTELLAZZO NOVARESE",
    "CASTELLEONE",
    "CASTELLEONE DI SUASA",
    "CASTELLERO",
    "CASTELLETTO CERVO",
    "CASTELLETTO D'ERRO",
    "CASTELLETTO DI BRANDUZZO",
    "CASTELLETTO D'ORBA",
    "CASTELLETTO MERLI",
    "CASTELLETTO MOLINA",
    "CASTELLETTO MONFERRATO",
    "CASTELLETTO SOPRA TICINO",
    "CASTELLETTO STURA",
    "CASTELLETTO UZZONE",
    "CASTELLI",
    "CASTELLI CALEPIO",
    "CASTELLINA IN CHIANTI",
    "CASTELLINA MARITTIMA",
    "CASTELLINALDO",
    "CASTELLINO DEL BIFERNO",
    "CASTELLINO TANARO",
    "CASTELLIRI",
    "CASTELLO CABIAGLIO",
    "CASTELLO D'AGOGNA",
    "CASTELLO D'ARGILE",
    "CASTELLO DEL MATESE",
    "CASTELLO DELL'ACQUA",
    "CASTELLO DI ANNONE",
    "CASTELLO DI BRIANZA",
    "CASTELLO DI CISTERNA",
    "CASTELLO DI GODEGO",
    "CASTELLO DI SERRAVALLE",
    "CASTELLO TESINO",
    "CASTELLO-MOLINA DI FIEMME",
    "CASTELLUCCHIO",
    "CASTELLUCCIO DEI SAURI",
    "CASTELLUCCIO INFERIORE",
    "CASTELLUCCIO SUPERIORE",
    "CASTELLUCCIO VALMAGGIORE",
    "CASTELL'UMBERTO",
    "CASTELMAGNO",
    "CASTELMARTE",
    "CASTELMASSA",
    "CASTELMAURO",
    "CASTELMEZZANO",
    "CASTELMOLA",
    "CASTELNOVETTO",
    "CASTELNOVO BARIANO",
    "CASTELNOVO DEL FRIULI",
    "CASTELNOVO DI SOTTO",
    "CASTELNOVO NE' MONTI",
    "CASTELNUOVO",
    "CASTELNUOVO BELBO",
    "CASTELNUOVO BERARDENGA",
    "CASTELNUOVO BOCCA D'ADDA",
    "CASTELNUOVO BORMIDA",
    "CASTELNUOVO BOZZENTE",
    "CASTELNUOVO CALCEA",
    "CASTELNUOVO CILENTO",
    "CASTELNUOVO DEL GARDA",
    "CASTELNUOVO DELLA DAUNIA",
    "CASTELNUOVO DI CEVA",
    "CASTELNUOVO DI CONZA",
    "CASTELNUOVO DI FARFA",
    "CASTELNUOVO DI GARFAGNANA",
    "CASTELNUOVO DI PORTO",
    "CASTELNUOVO DON BOSCO",
    "CASTELNUOVO MAGRA",
    "CASTELNUOVO NIGRA",
    "CASTELNUOVO PARANO",
    "CASTELNUOVO RANGONE",
    "CASTELNUOVO SCRIVIA",
    "CASTELNUOVO VAL DI CECINA",
    "CASTELPAGANO",
    "CASTELPETROSO",
    "CASTELPIZZUTO",
    "CASTELPLANIO",
    "CASTELPOTO",
    "CASTELRAIMONDO",
    "CASTELROTTO .KASTELRUTH.",
    "CASTELSANTANGELO SUL NERA",
    "CASTELSARACENO",
    "CASTELSARDO",
    "CASTELSEPRIO",
    "CASTELSILANO",
    "CASTELSPINA",
    "CASTELTERMINI",
    "CASTELVECCANA",
    "CASTELVECCHIO CALVISIO",
    "CASTELVECCHIO DI ROCCA BARBENA",
    "CASTELVECCHIO SUBEQUO",
    "CASTELVENERE",
    "CASTELVERDE",
    "CASTELVERRINO",
    "CASTELVETERE IN VAL FORTORE",
    "CASTELVETERE SUL CALORE",
    "CASTELVETRANO",
    "CASTELVETRO DI MODENA",
    "CASTELVETRO PIACENTINO",
    "CASTELVISCONTI",
    "CASTENASO",
    "CASTENEDOLO",
    "CASTIADAS",
    "CASTIGLION FIBOCCHI",
    "CASTIGLION FIORENTINO",
    "CASTIGLIONE A CASAURIA",
    "CASTIGLIONE CHIAVARESE",
    "CASTIGLIONE COSENTINO",
    "CASTIGLIONE D'ADDA",
    "CASTIGLIONE DEI PEPOLI",
    "CASTIGLIONE DEL GENOVESI",
    "CASTIGLIONE DEL LAGO",
    "CASTIGLIONE DELLA PESCAIA",
    "CASTIGLIONE DELLE STIVIERE",
    "CASTIGLIONE DI GARFAGNANA",
    "CASTIGLIONE DI SICILIA",
    "CASTIGLIONE D'INTELVI",
    "CASTIGLIONE D'ORCIA",
    "CASTIGLIONE FALLETTO",
    "CASTIGLIONE IN TEVERINA",
    "CASTIGLIONE MESSER MARINO",
    "CASTIGLIONE MESSER RAIMONDO",
    "CASTIGLIONE OLONA",
    "CASTIGLIONE TINELLA",
    "CASTIGLIONE TORINESE",
    "CASTIGNANO",
    "CASTILENTI",
    "CASTINO",
    "CASTIONE ANDEVENNO",
    "CASTIONE DELLA PRESOLANA",
    "CASTIONS DI STRADA",
    "CASTIRAGA VIDARDO",
    "CASTO",
    "CASTORANO",
    "CASTREZZATO",
    "CASTRI DI LECCE",
    "CASTRIGNANO DE' GRECI",
    "CASTRIGNANO DEL CAPO",
    "CASTRO",
    "CASTRO",
    "CASTRO DEI VOLSCI",
    "CASTROCARO TERME E TERRA DEL SOLE",
    "CASTROCIELO",
    "CASTROFILIPPO",
    "CASTROLIBERO",
    "CASTRONNO",
    "CASTRONOVO DI SICILIA",
    "CASTRONUOVO DI SANT'ANDREA",
    "CASTROPIGNANO",
    "CASTROREALE",
    "CASTROREGIO",
    "CASTROVILLARI",
    "CATANIA",
    "CATANZARO",
    "CATENANUOVA",
    "CATIGNANO",
    "CATTOLICA",
    "CATTOLICA ERACLEA",
    "CAULONIA",
    "CAUTANO",
    "CAVA DE' TIRRENI",
    "CAVA MANARA",
    "CAVACURTA",
    "CAVAGLIA'",
    "CAVAGLIETTO",
    "CAVAGLIO D'AGOGNA",
    "CAVAGLIO-SPOCCIA",
    "CAVAGNOLO",
    "CAVAION VERONESE",
    "CAVALESE",
    "CAVALLASCA",
    "CAVALLERLEONE",
    "CAVALLERMAGGIORE",
    "CAVALLINO",
    "CAVALLINO-TREPORTI",
    "CAVALLIRIO",
    "CAVARENO",
    "CAVARGNA",
    "CAVARIA CON PREMEZZO",
    "CAVARZERE",
    "CAVASO DEL TOMBA",
    "CAVASSO NUOVO",
    "CAVATORE",
    "CAVAZZO CARNICO",
    "CAVE",
    "CAVEDAGO",
    "CAVEDINE",
    "CAVENAGO D'ADDA",
    "CAVENAGO DI BRIANZA",
    "CAVERNAGO",
    "CAVEZZO",
    "CAVIZZANA",
    "CAVOUR",
    "CAVRIAGO",
    "CAVRIANA",
    "CAVRIGLIA",
    "CAZZAGO BRABBIA",
    "CAZZAGO SAN MARTINO",
    "CAZZANO DI TRAMIGNA",
    "CAZZANO SANT'ANDREA",
    "CECCANO",
    "CECIMA",
    "CECINA",
    "CEDEGOLO",
    "CEDRASCO",
    "CEFALA' DIANA",
    "CEFALU'",
    "CEGGIA",
    "CEGLIE MESSAPICO",
    "CELANO",
    "CELENZA SUL TRIGNO",
    "CELENZA VALFORTORE",
    "CELICO",
    "CELLA DATI",
    "CELLA MONTE",
    "CELLAMARE",
    "CELLARA",
    "CELLARENGO",
    "CELLATICA",
    "CELLE DI BULGHERIA",
    "CELLE DI MACRA",
    "CELLE ENOMONDO",
    "CELLE LIGURE",
    "CELLE SAN VITO",
    "CELLENO",
    "CELLERE",
    "CELLINO ATTANASIO",
    "CELLINO SAN MARCO",
    "CELLIO",
    "CELLOLE",
    "CEMBRA",
    "CENADI",
    "CENATE SOPRA",
    "CENATE SOTTO",
    "CENCENIGHE AGORDINO",
    "CENE",
    "CENESELLI",
    "CENGIO",
    "CENTA SAN NICOLO'",
    "CENTALLO",
    "CENTO",
    "CENTOLA",
    "CENTRACHE",
    "CENTURIPE",
    "CEPAGATTI",
    "CEPPALONI",
    "CEPPO MORELLI",
    "CEPRANO",
    "CERAMI",
    "CERANESI",
    "CERANO",
    "CERANO D'INTELVI",
    "CERANOVA",
    "CERASO",
    "CERCEMAGGIORE",
    "CERCENASCO",
    "CERCEPICCOLA",
    "CERCHIARA DI CALABRIA",
    "CERCHIO",
    "CERCINO",
    "CERCIVENTO",
    "CERCOLA",
    "CERDA",
    "CEREA",
    "CEREGNANO",
    "CERENZIA",
    "CERES",
    "CERESARA",
    "CERESETO",
    "CERESOLE ALBA",
    "CERESOLE REALE",
    "CERETE",
    "CERETTO LOMELLINA",
    "CERGNAGO",
    "CERIALE",
    "CERIANA",
    "CERIANO LAGHETTO",
    "CERIGNALE",
    "CERIGNOLA",
    "CERISANO",
    "CERMENATE",
    "CERMES .TSCHERMS.",
    "CERMIGNANO",
    "CERNOBBIO",
    "CERNUSCO LOMBARDONE",
    "CERNUSCO SUL NAVIGLIO",
    "CERRETO CASTELLO",
    "CERRETO D'ASTI",
    "CERRETO D'ESI",
    "CERRETO DI SPOLETO",
    "CERRETO GRUE",
    "CERRETO GUIDI",
    "CERRETO LAZIALE",
    "CERRETO SANNITA",
    "CERRETTO DELLE LANGHE",
    "CERRINA",
    "CERRIONE",
    "CERRO AL LAMBRO",
    "CERRO AL VOLTURNO",
    "CERRO MAGGIORE",
    "CERRO TANARO",
    "CERRO VERONESE",
    "CERSOSIMO",
    "CERTALDO",
    "CERTOSA DI PAVIA",
    "CERVA",
    "CERVARA DI ROMA",
    "CERVARESE SANTA CROCE",
    "CERVARO",
    "CERVASCA",
    "CERVATTO",
    "CERVENO",
    "CERVERE",
    "CERVESINA",
    "CERVETERI",
    "CERVIA",
    "CERVICATI",
    "CERVIGNANO D'ADDA",
    "CERVIGNANO DEL FRIULI",
    "CERVINARA",
    "CERVINO",
    "CERVO",
    "CERZETO",
    "CESA",
    "CESANA BRIANZA",
    "CESANA TORINESE",
    "CESANO BOSCONE",
    "CESANO MADERNO",
    "CESARA",
    "CESARO'",
    "CESATE",
    "CESENA",
    "CESENATICO",
    "CESINALI",
    "CESIO",
    "CESIOMAGGIORE",
    "CESSALTO",
    "CESSANITI",
    "CESSAPALOMBO",
    "CESSOLE",
    "CETARA",
    "CETO",
    "CETONA",
    "CETRARO",
    "CEVA",
    "CEVO",
    "CHALLAND-SAINT-ANSELME",
    "CHALLAND-SAINT-VICTOR",
    "CHAMBAVE",
    "CHAMOIS",
    "CHAMPDEPRAZ",
    "CHAMPORCHER",
    "CHARVENSOD",
    "CHATILLON",
    "CHERASCO",
    "CHEREMULE",
    "CHIALAMBERTO",
    "CHIAMPO",
    "CHIANCHE",
    "CHIANCIANO TERME",
    "CHIANNI",
    "CHIANOCCO",
    "CHIARAMONTE GULFI",
    "CHIARAMONTI",
    "CHIARANO",
    "CHIARAVALLE",
    "CHIARAVALLE CENTRALE",
    "CHIARI",
    "CHIAROMONTE",
    "CHIAUCI",
    "CHIAVARI",
    "CHIAVENNA",
    "CHIAVERANO",
    "CHIENES .KIENS.",
    "CHIERI",
    "CHIES D'ALPAGO",
    "CHIESA IN VALMALENCO",
    "CHIESANUOVA",
    "CHIESINA UZZANESE",
    "CHIETI",
    "CHIEUTI",
    "CHIEVE",
    "CHIGNOLO D'ISOLA",
    "CHIGNOLO PO",
    "CHIOGGIA",
    "CHIOMONTE",
    "CHIONS",
    "CHIOPRIS VISCONE",
    "CHITIGNANO",
    "CHIUDUNO",
    "CHIUPPANO",
    "CHIURO",
    "CHIUSA .KLAUSEN.",
    "CHIUSA DI PESIO",
    "CHIUSA DI SAN MICHELE",
    "CHIUSA SCLAFANI",
    "CHIUSAFORTE",
    "CHIUSANICO",
    "CHIUSANO D'ASTI",
    "CHIUSANO DI SAN DOMENICO",
    "CHIUSAVECCHIA",
    "CHIUSDINO",
    "CHIUSI",
    "CHIUSI DELLA VERNA",
    "CHIVASSO",
    "CIAMPINO",
    "CIANCIANA",
    "CIBIANA DI CADORE",
    "CICAGNA",
    "CICALA",
    "CICCIANO",
    "CICERALE",
    "CICILIANO",
    "CICOGNOLO",
    "CICONIO",
    "CIGLIANO",
    "CIGLIE'",
    "CIGOGNOLA",
    "CIGOLE",
    "CILAVEGNA",
    "CIMADOLMO",
    "CIMBERGO",
    "CIMEGO",
    "CIMINA'",
    "CIMINNA",
    "CIMITILE",
    "CIMOLAIS",
    "CIMONE",
    "CINAGLIO",
    "CINETO ROMANO",
    "CINGIA DE' BOTTI",
    "CINGOLI",
    "CINIGIANO",
    "CINISELLO BALSAMO",
    "CINISI",
    "CINO",
    "CINQUEFRONDI",
    "CINTANO",
    "CINTE TESINO",
    "CINTO CAOMAGGIORE",
    "CINTO EUGANEO",
    "CINZANO",
    "CIORLANO",
    "CIPRESSA",
    "CIRCELLO",
    "CIRIE'",
    "CIRIGLIANO",
    "CIRIMIDO",
    "CIRO'",
    "CIRO' MARINA",
    "CIS",
    "CISANO BERGAMASCO",
    "CISANO SUL NEVA",
    "CISERANO",
    "CISLAGO",
    "CISLIANO",
    "CISMON DEL GRAPPA",
    "CISON DI VALMARINO",
    "CISSONE",
    "CISTERNA D'ASTI",
    "CISTERNA DI LATINA",
    "CISTERNINO",
    "CITERNA",
    "CITTA' DELLA PIEVE",
    "CITTA' DI CASTELLO",
    "CITTA' SANT'ANGELO",
    "CITTADELLA",
    "CITTADUCALE",
    "CITTANOVA",
    "CITTAREALE",
    "CITTIGLIO",
    "CIVATE",
    "CIVENNA",
    "CIVEZZA",
    "CIVEZZANO",
    "CIVIASCO",
    "CIVIDALE DEL FRIULI",
    "CIVIDATE AL PIANO",
    "CIVIDATE CAMUNO",
    "CIVITA",
    "CIVITA CASTELLANA",
    "CIVITA D'ANTINO",
    "CIVITACAMPOMARANO",
    "CIVITALUPARELLA",
    "CIVITANOVA DEL SANNIO",
    "CIVITANOVA MARCHE",
    "CIVITAQUANA",
    "CIVITAVECCHIA",
    "CIVITELLA ALFEDENA",
    "CIVITELLA CASANOVA",
    "CIVITELLA D'AGLIANO",
    "CIVITELLA DEL TRONTO",
    "CIVITELLA DI ROMAGNA",
    "CIVITELLA IN VAL DI CHIANA",
    "CIVITELLA MESSER RAIMONDO",
    "CIVITELLA PAGANICO",
    "CIVITELLA ROVETO",
    "CIVITELLA SAN PAOLO",
    "CIVO",
    "CLAINO CON OSTENO",
    "CLAUT",
    "CLAUZETTO",
    "CLAVESANA",
    "CLAVIERE",
    "CLES",
    "CLETO",
    "CLIVIO",
    "CLOZ",
    "CLUSONE",
    "COASSOLO TORINESE",
    "COAZZE",
    "COAZZOLO",
    "COCCAGLIO",
    "COCCONATO",
    "COCQUIO-TREVISAGO",
    "COCULLO",
    "CODEVIGO",
    "CODEVILLA",
    "CODIGORO",
    "CODOGNE'",
    "CODOGNO",
    "CODROIPO",
    "CODRONGIANOS",
    "COGGIOLA",
    "COGLIATE",
    "COGNE",
    "COGOLETO",
    "COGOLLO DEL CENGIO",
    "COGORNO",
    "COLAZZA",
    "COLBORDOLO",
    "COLERE",
    "COLFELICE",
    "COLI",
    "COLICO",
    "COLLAGNA",
    "COLLALTO SABINO",
    "COLLARMELE",
    "COLLAZZONE",
    "COLLE BRIANZA",
    "COLLE D'ANCHISE",
    "COLLE DI TORA",
    "COLLE DI VAL D'ELSA",
    "COLLE SAN MAGNO",
    "COLLE SANNITA",
    "COLLE SANTA LUCIA",
    "COLLE UMBERTO",
    "COLLEBEATO",
    "COLLECCHIO",
    "COLLECORVINO",
    "COLLEDARA",
    "COLLEDIMACINE",
    "COLLEDIMEZZO",
    "COLLEFERRO",
    "COLLEGIOVE",
    "COLLEGNO",
    "COLLELONGO",
    "COLLEPARDO",
    "COLLEPASSO",
    "COLLEPIETRO",
    "COLLERETTO CASTELNUOVO",
    "COLLERETTO GIACOSA",
    "COLLESALVETTI",
    "COLLESANO",
    "COLLETORTO",
    "COLLEVECCHIO",
    "COLLI A VOLTURNO",
    "COLLI DEL TRONTO",
    "COLLI SUL VELINO",
    "COLLIANO",
    "COLLINAS",
    "COLLIO",
    "COLLOBIANO",
    "COLLOREDO DI MONTE ALBANO",
    "COLMURANO",
    "COLOBRARO",
    "COLOGNA VENETA",
    "COLOGNE",
    "COLOGNO AL SERIO",
    "COLOGNO MONZESE",
    "COLOGNOLA AI COLLI",
    "COLONNA",
    "COLONNELLA",
    "COLONNO",
    "COLORINA",
    "COLORNO",
    "COLOSIMI",
    "COLTURANO",
    "COLZATE",
    "COMABBIO",
    "COMACCHIO",
    "COMANO",
    "COMAZZO",
    "COMEGLIANS",
    "COMELICO SUPERIORE",
    "COMERIO",
    "COMEZZANO-CIZZAGO",
    "COMIGNAGO",
    "COMISO",
    "COMITINI",
    "COMIZIANO",
    "COMMESSAGGIO",
    "COMMEZZADURA",
    "COMO",
    "COMPIANO",
    "COMUN NUOVO",
    "COMUNANZA",
    "CONA",
    "CONCA CASALE",
    "CONCA DEI MARINI",
    "CONCA DELLA CAMPANIA",
    "CONCAMARISE",
    "CONCEI",
    "CONCERVIANO",
    "CONCESIO",
    "CONCO",
    "CONCORDIA SAGITTARIA",
    "CONCORDIA SULLA SECCHIA",
    "CONCOREZZO",
    "CONDINO",
    "CONDOFURI",
    "CONDOVE",
    "CONDRO'",
    "CONEGLIANO",
    "CONFIENZA",
    "CONFIGNI",
    "CONFLENTI",
    "CONIOLO",
    "CONSELICE",
    "CONSELVE",
    "CONSIGLIO DI RUMO",
    "CONTESSA ENTELLINA",
    "CONTIGLIANO",
    "CONTRADA",
    "CONTROGUERRA",
    "CONTRONE",
    "CONTURSI TERME",
    "CONVERSANO",
    "CONZA DELLA CAMPANIA",
    "CONZANO",
    "COPERTINO",
    "COPIANO",
    "COPPARO",
    "CORANA",
    "CORATO",
    "CORBARA",
    "CORBETTA",
    "CORBOLA",
    "CORCHIANO",
    "CORCIANO",
    "CORDENONS",
    "CORDIGNANO",
    "CORDOVADO",
    "COREDO",
    "COREGLIA ANTELMINELLI",
    "COREGLIA LIGURE",
    "CORENO AUSONIO",
    "CORFINIO",
    "CORI",
    "CORIANO",
    "CORIGLIANO CALABRO",
    "CORIGLIANO D'OTRANTO",
    "CORINALDO",
    "CORIO",
    "CORLEONE",
    "CORLETO MONFORTE",
    "CORLETO PERTICARA",
    "CORMANO",
    "CORMONS",
    "CORNA IMAGNA",
    "CORNALBA",
    "CORNALE",
    "CORNAREDO",
    "CORNATE D'ADDA",
    "CORNEDO ALL'ISARCO .KARNEID.",
    "CORNEDO VICENTINO",
    "CORNEGLIANO LAUDENSE",
    "CORNELIANO D'ALBA",
    "CORNIGLIO",
    "CORNO DI ROSAZZO",
    "CORNO GIOVINE",
    "CORNOVECCHIO",
    "CORNUDA",
    "CORREGGIO",
    "CORREZZANA",
    "CORREZZOLA",
    "CORRIDO",
    "CORRIDONIA",
    "CORROPOLI",
    "CORSANO",
    "CORSICO",
    "CORSIONE",
    "CORTACCIA SULLA STRADA DEL VINO .KURTATSCH A.",
    "CORTALE",
    "CORTANDONE",
    "CORTANZE",
    "CORTAZZONE",
    "CORTE BRUGNATELLA",
    "CORTE DE' CORTESI CON CIGNONE",
    "CORTE DE' FRATI",
    "CORTE FRANCA",
    "CORTE PALASIO",
    "CORTEMAGGIORE",
    "CORTEMILIA",
    "CORTENO GOLGI",
    "CORTENOVA",
    "CORTENUOVA",
    "CORTEOLONA",
    "CORTIGLIONE",
    "CORTINA D'AMPEZZO",
    "CORTINA SULLA STRADA DEL VINO .KURTINIG AN D.",
    "CORTINO",
    "CORTONA",
    "CORVARA",
    "CORVARA IN BADIA .CORVARA.",
    "CORVINO SAN QUIRICO",
    "CORZANO",
    "COSEANO",
    "COSENZA",
    "COSIO D'ARROSCIA",
    "COSIO VALTELLINO",
    "COSOLETO",
    "COSSANO BELBO",
    "COSSANO CANAVESE",
    "COSSATO",
    "COSSERIA",
    "COSSIGNANO",
    "COSSOGNO",
    "COSSOINE",
    "COSSOMBRATO",
    "COSTA DE' NOBILI",
    "COSTA DI MEZZATE",
    "COSTA DI ROVIGO",
    "COSTA DI SERINA",
    "COSTA MASNAGA",
    "COSTA VALLE IMAGNA",
    "COSTA VESCOVATO",
    "COSTA VOLPINO",
    "COSTABISSARA",
    "COSTACCIARO",
    "COSTANZANA",
    "COSTARAINERA",
    "COSTERMANO",
    "COSTIGLIOLE D'ASTI",
    "COSTIGLIOLE SALUZZO",
    "COTIGNOLA",
    "COTRONEI",
    "COTTANELLO",
    "COURMAYEUR",
    "COVO",
    "COZZO",
    "CRACO",
    "CRANDOLA VALSASSINA",
    "CRAVAGLIANA",
    "CRAVANZANA",
    "CRAVEGGIA",
    "CREAZZO",
    "CRECCHIO",
    "CREDARO",
    "CREDERA RUBBIANO",
    "CREMA",
    "CREMELLA",
    "CREMENAGA",
    "CREMENO",
    "CREMIA",
    "CREMOLINO",
    "CREMONA",
    "CREMOSANO",
    "CRESCENTINO",
    "CRESPADORO",
    "CRESPANO DEL GRAPPA",
    "CRESPELLANO",
    "CRESPIATICA",
    "CRESPINA",
    "CRESPINO",
    "CRESSA",
    "CREVACUORE",
    "CREVALCORE",
    "CREVOLADOSSOLA",
    "CRISPANO",
    "CRISPIANO",
    "CRISSOLO",
    "CROCEFIESCHI",
    "CROCETTA DEL MONTELLO",
    "CRODO",
    "CROGNALETO",
    "CROPALATI",
    "CROPANI",
    "CROSA",
    "CROSIA",
    "CROSIO DELLA VALLE",
    "CROTONE",
    "CROTTA D'ADDA",
    "CROVA",
    "CROVIANA",
    "CRUCOLI",
    "CUASSO AL MONTE",
    "CUCCARO MONFERRATO",
    "CUCCARO VETERE",
    "CUCCIAGO",
    "CUCEGLIO",
    "CUGGIONO",
    "CUGLIATE-FABIASCO",
    "CUGLIERI",
    "CUGNOLI",
    "CUMIANA",
    "CUMIGNANO SUL NAVIGLIO",
    "CUNARDO",
    "CUNEO",
    "CUNEVO",
    "CUNICO",
    "CUORGNE'",
    "CUPELLO",
    "CUPRA MARITTIMA",
    "CUPRAMONTANA",
    "CURA CARPIGNANO",
    "CURCURIS",
    "CUREGGIO",
    "CURIGLIA CON MONTEVIASCO",
    "CURINGA",
    "CURINO",
    "CURNO",
    "CURON VENOSTA .GRAUN IN VINSCHGAU.",
    "CURSI",
    "CURSOLO-ORASSO",
    "CURTAROLO",
    "CURTATONE",
    "CURTI",
    "CUSAGO",
    "CUSANO MILANINO",
    "CUSANO MUTRI",
    "CUSINO",
    "CUSIO",
    "CUSTONACI",
    "CUTIGLIANO",
    "CUTRO",
    "CUTROFIANO",
    "CUVEGLIO",
    "CUVIO",
    "DAIANO",
    "DAIRAGO",
    "DALMINE",
    "DAMBEL",
    "DANTA DI CADORE",
    "DAONE",
    "DARE'",
    "DARFO BOARIO TERME",
    "DASA'",
    "DAVAGNA",
    "DAVERIO",
    "DAVOLI",
    "DAZIO",
    "DECIMOMANNU",
    "DECIMOPUTZU",
    "DECOLLATURA",
    "DEGO",
    "DEIVA MARINA",
    "DELEBIO",
    "DELIA",
    "DELIANUOVA",
    "DELICETO",
    "DELLO",
    "DEMONTE",
    "DENICE",
    "DENNO",
    "DERNICE",
    "DEROVERE",
    "DERUTA",
    "DERVIO",
    "DESANA",
    "DESENZANO DEL GARDA",
    "DESIO",
    "DESULO",
    "DIAMANTE",
    "DIANO ARENTINO",
    "DIANO CASTELLO",
    "DIANO D'ALBA",
    "DIANO MARINA",
    "DIANO SAN PIETRO",
    "DICOMANO",
    "DIGNANO",
    "DIMARO",
    "DINAMI",
    "DIPIGNANO",
    "DISO",
    "DIVIGNANO",
    "DIZZASCO",
    "DOBBIACO .TOBLACH.",
    "DOBERDO' DEL LAGO",
    "DOGLIANI",
    "DOGLIOLA",
    "DOGNA",
    "DOLCE'",
    "DOLCEACQUA",
    "DOLCEDO",
    "DOLEGNA DEL COLLIO",
    "DOLIANOVA",
    "DOLO",
    "DOLZAGO",
    "DOMANICO",
    "DOMASO",
    "DOMEGGE DI CADORE",
    "DOMICELLA",
    "DOMODOSSOLA",
    "DOMUS DE MARIA",
    "DOMUSNOVAS",
    "DON",
    "DONATO",
    "DONGO",
    "DONNAS",
    "DONORI",
    "DORGALI",
    "DORIO",
    "DORMELLETTO",
    "DORNO",
    "DORSINO",
    "DORZANO",
    "DOSOLO",
    "DOSSENA",
    "DOSSO DEL LIRO",
    "DOUES",
    "DOVADOLA",
    "DOVERA",
    "DOZZA",
    "DRAGONI",
    "DRAPIA",
    "DRENA",
    "DRENCHIA",
    "DRESANO",
    "DREZZO",
    "DRIZZONA",
    "DRO",
    "DRONERO",
    "DRUENTO",
    "DRUOGNO",
    "DUALCHI",
    "DUBINO",
    "DUE CARRARE",
    "DUEVILLE",
    "DUGENTA",
    "DUINO-AURISINA",
    "DUMENZA",
    "DUNO",
    "DURAZZANO",
    "DURONIA",
    "DUSINO SAN MICHELE",
    "EBOLI",
    "EDOLO",
    "EGNA .NEUMARKT.",
    "ELICE",
    "ELINI",
    "ELLO",
    "ELMAS",
    "ELVA",
    "EMARESE",
    "EMPOLI",
    "ENDINE GAIANO",
    "ENEGO",
    "ENEMONZO",
    "ENNA",
    "ENTRACQUE",
    "ENTRATICO",
    "ENVIE",
    "EPISCOPIA",
    "ERACLEA",
    "ERBA",
    "ERBE'",
    "ERBEZZO",
    "ERBUSCO",
    "ERCHIE",
    "ERCOLANO",
    "ERICE",
    "ERLI",
    "ERTO E CASSO",
    "ERULA",
    "ERVE",
    "ESANATOGLIA",
    "ESCALAPLANO",
    "ESCOLCA",
    "ESINE",
    "ESINO LARIO",
    "ESPERIA",
    "ESPORLATU",
    "ESTE",
    "ESTERZILI",
    "ETROUBLES",
    "EUPILIO",
    "EXILLES",
    "FABBRICA CURONE",
    "FABBRICHE DI VALLICO",
    "FABBRICO",
    "FABRIANO",
    "FABRICA DI ROMA",
    "FABRIZIA",
    "FABRO",
    "FAEDIS",
    "FAEDO",
    "FAEDO VALTELLINO",
    "FAENZA",
    "FAETO",
    "FAGAGNA",
    "FAGGETO LARIO",
    "FAGGIANO",
    "FAGNANO ALTO",
    "FAGNANO CASTELLO",
    "FAGNANO OLONA",
    "FAI DELLA PAGANELLA",
    "FAICCHIO",
    "FALCADE",
    "FALCIANO DEL MASSICO",
    "FALCONARA ALBANESE",
    "FALCONARA MARITTIMA",
    "FALCONE",
    "FALERIA",
    "FALERNA",
    "FALERONE",
    "FALLO",
    "FALMENTA",
    "FALOPPIO",
    "FALVATERRA",
    "FALZES .PFALZEN.",
    "FANANO",
    "FANNA",
    "FANO",
    "FANO ADRIANO",
    "FARA FILIORUM PETRI",
    "FARA GERA D'ADDA",
    "FARA IN SABINA",
    "FARA NOVARESE",
    "FARA OLIVANA CON SOLA",
    "FARA SAN MARTINO",
    "FARA VICENTINO",
    "FARDELLA",
    "FARIGLIANO",
    "FARINDOLA",
    "FARINI",
    "FARNESE",
    "FARRA D'ALPAGO",
    "FARRA DI SOLIGO",
    "FARRA D'ISONZO",
    "FASANO",
    "FASCIA",
    "FAUGLIA",
    "FAULE",
    "FAVALE DI MALVARO",
    "FAVARA",
    "FAVER",
    "FAVIGNANA",
    "FAVRIA",
    "FEISOGLIO",
    "FELETTO",
    "FELINO",
    "FELITTO",
    "FELIZZANO",
    "FELONICA",
    "FELTRE",
    "FENEGRO'",
    "FENESTRELLE",
    "FENIS",
    "FERENTILLO",
    "FERENTINO",
    "FERLA",
    "FERMIGNANO",
    "FERMO",
    "FERNO",
    "FEROLETO ANTICO",
    "FEROLETO DELLA CHIESA",
    "FERRANDINA",
    "FERRARA",
    "FERRARA DI MONTE BALDO",
    "FERRAZZANO",
    "FERRERA DI VARESE",
    "FERRERA ERBOGNONE",
    "FERRERE",
    "FERRIERE",
    "FERRUZZANO",
    "FIAMIGNANO",
    "FIANO",
    "FIANO ROMANO",
    "FIASTRA",
    "FIAVE'",
    "FICARAZZI",
    "FICAROLO",
    "FICARRA",
    "FICULLE",
    "FIDENZA",
    "FIE' ALLO SCILIAR .VOLS AM SCHLERN.",
    "FIERA DI PRIMIERO",
    "FIEROZZO",
    "FIESCO",
    "FIESOLE",
    "FIESSE",
    "FIESSO D'ARTICO",
    "FIESSO UMBERTIANO",
    "FIGINO SERENZA",
    "FIGLINE VALDARNO",
    "FIGLINE VEGLIATURO",
    "FILACCIANO",
    "FILADELFIA",
    "FILAGO",
    "FILANDARI",
    "FILATTIERA",
    "FILETTINO",
    "FILETTO",
    "FILIANO",
    "FILIGHERA",
    "FILIGNANO",
    "FILOGASO",
    "FILOTTRANO",
    "FINALE EMILIA",
    "FINALE LIGURE",
    "FINO DEL MONTE",
    "FINO MORNASCO",
    "FIORANO AL SERIO",
    "FIORANO CANAVESE",
    "FIORANO MODENESE",
    "FIORDIMONTE",
    "FIORENZUOLA D'ARDA",
    "FIRENZE",
    "FIRENZUOLA",
    "FIRMO",
    "FISCIANO",
    "FIUGGI",
    "FIUMALBO",
    "FIUMARA",
    "FIUME VENETO",
    "FIUMEDINISI",
    "FIUMEFREDDO BRUZIO",
    "FIUMEFREDDO DI SICILIA",
    "FIUMICELLO",
    "FIUMICINO",
    "FIUMINATA",
    "FIVIZZANO",
    "FLAIBANO",
    "FLAVON",
    "FLERO",
    "FLORESTA",
    "FLORIDIA",
    "FLORINAS",
    "FLUMERI",
    "FLUMINIMAGGIORE",
    "FLUSSIO",
    "FOBELLO",
    "FOGGIA",
    "FOGLIANISE",
    "FOGLIANO REDIPUGLIA",
    "FOGLIZZO",
    "FOIANO DELLA CHIANA",
    "FOIANO DI VAL FORTORE",
    "FOLGARIA",
    "FOLIGNANO",
    "FOLIGNO",
    "FOLLINA",
    "FOLLO",
    "FOLLONICA",
    "FOMBIO",
    "FONDACHELLI-FANTINA",
    "FONDI",
    "FONDO",
    "FONNI",
    "FONTAINEMORE",
    "FONTANA LIRI",
    "FONTANAFREDDA",
    "FONTANAROSA",
    "FONTANELICE",
    "FONTANELLA",
    "FONTANELLATO",
    "FONTANELLE",
    "FONTANETO D'AGOGNA",
    "FONTANETTO PO",
    "FONTANIGORDA",
    "FONTANILE",
    "FONTANIVA",
    "FONTE",
    "FONTE NUOVA",
    "FONTECCHIO",
    "FONTECHIARI",
    "FONTEGRECA",
    "FONTENO",
    "FONTEVIVO",
    "FONZASO",
    "FOPPOLO",
    "FORANO",
    "FORCE",
    "FORCHIA",
    "FORCOLA",
    "FORDONGIANUS",
    "FORENZA",
    "FORESTO SPARSO",
    "FORGARIA NEL FRIULI",
    "FORINO",
    "FORIO",
    "FORLI'",
    "FORLI' DEL SANNIO",
    "FORLIMPOPOLI",
    "FORMAZZA",
    "FORMELLO",
    "FORMIA",
    "FORMICOLA",
    "FORMIGARA",
    "FORMIGINE",
    "FORMIGLIANA",
    "FORMIGNANA",
    "FORNACE",
    "FORNELLI",
    "FORNI AVOLTRI",
    "FORNI DI SOPRA",
    "FORNI DI SOTTO",
    "FORNO CANAVESE",
    "FORNO DI ZOLDO",
    "FORNOVO DI TARO",
    "FORNOVO SAN GIOVANNI",
    "FORTE DEI MARMI",
    "FORTEZZA .FRANZENSFESTEN.",
    "FORTUNAGO",
    "FORZA D'AGRO'",
    "FOSCIANDORA",
    "FOSDINOVO",
    "FOSSA",
    "FOSSACESIA",
    "FOSSALTA DI PIAVE",
    "FOSSALTA DI PORTOGRUARO",
    "FOSSALTO",
    "FOSSANO",
    "FOSSATO DI VICO",
    "FOSSATO SERRALTA",
    "FOSSO'",
    "FOSSOMBRONE",
    "FOZA",
    "FRABOSA SOPRANA",
    "FRABOSA SOTTANA",
    "FRACONALTO",
    "FRAGAGNANO",
    "FRAGNETO L'ABATE",
    "FRAGNETO MONFORTE",
    "FRAINE",
    "FRAMURA",
    "FRANCAVILLA AL MARE",
    "FRANCAVILLA ANGITOLA",
    "FRANCAVILLA BISIO",
    "FRANCAVILLA D'ETE",
    "FRANCAVILLA DI SICILIA",
    "FRANCAVILLA FONTANA",
    "FRANCAVILLA IN SINNI",
    "FRANCAVILLA MARITTIMA",
    "FRANCICA",
    "FRANCOFONTE",
    "FRANCOLISE",
    "FRASCARO",
    "FRASCAROLO",
    "FRASCATI",
    "FRASCINETO",
    "FRASSILONGO",
    "FRASSINELLE POLESINE",
    "FRASSINELLO MONFERRATO",
    "FRASSINETO PO",
    "FRASSINETTO",
    "FRASSINO",
    "FRASSINORO",
    "FRASSO SABINO",
    "FRASSO TELESINO",
    "FRATTA POLESINE",
    "FRATTA TODINA",
    "FRATTAMAGGIORE",
    "FRATTAMINORE",
    "FRATTE ROSA",
    "FRAZZANO'",
    "FREGONA",
    "FRESAGRANDINARIA",
    "FRESONARA",
    "FRIGENTO",
    "FRIGNANO",
    "FRINCO",
    "FRISA",
    "FRISANCO",
    "FRONT",
    "FRONTINO",
    "FRONTONE",
    "FROSINONE",
    "FROSOLONE",
    "FROSSASCO",
    "FRUGAROLO",
    "FUBINE",
    "FUCECCHIO",
    "FUIPIANO VALLE IMAGNA",
    "FUMANE",
    "FUMONE",
    "FUNES .VILLNOSS.",
    "FURCI",
    "FURCI SICULO",
    "FURNARI",
    "FURORE",
    "FURTEI",
    "FUSCALDO",
    "FUSIGNANO",
    "FUSINE",
    "FUTANI",
    "GABBIONETA BINANUOVA",
    "GABIANO",
    "GABICCE MARE",
    "GABY",
    "GADESCO PIEVE DELMONA",
    "GADONI",
    "GAETA",
    "GAGGI",
    "GAGGIANO",
    "GAGGIO MONTANO",
    "GAGLIANICO",
    "GAGLIANO ATERNO",
    "GAGLIANO CASTELFERRATO",
    "GAGLIANO DEL CAPO",
    "GAGLIATO",
    "GAGLIOLE",
    "GAIARINE",
    "GAIBA",
    "GAIOLA",
    "GAIOLE IN CHIANTI",
    "GAIRO",
    "GAIS .GAIS.",
    "GALATI MAMERTINO",
    "GALATINA",
    "GALATONE",
    "GALATRO",
    "GALBIATE",
    "GALEATA",
    "GALGAGNANO",
    "GALLARATE",
    "GALLESE",
    "GALLIATE",
    "GALLIATE LOMBARDO",
    "GALLIAVOLA",
    "GALLICANO",
    "GALLICANO NEL LAZIO",
    "GALLICCHIO",
    "GALLIERA",
    "GALLIERA VENETA",
    "GALLINARO",
    "GALLIO",
    "GALLIPOLI",
    "GALLO MATESE",
    "GALLODORO",
    "GALLUCCIO",
    "GALTELLI",
    "GALZIGNANO TERME",
    "GAMALERO",
    "GAMBARA",
    "GAMBARANA",
    "GAMBASCA",
    "GAMBASSI TERME",
    "GAMBATESA",
    "GAMBELLARA",
    "GAMBERALE",
    "GAMBETTOLA",
    "GAMBOLO'",
    "GAMBUGLIANO",
    "GANDELLINO",
    "GANDINO",
    "GANDOSSO",
    "GANGI",
    "GARAGUSO",
    "GARBAGNA",
    "GARBAGNA NOVARESE",
    "GARBAGNATE MILANESE",
    "GARBAGNATE MONASTERO",
    "GARDA",
    "GARDONE RIVIERA",
    "GARDONE VAL TROMPIA",
    "GARESSIO",
    "GARGALLO",
    "GARGAZZONE .GARGAZON.",
    "GARGNANO",
    "GARLASCO",
    "GARLATE",
    "GARLENDA",
    "GARNIGA TERME",
    "GARZENO",
    "GARZIGLIANA",
    "GASPERINA",
    "GASSINO TORINESE",
    "GATTATICO",
    "GATTEO",
    "GATTICO",
    "GATTINARA",
    "GAVARDO",
    "GAVAZZANA",
    "GAVELLO",
    "GAVERINA TERME",
    "GAVI",
    "GAVIGNANO",
    "GAVIRATE",
    "GAVOI",
    "GAVORRANO",
    "GAZOLDO DEGLI IPPOLITI",
    "GAZZADA SCHIANNO",
    "GAZZANIGA",
    "GAZZO PADOVANO",
    "GAZZO VERONESE",
    "GAZZOLA",
    "GAZZUOLO",
    "GELA",
    "GEMMANO",
    "GEMONA DEL FRIULI",
    "GEMONIO",
    "GENAZZANO",
    "GENGA",
    "GENIVOLTA",
    "GENOLA",
    "GENONI",
    "GENOVA",
    "GENURI",
    "GENZANO DI LUCANIA",
    "GENZANO DI ROMA",
    "GENZONE",
    "GERA LARIO",
    "GERACE",
    "GERACI SICULO",
    "GERANO",
    "GERENZAGO",
    "GERENZANO",
    "GERGEI",
    "GERMAGNANO",
    "GERMAGNO",
    "GERMASINO",
    "GERMIGNAGA",
    "GEROCARNE",
    "GEROLA ALTA",
    "GEROSA",
    "GERRE DE' CAPRIOLI",
    "GESICO",
    "GESSATE",
    "GESSOPALENA",
    "GESTURI",
    "GESUALDO",
    "GHEDI",
    "GHEMME",
    "GHIFFA",
    "GHILARZA",
    "GHISALBA",
    "GHISLARENGO",
    "GIACCIANO CON BARUCHELLA",
    "GIAGLIONE",
    "GIANICO",
    "GIANO DELL'UMBRIA",
    "GIANO VETUSTO",
    "GIARDINELLO",
    "GIARDINI-NAXOS",
    "GIAROLE",
    "GIARRATANA",
    "GIARRE",
    "GIAVE",
    "GIAVENO",
    "GIAVERA DEL MONTELLO",
    "GIBA",
    "GIBELLINA",
    "GIFFLENGA",
    "GIFFONE",
    "GIFFONI SEI CASALI",
    "GIFFONI VALLE PIANA",
    "GIGNESE",
    "GIGNOD",
    "GILDONE",
    "GIMIGLIANO",
    "GINESTRA",
    "GINESTRA DEGLI SCHIAVONI",
    "GINOSA",
    "GIOI",
    "GIOIA DEI MARSI",
    "GIOIA DEL COLLE",
    "GIOIA SANNITICA",
    "GIOIA TAURO",
    "GIOIOSA IONICA",
    "GIOIOSA MAREA",
    "GIOVE",
    "GIOVINAZZO",
    "GIOVO",
    "GIRASOLE",
    "GIRIFALCO",
    "GIRONICO",
    "GISSI",
    "GIUGGIANELLO",
    "GIUGLIANO IN CAMPANIA",
    "GIULIANA",
    "GIULIANO DI ROMA",
    "GIULIANO TEATINO",
    "GIULIANOVA",
    "GIUNCUGNANO",
    "GIUNGANO",
    "GIURDIGNANO",
    "GIUSSAGO",
    "GIUSSANO",
    "GIUSTENICE",
    "GIUSTINO",
    "GIUSVALLA",
    "GIVOLETTO",
    "GIZZERIA",
    "GLORENZA .GLURNS.",
    "GODEGA DI SANT'URBANO",
    "GODIASCO",
    "GODRANO",
    "GOITO",
    "GOLASECCA",
    "GOLFERENZO",
    "GOLFO ARANCI",
    "GOMBITO",
    "GONARS",
    "GONI",
    "GONNESA",
    "GONNOSCODINA",
    "GONNOSFANADIGA",
    "GONNOSNO'",
    "GONNOSTRAMATZA",
    "GONZAGA",
    "GORDONA",
    "GORGA",
    "GORGO AL MONTICANO",
    "GORGOGLIONE",
    "GORGONZOLA",
    "GORIANO SICOLI",
    "GORIZIA",
    "GORLA MAGGIORE",
    "GORLA MINORE",
    "GORLAGO",
    "GORLE",
    "GORNATE-OLONA",
    "GORNO",
    "GORO",
    "GORRETO",
    "GORZEGNO",
    "GOSALDO",
    "GOSSOLENGO",
    "GOTTASECCA",
    "GOTTOLENGO",
    "GOVONE",
    "GOZZANO",
    "GRADARA",
    "GRADISCA D'ISONZO",
    "GRADO",
    "GRADOLI",
    "GRAFFIGNANA",
    "GRAFFIGNANO",
    "GRAGLIA",
    "GRAGNANO",
    "GRAGNANO TREBBIENSE",
    "GRAMMICHELE",
    "GRANA",
    "GRANAGLIONE",
    "GRANAROLO DELL'EMILIA",
    "GRANCONA",
    "GRANDATE",
    "GRANDOLA ED UNITI",
    "GRANITI",
    "GRANOZZO CON MONTICELLO",
    "GRANTOLA",
    "GRANTORTO",
    "GRANZE",
    "GRASSANO",
    "GRASSOBBIO",
    "GRATTERI",
    "GRAUNO",
    "GRAVEDONA",
    "GRAVELLONA LOMELLINA",
    "GRAVELLONA TOCE",
    "GRAVERE",
    "GRAVINA DI CATANIA",
    "GRAVINA IN PUGLIA",
    "GRAZZANISE",
    "GRAZZANO BADOGLIO",
    "GRECCIO",
    "GRECI",
    "GREGGIO",
    "GREMIASCO",
    "GRESSAN",
    "GRESSONEY-LA-TRINITE'",
    "GRESSONEY-SAINT-JEAN",
    "GREVE IN CHIANTI",
    "GREZZAGO",
    "GREZZANA",
    "GRIANTE",
    "GRICIGNANO DI AVERSA",
    "GRIGNASCO",
    "GRIGNO",
    "GRIMACCO",
    "GRIMALDI",
    "GRINZANE CAVOUR",
    "GRISIGNANO DI ZOCCO",
    "GRISOLIA",
    "GRIZZANA MORANDI",
    "GROGNARDO",
    "GROMO",
    "GRONDONA",
    "GRONE",
    "GRONTARDO",
    "GROPELLO CAIROLI",
    "GROPPARELLO",
    "GROSCAVALLO",
    "GROSIO",
    "GROSOTTO",
    "GROSSETO",
    "GROSSO",
    "GROTTAFERRATA",
    "GROTTAGLIE",
    "GROTTAMINARDA",
    "GROTTAMMARE",
    "GROTTAZZOLINA",
    "GROTTE",
    "GROTTE DI CASTRO",
    "GROTTERIA",
    "GROTTOLE",
    "GROTTOLELLA",
    "GRUARO",
    "GRUGLIASCO",
    "GRUMELLO CREMONESE ED UNITI",
    "GRUMELLO DEL MONTE",
    "GRUMENTO NOVA",
    "GRUMES",
    "GRUMO APPULA",
    "GRUMO NEVANO",
    "GRUMOLO DELLE ABBADESSE",
    "GUAGNANO",
    "GUALDO",
    "GUALDO CATTANEO",
    "GUALDO TADINO",
    "GUALTIERI",
    "GUALTIERI SICAMINO'",
    "GUAMAGGIORE",
    "GUANZATE",
    "GUARCINO",
    "GUARDA VENETA",
    "GUARDABOSONE",
    "GUARDAMIGLIO",
    "GUARDAVALLE",
    "GUARDEA",
    "GUARDIA LOMBARDI",
    "GUARDIA PERTICARA",
    "GUARDIA PIEMONTESE",
    "GUARDIA SANFRAMONDI",
    "GUARDIAGRELE",
    "GUARDIALFIERA",
    "GUARDIAREGIA",
    "GUARDISTALLO",
    "GUARENE",
    "GUASILA",
    "GUASTALLA",
    "GUAZZORA",
    "GUBBIO",
    "GUDO VISCONTI",
    "GUGLIONESI",
    "GUIDIZZOLO",
    "GUIDONIA MONTECELIO",
    "GUIGLIA",
    "GUILMI",
    "GURRO",
    "GUSPINI",
    "GUSSAGO",
    "GUSSOLA",
    "HONE",
    "IDRO",
    "IGLESIAS",
    "IGLIANO",
    "ILBONO",
    "ILLASI",
    "ILLORAI",
    "IMBERSAGO",
    "IMER",
    "IMOLA",
    "IMPERIA",
    "IMPRUNETA",
    "INARZO",
    "INCISA IN VAL D'ARNO",
    "INCISA SCAPACCINO",
    "INCUDINE",
    "INDUNO OLONA",
    "INGRIA",
    "INTRAGNA",
    "INTROBIO",
    "INTROD",
    "INTRODACQUA",
    "INTROZZO",
    "INVERIGO",
    "INVERNO E MONTELEONE",
    "INVERSO PINASCA",
    "INVERUNO",
    "INVORIO",
    "INZAGO",
    "IONADI",
    "IRGOLI",
    "IRMA",
    "IRSINA",
    "ISASCA",
    "ISCA SULLO IONIO",
    "ISCHIA",
    "ISCHIA DI CASTRO",
    "ISCHITELLA",
    "ISEO",
    "ISERA",
    "ISERNIA",
    "ISILI",
    "ISNELLO",
    "ISOLA D'ASTI",
    "ISOLA DEL CANTONE",
    "ISOLA DEL GIGLIO",
    "ISOLA DEL GRAN SASSO D'ITALIA",
    "ISOLA DEL LIRI",
    "ISOLA DEL PIANO",
    "ISOLA DELLA SCALA",
    "ISOLA DELLE FEMMINE",
    "ISOLA DI CAPO RIZZUTO",
    "ISOLA DI FONDRA",
    "ISOLA DOVARESE",
    "ISOLA RIZZA",
    "ISOLA SANT'ANTONIO",
    "ISOLA VICENTINA",
    "ISOLABELLA",
    "ISOLABONA",
    "ISOLE TREMITI",
    "ISORELLA",
    "ISPANI",
    "ISPICA",
    "ISPRA",
    "ISSIGLIO",
    "ISSIME",
    "ISSO",
    "ISSOGNE",
    "ISTRANA",
    "ITALA",
    "ITRI",
    "ITTIREDDU",
    "ITTIRI",
    "IVANO FRACENA",
    "IVREA",
    "IZANO",
    "JACURSO",
    "JELSI",
    "JENNE",
    "JERAGO CON ORAGO",
    "JERZU",
    "JESI",
    "JESOLO",
    "JOLANDA DI SAVOIA",
    "JOPPOLO",
    "JOPPOLO GIANCAXIO",
    "JOVENCAN",
    "LA CASSA",
    "LA LOGGIA",
    "LA MADDALENA",
    "LA MAGDELEINE",
    "LA MORRA",
    "LA SALLE",
    "LA SPEZIA",
    "LA THUILE",
    "LA VALLE .WENGEN.",
    "LA VALLE AGORDINA",
    "LABICO",
    "LABRO",
    "LACCHIARELLA",
    "LACCO AMENO",
    "LACEDONIA",
    "LACES .LATSCH.",
    "LACONI",
    "LADISPOLI",
    "LAERRU",
    "LAGANADI",
    "LAGHI",
    "LAGLIO",
    "LAGNASCO",
    "LAGO",
    "LAGONEGRO",
    "LAGOSANTO",
    "LAGUNDO .ALGUND.",
    "LAIGUEGLIA",
    "LAINATE",
    "LAINO",
    "LAINO BORGO",
    "LAINO CASTELLO",
    "LAION .LAJEN.",
    "LAIVES .LEIFERS.",
    "LAJATICO",
    "LALLIO",
    "LAMA DEI PELIGNI",
    "LAMA MOCOGNO",
    "LAMBRUGO",
    "LAMEZIA TERME",
    "LAMON",
    "LAMPEDUSA E LINOSA",
    "LAMPORECCHIO",
    "LAMPORO",
    "LANA .LANA.",
    "LANCIANO",
    "LANDIONA",
    "LANDRIANO",
    "LANGHIRANO",
    "LANGOSCO",
    "LANUSEI",
    "LANUVIO",
    "LANZADA",
    "LANZO D'INTELVI",
    "LANZO TORINESE",
    "LAPEDONA",
    "LAPIO",
    "LAPPANO",
    "L'AQUILA",
    "LARCIANO",
    "LARDARO",
    "LARDIRAGO",
    "LARI",
    "LARIANO",
    "LARINO",
    "LAS PLASSAS",
    "LASA .LAAS.",
    "LASCARI",
    "LASINO",
    "LASNIGO",
    "LASTEBASSE",
    "LASTRA A SIGNA",
    "LATERA",
    "LATERINA",
    "LATERZA",
    "LATIANO",
    "LATINA",
    "LATISANA",
    "LATRONICO",
    "LATTARICO",
    "LAUCO",
    "LAUREANA CILENTO",
    "LAUREANA DI BORRELLO",
    "LAUREGNO .LAUREIN.",
    "LAURENZANA",
    "LAURIA",
    "LAURIANO",
    "LAURINO",
    "LAURITO",
    "LAURO",
    "LAVAGNA",
    "LAVAGNO",
    "LAVARONE",
    "LAVELLO",
    "LAVENA PONTE TRESA",
    "LAVENO-MOMBELLO",
    "LAVENONE",
    "LAVIANO",
    "LAVIS",
    "LAZISE",
    "LAZZATE",
    "LECCE",
    "LECCE NEI MARSI",
    "LECCO",
    "LEFFE",
    "LEGGIUNO",
    "LEGNAGO",
    "LEGNANO",
    "LEGNARO",
    "LEI",
    "LEINI",
    "LEIVI",
    "LEMIE",
    "LENDINARA",
    "LENI",
    "LENNA",
    "LENNO",
    "LENO",
    "LENOLA",
    "LENTA",
    "LENTATE SUL SEVESO",
    "LENTELLA",
    "LENTIAI",
    "LENTINI",
    "LEONESSA",
    "LEONFORTE",
    "LEPORANO",
    "LEQUILE",
    "LEQUIO BERRIA",
    "LEQUIO TANARO",
    "LERCARA FRIDDI",
    "LERICI",
    "LERMA",
    "LESA",
    "LESEGNO",
    "LESIGNANO DE' BAGNI",
    "LESINA",
    "LESMO",
    "LESSOLO",
    "LESSONA",
    "LESTIZZA",
    "LETINO",
    "LETOJANNI",
    "LETTERE",
    "LETTOMANOPPELLO",
    "LETTOPALENA",
    "LEVANTO",
    "LEVATE",
    "LEVERANO",
    "LEVICE",
    "LEVICO TERME",
    "LEVONE",
    "LEZZENO",
    "LIBERI",
    "LIBRIZZI",
    "LICATA",
    "LICCIANA NARDI",
    "LICENZA",
    "LICODIA EUBEA",
    "LIERNA",
    "LIGNANA",
    "LIGNANO SABBIADORO",
    "LIGONCHIO",
    "LIGOSULLO",
    "LILLIANES",
    "LIMANA",
    "LIMATOLA",
    "LIMBADI",
    "LIMBIATE",
    "LIMENA",
    "LIMIDO COMASCO",
    "LIMINA",
    "LIMONE PIEMONTE",
    "LIMONE SUL GARDA",
    "LIMOSANO",
    "LINAROLO",
    "LINGUAGLOSSA",
    "LIONI",
    "LIPARI",
    "LIPOMO",
    "LIRIO",
    "LISCATE",
    "LISCIA",
    "LISCIANO NICCONE",
    "LISIGNAGO",
    "LISIO",
    "LISSONE",
    "LIVERI",
    "LIVIGNO",
    "LIVINALLONGO DEL COL DI LANA",
    "LIVO",
    "LIVO",
    "LIVORNO",
    "LIVORNO FERRARIS",
    "LIVRAGA",
    "LIZZANELLO",
    "LIZZANO",
    "LIZZANO IN BELVEDERE",
    "LOANO",
    "LOAZZOLO",
    "LOCANA",
    "LOCATE DI TRIULZI",
    "LOCATE VARESINO",
    "LOCATELLO",
    "LOCERI",
    "LOCOROTONDO",
    "LOCRI",
    "LOCULI",
    "LODE'",
    "LODI",
    "LODI VECCHIO",
    "LODINE",
    "LODRINO",
    "LOGRATO",
    "LOIANO",
    "LOIRI PORTO SAN PAOLO",
    "LOMAGNA",
    "LOMASO",
    "LOMAZZO",
    "LOMBARDORE",
    "LOMBRIASCO",
    "LOMELLO",
    "LONA LASES",
    "LONATE CEPPINO",
    "LONATE POZZOLO",
    "LONATO",
    "LONDA",
    "LONGANO",
    "LONGARE",
    "LONGARONE",
    "LONGHENA",
    "LONGI",
    "LONGIANO",
    "LONGOBARDI",
    "LONGOBUCCO",
    "LONGONE AL SEGRINO",
    "LONGONE SABINO",
    "LONIGO",
    "LORANZE'",
    "LOREGGIA",
    "LOREGLIA",
    "LORENZAGO DI CADORE",
    "LORENZANA",
    "LOREO",
    "LORETO",
    "LORETO APRUTINO",
    "LORIA",
    "LORO CIUFFENNA",
    "LORO PICENO",
    "LORSICA",
    "LOSINE",
    "LOTZORAI",
    "LOVERE",
    "LOVERO",
    "LOZIO",
    "LOZZA",
    "LOZZO ATESTINO",
    "LOZZO DI CADORE",
    "LOZZOLO",
    "LU",
    "LUBRIANO",
    "LUCCA",
    "LUCCA SICULA",
    "LUCERA",
    "LUCIGNANO",
    "LUCINASCO",
    "LUCITO",
    "LUCO DEI MARSI",
    "LUCOLI",
    "LUGAGNANO VAL D'ARDA",
    "LUGNACCO",
    "LUGNANO IN TEVERINA",
    "LUGO",
    "LUGO DI VICENZA",
    "LUINO",
    "LUISAGO",
    "LULA",
    "LUMARZO",
    "LUMEZZANE",
    "LUNAMATRONA",
    "LUNANO",
    "LUNGAVILLA",
    "LUNGRO",
    "LUOGOSANO",
    "LUOGOSANTO",
    "LUPARA",
    "LURAGO D'ERBA",
    "LURAGO MARINONE",
    "LURANO",
    "LURAS",
    "LURATE CACCIVIO",
    "LUSCIANO",
    "LUSERNA",
    "LUSERNA SAN GIOVANNI",
    "LUSERNETTA",
    "LUSEVERA",
    "LUSIA",
    "LUSIANA",
    "LUSIGLIE'",
    "LUSON .LUSEN.",
    "LUSTRA",
    "LUVINATE",
    "LUZZANA",
    "LUZZARA",
    "LUZZI",
    "MACCAGNO",
    "MACCASTORNA",
    "MACCHIA D'ISERNIA",
    "MACCHIA VALFORTORE",
    "MACCHIAGODENA",
    "MACELLO",
    "MACERATA",
    "MACERATA CAMPANIA",
    "MACERATA FELTRIA",
    "MACHERIO",
    "MACLODIO",
    "MACOMER",
    "MACRA",
    "MACUGNAGA",
    "MADDALONI",
    "MADESIMO",
    "MADIGNANO",
    "MADONE",
    "MADONNA DEL SASSO",
    "MAENZA",
    "MAFALDA",
    "MAGASA",
    "MAGENTA",
    "MAGGIORA",
    "MAGHERNO",
    "MAGIONE",
    "MAGISANO",
    "MAGLIANO ALFIERI",
    "MAGLIANO ALPI",
    "MAGLIANO DE' MARSI",
    "MAGLIANO DI TENNA",
    "MAGLIANO IN TOSCANA",
    "MAGLIANO ROMANO",
    "MAGLIANO SABINA",
    "MAGLIANO VETERE",
    "MAGLIE",
    "MAGLIOLO",
    "MAGLIONE",
    "MAGNACAVALLO",
    "MAGNAGO",
    "MAGNANO",
    "MAGNANO IN RIVIERA",
    "MAGOMADAS",
    "MAGRE' SULLA STRADA DEL VINO .MARGREID AN DE.",
    "MAGREGLIO",
    "MAIDA",
    "MAIERA'",
    "MAIERATO",
    "MAIOLATI SPONTINI",
    "MAIOLO",
    "MAIORI",
    "MAIRAGO",
    "MAIRANO",
    "MAISSANA",
    "MAJANO",
    "MALAGNINO",
    "MALALBERGO",
    "MALBORGHETTO-VALBRUNA",
    "MALCESINE",
    "MALE'",
    "MALEGNO",
    "MALEO",
    "MALESCO",
    "MALETTO",
    "MALFA",
    "MALGESSO",
    "MALGRATE",
    "MALITO",
    "MALLARE",
    "MALLES VENOSTA .MALS.",
    "MALNATE",
    "MALO",
    "MALONNO",
    "MALOSCO",
    "MALTIGNANO",
    "MALVAGNA",
    "MALVICINO",
    "MALVITO",
    "MAMMOLA",
    "MAMOIADA",
    "MANCIANO",
    "MANDANICI",
    "MANDAS",
    "MANDATORICCIO",
    "MANDELA",
    "MANDELLO DEL LARIO",
    "MANDELLO VITTA",
    "MANDURIA",
    "MANERBA DEL GARDA",
    "MANERBIO",
    "MANFREDONIA",
    "MANGO",
    "MANGONE",
    "MANIACE",
    "MANIAGO",
    "MANOCALZATI",
    "MANOPPELLO",
    "MANSUE'",
    "MANTA",
    "MANTELLO",
    "MANTOVA",
    "MANZANO",
    "MANZIANA",
    "MAPELLO",
    "MARA",
    "MARACALAGONIS",
    "MARANELLO",
    "MARANO DI NAPOLI",
    "MARANO DI VALPOLICELLA",
    "MARANO EQUO",
    "MARANO LAGUNARE",
    "MARANO MARCHESATO",
    "MARANO PRINCIPATO",
    "MARANO SUL PANARO",
    "MARANO TICINO",
    "MARANO VICENTINO",
    "MARANZANA",
    "MARATEA",
    "MARCALLO CON CASONE",
    "MARCARIA",
    "MARCEDUSA",
    "MARCELLINA",
    "MARCELLINARA",
    "MARCETELLI",
    "MARCHENO",
    "MARCHIROLO",
    "MARCIANA",
    "MARCIANA MARINA",
    "MARCIANISE",
    "MARCIANO DELLA CHIANA",
    "MARCIGNAGO",
    "MARCON",
    "MAREBBE .ENNEBERG.",
    "MARENE",
    "MARENO DI PIAVE",
    "MARENTINO",
    "MARETTO",
    "MARGARITA",
    "MARGHERITA DI SAVOIA",
    "MARGNO",
    "MARIANA MANTOVANA",
    "MARIANO COMENSE",
    "MARIANO DEL FRIULI",
    "MARIANOPOLI",
    "MARIGLIANELLA",
    "MARIGLIANO",
    "MARINA DI GIOIOSA IONICA",
    "MARINEO",
    "MARINO",
    "MARLENGO .MARLING.",
    "MARLIANA",
    "MARMENTINO",
    "MARMIROLO",
    "MARMORA",
    "MARNATE",
    "MARONE",
    "MAROPATI",
    "MAROSTICA",
    "MARRADI",
    "MARRUBIU",
    "MARSAGLIA",
    "MARSALA",
    "MARSCIANO",
    "MARSICO NUOVO",
    "MARSICOVETERE",
    "MARTA",
    "MARTANO",
    "MARTELLAGO",
    "MARTELLO .MARTELL.",
    "MARTIGNACCO",
    "MARTIGNANA DI PO",
    "MARTIGNANO",
    "MARTINA FRANCA",
    "MARTINENGO",
    "MARTINIANA PO",
    "MARTINSICURO",
    "MARTIRANO",
    "MARTIRANO LOMBARDO",
    "MARTIS",
    "MARTONE",
    "MARUDO",
    "MARUGGIO",
    "MARZABOTTO",
    "MARZANO",
    "MARZANO APPIO",
    "MARZANO DI NOLA",
    "MARZI",
    "MARZIO",
    "MASAINAS",
    "MASATE",
    "MASCALI",
    "MASCALUCIA",
    "MASCHITO",
    "MASCIAGO PRIMO",
    "MASER",
    "MASERA",
    "MASERA' DI PADOVA",
    "MASERADA SUL PIAVE",
    "MASI",
    "MASI TORELLO",
    "MASIO",
    "MASLIANICO",
    "MASON VICENTINO",
    "MASONE",
    "MASSA",
    "MASSA D'ALBE",
    "MASSA DI SOMMA",
    "MASSA E COZZILE",
    "MASSA FERMANA",
    "MASSA FISCAGLIA",
    "MASSA LOMBARDA",
    "MASSA LUBRENSE",
    "MASSA MARITTIMA",
    "MASSA MARTANA",
    "MASSAFRA",
    "MASSALENGO",
    "MASSANZAGO",
    "MASSAROSA",
    "MASSAZZA",
    "MASSELLO",
    "MASSERANO",
    "MASSIGNANO",
    "MASSIMENO",
    "MASSIMINO",
    "MASSINO VISCONTI",
    "MASSIOLA",
    "MASULLAS",
    "MATELICA",
    "MATERA",
    "MATHI",
    "MATINO",
    "MATRICE",
    "MATTIE",
    "MATTINATA",
    "MAZARA DEL VALLO",
    "MAZZANO",
    "MAZZANO ROMANO",
    "MAZZARINO",
    "MAZZARRA' SANT'ANDREA",
    "MAZZARRONE",
    "MAZZE'",
    "MAZZIN",
    "MAZZO DI VALTELLINA",
    "MEANA DI SUSA",
    "MEANA SARDO",
    "MEDA",
    "MEDE",
    "MEDEA",
    "MEDESANO",
    "MEDICINA",
    "MEDIGLIA",
    "MEDOLAGO",
    "MEDOLE",
    "MEDOLLA",
    "MEDUNA DI LIVENZA",
    "MEDUNO",
    "MEGLIADINO SAN FIDENZIO",
    "MEGLIADINO SAN VITALE",
    "MEINA",
    "MEL",
    "MELARA",
    "MELAZZO",
    "MELDOLA",
    "MELE",
    "MELEGNANO",
    "MELENDUGNO",
    "MELETI",
    "MELFI",
    "MELICUCCA'",
    "MELICUCCO",
    "MELILLI",
    "MELISSA",
    "MELISSANO",
    "MELITO DI NAPOLI",
    "MELITO DI PORTO SALVO",
    "MELITO IRPINO",
    "MELIZZANO",
    "MELLE",
    "MELLO",
    "MELPIGNANO",
    "MELTINA .MOLTEN.",
    "MELZO",
    "MENAGGIO",
    "MENAROLA",
    "MENCONICO",
    "MENDATICA",
    "MENDICINO",
    "MENFI",
    "MENTANA",
    "MEOLO",
    "MERANA",
    "MERANO .MERAN.",
    "MERATE",
    "MERCALLO",
    "MERCATELLO SUL METAURO",
    "MERCATINO CONCA",
    "MERCATO SAN SEVERINO",
    "MERCATO SARACENO",
    "MERCENASCO",
    "MERCOGLIANO",
    "MERETO DI TOMBA",
    "MERGO",
    "MERGOZZO",
    "MERI'",
    "MERLARA",
    "MERLINO",
    "MERONE",
    "MESAGNE",
    "MESE",
    "MESENZANA",
    "MESERO",
    "MESOLA",
    "MESORACA",
    "MESSINA",
    "MESTRINO",
    "META",
    "MEUGLIANO",
    "MEZZAGO",
    "MEZZANA",
    "MEZZANA BIGLI",
    "MEZZANA MORTIGLIENGO",
    "MEZZANA RABATTONE",
    "MEZZANE DI SOTTO",
    "MEZZANEGO",
    "MEZZANI",
    "MEZZANINO",
    "MEZZANO",
    "MEZZEGRA",
    "MEZZENILE",
    "MEZZOCORONA",
    "MEZZOJUSO",
    "MEZZOLDO",
    "MEZZOLOMBARDO",
    "MEZZOMERICO",
    "MIAGLIANO",
    "MIANE",
    "MIASINO",
    "MIAZZINA",
    "MICIGLIANO",
    "MIGGIANO",
    "MIGLIANICO",
    "MIGLIARINO",
    "MIGLIARO",
    "MIGLIERINA",
    "MIGLIONICO",
    "MIGNANEGO",
    "MIGNANO MONTE LUNGO",
    "MILANO",
    "MILAZZO",
    "MILENA",
    "MILETO",
    "MILIS",
    "MILITELLO IN VAL DI CATANIA",
    "MILITELLO ROSMARINO",
    "MILLESIMO",
    "MILO",
    "MILZANO",
    "MINEO",
    "MINERBE",
    "MINERBIO",
    "MINERVINO DI LECCE",
    "MINERVINO MURGE",
    "MINORI",
    "MINTURNO",
    "MINUCCIANO",
    "MIOGLIA",
    "MIRA",
    "MIRABELLA ECLANO",
    "MIRABELLA IMBACCARI",
    "MIRABELLO",
    "MIRABELLO MONFERRATO",
    "MIRABELLO SANNITICO",
    "MIRADOLO TERME",
    "MIRANDA",
    "MIRANDOLA",
    "MIRANO",
    "MIRTO",
    "MISANO ADRIATICO",
    "MISANO DI GERA D'ADDA",
    "MISILMERI",
    "MISINTO",
    "MISSAGLIA",
    "MISSANELLO",
    "MISTERBIANCO",
    "MISTRETTA",
    "MOASCA",
    "MOCONESI",
    "MODENA",
    "MODICA",
    "MODIGLIANA",
    "MODOLO",
    "MODUGNO",
    "MOENA",
    "MOGGIO",
    "MOGGIO UDINESE",
    "MOGLIA",
    "MOGLIANO",
    "MOGLIANO VENETO",
    "MOGORELLA",
    "MOGORO",
    "MOIANO",
    "MOIMACCO",
    "MOIO ALCANTARA",
    "MOIO DE' CALVI",
    "MOIO DELLA CIVITELLA",
    "MOIOLA",
    "MOLA DI BARI",
    "MOLARE",
    "MOLAZZANA",
    "MOLFETTA",
    "MOLINA ATERNO",
    "MOLINA DI LEDRO",
    "MOLINARA",
    "MOLINELLA",
    "MOLINI DI TRIORA",
    "MOLINO DEI TORTI",
    "MOLISE",
    "MOLITERNO",
    "MOLLIA",
    "MOLOCHIO",
    "MOLTENO",
    "MOLTRASIO",
    "MOLVENA",
    "MOLVENO",
    "MOMBALDONE",
    "MOMBARCARO",
    "MOMBAROCCIO",
    "MOMBARUZZO",
    "MOMBASIGLIO",
    "MOMBELLO DI TORINO",
    "MOMBELLO MONFERRATO",
    "MOMBERCELLI",
    "MOMO",
    "MOMPANTERO",
    "MOMPEO",
    "MOMPERONE",
    "MONACILIONI",
    "MONALE",
    "MONASTERACE",
    "MONASTERO BORMIDA",
    "MONASTERO DI LANZO",
    "MONASTERO DI VASCO",
    "MONASTEROLO CASOTTO",
    "MONASTEROLO DEL CASTELLO",
    "MONASTEROLO DI SAVIGLIANO",
    "MONASTIER DI TREVISO",
    "MONASTIR",
    "MONCALIERI",
    "MONCALVO",
    "MONCENISIO",
    "MONCESTINO",
    "MONCHIERO",
    "MONCHIO DELLE CORTI",
    "MONCLASSICO",
    "MONCRIVELLO",
    "MONCUCCO TORINESE",
    "MONDAINO",
    "MONDAVIO",
    "MONDOLFO",
    "MONDOVI'",
    "MONDRAGONE",
    "MONEGLIA",
    "MONESIGLIO",
    "MONFALCONE",
    "MONFORTE D'ALBA",
    "MONFORTE SAN GIORGIO",
    "MONFUMO",
    "MONGARDINO",
    "MONGHIDORO",
    "MONGIANA",
    "MONGIARDINO LIGURE",
    "MONGIUFFI MELIA",
    "MONGRANDO",
    "MONGRASSANO",
    "MONGUELFO .WELSBERG.",
    "MONGUZZO",
    "MONIGA DEL GARDA",
    "MONLEALE",
    "MONNO",
    "MONOPOLI",
    "MONREALE",
    "MONRUPINO",
    "MONSAMPIETRO MORICO",
    "MONSAMPOLO DEL TRONTO",
    "MONSANO",
    "MONSELICE",
    "MONSERRATO",
    "MONSUMMANO TERME",
    "MONTA'",
    "MONTABONE",
    "MONTACUTO",
    "MONTAFIA",
    "MONTAGANO",
    "MONTAGNA .MONTAN.",
    "MONTAGNA IN VALTELLINA",
    "MONTAGNANA",
    "MONTAGNAREALE",
    "MONTAGNE",
    "MONTAGUTO",
    "MONTAIONE",
    "MONTALBANO ELICONA",
    "MONTALBANO JONICO",
    "MONTALCINO",
    "MONTALDEO",
    "MONTALDO BORMIDA",
    "MONTALDO DI MONDOVI'",
    "MONTALDO ROERO",
    "MONTALDO SCARAMPI",
    "MONTALDO TORINESE",
    "MONTALE",
    "MONTALENGHE",
    "MONTALLEGRO",
    "MONTALTO DELLE MARCHE",
    "MONTALTO DI CASTRO",
    "MONTALTO DORA",
    "MONTALTO LIGURE",
    "MONTALTO PAVESE",
    "MONTALTO UFFUGO",
    "MONTANARO",
    "MONTANASO LOMBARDO",
    "MONTANERA",
    "MONTANO ANTILIA",
    "MONTANO LUCINO",
    "MONTAPPONE",
    "MONTAQUILA",
    "MONTASOLA",
    "MONTAURO",
    "MONTAZZOLI",
    "MONTE ARGENTARIO",
    "MONTE CASTELLO DI VIBIO",
    "MONTE CAVALLO",
    "MONTE CERIGNONE",
    "MONTE COLOMBO",
    "MONTE CREMASCO",
    "MONTE DI MALO",
    "MONTE DI PROCIDA",
    "MONTE GIBERTO",
    "MONTE GRIMANO TERME",
    "MONTE ISOLA",
    "MONTE MARENZO",
    "MONTE PORZIO",
    "MONTE PORZIO CATONE",
    "MONTE RINALDO",
    "MONTE ROBERTO",
    "MONTE ROMANO",
    "MONTE SAN BIAGIO",
    "MONTE SAN GIACOMO",
    "MONTE SAN GIOVANNI CAMPANO",
    "MONTE SAN GIOVANNI IN SABINA",
    "MONTE SAN GIUSTO",
    "MONTE SAN MARTINO",
    "MONTE SAN PIETRANGELI",
    "MONTE SAN PIETRO",
    "MONTE SAN SAVINO",
    "MONTE SAN VITO",
    "MONTE SANTA MARIA TIBERINA",
    "MONTE SANT'ANGELO",
    "MONTE URANO",
    "MONTE VIDON COMBATTE",
    "MONTE VIDON CORRADO",
    "MONTEBELLO DELLA BATTAGLIA",
    "MONTEBELLO DI BERTONA",
    "MONTEBELLO IONICO",
    "MONTEBELLO SUL SANGRO",
    "MONTEBELLO VICENTINO",
    "MONTEBELLUNA",
    "MONTEBRUNO",
    "MONTEBUONO",
    "MONTECALVO IN FOGLIA",
    "MONTECALVO IRPINO",
    "MONTECALVO VERSIGGIA",
    "MONTECARLO",
    "MONTECAROTTO",
    "MONTECASSIANO",
    "MONTECASTELLO",
    "MONTECASTRILLI",
    "MONTECATINI TERME",
    "MONTECATINI VAL DI CECINA",
    "MONTECCHIA DI CROSARA",
    "MONTECCHIO",
    "MONTECCHIO EMILIA",
    "MONTECCHIO MAGGIORE",
    "MONTECCHIO PRECALCINO",
    "MONTECHIARO D'ACQUI",
    "MONTECHIARO D'ASTI",
    "MONTECHIARUGOLO",
    "MONTECICCARDO",
    "MONTECILFONE",
    "MONTECOMPATRI",
    "MONTECOPIOLO",
    "MONTECORICE",
    "MONTECORVINO PUGLIANO",
    "MONTECORVINO ROVELLA",
    "MONTECOSARO",
    "MONTECRESTESE",
    "MONTECRETO",
    "MONTEDINOVE",
    "MONTEDORO",
    "MONTEFALCIONE",
    "MONTEFALCO",
    "MONTEFALCONE APPENNINO",
    "MONTEFALCONE DI VAL FORTORE",
    "MONTEFALCONE NEL SANNIO",
    "MONTEFANO",
    "MONTEFELCINO",
    "MONTEFERRANTE",
    "MONTEFIASCONE",
    "MONTEFINO",
    "MONTEFIORE CONCA",
    "MONTEFIORE DELL'ASO",
    "MONTEFIORINO",
    "MONTEFLAVIO",
    "MONTEFORTE CILENTO",
    "MONTEFORTE D'ALPONE",
    "MONTEFORTE IRPINO",
    "MONTEFORTINO",
    "MONTEFRANCO",
    "MONTEFREDANE",
    "MONTEFUSCO",
    "MONTEGABBIONE",
    "MONTEGALDA",
    "MONTEGALDELLA",
    "MONTEGALLO",
    "MONTEGIOCO",
    "MONTEGIORDANO",
    "MONTEGIORGIO",
    "MONTEGRANARO",
    "MONTEGRIDOLFO",
    "MONTEGRINO VALTRAVAGLIA",
    "MONTEGROSSO D'ASTI",
    "MONTEGROSSO PIAN LATTE",
    "MONTEGROTTO TERME",
    "MONTEIASI",
    "MONTELABBATE",
    "MONTELANICO",
    "MONTELAPIANO",
    "MONTELEONE DI FERMO",
    "MONTELEONE DI PUGLIA",
    "MONTELEONE DI SPOLETO",
    "MONTELEONE D'ORVIETO",
    "MONTELEONE ROCCA DORIA",
    "MONTELEONE SABINO",
    "MONTELEPRE",
    "MONTELIBRETTI",
    "MONTELLA",
    "MONTELLO",
    "MONTELONGO",
    "MONTELPARO",
    "MONTELUPO ALBESE",
    "MONTELUPO FIORENTINO",
    "MONTELUPONE",
    "MONTEMAGGIORE AL METAURO",
    "MONTEMAGGIORE BELSITO",
    "MONTEMAGNO",
    "MONTEMALE DI CUNEO",
    "MONTEMARANO",
    "MONTEMARCIANO",
    "MONTEMARZINO",
    "MONTEMESOLA",
    "MONTEMEZZO",
    "MONTEMIGNAIO",
    "MONTEMILETTO",
    "MONTEMILONE",
    "MONTEMITRO",
    "MONTEMONACO",
    "MONTEMURLO",
    "MONTEMURRO",
    "MONTENARS",
    "MONTENERO DI BISACCIA",
    "MONTENERO SABINO",
    "MONTENERO VAL COCCHIARA",
    "MONTENERODOMO",
    "MONTEODORISIO",
    "MONTEPAONE",
    "MONTEPARANO",
    "MONTEPRANDONE",
    "MONTEPULCIANO",
    "MONTERADO",
    "MONTERCHI",
    "MONTEREALE",
    "MONTEREALE VALCELLINA",
    "MONTERENZIO",
    "MONTERIGGIONI",
    "MONTERODUNI",
    "MONTERONI D'ARBIA",
    "MONTERONI DI LECCE",
    "MONTEROSI",
    "MONTEROSSO AL MARE",
    "MONTEROSSO ALMO",
    "MONTEROSSO CALABRO",
    "MONTEROSSO GRANA",
    "MONTEROTONDO",
    "MONTEROTONDO MARITTIMO",
    "MONTERUBBIANO",
    "MONTESANO SALENTINO",
    "MONTESANO SULLA MARCELLANA",
    "MONTESARCHIO",
    "MONTESCAGLIOSO",
    "MONTESCANO",
    "MONTESCHENO",
    "MONTESCUDAIO",
    "MONTESCUDO",
    "MONTESE",
    "MONTESEGALE",
    "MONTESILVANO",
    "MONTESPERTOLI",
    "MONTEU DA PO",
    "MONTEU ROERO",
    "MONTEVAGO",
    "MONTEVARCHI",
    "MONTEVECCHIA",
    "MONTEVEGLIO",
    "MONTEVERDE",
    "MONTEVERDI MARITTIMO",
    "MONTEVIALE",
    "MONTEZEMOLO",
    "MONTI",
    "MONTIANO",
    "MONTICELLI BRUSATI",
    "MONTICELLI D'ONGINA",
    "MONTICELLI PAVESE",
    "MONTICELLO BRIANZA",
    "MONTICELLO CONTE OTTO",
    "MONTICELLO D'ALBA",
    "MONTICHIARI",
    "MONTICIANO",
    "MONTIERI",
    "MONTIGLIO MONFERRATO",
    "MONTIGNOSO",
    "MONTIRONE",
    "MONTJOVET",
    "MONTODINE",
    "MONTOGGIO",
    "MONTONE",
    "MONTOPOLI DI SABINA",
    "MONTOPOLI IN VAL D'ARNO",
    "MONTORFANO",
    "MONTORIO AL VOMANO",
    "MONTORIO NEI FRENTANI",
    "MONTORIO ROMANO",
    "MONTORO INFERIORE",
    "MONTORO SUPERIORE",
    "MONTORSO VICENTINO",
    "MONTOTTONE",
    "MONTRESTA",
    "MONTU' BECCARIA",
    "MONVALLE",
    "MONZA",
    "MONZAMBANO",
    "MONZUNO",
    "MORANO CALABRO",
    "MORANO SUL PO",
    "MORANSENGO",
    "MORARO",
    "MORAZZONE",
    "MORBEGNO",
    "MORBELLO",
    "MORCIANO DI LEUCA",
    "MORCIANO DI ROMAGNA",
    "MORCONE",
    "MORDANO",
    "MORENGO",
    "MORES",
    "MORESCO",
    "MORETTA",
    "MORFASSO",
    "MORGANO",
    "MORGEX",
    "MORGONGIORI",
    "MORI",
    "MORIAGO DELLA BATTAGLIA",
    "MORICONE",
    "MORIGERATI",
    "MORIMONDO",
    "MORINO",
    "MORIONDO TORINESE",
    "MORLUPO",
    "MORMANNO",
    "MORNAGO",
    "MORNESE",
    "MORNICO AL SERIO",
    "MORNICO LOSANA",
    "MOROLO",
    "MOROZZO",
    "MORRA DE SANCTIS",
    "MORRO D'ALBA",
    "MORRO D'ORO",
    "MORRO REATINO",
    "MORRONE DEL SANNIO",
    "MORROVALLE",
    "MORSANO AL TAGLIAMENTO",
    "MORSASCO",
    "MORTARA",
    "MORTEGLIANO",
    "MORTERONE",
    "MORUZZO",
    "MOSCAZZANO",
    "MOSCHIANO",
    "MOSCIANO SANT'ANGELO",
    "MOSCUFO",
    "MOSO IN PASSIRIA .MOOS IN PASSEIER.",
    "MOSSA",
    "MOSSANO",
    "MOSSO",
    "MOTTA BALUFFI",
    "MOTTA CAMASTRA",
    "MOTTA D'AFFERMO",
    "MOTTA DE' CONTI",
    "MOTTA DI LIVENZA",
    "MOTTA MONTECORVINO",
    "MOTTA SAN GIOVANNI",
    "MOTTA SANTA LUCIA",
    "MOTTA SANT'ANASTASIA",
    "MOTTA VISCONTI",
    "MOTTAFOLLONE",
    "MOTTALCIATA",
    "MOTTEGGIANA",
    "MOTTOLA",
    "MOZZAGROGNA",
    "MOZZANICA",
    "MOZZATE",
    "MOZZECANE",
    "MOZZO",
    "MUCCIA",
    "MUGGIA",
    "MUGGIO'",
    "MUGNANO DEL CARDINALE",
    "MUGNANO DI NAPOLI",
    "MULAZZANO",
    "MULAZZO",
    "MURA",
    "MURAVERA",
    "MURAZZANO",
    "MURELLO",
    "MURIALDO",
    "MURISENGO",
    "MURLO",
    "MURO LECCESE",
    "MURO LUCANO",
    "MUROS",
    "MUSCOLINE",
    "MUSEI",
    "MUSILE DI PIAVE",
    "MUSSO",
    "MUSSOLENTE",
    "MUSSOMELI",
    "MUZZANA DEL TURGNANO",
    "MUZZANO",
    "NAGO-TORBOLE",
    "NALLES .NALS.",
    "NANNO",
    "NANTO",
    "NAPOLI",
    "NARBOLIA",
    "NARCAO",
    "NARDO'",
    "NARDODIPACE",
    "NARNI",
    "NARO",
    "NARZOLE",
    "NASINO",
    "NASO",
    "NATURNO .NATURNS.",
    "NAVE",
    "NAVE SAN ROCCO",
    "NAVELLI",
    "NAZ SCIAVES .NATZ SCHABS.",
    "NAZZANO",
    "NE",
    "NEBBIUNO",
    "NEGRAR",
    "NEIRONE",
    "NEIVE",
    "NEMBRO",
    "NEMI",
    "NEMOLI",
    "NEONELI",
    "NEPI",
    "NERETO",
    "NEROLA",
    "NERVESA DELLA BATTAGLIA",
    "NERVIANO",
    "NESPOLO",
    "NESSO",
    "NETRO",
    "NETTUNO",
    "NEVIANO",
    "NEVIANO DEGLI ARDUINI",
    "NEVIGLIE",
    "NIARDO",
    "NIBBIANO",
    "NIBBIOLA",
    "NIBIONNO",
    "NICHELINO",
    "NICOLOSI",
    "NICORVO",
    "NICOSIA",
    "NICOTERA",
    "NIELLA BELBO",
    "NIELLA TANARO",
    "NIMIS",
    "NISCEMI",
    "NISSORIA",
    "NIZZA DI SICILIA",
    "NIZZA MONFERRATO",
    "NOALE",
    "NOASCA",
    "NOCARA",
    "NOCCIANO",
    "NOCERA INFERIORE",
    "NOCERA SUPERIORE",
    "NOCERA TERINESE",
    "NOCERA UMBRA",
    "NOCETO",
    "NOCI",
    "NOCIGLIA",
    "NOEPOLI",
    "NOGARA",
    "NOGAREDO",
    "NOGAROLE ROCCA",
    "NOGAROLE VICENTINO",
    "NOICATTARO",
    "NOLA",
    "NOLE",
    "NOLI",
    "NOMAGLIO",
    "NOMI",
    "NONANTOLA",
    "NONE",
    "NONIO",
    "NORAGUGUME",
    "NORBELLO",
    "NORCIA",
    "NORMA",
    "NOSATE",
    "NOTARESCO",
    "NOTO",
    "NOVA LEVANTE .WELSCHNOFEN.",
    "NOVA MILANESE",
    "NOVA PONENTE .DEUTSCHNOFEN.",
    "NOVA SIRI",
    "NOVAFELTRIA",
    "NOVALEDO",
    "NOVALESA",
    "NOVARA",
    "NOVARA DI SICILIA",
    "NOVATE MEZZOLA",
    "NOVATE MILANESE",
    "NOVE",
    "NOVEDRATE",
    "NOVELLARA",
    "NOVELLO",
    "NOVENTA DI PIAVE",
    "NOVENTA PADOVANA",
    "NOVENTA VICENTINA",
    "NOVI DI MODENA",
    "NOVI LIGURE",
    "NOVI VELIA",
    "NOVIGLIO",
    "NOVOLI",
    "NUCETTO",
    "NUGHEDU SAN NICOLO'",
    "NUGHEDU SANTA VITTORIA",
    "NULE",
    "NULVI",
    "NUMANA",
    "NUORO",
    "NURACHI",
    "NURAGUS",
    "NURALLAO",
    "NURAMINIS",
    "NURECI",
    "NURRI",
    "NUS",
    "NUSCO",
    "NUVOLENTO",
    "NUVOLERA",
    "NUXIS",
    "OCCHIEPPO INFERIORE",
    "OCCHIEPPO SUPERIORE",
    "OCCHIOBELLO",
    "OCCIMIANO",
    "OCRE",
    "ODALENGO GRANDE",
    "ODALENGO PICCOLO",
    "ODERZO",
    "ODOLO",
    "OFENA",
    "OFFAGNA",
    "OFFANENGO",
    "OFFIDA",
    "OFFLAGA",
    "OGGEBBIO",
    "OGGIONA CON SANTO STEFANO",
    "OGGIONO",
    "OGLIANICO",
    "OGLIASTRO CILENTO",
    "OLBIA",
    "OLCENENGO",
    "OLDENICO",
    "OLEGGIO",
    "OLEGGIO CASTELLO",
    "OLEVANO DI LOMELLINA",
    "OLEVANO ROMANO",
    "OLEVANO SUL TUSCIANO",
    "OLGIATE COMASCO",
    "OLGIATE MOLGORA",
    "OLGIATE OLONA",
    "OLGINATE",
    "OLIENA",
    "OLIVA GESSI",
    "OLIVADI",
    "OLIVERI",
    "OLIVETO CITRA",
    "OLIVETO LARIO",
    "OLIVETO LUCANO",
    "OLIVETTA SAN MICHELE",
    "OLIVOLA",
    "OLLASTRA",
    "OLLOLAI",
    "OLLOMONT",
    "OLMEDO",
    "OLMENETA",
    "OLMO AL BREMBO",
    "OLMO GENTILE",
    "OLTRE IL COLLE",
    "OLTRESSENDA ALTA",
    "OLTRONA DI SAN MAMETTE",
    "OLZAI",
    "OME",
    "OMEGNA",
    "OMIGNANO",
    "ONANI",
    "ONANO",
    "ONCINO",
    "ONETA",
    "ONIFAI",
    "ONIFERI",
    "ONO SAN PIETRO",
    "ONORE",
    "ONZO",
    "OPERA",
    "OPI",
    "OPPEANO",
    "OPPIDO LUCANO",
    "OPPIDO MAMERTINA",
    "ORA .AUER.",
    "ORANI",
    "ORATINO",
    "ORBASSANO",
    "ORBETELLO",
    "ORCIANO DI PESARO",
    "ORCIANO PISANO",
    "ORCO FEGLINO",
    "ORDONA",
    "ORERO",
    "ORGIANO",
    "ORGOSOLO",
    "ORIA",
    "ORICOLA",
    "ORIGGIO",
    "ORINO",
    "ORIO AL SERIO",
    "ORIO CANAVESE",
    "ORIO LITTA",
    "ORIOLO",
    "ORIOLO ROMANO",
    "ORISTANO",
    "ORMEA",
    "ORMELLE",
    "ORNAGO",
    "ORNAVASSO",
    "ORNICA",
    "OROSEI",
    "OROTELLI",
    "ORRIA",
    "ORROLI",
    "ORSAGO",
    "ORSARA BORMIDA",
    "ORSARA DI PUGLIA",
    "ORSENIGO",
    "ORSOGNA",
    "ORSOMARSO",
    "ORTA DI ATELLA",
    "ORTA NOVA",
    "ORTA SAN GIULIO",
    "ORTACESUS",
    "ORTE",
    "ORTELLE",
    "ORTEZZANO",
    "ORTIGNANO RAGGIOLO",
    "ORTISEI .ST ULRICH.",
    "ORTONA",
    "ORTONA DEI MARSI",
    "ORTONOVO",
    "ORTOVERO",
    "ORTUCCHIO",
    "ORTUERI",
    "ORUNE",
    "ORVIETO",
    "ORVINIO",
    "ORZINUOVI",
    "ORZIVECCHI",
    "OSASCO",
    "OSASIO",
    "OSCHIRI",
    "OSIDDA",
    "OSIGLIA",
    "OSILO",
    "OSIMO",
    "OSINI",
    "OSIO SOPRA",
    "OSIO SOTTO",
    "OSMATE",
    "OSNAGO",
    "OSOPPO",
    "OSPEDALETTI",
    "OSPEDALETTO",
    "OSPEDALETTO D'ALPINOLO",
    "OSPEDALETTO EUGANEO",
    "OSPEDALETTO LODIGIANO",
    "OSPITALE DI CADORE",
    "OSPITALETTO",
    "OSSAGO LODIGIANO",
    "OSSANA",
    "OSSI",
    "OSSIMO",
    "OSSONA",
    "OSSUCCIO",
    "OSTANA",
    "OSTELLATO",
    "OSTIANO",
    "OSTIGLIA",
    "OSTRA",
    "OSTRA VETERE",
    "OSTUNI",
    "OTRANTO",
    "OTRICOLI",
    "OTTANA",
    "OTTATI",
    "OTTAVIANO",
    "OTTIGLIO",
    "OTTOBIANO",
    "OTTONE",
    "OULX",
    "OVADA",
    "OVARO",
    "OVIGLIO",
    "OVINDOLI",
    "OVODDA",
    "OYACE",
    "OZEGNA",
    "OZIERI",
    "OZZANO DELL'EMILIA",
    "OZZANO MONFERRATO",
    "OZZERO",
    "PABILLONIS",
    "PACE DEL MELA",
    "PACECO",
    "PACENTRO",
    "PACHINO",
    "PACIANO",
    "PADENGHE SUL GARDA",
    "PADERGNONE",
    "PADERNA",
    "PADERNO D'ADDA",
    "PADERNO DEL GRAPPA",
    "PADERNO DUGNANO",
    "PADERNO FRANCIACORTA",
    "PADERNO PONCHIELLI",
    "PADOVA",
    "PADRIA",
    "PADRU",
    "PADULA",
    "PADULI",
    "PAESANA",
    "PAESE",
    "PAGANI",
    "PAGANICO SABINO",
    "PAGAZZANO",
    "PAGLIARA",
    "PAGLIETA",
    "PAGNACCO",
    "PAGNO",
    "PAGNONA",
    "PAGO DEL VALLO DI LAURO",
    "PAGO VEIANO",
    "PAISCO LOVENO",
    "PAITONE",
    "PALADINA",
    "PALAGANO",
    "PALAGIANELLO",
    "PALAGIANO",
    "PALAGONIA",
    "PALAIA",
    "PALANZANO",
    "PALATA",
    "PALAU",
    "PALAZZAGO",
    "PALAZZO ADRIANO",
    "PALAZZO CANAVESE",
    "PALAZZO PIGNANO",
    "PALAZZO SAN GERVASIO",
    "PALAZZOLO ACREIDE",
    "PALAZZOLO DELLO STELLA",
    "PALAZZOLO SULL'OGLIO",
    "PALAZZOLO VERCELLESE",
    "PALAZZUOLO SUL SENIO",
    "PALENA",
    "PALERMITI",
    "PALERMO",
    "PALESTRINA",
    "PALESTRO",
    "PALIANO",
    "PALIZZI",
    "PALLAGORIO",
    "PALLANZENO",
    "PALLARE",
    "PALMA CAMPANIA",
    "PALMA DI MONTECHIARO",
    "PALMANOVA",
    "PALMARIGGI",
    "PALMAS ARBOREA",
    "PALMI",
    "PALMIANO",
    "PALMOLI",
    "PALO DEL COLLE",
    "PALOMBARA SABINA",
    "PALOMBARO",
    "PALOMONTE",
    "PALOSCO",
    "PALU'",
    "PALU' DEL FERSINA",
    "PALUDI",
    "PALUZZA",
    "PAMPARATO",
    "PANCALIERI",
    "PANCARANA",
    "PANCHIA'",
    "PANDINO",
    "PANETTIERI",
    "PANICALE",
    "PANNARANO",
    "PANNI",
    "PANTELLERIA",
    "PANTIGLIATE",
    "PAOLA",
    "PAOLISI",
    "PAPASIDERO",
    "PAPOZZE",
    "PARABIAGO",
    "PARABITA",
    "PARATICO",
    "PARCINES .PARTSCHINS.",
    "PARE'",
    "PARELLA",
    "PARENTI",
    "PARETE",
    "PARETO",
    "PARGHELIA",
    "PARLASCO",
    "PARMA",
    "PARODI LIGURE",
    "PAROLDO",
    "PAROLISE",
    "PARONA",
    "PARRANO",
    "PARRE",
    "PARTANNA",
    "PARTINICO",
    "PARUZZARO",
    "PARZANICA",
    "PASIAN DI PRATO",
    "PASIANO DI PORDENONE",
    "PASPARDO",
    "PASSERANO MARMORITO",
    "PASSIGNANO SUL TRASIMENO",
    "PASSIRANO",
    "PASTENA",
    "PASTORANO",
    "PASTRENGO",
    "PASTURANA",
    "PASTURO",
    "PATERNO",
    "PATERNO'",
    "PATERNO CALABRO",
    "PATERNOPOLI",
    "PATRICA",
    "PATTADA",
    "PATTI",
    "PATU'",
    "PAU",
    "PAULARO",
    "PAULI ARBAREI",
    "PAULILATINO",
    "PAULLO",
    "PAUPISI",
    "PAVAROLO",
    "PAVIA",
    "PAVIA DI UDINE",
    "PAVONE CANAVESE",
    "PAVONE DEL MELLA",
    "PAVULLO NEL FRIGNANO",
    "PAZZANO",
    "PECCIOLI",
    "PECCO",
    "PECETTO DI VALENZA",
    "PECETTO TORINESE",
    "PECORARA",
    "PEDACE",
    "PEDARA",
    "PEDASO",
    "PEDAVENA",
    "PEDEMONTE",
    "PEDEROBBA",
    "PEDESINA",
    "PEDIVIGLIANO",
    "PEDRENGO",
    "PEGLIO",
    "PEGLIO",
    "PEGOGNAGA",
    "PEIA",
    "PEJO",
    "PELAGO",
    "PELLA",
    "PELLEGRINO PARMENSE",
    "PELLEZZANO",
    "PELLIO INTELVI",
    "PELLIZZANO",
    "PELUGO",
    "PENANGO",
    "PENNA IN TEVERINA",
    "PENNA SAN GIOVANNI",
    "PENNA SANT'ANDREA",
    "PENNABILLI",
    "PENNADOMO",
    "PENNAPIEDIMONTE",
    "PENNE",
    "PENTONE",
    "PERANO",
    "PERAROLO DI CADORE",
    "PERCA .PERCHA.",
    "PERCILE",
    "PERDASDEFOGU",
    "PERDAXIUS",
    "PERDIFUMO",
    "PEREGO",
    "PERETO",
    "PERFUGAS",
    "PERGINE VALDARNO",
    "PERGINE VALSUGANA",
    "PERGOLA",
    "PERINALDO",
    "PERITO",
    "PERLEDO",
    "PERLETTO",
    "PERLO",
    "PERLOZ",
    "PERNUMIA",
    "PERO",
    "PEROSA ARGENTINA",
    "PEROSA CANAVESE",
    "PERRERO",
    "PERSICO DOSIMO",
    "PERTENGO",
    "PERTICA ALTA",
    "PERTICA BASSA",
    "PERTOSA",
    "PERTUSIO",
    "PERUGIA",
    "PESARO",
    "PESCAGLIA",
    "PESCANTINA",
    "PESCARA",
    "PESCAROLO ED UNITI",
    "PESCASSEROLI",
    "PESCATE",
    "PESCHE",
    "PESCHICI",
    "PESCHIERA BORROMEO",
    "PESCHIERA DEL GARDA",
    "PESCIA",
    "PESCINA",
    "PESCO SANNITA",
    "PESCOCOSTANZO",
    "PESCOLANCIANO",
    "PESCOPAGANO",
    "PESCOPENNATARO",
    "PESCOROCCHIANO",
    "PESCOSANSONESCO",
    "PESCOSOLIDO",
    "PESSANO CON BORNAGO",
    "PESSINA CREMONESE",
    "PESSINETTO",
    "PETACCIATO",
    "PETILIA POLICASTRO",
    "PETINA",
    "PETRALIA SOPRANA",
    "PETRALIA SOTTANA",
    "PETRELLA SALTO",
    "PETRELLA TIFERNINA",
    "PETRIANO",
    "PETRIOLO",
    "PETRITOLI",
    "PETRIZZI",
    "PETRONA'",
    "PETROSINO",
    "PETRURO IRPINO",
    "PETTENASCO",
    "PETTINENGO",
    "PETTINEO",
    "PETTORANELLO DEL MOLISE",
    "PETTORANO SUL GIZIO",
    "PETTORAZZA GRIMANI",
    "PEVERAGNO",
    "PEZZANA",
    "PEZZAZE",
    "PEZZOLO VALLE UZZONE",
    "PIACENZA",
    "PIACENZA D'ADIGE",
    "PIADENA",
    "PIAGGE",
    "PIAGGINE",
    "PIAN CAMUNO",
    "PIAN DI SCO",
    "PIANA CRIXIA",
    "PIANA DEGLI ALBANESI",
    "PIANA DI MONTE VERNA",
    "PIANCASTAGNAIO",
    "PIANCOGNO",
    "PIANDIMELETO",
    "PIANE CRATI",
    "PIANELLA",
    "PIANELLO DEL LARIO",
    "PIANELLO VAL TIDONE",
    "PIANENGO",
    "PIANEZZA",
    "PIANEZZE",
    "PIANFEI",
    "PIANICO",
    "PIANIGA",
    "PIANO DI SORRENTO",
    "PIANOPOLI",
    "PIANORO",
    "PIANSANO",
    "PIANTEDO",
    "PIARIO",
    "PIASCO",
    "PIATEDA",
    "PIATTO",
    "PIAZZA AL SERCHIO",
    "PIAZZA ARMERINA",
    "PIAZZA BREMBANA",
    "PIAZZATORRE",
    "PIAZZOLA SUL BRENTA",
    "PIAZZOLO",
    "PICCIANO",
    "PICERNO",
    "PICINISCO",
    "PICO",
    "PIEA",
    "PIEDICAVALLO",
    "PIEDIMONTE ETNEO",
    "PIEDIMONTE MATESE",
    "PIEDIMONTE SAN GERMANO",
    "PIEDIMULERA",
    "PIEGARO",
    "PIENZA",
    "PIERANICA",
    "PIETRA DE' GIORGI",
    "PIETRA LIGURE",
    "PIETRA MARAZZI",
    "PIETRABBONDANTE",
    "PIETRABRUNA",
    "PIETRACAMELA",
    "PIETRACATELLA",
    "PIETRACUPA",
    "PIETRADEFUSI",
    "PIETRAFERRAZZANA",
    "PIETRAFITTA",
    "PIETRAGALLA",
    "PIETRALUNGA",
    "PIETRAMELARA",
    "PIETRAMONTECORVINO",
    "PIETRANICO",
    "PIETRAPAOLA",
    "PIETRAPERTOSA",
    "PIETRAPERZIA",
    "PIETRAPORZIO",
    "PIETRAROJA",
    "PIETRARUBBIA",
    "PIETRASANTA",
    "PIETRASTORNINA",
    "PIETRAVAIRANO",
    "PIETRELCINA",
    "PIEVE A NIEVOLE",
    "PIEVE ALBIGNOLA",
    "PIEVE D'ALPAGO",
    "PIEVE DEL CAIRO",
    "PIEVE DI BONO",
    "PIEVE DI CADORE",
    "PIEVE DI CENTO",
    "PIEVE DI CORIANO",
    "PIEVE DI LEDRO",
    "PIEVE DI SOLIGO",
    "PIEVE DI TECO",
    "PIEVE D'OLMI",
    "PIEVE EMANUELE",
    "PIEVE FISSIRAGA",
    "PIEVE FOSCIANA",
    "PIEVE LIGURE",
    "PIEVE PORTO MORONE",
    "PIEVE SAN GIACOMO",
    "PIEVE SANTO STEFANO",
    "PIEVE TESINO",
    "PIEVE TORINA",
    "PIEVE VERGONTE",
    "PIEVEBOVIGLIANA",
    "PIEVEPELAGO",
    "PIGLIO",
    "PIGNA",
    "PIGNATARO INTERAMNA",
    "PIGNATARO MAGGIORE",
    "PIGNOLA",
    "PIGNONE",
    "PIGRA",
    "PILA",
    "PIMENTEL",
    "PIMONTE",
    "PINAROLO PO",
    "PINASCA",
    "PINCARA",
    "PINEROLO",
    "PINETO",
    "PINO D'ASTI",
    "PINO SULLA SPONDA DEL LAGO MAGGIORE",
    "PINO TORINESE",
    "PINZANO AL TAGLIAMENTO",
    "PINZOLO",
    "PIOBBICO",
    "PIOBESI D'ALBA",
    "PIOBESI TORINESE",
    "PIODE",
    "PIOLTELLO",
    "PIOMBINO",
    "PIOMBINO DESE",
    "PIORACO",
    "PIOSSASCO",
    "PIOVA' MASSAIA",
    "PIOVE DI SACCO",
    "PIOVENE ROCCHETTE",
    "PIOVERA",
    "PIOZZANO",
    "PIOZZO",
    "PIRAINO",
    "PISA",
    "PISANO",
    "PISCINA",
    "PISCINAS",
    "PISCIOTTA",
    "PISOGNE",
    "PISONIANO",
    "PISTICCI",
    "PISTOIA",
    "PITEGLIO",
    "PITIGLIANO",
    "PIUBEGA",
    "PIURO",
    "PIVERONE",
    "PIZZALE",
    "PIZZIGHETTONE",
    "PIZZO",
    "PIZZOFERRATO",
    "PIZZOLI",
    "PIZZONE",
    "PIZZONI",
    "PLACANICA",
    "PLATACI",
    "PLATANIA",
    "PLATI'",
    "PLAUS .PLAUS.",
    "PLESIO",
    "PLOAGHE",
    "PLODIO",
    "POCAPAGLIA",
    "POCENIA",
    "PODENZANA",
    "PODENZANO",
    "POFI",
    "POGGIARDO",
    "POGGIBONSI",
    "POGGIO A CAIANO",
    "POGGIO BERNI",
    "POGGIO BUSTONE",
    "POGGIO CATINO",
    "POGGIO IMPERIALE",
    "POGGIO MIRTETO",
    "POGGIO MOIANO",
    "POGGIO NATIVO",
    "POGGIO PICENZE",
    "POGGIO RENATICO",
    "POGGIO RUSCO",
    "POGGIO SAN LORENZO",
    "POGGIO SAN MARCELLO",
    "POGGIO SAN VICINO",
    "POGGIO SANNITA",
    "POGGIODOMO",
    "POGGIOFIORITO",
    "POGGIOMARINO",
    "POGGIOREALE",
    "POGGIORSINI",
    "POGGIRIDENTI",
    "POGLIANO MILANESE",
    "POGNANA LARIO",
    "POGNANO",
    "POGNO",
    "POIANA MAGGIORE",
    "POIRINO",
    "POLAVENO",
    "POLCENIGO",
    "POLESELLA",
    "POLESINE PARMENSE",
    "POLI",
    "POLIA",
    "POLICORO",
    "POLIGNANO A MARE",
    "POLINAGO",
    "POLINO",
    "POLISTENA",
    "POLIZZI GENEROSA",
    "POLLA",
    "POLLEIN",
    "POLLENA TROCCHIA",
    "POLLENZA",
    "POLLICA",
    "POLLINA",
    "POLLONE",
    "POLLUTRI",
    "POLONGHERA",
    "POLPENAZZE DEL GARDA",
    "POLVERARA",
    "POLVERIGI",
    "POMARANCE",
    "POMARETTO",
    "POMARICO",
    "POMARO MONFERRATO",
    "POMAROLO",
    "POMBIA",
    "POMEZIA",
    "POMIGLIANO D'ARCO",
    "POMPEI",
    "POMPEIANA",
    "POMPIANO",
    "POMPONESCO",
    "POMPU",
    "PONCARALE",
    "PONDERANO",
    "PONNA",
    "PONSACCO",
    "PONSO",
    "PONT CANAVESE",
    "PONTASSIEVE",
    "PONTBOSET",
    "PONTE",
    "PONTE BUGGIANESE",
    "PONTE DELL'OLIO",
    "PONTE DI LEGNO",
    "PONTE DI PIAVE",
    "PONTE GARDENA .WAIDBRUCK.",
    "PONTE IN VALTELLINA",
    "PONTE LAMBRO",
    "PONTE NELLE ALPI",
    "PONTE NIZZA",
    "PONTE NOSSA",
    "PONTE SAN NICOLO'",
    "PONTE SAN PIETRO",
    "PONTEBBA",
    "PONTECAGNANO FAIANO",
    "PONTECCHIO POLESINE",
    "PONTECHIANALE",
    "PONTECORVO",
    "PONTECURONE",
    "PONTEDASSIO",
    "PONTEDERA",
    "PONTELANDOLFO",
    "PONTELATONE",
    "PONTELONGO",
    "PONTENURE",
    "PONTERANICA",
    "PONTESTURA",
    "PONTEVICO",
    "PONTEY",
    "PONTI",
    "PONTI SUL MINCIO",
    "PONTIDA",
    "PONTINIA",
    "PONTINVREA",
    "PONTIROLO NUOVO",
    "PONTOGLIO",
    "PONTREMOLI",
    "PONT-SAINT-MARTIN",
    "PONZA",
    "PONZANO DI FERMO",
    "PONZANO MONFERRATO",
    "PONZANO ROMANO",
    "PONZANO VENETO",
    "PONZONE",
    "POPOLI",
    "POPPI",
    "PORANO",
    "PORCARI",
    "PORCIA",
    "PORDENONE",
    "PORLEZZA",
    "PORNASSIO",
    "PORPETTO",
    "PORRETTA TERME",
    "PORTACOMARO",
    "PORTALBERA",
    "PORTE",
    "PORTICI",
    "PORTICO DI CASERTA",
    "PORTICO E SAN BENEDETTO",
    "PORTIGLIOLA",
    "PORTO AZZURRO",
    "PORTO CERESIO",
    "PORTO CESAREO",
    "PORTO EMPEDOCLE",
    "PORTO MANTOVANO",
    "PORTO RECANATI",
    "PORTO SAN GIORGIO",
    "PORTO SANT'ELPIDIO",
    "PORTO TOLLE",
    "PORTO TORRES",
    "PORTO VALTRAVAGLIA",
    "PORTO VIRO",
    "PORTOBUFFOLE'",
    "PORTOCANNONE",
    "PORTOFERRAIO",
    "PORTOFINO",
    "PORTOGRUARO",
    "PORTOMAGGIORE",
    "PORTOPALO DI CAPO PASSERO",
    "PORTOSCUSO",
    "PORTOVENERE",
    "PORTULA",
    "POSADA",
    "POSINA",
    "POSITANO",
    "POSSAGNO",
    "POSTA",
    "POSTA FIBRENO",
    "POSTAL .BURGSTALL.",
    "POSTALESIO",
    "POSTIGLIONE",
    "POSTUA",
    "POTENZA",
    "POTENZA PICENA",
    "POVE DEL GRAPPA",
    "POVEGLIANO",
    "POVEGLIANO VERONESE",
    "POVIGLIO",
    "POVOLETTO",
    "POZZA DI FASSA",
    "POZZAGLIA SABINA",
    "POZZAGLIO ED UNITI",
    "POZZALLO",
    "POZZILLI",
    "POZZO D'ADDA",
    "POZZOL GROPPO",
    "POZZOLENGO",
    "POZZOLEONE",
    "POZZOLO FORMIGARO",
    "POZZOMAGGIORE",
    "POZZONOVO",
    "POZZUOLI",
    "POZZUOLO DEL FRIULI",
    "POZZUOLO MARTESANA",
    "PRADALUNGA",
    "PRADAMANO",
    "PRADLEVES",
    "PRAGELATO",
    "PRAIA A MARE",
    "PRAIANO",
    "PRALBOINO",
    "PRALI",
    "PRALORMO",
    "PRALUNGO",
    "PRAMAGGIORE",
    "PRAMOLLO",
    "PRAROLO",
    "PRAROSTINO",
    "PRASCO",
    "PRASCORSANO",
    "PRASO",
    "PRATA CAMPORTACCIO",
    "PRATA D'ANSIDONIA",
    "PRATA DI PORDENONE",
    "PRATA DI PRINCIPATO ULTRA",
    "PRATA SANNITA",
    "PRATELLA",
    "PRATIGLIONE",
    "PRATO",
    "PRATO ALLO STELVIO .PRAD AM STILFSERJOCH.",
    "PRATO CARNICO",
    "PRATO SESIA",
    "PRATOLA PELIGNA",
    "PRATOLA SERRA",
    "PRATOVECCHIO",
    "PRAVISDOMINI",
    "PRAY",
    "PRAZZO",
    "PRECENICCO",
    "PRECI",
    "PREDAPPIO",
    "PREDAZZO",
    "PREDOI .PRETTAU.",
    "PREDORE",
    "PREDOSA",
    "PREGANZIOL",
    "PREGNANA MILANESE",
    "PRELA'",
    "PREMANA",
    "PREMARIACCO",
    "PREMENO",
    "PREMIA",
    "PREMILCUORE",
    "PREMOLO",
    "PREMOSELLO-CHIOVENDA",
    "PREONE",
    "PREORE",
    "PREPOTTO",
    "PRE'-SAINT-DIDIER",
    "PRESEGLIE",
    "PRESENZANO",
    "PRESEZZO",
    "PRESICCE",
    "PRESSANA",
    "PRESTINE",
    "PRETORO",
    "PREVALLE",
    "PREZZA",
    "PREZZO",
    "PRIERO",
    "PRIGNANO CILENTO",
    "PRIGNANO SULLA SECCHIA",
    "PRIMALUNA",
    "PRIOCCA",
    "PRIOLA",
    "PRIOLO GARGALLO",
    "PRIVERNO",
    "PRIZZI",
    "PROCENO",
    "PROCIDA",
    "PROPATA",
    "PROSERPIO",
    "PROSSEDI",
    "PROVAGLIO D'ISEO",
    "PROVAGLIO VAL SABBIA",
    "PROVES .PROVEIS.",
    "PROVVIDENTI",
    "PRUNETTO",
    "PUEGNAGO SUL GARDA",
    "PUGLIANELLO",
    "PULA",
    "PULFERO",
    "PULSANO",
    "PUMENENGO",
    "PUOS D'ALPAGO",
    "PUSIANO",
    "PUTIFIGARI",
    "PUTIGNANO",
    "QUADRELLE",
    "QUADRI",
    "QUAGLIUZZO",
    "QUALIANO",
    "QUARANTI",
    "QUAREGNA",
    "QUARGNENTO",
    "QUARNA SOPRA",
    "QUARNA SOTTO",
    "QUARONA",
    "QUARRATA",
    "QUART",
    "QUARTO",
    "QUARTO D'ALTINO",
    "QUARTU SANT'ELENA",
    "QUARTUCCIU",
    "QUASSOLO",
    "QUATTORDIO",
    "QUATTRO CASTELLA",
    "QUERO",
    "QUILIANO",
    "QUINCINETTO",
    "QUINDICI",
    "QUINGENTOLE",
    "QUINTANO",
    "QUINTO DI TREVISO",
    "QUINTO VERCELLESE",
    "QUINTO VICENTINO",
    "QUINZANO D'OGLIO",
    "QUISTELLO",
    "QUITTENGO",
    "RABBI",
    "RACALE",
    "RACALMUTO",
    "RACCONIGI",
    "RACCUJA",
    "RACINES .RATSCHINGS.",
    "RADDA IN CHIANTI",
    "RADDUSA",
    "RADICOFANI",
    "RADICONDOLI",
    "RAFFADALI",
    "RAGALNA",
    "RAGOGNA",
    "RAGOLI",
    "RAGUSA",
    "RAIANO",
    "RAMACCA",
    "RAMISETO",
    "RAMPONIO VERNA",
    "RANCIO VALCUVIA",
    "RANCO",
    "RANDAZZO",
    "RANICA",
    "RANZANICO",
    "RANZO",
    "RAPAGNANO",
    "RAPALLO",
    "RAPINO",
    "RAPOLANO TERME",
    "RAPOLLA",
    "RAPONE",
    "RASSA",
    "RASUN ANTERSELVA .RASEN ANTHOLZ.",
    "RASURA",
    "RAVANUSA",
    "RAVARINO",
    "RAVASCLETTO",
    "RAVELLO",
    "RAVENNA",
    "RAVEO",
    "RAVISCANINA",
    "RE",
    "REA",
    "REALMONTE",
    "REANA DEL ROIALE",
    "REANO",
    "RECALE",
    "RECANATI",
    "RECCO",
    "RECETTO",
    "RECOARO TERME",
    "REDAVALLE",
    "REDONDESCO",
    "REFRANCORE",
    "REFRONTOLO",
    "REGALBUTO",
    "REGGELLO",
    "REGGIO DI CALABRIA",
    "REGGIO NELL'EMILIA",
    "REGGIOLO",
    "REINO",
    "REITANO",
    "REMANZACCO",
    "REMEDELLO",
    "RENATE",
    "RENDE",
    "RENON .RITTEN.",
    "RESANA",
    "RESCALDINA",
    "RESIA",
    "RESIUTTA",
    "RESUTTANO",
    "RETORBIDO",
    "REVELLO",
    "REVERE",
    "REVIGLIASCO D'ASTI",
    "REVINE LAGO",
    "REVO'",
    "REZZAGO",
    "REZZATO",
    "REZZO",
    "REZZOAGLIO",
    "RHEMES-NOTRE-DAME",
    "RHEMES-SAINT-GEORGES",
    "RHO",
    "RIACE",
    "RIALTO",
    "RIANO",
    "RIARDO",
    "RIBERA",
    "RIBORDONE",
    "RICADI",
    "RICALDONE",
    "RICCIA",
    "RICCIONE",
    "RICCO' DEL GOLFO DI SPEZIA",
    "RICENGO",
    "RICIGLIANO",
    "RIESE PIO X",
    "RIESI",
    "RIETI",
    "RIFIANO .RIFFIAN.",
    "RIFREDDO",
    "RIGNANO FLAMINIO",
    "RIGNANO GARGANICO",
    "RIGNANO SULL'ARNO",
    "RIGOLATO",
    "RIMA SAN GIUSEPPE",
    "RIMASCO",
    "RIMELLA",
    "RIMINI",
    "RIO DI PUSTERIA .MUHLBACH.",
    "RIO MARINA",
    "RIO NELL'ELBA",
    "RIO SALICETO",
    "RIOFREDDO",
    "RIOLA SARDO",
    "RIOLO TERME",
    "RIOLUNATO",
    "RIOMAGGIORE",
    "RIONERO IN VULTURE",
    "RIONERO SANNITICO",
    "RIPA TEATINA",
    "RIPABOTTONI",
    "RIPACANDIDA",
    "RIPALIMOSANI",
    "RIPALTA ARPINA",
    "RIPALTA CREMASCA",
    "RIPALTA GUERINA",
    "RIPARBELLA",
    "RIPATRANSONE",
    "RIPE",
    "RIPE SAN GINESIO",
    "RIPI",
    "RIPOSTO",
    "RITTANA",
    "RIVA DEL GARDA",
    "RIVA DI SOLTO",
    "RIVA LIGURE",
    "RIVA PRESSO CHIERI",
    "RIVA VALDOBBIA",
    "RIVALBA",
    "RIVALTA BORMIDA",
    "RIVALTA DI TORINO",
    "RIVAMONTE AGORDINO",
    "RIVANAZZANO",
    "RIVARA",
    "RIVAROLO CANAVESE",
    "RIVAROLO DEL RE ED UNITI",
    "RIVAROLO MANTOVANO",
    "RIVARONE",
    "RIVAROSSA",
    "RIVE",
    "RIVE D'ARCANO",
    "RIVELLO",
    "RIVERGARO",
    "RIVIGNANO",
    "RIVISONDOLI",
    "RIVODUTRI",
    "RIVOLI",
    "RIVOLI VERONESE",
    "RIVOLTA D'ADDA",
    "RIZZICONI",
    "RO",
    "ROANA",
    "ROASCHIA",
    "ROASCIO",
    "ROASIO",
    "ROATTO",
    "ROBASSOMERO",
    "ROBBIATE",
    "ROBBIO",
    "ROBECCHETTO CON INDUNO",
    "ROBECCO D'OGLIO",
    "ROBECCO PAVESE",
    "ROBECCO SUL NAVIGLIO",
    "ROBELLA",
    "ROBILANTE",
    "ROBURENT",
    "ROCCA CANAVESE",
    "ROCCA CANTERANO",
    "ROCCA CIGLIE'",
    "ROCCA D'ARAZZO",
    "ROCCA DE' BALDI",
    "ROCCA DE' GIORGI",
    "ROCCA D'EVANDRO",
    "ROCCA DI BOTTE",
    "ROCCA DI CAMBIO",
    "ROCCA DI CAVE",
    "ROCCA DI MEZZO",
    "ROCCA DI NETO",
    "ROCCA DI PAPA",
    "ROCCA GRIMALDA",
    "ROCCA IMPERIALE",
    "ROCCA MASSIMA",
    "ROCCA PIA",
    "ROCCA PIETORE",
    "ROCCA PRIORA",
    "ROCCA SAN CASCIANO",
    "ROCCA SAN FELICE",
    "ROCCA SAN GIOVANNI",
    "ROCCA SANTA MARIA",
    "ROCCA SANTO STEFANO",
    "ROCCA SINIBALDA",
    "ROCCA SUSELLA",
    "ROCCABASCERANA",
    "ROCCABERNARDA",
    "ROCCABIANCA",
    "ROCCABRUNA",
    "ROCCACASALE",
    "ROCCADARCE",
    "ROCCADASPIDE",
    "ROCCAFIORITA",
    "ROCCAFLUVIONE",
    "ROCCAFORTE DEL GRECO",
    "ROCCAFORTE LIGURE",
    "ROCCAFORTE MONDOVI'",
    "ROCCAFORZATA",
    "ROCCAFRANCA",
    "ROCCAGIOVINE",
    "ROCCAGLORIOSA",
    "ROCCAGORGA",
    "ROCCALBEGNA",
    "ROCCALUMERA",
    "ROCCAMANDOLFI",
    "ROCCAMENA",
    "ROCCAMONFINA",
    "ROCCAMONTEPIANO",
    "ROCCAMORICE",
    "ROCCANOVA",
    "ROCCANTICA",
    "ROCCAPALUMBA",
    "ROCCAPIEMONTE",
    "ROCCARAINOLA",
    "ROCCARASO",
    "ROCCAROMANA",
    "ROCCASCALEGNA",
    "ROCCASECCA",
    "ROCCASECCA DEI VOLSCI",
    "ROCCASICURA",
    "ROCCASPARVERA",
    "ROCCASPINALVETI",
    "ROCCASTRADA",
    "ROCCAVALDINA",
    "ROCCAVERANO",
    "ROCCAVIGNALE",
    "ROCCAVIONE",
    "ROCCAVIVARA",
    "ROCCELLA IONICA",
    "ROCCELLA VALDEMONE",
    "ROCCHETTA A VOLTURNO",
    "ROCCHETTA BELBO",
    "ROCCHETTA DI VARA",
    "ROCCHETTA E CROCE",
    "ROCCHETTA LIGURE",
    "ROCCHETTA NERVINA",
    "ROCCHETTA PALAFEA",
    "ROCCHETTA SANT'ANTONIO",
    "ROCCHETTA TANARO",
    "RODANO",
    "RODDI",
    "RODDINO",
    "RODELLO",
    "RODENGO .RODENECK.",
    "RODENGO-SAIANO",
    "RODERO",
    "RODI GARGANICO",
    "RODI' MILICI",
    "RODIGO",
    "ROE' VOLCIANO",
    "ROFRANO",
    "ROGENO",
    "ROGGIANO GRAVINA",
    "ROGHUDI",
    "ROGLIANO",
    "ROGNANO",
    "ROGNO",
    "ROGOLO",
    "ROIATE",
    "ROIO DEL SANGRO",
    "ROISAN",
    "ROLETTO",
    "ROLO",
    "ROMA",
    "ROMAGNANO AL MONTE",
    "ROMAGNANO SESIA",
    "ROMAGNESE",
    "ROMALLO",
    "ROMANA",
    "ROMANENGO",
    "ROMANO CANAVESE",
    "ROMANO D'EZZELINO",
    "ROMANO DI LOMBARDIA",
    "ROMANS D'ISONZO",
    "ROMBIOLO",
    "ROMENO",
    "ROMENTINO",
    "ROMETTA",
    "RONAGO",
    "RONCA'",
    "RONCADE",
    "RONCADELLE",
    "RONCARO",
    "RONCEGNO",
    "RONCELLO",
    "RONCHI DEI LEGIONARI",
    "RONCHI VALSUGANA",
    "RONCHIS",
    "RONCIGLIONE",
    "RONCO ALL'ADIGE",
    "RONCO BIELLESE",
    "RONCO BRIANTINO",
    "RONCO CANAVESE",
    "RONCO SCRIVIA",
    "RONCOBELLO",
    "RONCOFERRARO",
    "RONCOFREDDO",
    "RONCOLA",
    "RONCONE",
    "RONDANINA",
    "RONDISSONE",
    "RONSECCO",
    "RONZO-CHIENIS",
    "RONZONE",
    "ROPPOLO",
    "RORA'",
    "ROSA'",
    "ROSARNO",
    "ROSASCO",
    "ROSATE",
    "ROSAZZA",
    "ROSCIANO",
    "ROSCIGNO",
    "ROSE",
    "ROSELLO",
    "ROSETO CAPO SPULICO",
    "ROSETO DEGLI ABRUZZI",
    "ROSETO VALFORTORE",
    "ROSIGNANO MARITTIMO",
    "ROSIGNANO MONFERRATO",
    "ROSOLINA",
    "ROSOLINI",
    "ROSORA",
    "ROSSA",
    "ROSSANA",
    "ROSSANO",
    "ROSSANO VENETO",
    "ROSSIGLIONE",
    "ROSTA",
    "ROTA D'IMAGNA",
    "ROTA GRECA",
    "ROTELLA",
    "ROTELLO",
    "ROTONDA",
    "ROTONDELLA",
    "ROTONDI",
    "ROTTOFRENO",
    "ROTZO",
    "ROURE",
    "ROVAGNATE",
    "ROVASENDA",
    "ROVATO",
    "ROVEGNO",
    "ROVELLASCA",
    "ROVELLO PORRO",
    "ROVERBELLA",
    "ROVERCHIARA",
    "ROVERE' DELLA LUNA",
    "ROVERE' VERONESE",
    "ROVEREDO DI GUA'",
    "ROVEREDO IN PIANO",
    "ROVERETO",
    "ROVESCALA",
    "ROVETTA",
    "ROVIANO",
    "ROVIGO",
    "ROVITO",
    "ROVOLON",
    "ROZZANO",
    "RUBANO",
    "RUBIANA",
    "RUBIERA",
    "RUDA",
    "RUDIANO",
    "RUEGLIO",
    "RUFFANO",
    "RUFFIA",
    "RUFFRE'",
    "RUFINA",
    "RUINAS",
    "RUINO",
    "RUMO",
    "RUOTI",
    "RUSSI",
    "RUTIGLIANO",
    "RUTINO",
    "RUVIANO",
    "RUVO DEL MONTE",
    "RUVO DI PUGLIA",
    "SABAUDIA",
    "SABBIA",
    "SABBIO CHIESE",
    "SABBIONETA",
    "SACCO",
    "SACCOLONGO",
    "SACILE",
    "SACROFANO",
    "SADALI",
    "SAGAMA",
    "SAGLIANO MICCA",
    "SAGRADO",
    "SAGRON MIS",
    "SAINT-CHRISTOPHE",
    "SAINT-DENIS",
    "SAINT-MARCEL",
    "SAINT-NICOLAS",
    "SAINT-OYEN",
    "SAINT-PIERRE",
    "SAINT-RHEMY-EN-BOSSES",
    "SAINT-VINCENT",
    "SALA BAGANZA",
    "SALA BIELLESE",
    "SALA BOLOGNESE",
    "SALA COMACINA",
    "SALA CONSILINA",
    "SALA MONFERRATO",
    "SALANDRA",
    "SALAPARUTA",
    "SALARA",
    "SALASCO",
    "SALASSA",
    "SALBERTRAND",
    "SALCEDO",
    "SALCITO",
    "SALE",
    "SALE DELLE LANGHE",
    "SALE MARASINO",
    "SALE SAN GIOVANNI",
    "SALEMI",
    "SALENTO",
    "SALERANO CANAVESE",
    "SALERANO SUL LAMBRO",
    "SALERNO",
    "SALETTO",
    "SALGAREDA",
    "SALI VERCELLESE",
    "SALICE SALENTINO",
    "SALICETO",
    "SALISANO",
    "SALIZZOLE",
    "SALLE",
    "SALMOUR",
    "SALO'",
    "SALORNO .SALURN.",
    "SALSOMAGGIORE TERME",
    "SALTARA",
    "SALTRIO",
    "SALUDECIO",
    "SALUGGIA",
    "SALUSSOLA",
    "SALUZZO",
    "SALVE",
    "SALVIROLA",
    "SALVITELLE",
    "SALZA DI PINEROLO",
    "SALZA IRPINA",
    "SALZANO",
    "SAMARATE",
    "SAMASSI",
    "SAMATZAI",
    "SAMBUCA DI SICILIA",
    "SAMBUCA PISTOIESE",
    "SAMBUCI",
    "SAMBUCO",
    "SAMMICHELE DI BARI",
    "SAMO",
    "SAMOLACO",
    "SAMONE",
    "SAMONE",
    "SAMPEYRE",
    "SAMUGHEO",
    "SAN BARTOLOMEO AL MARE",
    "SAN BARTOLOMEO IN GALDO",
    "SAN BARTOLOMEO VAL CAVARGNA",
    "SAN BASILE",
    "SAN BASILIO",
    "SAN BASSANO",
    "SAN BELLINO",
    "SAN BENEDETTO BELBO",
    "SAN BENEDETTO DEI MARSI",
    "SAN BENEDETTO DEL TRONTO",
    "SAN BENEDETTO IN PERILLIS",
    "SAN BENEDETTO PO",
    "SAN BENEDETTO ULLANO",
    "SAN BENEDETTO VAL DI SAMBRO",
    "SAN BENIGNO CANAVESE",
    "SAN BERNARDINO VERBANO",
    "SAN BIAGIO DELLA CIMA",
    "SAN BIAGIO DI CALLALTA",
    "SAN BIAGIO PLATANI",
    "SAN BIAGIO SARACINISCO",
    "SAN BIASE",
    "SAN BONIFACIO",
    "SAN BUONO",
    "SAN CALOGERO",
    "SAN CANDIDO .INNICHEN.",
    "SAN CANZIAN D'ISONZO",
    "SAN CARLO CANAVESE",
    "SAN CASCIANO DEI BAGNI",
    "SAN CASCIANO IN VAL DI PESA",
    "SAN CASSIANO",
    "SAN CATALDO",
    "SAN CESAREO",
    "SAN CESARIO DI LECCE",
    "SAN CESARIO SUL PANARO",
    "SAN CHIRICO NUOVO",
    "SAN CHIRICO RAPARO",
    "SAN CIPIRELLO",
    "SAN CIPRIANO D'AVERSA",
    "SAN CIPRIANO PICENTINO",
    "SAN CIPRIANO PO",
    "SAN CLEMENTE",
    "SAN COLOMBANO AL LAMBRO",
    "SAN COLOMBANO BELMONTE",
    "SAN COLOMBANO CERTENOLI",
    "SAN CONO",
    "SAN COSMO ALBANESE",
    "SAN COSTANTINO ALBANESE",
    "SAN COSTANTINO CALABRO",
    "SAN COSTANZO",
    "SAN CRISTOFORO",
    "SAN DAMIANO AL COLLE",
    "SAN DAMIANO D'ASTI",
    "SAN DAMIANO MACRA",
    "SAN DANIELE DEL FRIULI",
    "SAN DANIELE PO",
    "SAN DEMETRIO CORONE",
    "SAN DEMETRIO NE' VESTINI",
    "SAN DIDERO",
    "SAN DONA' DI PIAVE",
    "SAN DONACI",
    "SAN DONATO DI LECCE",
    "SAN DONATO DI NINEA",
    "SAN DONATO MILANESE",
    "SAN DONATO VAL DI COMINO",
    "SAN DORLIGO DELLA VALLE",
    "SAN FEDELE INTELVI",
    "SAN FELE",
    "SAN FELICE A CANCELLO",
    "SAN FELICE CIRCEO",
    "SAN FELICE DEL BENACO",
    "SAN FELICE DEL MOLISE",
    "SAN FELICE SUL PANARO",
    "SAN FERDINANDO",
    "SAN FERDINANDO DI PUGLIA",
    "SAN FERMO DELLA BATTAGLIA",
    "SAN FILI",
    "SAN FILIPPO DEL MELA",
    "SAN FIOR",
    "SAN FIORANO",
    "SAN FLORIANO DEL COLLIO",
    "SAN FLORO",
    "SAN FRANCESCO AL CAMPO",
    "SAN FRATELLO",
    "SAN GAVINO MONREALE",
    "SAN GEMINI",
    "SAN GENESIO ATESINO .JENESIEN.",
    "SAN GENESIO ED UNITI",
    "SAN GENNARO VESUVIANO",
    "SAN GERMANO CHISONE",
    "SAN GERMANO DEI BERICI",
    "SAN GERMANO VERCELLESE",
    "SAN GERVASIO BRESCIANO",
    "SAN GIACOMO DEGLI SCHIAVONI",
    "SAN GIACOMO DELLE SEGNATE",
    "SAN GIACOMO FILIPPO",
    "SAN GIACOMO VERCELLESE",
    "SAN GILLIO",
    "SAN GIMIGNANO",
    "SAN GINESIO",
    "SAN GIORGIO A CREMANO",
    "SAN GIORGIO A LIRI",
    "SAN GIORGIO ALBANESE",
    "SAN GIORGIO CANAVESE",
    "SAN GIORGIO DEL SANNIO",
    "SAN GIORGIO DELLA RICHINVELDA",
    "SAN GIORGIO DELLE PERTICHE",
    "SAN GIORGIO DI LOMELLINA",
    "SAN GIORGIO DI MANTOVA",
    "SAN GIORGIO DI NOGARO",
    "SAN GIORGIO DI PESARO",
    "SAN GIORGIO DI PIANO",
    "SAN GIORGIO IN BOSCO",
    "SAN GIORGIO IONICO",
    "SAN GIORGIO LA MOLARA",
    "SAN GIORGIO LUCANO",
    "SAN GIORGIO MONFERRATO",
    "SAN GIORGIO MORGETO",
    "SAN GIORGIO PIACENTINO",
    "SAN GIORGIO SCARAMPI",
    "SAN GIORGIO SU LEGNANO",
    "SAN GIORIO DI SUSA",
    "SAN GIOVANNI A PIRO",
    "SAN GIOVANNI AL NATISONE",
    "SAN GIOVANNI BIANCO",
    "SAN GIOVANNI D'ASSO",
    "SAN GIOVANNI DEL DOSSO",
    "SAN GIOVANNI DI GERACE",
    "SAN GIOVANNI GEMINI",
    "SAN GIOVANNI ILARIONE",
    "SAN GIOVANNI IN CROCE",
    "SAN GIOVANNI IN FIORE",
    "SAN GIOVANNI IN GALDO",
    "SAN GIOVANNI IN MARIGNANO",
    "SAN GIOVANNI IN PERSICETO",
    "SAN GIOVANNI INCARICO",
    "SAN GIOVANNI LA PUNTA",
    "SAN GIOVANNI LIPIONI",
    "SAN GIOVANNI LUPATOTO",
    "SAN GIOVANNI ROTONDO",
    "SAN GIOVANNI SUERGIU",
    "SAN GIOVANNI TEATINO",
    "SAN GIOVANNI VALDARNO",
    "SAN GIULIANO DEL SANNIO",
    "SAN GIULIANO DI PUGLIA",
    "SAN GIULIANO MILANESE",
    "SAN GIULIANO TERME",
    "SAN GIUSEPPE JATO",
    "SAN GIUSEPPE VESUVIANO",
    "SAN GIUSTINO",
    "SAN GIUSTO CANAVESE",
    "SAN GODENZO",
    "SAN GREGORIO DA SASSOLA",
    "SAN GREGORIO DI CATANIA",
    "SAN GREGORIO D'IPPONA",
    "SAN GREGORIO MAGNO",
    "SAN GREGORIO MATESE",
    "SAN GREGORIO NELLE ALPI",
    "SAN LAZZARO DI SAVENA",
    "SAN LEO",
    "SAN LEONARDO",
    "SAN LEONARDO IN PASSIRIA .ST LEONHARD IN PAS.",
    "SAN LEUCIO DEL SANNIO",
    "SAN LORENZELLO",
    "SAN LORENZO",
    "SAN LORENZO AL MARE",
    "SAN LORENZO BELLIZZI",
    "SAN LORENZO DEL VALLO",
    "SAN LORENZO DI SEBATO .ST LORENZEN.",
    "SAN LORENZO IN BANALE",
    "SAN LORENZO IN CAMPO",
    "SAN LORENZO ISONTINO",
    "SAN LORENZO MAGGIORE",
    "SAN LORENZO NUOVO",
    "SAN LUCA",
    "SAN LUCIDO",
    "SAN LUPO",
    "SAN MANGO D'AQUINO",
    "SAN MANGO PIEMONTE",
    "SAN MANGO SUL CALORE",
    "SAN MARCELLINO",
    "SAN MARCELLO",
    "SAN MARCELLO PISTOIESE",
    "SAN MARCO ARGENTANO",
    "SAN MARCO D'ALUNZIO",
    "SAN MARCO DEI CAVOTI",
    "SAN MARCO EVANGELISTA",
    "SAN MARCO IN LAMIS",
    "SAN MARCO LA CATOLA",
    "SAN MARTINO AL TAGLIAMENTO",
    "SAN MARTINO ALFIERI",
    "SAN MARTINO BUON ALBERGO",
    "SAN MARTINO CANAVESE",
    "SAN MARTINO D'AGRI",
    "SAN MARTINO DALL'ARGINE",
    "SAN MARTINO DEL LAGO",
    "SAN MARTINO DI FINITA",
    "SAN MARTINO DI LUPARI",
    "SAN MARTINO DI VENEZZE",
    "SAN MARTINO IN BADIA .ST MARTIN IN THURN.",
    "SAN MARTINO IN PASSIRIA .ST MARTIN IN PASSEI.",
    "SAN MARTINO IN PENSILIS",
    "SAN MARTINO IN RIO",
    "SAN MARTINO IN STRADA",
    "SAN MARTINO SANNITA",
    "SAN MARTINO SICCOMARIO",
    "SAN MARTINO SULLA MARRUCINA",
    "SAN MARTINO VALLE CAUDINA",
    "SAN MARZANO DI SAN GIUSEPPE",
    "SAN MARZANO OLIVETO",
    "SAN MARZANO SUL SARNO",
    "SAN MASSIMO",
    "SAN MAURIZIO CANAVESE",
    "SAN MAURIZIO D'OPAGLIO",
    "SAN MAURO CASTELVERDE",
    "SAN MAURO CILENTO",
    "SAN MAURO DI SALINE",
    "SAN MAURO FORTE",
    "SAN MAURO LA BRUCA",
    "SAN MAURO MARCHESATO",
    "SAN MAURO PASCOLI",
    "SAN MAURO TORINESE",
    "SAN MICHELE AL TAGLIAMENTO",
    "SAN MICHELE ALL'ADIGE",
    "SAN MICHELE DI GANZARIA",
    "SAN MICHELE DI SERINO",
    "SAN MICHELE MONDOVI'",
    "SAN MICHELE SALENTINO",
    "SAN MINIATO",
    "SAN NAZARIO",
    "SAN NAZZARO",
    "SAN NAZZARO SESIA",
    "SAN NAZZARO VAL CAVARGNA",
    "SAN NICOLA ARCELLA",
    "SAN NICOLA BARONIA",
    "SAN NICOLA DA CRISSA",
    "SAN NICOLA DELL'ALTO",
    "SAN NICOLA LA STRADA",
    "SAN NICOLA MANFREDI",
    "SAN NICOLO' D'ARCIDANO",
    "SAN NICOLO' DI COMELICO",
    "SAN NICOLO' GERREI",
    "SAN PANCRAZIO .ST PANKRAZ.",
    "SAN PANCRAZIO SALENTINO",
    "SAN PAOLO",
    "SAN PAOLO ALBANESE",
    "SAN PAOLO BEL SITO",
    "SAN PAOLO CERVO",
    "SAN PAOLO D'ARGON",
    "SAN PAOLO DI CIVITATE",
    "SAN PAOLO DI JESI",
    "SAN PAOLO SOLBRITO",
    "SAN PELLEGRINO TERME",
    "SAN PIER D'ISONZO",
    "SAN PIER NICETO",
    "SAN PIERO A SIEVE",
    "SAN PIERO PATTI",
    "SAN PIETRO A MAIDA",
    "SAN PIETRO AL NATISONE",
    "SAN PIETRO AL TANAGRO",
    "SAN PIETRO APOSTOLO",
    "SAN PIETRO AVELLANA",
    "SAN PIETRO CLARENZA",
    "SAN PIETRO DI CADORE",
    "SAN PIETRO DI CARIDA'",
    "SAN PIETRO DI FELETTO",
    "SAN PIETRO DI MORUBIO",
    "SAN PIETRO IN AMANTEA",
    "SAN PIETRO IN CARIANO",
    "SAN PIETRO IN CASALE",
    "SAN PIETRO IN CERRO",
    "SAN PIETRO IN GU",
    "SAN PIETRO IN GUARANO",
    "SAN PIETRO IN LAMA",
    "SAN PIETRO INFINE",
    "SAN PIETRO MOSEZZO",
    "SAN PIETRO MUSSOLINO",
    "SAN PIETRO VAL LEMINA",
    "SAN PIETRO VERNOTICO",
    "SAN PIETRO VIMINARIO",
    "SAN PIO DELLE CAMERE",
    "SAN POLO DEI CAVALIERI",
    "SAN POLO D'ENZA",
    "SAN POLO DI PIAVE",
    "SAN POLO MATESE",
    "SAN PONSO",
    "SAN POSSIDONIO",
    "SAN POTITO SANNITICO",
    "SAN POTITO ULTRA",
    "SAN PRISCO",
    "SAN PROCOPIO",
    "SAN PROSPERO",
    "SAN QUIRICO D'ORCIA",
    "SAN QUIRINO",
    "SAN RAFFAELE CIMENA",
    "SAN REMO",
    "SAN ROBERTO",
    "SAN ROCCO AL PORTO",
    "SAN ROMANO IN GARFAGNANA",
    "SAN RUFO",
    "SAN SALVATORE DI FITALIA",
    "SAN SALVATORE MONFERRATO",
    "SAN SALVATORE TELESINO",
    "SAN SALVO",
    "SAN SEBASTIANO AL VESUVIO",
    "SAN SEBASTIANO CURONE",
    "SAN SEBASTIANO DA PO",
    "SAN SECONDO DI PINEROLO",
    "SAN SECONDO PARMENSE",
    "SAN SEVERINO LUCANO",
    "SAN SEVERINO MARCHE",
    "SAN SEVERO",
    "SAN SIRO",
    "SAN SOSSIO BARONIA",
    "SAN SOSTENE",
    "SAN SOSTI",
    "SAN SPERATE",
    "SAN TAMMARO",
    "SAN TEODORO",
    "SAN TEODORO",
    "SAN TOMASO AGORDINO",
    "SAN VALENTINO IN ABRUZZO CITERIORE",
    "SAN VALENTINO TORIO",
    "SAN VENANZO",
    "SAN VENDEMIANO",
    "SAN VERO MILIS",
    "SAN VINCENZO",
    "SAN VINCENZO LA COSTA",
    "SAN VINCENZO VALLE ROVETO",
    "SAN VITALIANO",
    "SAN VITO",
    "SAN VITO AL TAGLIAMENTO",
    "SAN VITO AL TORRE",
    "SAN VITO CHIETINO",
    "SAN VITO DEI NORMANNI",
    "SAN VITO DI CADORE",
    "SAN VITO DI FAGAGNA",
    "SAN VITO DI LEGUZZANO",
    "SAN VITO LO CAPO",
    "SAN VITO ROMANO",
    "SAN VITO SULLO IONIO",
    "SAN VITTORE DEL LAZIO",
    "SAN VITTORE OLONA",
    "SAN ZENO DI MONTAGNA",
    "SAN ZENO NAVIGLIO",
    "SAN ZENONE AL LAMBRO",
    "SAN ZENONE AL PO",
    "SAN ZENONE DEGLI EZZELINI",
    "SANARICA",
    "SANDIGLIANO",
    "SANDRIGO",
    "SANFRE'",
    "SANFRONT",
    "SANGANO",
    "SANGIANO",
    "SANGINETO",
    "SANGUINETTO",
    "SANLURI",
    "SANNAZZARO DE' BURGONDI",
    "SANNICANDRO DI BARI",
    "SANNICANDRO GARGANICO",
    "SANNICOLA",
    "SANSEPOLCRO",
    "SANTA BRIGIDA",
    "SANTA CATERINA ALBANESE",
    "SANTA CATERINA DELLO IONIO",
    "SANTA CATERINA VILLARMOSA",
    "SANTA CESAREA TERME",
    "SANTA CRISTINA D'ASPROMONTE",
    "SANTA CRISTINA E BISSONE",
    "SANTA CRISTINA GELA",
    "SANTA CRISTINA VALGARDENA .ST CHRISTINA IN G.",
    "SANTA CROCE CAMERINA",
    "SANTA CROCE DEL SANNIO",
    "SANTA CROCE DI MAGLIANO",
    "SANTA CROCE SULL'ARNO",
    "SANTA DOMENICA TALAO",
    "SANTA DOMENICA VITTORIA",
    "SANTA ELISABETTA",
    "SANTA FIORA",
    "SANTA FLAVIA",
    "SANTA GIULETTA",
    "SANTA GIUSTA",
    "SANTA GIUSTINA",
    "SANTA GIUSTINA IN COLLE",
    "SANTA LUCE",
    "SANTA LUCIA DEL MELA",
    "SANTA LUCIA DI PIAVE",
    "SANTA LUCIA DI SERINO",
    "SANTA MARGHERITA D'ADIGE",
    "SANTA MARGHERITA DI BELICE",
    "SANTA MARGHERITA DI STAFFORA",
    "SANTA MARGHERITA LIGURE",
    "SANTA MARIA A MONTE",
    "SANTA MARIA A VICO",
    "SANTA MARIA CAPUA VETERE",
    "SANTA MARIA COGHINAS",
    "SANTA MARIA DEL CEDRO",
    "SANTA MARIA DEL MOLISE",
    "SANTA MARIA DELLA VERSA",
    "SANTA MARIA DI LICODIA",
    "SANTA MARIA DI SALA",
    "SANTA MARIA HOE'",
    "SANTA MARIA IMBARO",
    "SANTA MARIA LA CARITA'",
    "SANTA MARIA LA FOSSA",
    "SANTA MARIA LA LONGA",
    "SANTA MARIA MAGGIORE",
    "SANTA MARIA NUOVA",
    "SANTA MARINA",
    "SANTA MARINA SALINA",
    "SANTA MARINELLA",
    "SANTA NINFA",
    "SANTA PAOLINA",
    "SANTA SEVERINA",
    "SANTA SOFIA",
    "SANTA SOFIA D'EPIRO",
    "SANTA TERESA DI RIVA",
    "SANTA TERESA GALLURA",
    "SANTA VENERINA",
    "SANTA VITTORIA D'ALBA",
    "SANTA VITTORIA IN MATENANO",
    "SANTADI",
    "SANT'AGAPITO",
    "SANT'AGATA BOLOGNESE",
    "SANT'AGATA DE' GOTI",
    "SANT'AGATA DEL BIANCO",
    "SANT'AGATA DI ESARO",
    "SANT'AGATA DI MILITELLO",
    "SANT'AGATA DI PUGLIA",
    "SANT'AGATA FELTRIA",
    "SANT'AGATA FOSSILI",
    "SANT'AGATA LI BATTIATI",
    "SANT'AGATA SUL SANTERNO",
    "SANT'AGNELLO",
    "SANT'AGOSTINO",
    "SANT'ALBANO STURA",
    "SANT'ALESSIO CON VIALONE",
    "SANT'ALESSIO IN ASPROMONTE",
    "SANT'ALESSIO SICULO",
    "SANT'ALFIO",
    "SANT'AMBROGIO DI TORINO",
    "SANT'AMBROGIO DI VALPOLICELLA",
    "SANT'AMBROGIO SUL GARIGLIANO",
    "SANT'ANASTASIA",
    "SANT'ANATOLIA DI NARCO",
    "SANT'ANDREA APOSTOLO DELLO IONIO",
    "SANT'ANDREA DEL GARIGLIANO",
    "SANT'ANDREA DI CONZA",
    "SANT'ANDREA FRIUS",
    "SANT'ANGELO A CUPOLO",
    "SANT'ANGELO A FASANELLA",
    "SANT'ANGELO A SCALA",
    "SANT'ANGELO ALL'ESCA",
    "SANT'ANGELO D'ALIFE",
    "SANT'ANGELO DEI LOMBARDI",
    "SANT'ANGELO DEL PESCO",
    "SANT'ANGELO DI BROLO",
    "SANT'ANGELO DI PIOVE DI SACCO",
    "SANT'ANGELO IN LIZZOLA",
    "SANT'ANGELO IN PONTANO",
    "SANT'ANGELO IN VADO",
    "SANT'ANGELO LE FRATTE",
    "SANT'ANGELO LIMOSANO",
    "SANT'ANGELO LODIGIANO",
    "SANT'ANGELO LOMELLINA",
    "SANT'ANGELO MUXARO",
    "SANT'ANGELO ROMANO",
    "SANT'ANNA ARRESI",
    "SANT'ANNA D'ALFAEDO",
    "SANT'ANTIMO",
    "SANT'ANTIOCO",
    "SANT'ANTONINO DI SUSA",
    "SANT'ANTONIO ABATE",
    "SANT'ANTONIO DI GALLURA",
    "SANT'APOLLINARE",
    "SANT'ARCANGELO",
    "SANTARCANGELO DI ROMAGNA",
    "SANT'ARCANGELO TRIMONTE",
    "SANT'ARPINO",
    "SANT'ARSENIO",
    "SANTE MARIE",
    "SANT'EGIDIO ALLA VIBRATA",
    "SANT'EGIDIO DEL MONTE ALBINO",
    "SANT'ELENA",
    "SANT'ELENA SANNITA",
    "SANT'ELIA A PIANISI",
    "SANT'ELIA FIUMERAPIDO",
    "SANT'ELPIDIO A MARE",
    "SANTENA",
    "SANTERAMO IN COLLE",
    "SANT'EUFEMIA A MAIELLA",
    "SANT'EUFEMIA D'ASPROMONTE",
    "SANT'EUSANIO DEL SANGRO",
    "SANT'EUSANIO FORCONESE",
    "SANTHIA'",
    "SANTI COSMA E DAMIANO",
    "SANT'ILARIO DELLO IONIO",
    "SANT'ILARIO D'ENZA",
    "SANT'IPPOLITO",
    "SANTO STEFANO AL MARE",
    "SANTO STEFANO BELBO",
    "SANTO STEFANO D'AVETO",
    "SANTO STEFANO DEL SOLE",
    "SANTO STEFANO DI CADORE",
    "SANTO STEFANO DI CAMASTRA",
    "SANTO STEFANO DI MAGRA",
    "SANTO STEFANO DI ROGLIANO",
    "SANTO STEFANO DI SESSANIO",
    "SANTO STEFANO IN ASPROMONTE",
    "SANTO STEFANO LODIGIANO",
    "SANTO STEFANO QUISQUINA",
    "SANTO STEFANO ROERO",
    "SANTO STEFANO TICINO",
    "SANTO STINO DI LIVENZA",
    "SANT'OLCESE",
    "SANTOMENNA",
    "SANT'OMERO",
    "SANT'OMOBONO IMAGNA",
    "SANT'ONOFRIO",
    "SANTOPADRE",
    "SANT'ORESTE",
    "SANTORSO",
    "SANT'ORSOLA TERME",
    "SANTU LUSSURGIU",
    "SANT'URBANO",
    "SANZA",
    "SANZENO",
    "SAONARA",
    "SAPONARA",
    "SAPPADA",
    "SAPRI",
    "SARACENA",
    "SARACINESCO",
    "SARCEDO",
    "SARCONI",
    "SARDARA",
    "SARDIGLIANO",
    "SAREGO",
    "SARENTINO .SARNTAL.",
    "SAREZZANO",
    "SAREZZO",
    "SARMATO",
    "SARMEDE",
    "SARNANO",
    "SARNICO",
    "SARNO",
    "SARNONICO",
    "SARONNO",
    "SARRE",
    "SARROCH",
    "SARSINA",
    "SARTEANO",
    "SARTIRANA LOMELLINA",
    "SARULE",
    "SARZANA",
    "SASSANO",
    "SASSARI",
    "SASSELLO",
    "SASSETTA",
    "SASSINORO",
    "SASSO DI CASTALDA",
    "SASSO MARCONI",
    "SASSOCORVARO",
    "SASSOFELTRIO",
    "SASSOFERRATO",
    "SASSUOLO",
    "SATRIANO",
    "SATRIANO DI LUCANIA",
    "SAURIS",
    "SAUZE DI CESANA",
    "SAUZE D'OULX",
    "SAVA",
    "SAVELLI",
    "SAVIANO",
    "SAVIGLIANO",
    "SAVIGNANO IRPINO",
    "SAVIGNANO SUL PANARO",
    "SAVIGNANO SUL RUBICONE",
    "SAVIGNO",
    "SAVIGNONE",
    "SAVIORE DELL'ADAMELLO",
    "SAVOCA",
    "SAVOGNA",
    "SAVOGNA D'ISONZO",
    "SAVOIA DI LUCANIA",
    "SAVONA",
    "SCAFA",
    "SCAFATI",
    "SCAGNELLO",
    "SCALA",
    "SCALA COELI",
    "SCALDASOLE",
    "SCALEA",
    "SCALENGHE",
    "SCALETTA ZANCLEA",
    "SCAMPITELLA",
    "SCANDALE",
    "SCANDIANO",
    "SCANDICCI",
    "SCANDOLARA RAVARA",
    "SCANDOLARA RIPA D'OGLIO",
    "SCANDRIGLIA",
    "SCANNO",
    "SCANO DI MONTIFERRO",
    "SCANSANO",
    "SCANZANO JONICO",
    "SCANZOROSCIATE",
    "SCAPOLI",
    "SCARLINO",
    "SCARMAGNO",
    "SCARNAFIGI",
    "SCARPERIA",
    "SCENA .SCHONNA.",
    "SCERNI",
    "SCHEGGIA E PASCELUPO",
    "SCHEGGINO",
    "SCHIAVI DI ABRUZZO",
    "SCHIAVON",
    "SCHIGNANO",
    "SCHILPARIO",
    "SCHIO",
    "SCHIVENOGLIA",
    "SCIACCA",
    "SCIARA",
    "SCICLI",
    "SCIDO",
    "SCIGLIANO",
    "SCILLA",
    "SCILLATO",
    "SCIOLZE",
    "SCISCIANO",
    "SCLAFANI BAGNI",
    "SCONTRONE",
    "SCOPA",
    "SCOPELLO",
    "SCOPPITO",
    "SCORDIA",
    "SCORRANO",
    "SCORZE'",
    "SCURCOLA MARSICANA",
    "SCURELLE",
    "SCURZOLENGO",
    "SEBORGA",
    "SECINARO",
    "SECLI'",
    "SECUGNAGO",
    "SEDEGLIANO",
    "SEDICO",
    "SEDILO",
    "SEDINI",
    "SEDRIANO",
    "SEDRINA",
    "SEFRO",
    "SEGARIU",
    "SEGGIANO",
    "SEGNI",
    "SEGONZANO",
    "SEGRATE",
    "SEGUSINO",
    "SELARGIUS",
    "SELCI",
    "SELEGAS",
    "SELLANO",
    "SELLERO",
    "SELLIA",
    "SELLIA MARINA",
    "SELVA DEI MOLINI .MUHLWALD.",
    "SELVA DI CADORE",
    "SELVA DI PROGNO",
    "SELVA DI VAL GARDENA .WOLKENSTEIN IN GROEDEN.",
    "SELVAZZANO DENTRO",
    "SELVE MARCONE",
    "SELVINO",
    "SEMESTENE",
    "SEMIANA",
    "SEMINARA",
    "SEMPRONIANO",
    "SENAGO",
    "SENALES .SCHNALS.",
    "SENALE-SAN FELICE .UNSERE LIEBE FRAU IM WALD.",
    "SENEGHE",
    "SENERCHIA",
    "SENIGA",
    "SENIGALLIA",
    "SENIS",
    "SENISE",
    "SENNA COMASCO",
    "SENNA LODIGIANA",
    "SENNARIOLO",
    "SENNORI",
    "SENORBI'",
    "SEPINO",
    "SEPPIANA",
    "SEQUALS",
    "SERAVEZZA",
    "SERDIANA",
    "SEREGNO",
    "SEREN DEL GRAPPA",
    "SERGNANO",
    "SERIATE",
    "SERINA",
    "SERINO",
    "SERLE",
    "SERMIDE",
    "SERMONETA",
    "SERNAGLIA DELLA BATTAGLIA",
    "SERNIO",
    "SEROLE",
    "SERRA D'AIELLO",
    "SERRA DE' CONTI",
    "SERRA PEDACE",
    "SERRA RICCO'",
    "SERRA SAN BRUNO",
    "SERRA SAN QUIRICO",
    "SERRA SANT'ABBONDIO",
    "SERRACAPRIOLA",
    "SERRADIFALCO",
    "SERRALUNGA D'ALBA",
    "SERRALUNGA DI CREA",
    "SERRAMANNA",
    "SERRAMAZZONI",
    "SERRAMEZZANA",
    "SERRAMONACESCA",
    "SERRAPETRONA",
    "SERRARA FONTANA",
    "SERRASTRETTA",
    "SERRATA",
    "SERRAVALLE A PO",
    "SERRAVALLE DI CHIENTI",
    "SERRAVALLE LANGHE",
    "SERRAVALLE PISTOIESE",
    "SERRAVALLE SCRIVIA",
    "SERRAVALLE SESIA",
    "SERRE",
    "SERRENTI",
    "SERRI",
    "SERRONE",
    "SERRUNGARINA",
    "SERSALE",
    "SERVIGLIANO",
    "SESSA AURUNCA",
    "SESSA CILENTO",
    "SESSAME",
    "SESSANO DEL MOLISE",
    "SESTA GODANO",
    "SESTINO",
    "SESTO .SEXTEN.",
    "SESTO AL REGHENA",
    "SESTO CALENDE",
    "SESTO CAMPANO",
    "SESTO ED UNITI",
    "SESTO FIORENTINO",
    "SESTO SAN GIOVANNI",
    "SESTOLA",
    "SESTRI LEVANTE",
    "SESTRIERE",
    "SESTU",
    "SETTALA",
    "SETTEFRATI",
    "SETTIME",
    "SETTIMO MILANESE",
    "SETTIMO ROTTARO",
    "SETTIMO SAN PIETRO",
    "SETTIMO TORINESE",
    "SETTIMO VITTONE",
    "SETTINGIANO",
    "SETZU",
    "SEUI",
    "SEULO",
    "SEVESO",
    "SEZZADIO",
    "SEZZE",
    "SFRUZ",
    "SGONICO",
    "SGURGOLA",
    "SIAMAGGIORE",
    "SIAMANNA",
    "SIANO",
    "SIAPICCIA",
    "SICIGNANO DEGLI ALBURNI",
    "SICULIANA",
    "SIDDI",
    "SIDERNO",
    "SIENA",
    "SIGILLO",
    "SIGNA",
    "SILANDRO .SCHLANDERS.",
    "SILANUS",
    "SILEA",
    "SILIGO",
    "SILIQUA",
    "SILIUS",
    "SILLANO",
    "SILLAVENGO",
    "SILVANO D'ORBA",
    "SILVANO PIETRA",
    "SILVI",
    "SIMALA",
    "SIMAXIS",
    "SIMBARIO",
    "SIMERI CRICHI",
    "SINAGRA",
    "SINALUNGA",
    "SINDIA",
    "SINI",
    "SINIO",
    "SINISCOLA",
    "SINNAI",
    "SINOPOLI",
    "SIRACUSA",
    "SIRIGNANO",
    "SIRIS",
    "SIRMIONE",
    "SIROLO",
    "SIRONE",
    "SIROR",
    "SIRTORI",
    "SISSA",
    "SIURGUS DONIGALA",
    "SIZIANO",
    "SIZZANO",
    "SLUDERNO .SCHLUDERNS.",
    "SMARANO",
    "SMERILLO",
    "SOAVE",
    "SOCCHIEVE",
    "SODDI'",
    "SOGLIANO AL RUBICONE",
    "SOGLIANO CAVOUR",
    "SOGLIO",
    "SOIANO DEL LAGO",
    "SOLAGNA",
    "SOLARINO",
    "SOLARO",
    "SOLAROLO",
    "SOLAROLO RAINERIO",
    "SOLARUSSA",
    "SOLBIATE",
    "SOLBIATE ARNO",
    "SOLBIATE OLONA",
    "SOLDANO",
    "SOLEMINIS",
    "SOLERO",
    "SOLESINO",
    "SOLETO",
    "SOLFERINO",
    "SOLIERA",
    "SOLIGNANO",
    "SOLOFRA",
    "SOLONGHELLO",
    "SOLOPACA",
    "SOLTO COLLINA",
    "SOLZA",
    "SOMAGLIA",
    "SOMANO",
    "SOMMA LOMBARDO",
    "SOMMA VESUVIANA",
    "SOMMACAMPAGNA",
    "SOMMARIVA DEL BOSCO",
    "SOMMARIVA PERNO",
    "SOMMATINO",
    "SOMMO",
    "SONA",
    "SONCINO",
    "SONDALO",
    "SONDRIO",
    "SONGAVAZZO",
    "SONICO",
    "SONNINO",
    "SOPRANA",
    "SORA",
    "SORAGA",
    "SORAGNA",
    "SORANO",
    "SORBO SAN BASILE",
    "SORBO SERPICO",
    "SORBOLO",
    "SORDEVOLO",
    "SORDIO",
    "SORESINA",
    "SORGA'",
    "SORGONO",
    "SORI",
    "SORIANELLO",
    "SORIANO CALABRO",
    "SORIANO NEL CIMINO",
    "SORICO",
    "SORISO",
    "SORISOLE",
    "SORMANO",
    "SORRADILE",
    "SORRENTO",
    "SORSO",
    "SORTINO",
    "SOSPIRO",
    "SOSPIROLO",
    "SOSSANO",
    "SOSTEGNO",
    "SOTTO IL MONTE GIOVANNI XXIII",
    "SOVER",
    "SOVERATO",
    "SOVERE",
    "SOVERIA MANNELLI",
    "SOVERIA SIMERI",
    "SOVERZENE",
    "SOVICILLE",
    "SOVICO",
    "SOVIZZO",
    "SOVRAMONTE",
    "SOZZAGO",
    "SPADAFORA",
    "SPADOLA",
    "SPARANISE",
    "SPARONE",
    "SPECCHIA",
    "SPELLO",
    "SPERA",
    "SPERLINGA",
    "SPERLONGA",
    "SPERONE",
    "SPESSA",
    "SPEZZANO ALBANESE",
    "SPEZZANO DELLA SILA",
    "SPEZZANO PICCOLO",
    "SPIAZZO",
    "SPIGNO MONFERRATO",
    "SPIGNO SATURNIA",
    "SPILAMBERTO",
    "SPILIMBERGO",
    "SPILINGA",
    "SPINADESCO",
    "SPINAZZOLA",
    "SPINEA",
    "SPINEDA",
    "SPINETE",
    "SPINETO SCRIVIA",
    "SPINETOLI",
    "SPINO D'ADDA",
    "SPINONE AL LAGO",
    "SPINOSO",
    "SPIRANO",
    "SPOLETO",
    "SPOLTORE",
    "SPONGANO",
    "SPORMAGGIORE",
    "SPORMINORE",
    "SPOTORNO",
    "SPRESIANO",
    "SPRIANA",
    "SQUILLACE",
    "SQUINZANO",
    "STAFFOLO",
    "STAGNO LOMBARDO",
    "STAITI",
    "STALETTI",
    "STANGHELLA",
    "STARANZANO",
    "STATTE",
    "STAZZANO",
    "STAZZEMA",
    "STAZZONA",
    "STEFANACONI",
    "STELLA",
    "STELLA CILENTO",
    "STELLANELLO",
    "STELVIO .STILFS.",
    "STENICO",
    "STERNATIA",
    "STEZZANO",
    "STIA",
    "STIENTA",
    "STIGLIANO",
    "STIGNANO",
    "STILO",
    "STIMIGLIANO",
    "STINTINO",
    "STIO",
    "STORNARA",
    "STORNARELLA",
    "STORO",
    "STRA",
    "STRADELLA",
    "STRAMBINELLO",
    "STRAMBINO",
    "STRANGOLAGALLI",
    "STREGNA",
    "STREMBO",
    "STRESA",
    "STREVI",
    "STRIANO",
    "STRIGNO",
    "STRONA",
    "STRONCONE",
    "STRONGOLI",
    "STROPPIANA",
    "STROPPO",
    "STROZZA",
    "STURNO",
    "SUARDI",
    "SUBBIANO",
    "SUBIACO",
    "SUCCIVO",
    "SUEGLIO",
    "SUELLI",
    "SUELLO",
    "SUISIO",
    "SULBIATE",
    "SULMONA",
    "SULZANO",
    "SUMIRAGO",
    "SUMMONTE",
    "SUNI",
    "SUNO",
    "SUPERSANO",
    "SUPINO",
    "SURANO",
    "SURBO",
    "SUSA",
    "SUSEGANA",
    "SUSTINENTE",
    "SUTERA",
    "SUTRI",
    "SUTRIO",
    "SUVERETO",
    "SUZZARA",
    "TACENO",
    "TADASUNI",
    "TAGGIA",
    "TAGLIACOZZO",
    "TAGLIO DI PO",
    "TAGLIOLO MONFERRATO",
    "TAIBON AGORDINO",
    "TAINO",
    "TAIO",
    "TAIPANA",
    "TALAMELLO",
    "TALAMONA",
    "TALANA",
    "TALEGGIO",
    "TALLA",
    "TALMASSONS",
    "TAMBRE",
    "TAORMINA",
    "TAPOGLIANO",
    "TARANO",
    "TARANTA PELIGNA",
    "TARANTASCA",
    "TARANTO",
    "TARCENTO",
    "TARQUINIA",
    "TARSIA",
    "TARTANO",
    "TARVISIO",
    "TARZO",
    "TASSAROLO",
    "TASSULLO",
    "TAURANO",
    "TAURASI",
    "TAURIANOVA",
    "TAURISANO",
    "TAVAGNACCO",
    "TAVAGNASCO",
    "TAVARNELLE VAL DI PESA",
    "TAVAZZANO CON VILLAVESCO",
    "TAVENNA",
    "TAVERNA",
    "TAVERNERIO",
    "TAVERNOLA BERGAMASCA",
    "TAVERNOLE SUL MELLA",
    "TAVIANO",
    "TAVIGLIANO",
    "TAVOLETO",
    "TAVULLIA",
    "TEANA",
    "TEANO",
    "TEGGIANO",
    "TEGLIO",
    "TEGLIO VENETO",
    "TELESE TERME",
    "TELGATE",
    "TELTI",
    "TELVE",
    "TELVE DI SOPRA",
    "TEMPIO PAUSANIA",
    "TEMU'",
    "TENNA",
    "TENNO",
    "TEOLO",
    "TEOR",
    "TEORA",
    "TERAMO",
    "TERDOBBIATE",
    "TERELLE",
    "TERENTO .TERENTEN.",
    "TERENZO",
    "TERGU",
    "TERLAGO",
    "TERLANO .TERLAN.",
    "TERLIZZI",
    "TERME VIGLIATORE",
    "TERMENO SULLA STRADA DEL VINO .TRAMIN AN DER.",
    "TERMINI IMERESE",
    "TERMOLI",
    "TERNATE",
    "TERNENGO",
    "TERNI",
    "TERNO D'ISOLA",
    "TERRACINA",
    "TERRAGNOLO",
    "TERRALBA",
    "TERRANOVA DA SIBARI",
    "TERRANOVA DEI PASSERINI",
    "TERRANOVA DI POLLINO",
    "TERRANOVA SAPPO MINULIO",
    "TERRANUOVA BRACCIOLINI",
    "TERRASINI",
    "TERRASSA PADOVANA",
    "TERRAVECCHIA",
    "TERRAZZO",
    "TERRES",
    "TERRICCIOLA",
    "TERRUGGIA",
    "TERTENIA",
    "TERZIGNO",
    "TERZO",
    "TERZO DI AQUILEIA",
    "TERZOLAS",
    "TERZORIO",
    "TESERO",
    "TESIMO .TISENS.",
    "TESSENNANO",
    "TESTICO",
    "TETI",
    "TEULADA",
    "TEVEROLA",
    "TEZZE SUL BRENTA",
    "THIENE",
    "THIESI",
    "TIANA",
    "TIARNO DI SOPRA",
    "TIARNO DI SOTTO",
    "TICENGO",
    "TICINETO",
    "TIGGIANO",
    "TIGLIETO",
    "TIGLIOLE",
    "TIGNALE",
    "TINNURA",
    "TIONE DEGLI ABRUZZI",
    "TIONE DI TRENTO",
    "TIRANO",
    "TIRES .TIERS.",
    "TIRIOLO",
    "TIROLO .TIROL.",
    "TISSI",
    "TITO",
    "TIVOLI",
    "TIZZANO VAL PARMA",
    "TOANO",
    "TOCCO CAUDIO",
    "TOCCO DA CASAURIA",
    "TOCENO",
    "TODI",
    "TOFFIA",
    "TOIRANO",
    "TOLENTINO",
    "TOLFA",
    "TOLLEGNO",
    "TOLLO",
    "TOLMEZZO",
    "TOLVE",
    "TOMBOLO",
    "TON",
    "TONADICO",
    "TONARA",
    "TONCO",
    "TONENGO",
    "TONEZZA DEL CIMONE",
    "TORA E PICCILLI",
    "TORANO CASTELLO",
    "TORANO NUOVO",
    "TORBOLE CASAGLIA",
    "TORCEGNO",
    "TORCHIARA",
    "TORCHIAROLO",
    "TORELLA DEI LOMBARDI",
    "TORELLA DEL SANNIO",
    "TORGIANO",
    "TORGNON",
    "TORINO",
    "TORINO DI SANGRO",
    "TORITTO",
    "TORLINO VIMERCATI",
    "TORNACO",
    "TORNARECCIO",
    "TORNATA",
    "TORNIMPARTE",
    "TORNO",
    "TORNOLO",
    "TORO",
    "TORPE'",
    "TORRACA",
    "TORRALBA",
    "TORRAZZA COSTE",
    "TORRAZZA PIEMONTE",
    "TORRAZZO",
    "TORRE ANNUNZIATA",
    "TORRE BERETTI E CASTELLARO",
    "TORRE BOLDONE",
    "TORRE BORMIDA",
    "TORRE CAJETANI",
    "TORRE CANAVESE",
    "TORRE D'ARESE",
    "TORRE DE' BUSI",
    "TORRE DE' NEGRI",
    "TORRE DE' PASSERI",
    "TORRE DE' PICENARDI",
    "TORRE DE' ROVERI",
    "TORRE DEL GRECO",
    "TORRE DI MOSTO",
    "TORRE DI RUGGIERO",
    "TORRE DI SANTA MARIA",
    "TORRE D'ISOLA",
    "TORRE LE NOCELLE",
    "TORRE MONDOVI'",
    "TORRE ORSAIA",
    "TORRE PALLAVICINA",
    "TORRE PELLICE",
    "TORRE SAN GIORGIO",
    "TORRE SAN PATRIZIO",
    "TORRE SANTA SUSANNA",
    "TORREANO",
    "TORREBELVICINO",
    "TORREBRUNA",
    "TORRECUSO",
    "TORREGLIA",
    "TORREGROTTA",
    "TORREMAGGIORE",
    "TORRENOVA",
    "TORRESINA",
    "TORRETTA",
    "TORREVECCHIA PIA",
    "TORREVECCHIA TEATINA",
    "TORRI DEL BENACO",
    "TORRI DI QUARTESOLO",
    "TORRI IN SABINA",
    "TORRIANA",
    "TORRICE",
    "TORRICELLA",
    "TORRICELLA DEL PIZZO",
    "TORRICELLA IN SABINA",
    "TORRICELLA PELIGNA",
    "TORRICELLA SICURA",
    "TORRICELLA VERZATE",
    "TORRIGLIA",
    "TORRILE",
    "TORRIONI",
    "TORRITA DI SIENA",
    "TORRITA TIBERINA",
    "TORTOLI'",
    "TORTONA",
    "TORTORA",
    "TORTORELLA",
    "TORTORETO",
    "TORTORICI",
    "TORVISCOSA",
    "TOSCOLANO-MADERNO",
    "TOSSICIA",
    "TOVO DI SANT'AGATA",
    "TOVO SAN GIACOMO",
    "TRABIA",
    "TRADATE",
    "TRAMATZA",
    "TRAMBILENO",
    "TRAMONTI",
    "TRAMONTI DI SOPRA",
    "TRAMONTI DI SOTTO",
    "TRAMUTOLA",
    "TRANA",
    "TRANI",
    "TRANSACQUA",
    "TRAONA",
    "TRAPANI",
    "TRAPPETO",
    "TRAREGO VIGGIONA",
    "TRASACCO",
    "TRASAGHIS",
    "TRASQUERA",
    "TRATALIAS",
    "TRAUSELLA",
    "TRAVACO' SICCOMARIO",
    "TRAVAGLIATO",
    "TRAVEDONA-MONATE",
    "TRAVERSELLA",
    "TRAVERSETOLO",
    "TRAVES",
    "TRAVESIO",
    "TRAVO",
    "TREBASELEGHE",
    "TREBISACCE",
    "TRECASALI",
    "TRECASE",
    "TRECASTAGNI",
    "TRECATE",
    "TRECCHINA",
    "TRECENTA",
    "TREDOZIO",
    "TREGLIO",
    "TREGNAGO",
    "TREIA",
    "TREISO",
    "TREMENICO",
    "TREMESTIERI ETNEO",
    "TREMEZZO",
    "TREMOSINE",
    "TRENTA",
    "TRENTINARA",
    "TRENTO",
    "TRENTOLA DUCENTA",
    "TRENZANO",
    "TREPPO CARNICO",
    "TREPPO GRANDE",
    "TREPUZZI",
    "TREQUANDA",
    "TRES",
    "TRESANA",
    "TRESCORE BALNEARIO",
    "TRESCORE CREMASCO",
    "TRESIGALLO",
    "TRESIVIO",
    "TRESNURAGHES",
    "TREVENZUOLO",
    "TREVI",
    "TREVI NEL LAZIO",
    "TREVICO",
    "TREVIGLIO",
    "TREVIGNANO",
    "TREVIGNANO ROMANO",
    "TREVILLE",
    "TREVIOLO",
    "TREVISO",
    "TREVISO BRESCIANO",
    "TREZZANO ROSA",
    "TREZZANO SUL NAVIGLIO",
    "TREZZO SULL'ADDA",
    "TREZZO TINELLA",
    "TREZZONE",
    "TRIBANO",
    "TRIBIANO",
    "TRIBOGNA",
    "TRICARICO",
    "TRICASE",
    "TRICERRO",
    "TRICESIMO",
    "TRICHIANA",
    "TRIEI",
    "TRIESTE",
    "TRIGGIANO",
    "TRIGOLO",
    "TRINITA'",
    "TRINITA' D'AGULTU E VIGNOLA",
    "TRINITAPOLI",
    "TRINO VERCELLESE",
    "TRIORA",
    "TRIPI",
    "TRISOBBIO",
    "TRISSINO",
    "TRIUGGIO",
    "TRIVENTO",
    "TRIVERO",
    "TRIVIGLIANO",
    "TRIVIGNANO UDINESE",
    "TRIVIGNO",
    "TRIVOLZIO",
    "TRODENA .TRUDEN.",
    "TROFARELLO",
    "TROIA",
    "TROINA",
    "TROMELLO",
    "TRONTANO",
    "TRONZANO LAGO MAGGIORE",
    "TRONZANO VERCELLESE",
    "TROPEA",
    "TROVO",
    "TRUCCAZZANO",
    "TUBRE .TAUFERS IN MUNSTERTHAL.",
    "TUENNO",
    "TUFARA",
    "TUFILLO",
    "TUFINO",
    "TUFO",
    "TUGLIE",
    "TUILI",
    "TULA",
    "TUORO SUL TRASIMENO",
    "TURANIA",
    "TURANO LODIGIANO",
    "TURATE",
    "TURBIGO",
    "TURI",
    "TURRI",
    "TURRIACO",
    "TURRIVALIGNANI",
    "TURSI",
    "TUSA",
    "TUSCANIA",
    "UBIALE CLANEZZO",
    "UBOLDO",
    "UCRIA",
    "UDINE",
    "UGENTO",
    "UGGIANO LA CHIESA",
    "UGGIATE-TREVANO",
    "ULA' TIRSO",
    "ULASSAI",
    "ULTIMO .ULTEN.",
    "UMBERTIDE",
    "UMBRIATICO",
    "URAGO D'OGLIO",
    "URAS",
    "URBANA",
    "URBANIA",
    "URBE",
    "URBINO",
    "URBISAGLIA",
    "URGNANO",
    "URI",
    "URURI",
    "URZULEI",
    "USCIO",
    "USELLUS",
    "USINI",
    "USMATE VELATE",
    "USSANA",
    "USSARAMANNA",
    "USSASSAI",
    "USSEAUX",
    "USSEGLIO",
    "USSITA",
    "USTICA",
    "UTA",
    "UZZANO",
    "VACCARIZZO ALBANESE",
    "VACONE",
    "VACRI",
    "VADENA .PFATTEN.",
    "VADO LIGURE",
    "VAGLI SOTTO",
    "VAGLIA",
    "VAGLIO BASILICATA",
    "VAGLIO SERRA",
    "VAIANO",
    "VAIANO CREMASCO",
    "VAIE",
    "VAILATE",
    "VAIRANO PATENORA",
    "VAJONT",
    "VAL DELLA TORRE",
    "VAL DI NIZZA",
    "VAL DI VIZZE .PFITSCH.",
    "VAL MASINO",
    "VAL REZZO",
    "VALBONDIONE",
    "VALBREMBO",
    "VALBREVENNA",
    "VALBRONA",
    "VALDA",
    "VALDAGNO",
    "VALDAORA .OLANG.",
    "VALDASTICO",
    "VALDENGO",
    "VALDERICE",
    "VALDIDENTRO",
    "VALDIERI",
    "VALDINA",
    "VALDISOTTO",
    "VALDOBBIADENE",
    "VALDUGGIA",
    "VALEGGIO",
    "VALEGGIO SUL MINCIO",
    "VALENTANO",
    "VALENZA",
    "VALENZANO",
    "VALERA FRATTA",
    "VALFABBRICA",
    "VALFENERA",
    "VALFLORIANA",
    "VALFURVA",
    "VALGANNA",
    "VALGIOIE",
    "VALGOGLIO",
    "VALGRANA",
    "VALGREGHENTINO",
    "VALGRISENCHE",
    "VALGUARNERA CAROPEPE",
    "VALLADA AGORDINA",
    "VALLANZENGO",
    "VALLARSA",
    "VALLATA",
    "VALLE AGRICOLA",
    "VALLE AURINA .AHRNTAL.",
    "VALLE CASTELLANA",
    "VALLE DELL'ANGELO",
    "VALLE DI CADORE",
    "VALLE DI CASIES .GSIES.",
    "VALLE DI MADDALONI",
    "VALLE LOMELLINA",
    "VALLE MOSSO",
    "VALLE SALIMBENE",
    "VALLE SAN NICOLAO",
    "VALLEBONA",
    "VALLECORSA",
    "VALLECROSIA",
    "VALLEDOLMO",
    "VALLEDORIA",
    "VALLEFIORITA",
    "VALLELONGA",
    "VALLELUNGA PRATAMENO",
    "VALLEMAIO",
    "VALLEPIETRA",
    "VALLERANO",
    "VALLERMOSA",
    "VALLEROTONDA",
    "VALLESACCARDA",
    "VALLEVE",
    "VALLI DEL PASUBIO",
    "VALLINFREDA",
    "VALLIO TERME",
    "VALLO DELLA LUCANIA",
    "VALLO DI NERA",
    "VALLO TORINESE",
    "VALLORIATE",
    "VALMACCA",
    "VALMADRERA",
    "VALMALA",
    "VALMONTONE",
    "VALMOREA",
    "VALMOZZOLA",
    "VALNEGRA",
    "VALPELLINE",
    "VALPERGA",
    "VALPRATO SOANA",
    "VALSAVARENCHE",
    "VALSECCA",
    "VALSINNI",
    "VALSOLDA",
    "VALSTAGNA",
    "VALSTRONA",
    "VALTOPINA",
    "VALTORTA",
    "VALTOURNENCHE",
    "VALVA",
    "VALVASONE",
    "VALVERDE",
    "VALVERDE",
    "VALVESTINO",
    "VANDOIES .VINTL.",
    "VANZAGHELLO",
    "VANZAGO",
    "VANZONE CON SAN CARLO",
    "VAPRIO D'ADDA",
    "VAPRIO D'AGOGNA",
    "VARALLO",
    "VARALLO POMBIA",
    "VARANO BORGHI",
    "VARANO DE' MELEGARI",
    "VARAPODIO",
    "VARAZZE",
    "VARCO SABINO",
    "VAREDO",
    "VARENA",
    "VARENNA",
    "VARESE",
    "VARESE LIGURE",
    "VARISELLA",
    "VARMO",
    "VARNA .VAHRN.",
    "VARSI",
    "VARZI",
    "VARZO",
    "VAS",
    "VASANELLO",
    "VASIA",
    "VASTO",
    "VASTOGIRARDI",
    "VATTARO",
    "VAUDA CANAVESE",
    "VAZZANO",
    "VAZZOLA",
    "VECCHIANO",
    "VEDANO AL LAMBRO",
    "VEDANO OLONA",
    "VEDDASCA",
    "VEDELAGO",
    "VEDESETA",
    "VEDUGGIO CON COLZANO",
    "VEGGIANO",
    "VEGLIE",
    "VEGLIO",
    "VEJANO",
    "VELESO",
    "VELEZZO LOMELLINA",
    "VELLETRI",
    "VELLEZZO BELLINI",
    "VELO D'ASTICO",
    "VELO VERONESE",
    "VELTURNO .FELDTHURNS.",
    "VENAFRO",
    "VENARIA REALE",
    "VENAROTTA",
    "VENASCA",
    "VENAUS",
    "VENDONE",
    "VENDROGNO",
    "VENEGONO INFERIORE",
    "VENEGONO SUPERIORE",
    "VENETICO",
    "VENEZIA",
    "VENIANO",
    "VENOSA",
    "VENTICANO",
    "VENTIMIGLIA",
    "VENTIMIGLIA DI SICILIA",
    "VENTOTENE",
    "VENZONE",
    "VERANO .VORAN.",
    "VERANO BRIANZA",
    "VERBANIA",
    "VERBICARO",
    "VERCANA",
    "VERCEIA",
    "VERCELLI",
    "VERCURAGO",
    "VERDELLINO",
    "VERDELLO",
    "VERDERIO INFERIORE",
    "VERDERIO SUPERIORE",
    "VERDUNO",
    "VERGATO",
    "VERGEMOLI",
    "VERGHERETO",
    "VERGIATE",
    "VERMEZZO",
    "VERMIGLIO",
    "VERNANTE",
    "VERNASCA",
    "VERNATE",
    "VERNAZZA",
    "VERNIO",
    "VERNOLE",
    "VEROLANUOVA",
    "VEROLAVECCHIA",
    "VEROLENGO",
    "VEROLI",
    "VERONA",
    "VERONELLA",
    "VERRAYES",
    "VERRES",
    "VERRETTO",
    "VERRONE",
    "VERRUA PO",
    "VERRUA SAVOIA",
    "VERTEMATE CON MINOPRIO",
    "VERTOVA",
    "VERUCCHIO",
    "VERUNO",
    "VERVIO",
    "VERVO'",
    "VERZEGNIS",
    "VERZINO",
    "VERZUOLO",
    "VESCOVANA",
    "VESCOVATO",
    "VESIME",
    "VESPOLATE",
    "VESSALICO",
    "VESTENANOVA",
    "VESTIGNE'",
    "VESTONE",
    "VESTRENO",
    "VETRALLA",
    "VETTO",
    "VEZZA D'ALBA",
    "VEZZA D'OGLIO",
    "VEZZANO",
    "VEZZANO LIGURE",
    "VEZZANO SUL CROSTOLO",
    "VEZZI PORTIO",
    "VIADANA",
    "VIADANICA",
    "VIAGRANDE",
    "VIALE D'ASTI",
    "VIALFRE'",
    "VIANO",
    "VIAREGGIO",
    "VIARIGI",
    "VIBO VALENTIA",
    "VIBONATI",
    "VICALVI",
    "VICARI",
    "VICCHIO",
    "VICENZA",
    "VICO CANAVESE",
    "VICO DEL GARGANO",
    "VICO EQUENSE",
    "VICO NEL LAZIO",
    "VICOFORTE",
    "VICOLI",
    "VICOLUNGO",
    "VICOPISANO",
    "VICOVARO",
    "VIDDALBA",
    "VIDIGULFO",
    "VIDOR",
    "VIDRACCO",
    "VIESTE",
    "VIETRI DI POTENZA",
    "VIETRI SUL MARE",
    "VIGANELLA",
    "VIGANO'",
    "VIGANO SAN MARTINO",
    "VIGARANO MAINARDA",
    "VIGASIO",
    "VIGEVANO",
    "VIGGIANELLO",
    "VIGGIANO",
    "VIGGIU'",
    "VIGHIZZOLO D'ESTE",
    "VIGLIANO BIELLESE",
    "VIGLIANO D'ASTI",
    "VIGNALE MONFERRATO",
    "VIGNANELLO",
    "VIGNATE",
    "VIGNOLA",
    "VIGNOLA FALESINA",
    "VIGNOLE BORBERA",
    "VIGNOLO",
    "VIGNONE",
    "VIGO DI CADORE",
    "VIGO DI FASSA",
    "VIGO RENDENA",
    "VIGODARZERE",
    "VIGOLO",
    "VIGOLO VATTARO",
    "VIGOLZONE",
    "VIGONE",
    "VIGONOVO",
    "VIGONZA",
    "VIGUZZOLO",
    "VILLA AGNEDO",
    "VILLA BARTOLOMEA",
    "VILLA BASILICA",
    "VILLA BISCOSSI",
    "VILLA CARCINA",
    "VILLA CASTELLI",
    "VILLA CELIERA",
    "VILLA COLLEMANDINA",
    "VILLA CORTESE",
    "VILLA D'ADDA",
    "VILLA D'ALME'",
    "VILLA DEL BOSCO",
    "VILLA DEL CONTE",
    "VILLA DI BRIANO",
    "VILLA DI CHIAVENNA",
    "VILLA DI SERIO",
    "VILLA DI TIRANO",
    "VILLA D'OGNA",
    "VILLA ESTENSE",
    "VILLA FARALDI",
    "VILLA GUARDIA",
    "VILLA LAGARINA",
    "VILLA LATINA",
    "VILLA LITERNO",
    "VILLA MINOZZO",
    "VILLA POMA",
    "VILLA RENDENA",
    "VILLA SAN GIOVANNI",
    "VILLA SAN GIOVANNI IN TUSCIA",
    "VILLA SAN PIETRO",
    "VILLA SAN SECONDO",
    "VILLA SANTA LUCIA",
    "VILLA SANTA LUCIA DEGLI ABRUZZI",
    "VILLA SANTA MARIA",
    "VILLA SANT'ANGELO",
    "VILLA SANT'ANTONIO",
    "VILLA SANTINA",
    "VILLA SANTO STEFANO",
    "VILLA VERDE",
    "VILLA VICENTINA",
    "VILLABASSA .NIEDERDORF.",
    "VILLABATE",
    "VILLACHIARA",
    "VILLACIDRO",
    "VILLADEATI",
    "VILLADOSE",
    "VILLADOSSOLA",
    "VILLAFALLETTO",
    "VILLAFRANCA D'ASTI",
    "VILLAFRANCA DI VERONA",
    "VILLAFRANCA IN LUNIGIANA",
    "VILLAFRANCA PADOVANA",
    "VILLAFRANCA PIEMONTE",
    "VILLAFRANCA SICULA",
    "VILLAFRANCA TIRRENA",
    "VILLAFRATI",
    "VILLAGA",
    "VILLAGRANDE STRISAILI",
    "VILLALAGO",
    "VILLALBA",
    "VILLALFONSINA",
    "VILLALVERNIA",
    "VILLAMAGNA",
    "VILLAMAINA",
    "VILLAMAR",
    "VILLAMARZANA",
    "VILLAMASSARGIA",
    "VILLAMIROGLIO",
    "VILLANDRO .VILLANDERS.",
    "VILLANOVA BIELLESE",
    "VILLANOVA CANAVESE",
    "VILLANOVA D'ALBENGA",
    "VILLANOVA D'ARDENGHI",
    "VILLANOVA D'ASTI",
    "VILLANOVA DEL BATTISTA",
    "VILLANOVA DEL GHEBBO",
    "VILLANOVA DEL SILLARO",
    "VILLANOVA DI CAMPOSAMPIERO",
    "VILLANOVA MARCHESANA",
    "VILLANOVA MONDOVI'",
    "VILLANOVA MONFERRATO",
    "VILLANOVA MONTELEONE",
    "VILLANOVA SOLARO",
    "VILLANOVA SULL'ARDA",
    "VILLANOVA TRUSCHEDU",
    "VILLANOVAFORRU",
    "VILLANOVAFRANCA",
    "VILLANOVATULO",
    "VILLANTERIO",
    "VILLANUOVA SUL CLISI",
    "VILLAPERUCCIO",
    "VILLAPIANA",
    "VILLAPUTZU",
    "VILLAR DORA",
    "VILLAR FOCCHIARDO",
    "VILLAR PELLICE",
    "VILLAR PEROSA",
    "VILLAR SAN COSTANZO",
    "VILLARBASSE",
    "VILLARBOIT",
    "VILLAREGGIA",
    "VILLARICCA",
    "VILLAROMAGNANO",
    "VILLAROSA",
    "VILLASALTO",
    "VILLASANTA",
    "VILLASIMIUS",
    "VILLASOR",
    "VILLASPECIOSA",
    "VILLASTELLONE",
    "VILLATA",
    "VILLAURBANA",
    "VILLAVALLELONGA",
    "VILLAVERLA",
    "VILLENEUVE",
    "VILLESSE",
    "VILLETTA BARREA",
    "VILLETTE",
    "VILLIMPENTA",
    "VILLONGO",
    "VILLORBA",
    "VILMINORE DI SCALVE",
    "VIMERCATE",
    "VIMODRONE",
    "VINADIO",
    "VINCHIATURO",
    "VINCHIO",
    "VINCI",
    "VINOVO",
    "VINZAGLIO",
    "VIOLA",
    "VIONE",
    "VIPITENO .STERZING.",
    "VIRGILIO",
    "VIRLE PIEMONTE",
    "VISANO",
    "VISCHE",
    "VISCIANO",
    "VISCO",
    "VISONE",
    "VISSO",
    "VISTARINO",
    "VISTRORIO",
    "VITA",
    "VITERBO",
    "VITICUSO",
    "VITO D'ASIO",
    "VITORCHIANO",
    "VITTORIA",
    "VITTORIO VENETO",
    "VITTORITO",
    "VITTUONE",
    "VITULANO",
    "VITULAZIO",
    "VIU'",
    "VIVARO",
    "VIVARO ROMANO",
    "VIVERONE",
    "VIZZINI",
    "VIZZOLA TICINO",
    "VIZZOLO PREDABISSI",
    "VO",
    "VOBARNO",
    "VOBBIA",
    "VOCCA",
    "VODO DI CADORE",
    "VOGHERA",
    "VOGHIERA",
    "VOGOGNA",
    "VOLANO",
    "VOLLA",
    "VOLONGO",
    "VOLPAGO DEL MONTELLO",
    "VOLPARA",
    "VOLPEDO",
    "VOLPEGLINO",
    "VOLPIANO",
    "VOLTA MANTOVANA",
    "VOLTAGGIO",
    "VOLTAGO AGORDINO",
    "VOLTERRA",
    "VOLTIDO",
    "VOLTURARA APPULA",
    "VOLTURARA IRPINA",
    "VOLTURINO",
    "VOLVERA",
    "VOTTIGNASCO",
    "ZACCANOPOLI",
    "ZAFFERANA ETNEA",
    "ZAGARISE",
    "ZAGAROLO",
    "ZAMBANA",
    "ZAMBRONE",
    "ZANDOBBIO",
    "ZANE'",
    "ZANICA",
    "ZAPPONETA",
    "ZAVATTARELLO",
    "ZECCONE",
    "ZEDDIANI",
    "ZELBIO",
    "ZELO BUON PERSICO",
    "ZELO SURRIGONE",
    "ZEME",
    "ZENEVREDO",
    "ZENSON DI PIAVE",
    "ZERBA",
    "ZERBO",
    "ZERBOLO'",
    "ZERFALIU",
    "ZERI",
    "ZERMEGHEDO",
    "ZERO BRANCO",
    "ZEVIO",
    "ZIANO DI FIEMME",
    "ZIANO PIACENTINO",
    "ZIBELLO",
    "ZIBIDO SAN GIACOMO",
    "ZIGNAGO",
    "ZIMELLA",
    "ZIMONE",
    "ZINASCO",
    "ZOAGLI",
    "ZOCCA",
    "ZOGNO",
    "ZOLA PREDOSA",
    "ZOLDO ALTO",
    "ZOLLINO",
    "ZONE",
    "ZOPPE' DI CADORE",
    "ZOPPOLA",
    "ZOVENCEDO",
    "ZUBIENA",
    "ZUCCARELLO",
    "ZUCLO",
    "ZUGLIANO",
    "ZUGLIO",
    "ZUMAGLIA",
    "ZUMPANO",
    "ZUNGOLI",
    "ZUNGRI",
  ];

  let localita_presa: string | undefined;
  let indice_provincia: number | undefined;
  let indice_citta: number | undefined;
  let codice: string | undefined;

  for (let i: number = 0; i <= provincia.length; i++) {
    if (localita.toUpperCase() === provincia[i]) {
      localita_presa = provincia[i];
      switch (localita_presa) {
        case localita_presa:
          localita_presa = cod_citta[i];
          break;
      }
    }
  }

  console.log(anno, meseCod, giornoCF, localita_presa);
  const CF: string =
    (consonanti_prese_cognome || []).join("") +
    (consonanti_prese_nome || []).join("") +
    anno +
    meseCod +
    giornoCF +
    (localita_presa || "");

  const CF_FX: string = GetCF(CF);

  let somma_dispari: number = 0;
  let somma_pari: number = 0;
  let somma_controllo: number = 0;

  const caratteri_lista: string[] = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  for (let i: number = 0; i < CF_FX.length; i++) {
    if (i % 2 !== 1) {
      const char = CF_FX.charAt(i);
      const value = getValueForChar(char);
      somma_dispari += value;
    } else {
      const char = CF_FX.charAt(i);
      const value = getValueForCharOther(char);
      somma_pari += value;
    }
  }

  somma_controllo = (somma_dispari + somma_pari) % 26;

  let lettera_controllo: string | undefined;
  for (let i: number = 0; i <= 26; i++) {
    if (somma_controllo === i) {
      lettera_controllo = caratteri_lista[i];
      break;
    }
  }

  return CF_FX + (lettera_controllo || "");
};
