import type { Marker } from "@googlemaps/markerclusterer";
import React, { useCallback } from "react";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { LazyLoadImage } from "react-lazy-load-image-component";
import UserPlaceholder from "../../assets/images/placeholder.png";
import CityPinImage from "../../assets/images/city_pin.png";

export type MapMarkerProps = {
  info: any;
  onClick: (tree: any) => void;
  setMarkerRef: (marker: Marker | null, key: string) => void;
};

/**
 * Wrapper Component for an AdvancedMarker for a single tree.
 */
export const MapMarker = (props: MapMarkerProps) => {
  const { info, onClick, setMarkerRef } = props;
  const handleClick = useCallback(() => onClick(info), [onClick, info]);

  const ref = useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement) =>
      setMarkerRef(marker, info.id),
    [setMarkerRef, info.id]
  );

  return (
    <AdvancedMarker
      position={{ lat: info.lat, lng: info.lng }}
      ref={ref}
      onClick={handleClick}
    >
      <div className="flex items-center justify-center w-[48px] h-[48px] rounded-full">
        <LazyLoadImage
          effect="blur"
          // src={
          //   info?.main_photo && info.main_photo.startsWith("http")
          //     ? info.main_photo
          //     : UserPlaceholder
          // }
          src={UserPlaceholder}
          className="w-[48px] h-[48px] object-cover rounded-full"
        />
      </div>
    </AdvancedMarker>
  );
};
