import { FC, useContext, useEffect, useState } from "react";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import L from "i18n-react";
import ReportInfo from "src/components/report/info";
import { UserIcon } from "src/components/icons/user-icon";
import { CalendarStarIcon } from "src/components/icons/calendar-star-icon";
import { AddressBookIcon } from "src/components/icons/address-book-icon";
import { UserLockIcon } from "src/components/icons/user-lock-icon";
import routes from "src/config/routes";
import Chart from "react-apexcharts";
import DropdownWithoutLabel from "src/components/ui/dropdown-without-label";
import { makeYearSelection } from "src/lib/utils";
import { useMutation } from "react-query";
import client from "../../api";
import { Spin } from "antd";
import ReportEventsPage from "src/components/report/events";
import ReportCollaboratorsPage from "src/components/report/collabs";
import ReportClientsPage from "src/components/report/clients";

const ReportsPage: FC = () => {
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [viewHeight, setViewHeight] = useState(0);

  const years = makeYearSelection();
  const [currentYear, setCurrentYear] = useState(10);
  const [currentStatus, setCurrentStatus] = useState({
    total_clients: 0,
    total_collabs: 0,
    total_admins: 0,
    total_events: 0,
  });
  const [eventStatistics, setEventStatistics] = useState<{
    [key: string]: number;
  }>({});

  // Stato per gestire l'espansione dei report
  const [expandedSections, setExpandedSections] = useState<{
    events: boolean;
    collaborators: boolean;
    clients: boolean;
    eventStatistics: boolean;
  }>({
    events: false,
    collaborators: false,
    clients: false,
    eventStatistics: true,
  });

  useEffect(() => {
    if (!isMounted) return;
  }, [isMounted]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);
    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
    setViewHeight(window.innerHeight);
  }

  useEffect(() => {
    if (!isMounted) return;

    const selectedYears = years.filter((item) => item.id == currentYear);
    selectedYears.length > 0 && getStatus(selectedYears[0].name);
  }, [isMounted, currentYear]);

  const { mutate: getStatus, isLoading } = useMutation(client.reports.status, {
    onSuccess: (data) => {
      setCurrentStatus(data.status);
      setEventStatistics(data.events);
    },
  });

  // Funzione per alternare l'espansione delle sezioni
  const toggleSection = (section: "events" | "collaborators" | "clients" | "eventStatistics") => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <div className="w-full h-full bg-whole-gray">
      <div className="bg-white p-4 rounded-[8px] mb-4">
        {/* Report Info */}
        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full gap-4">
          <ReportInfo
            icon={<UserIcon className="text-success w-[20px] h-20px]" />}
            title={String(L.translate("CommonForm.total_clients"))}
            amount={currentStatus.total_clients}
            backgroundColor={"bg-success"}
            path={routes.clients}
          />
          <ReportInfo
            icon={<AddressBookIcon className="text-warning w-[20px] h-20px]" />}
            title={String(L.translate("CommonForm.total_collabs"))}
            amount={currentStatus.total_collabs}
            backgroundColor={"bg-warning"}
            path={routes.collaborators}
          />
          <ReportInfo
            icon={<CalendarStarIcon className="text-active w-[20px] h-20px]" />}
            title={String(L.translate("CommonForm.total_events"))}
            amount={currentStatus.total_events}
            backgroundColor={"bg-active"}
            path={routes.events}
          />
          <ReportInfo
            icon={<UserLockIcon className="text-purple w-[20px] h-20px]" />}
            title={String(L.translate("CommonForm.total_basic_admins"))}
            amount={currentStatus.total_admins}
            backgroundColor={"bg-purple"}
            path={routes.admins}
          />
        </div>
        <div className="mt-8">
        {/* Event Statistics */}
        <div className="mt-8 w-full relative">
          <div
            className="flex items-center justify-between cursor-pointer mb-4"
            onClick={() => toggleSection("eventStatistics")}
          >
            <h3 className="text-primary font-gilroy-semibold text-[16px] flex items-center">
              {L.translate("CommonForm.event_statistics_year")}
              <span className="ml-2 text-primary">
                {expandedSections.eventStatistics ? "↓" : "→"}
              </span>
            </h3>
          </div>

          {expandedSections.eventStatistics && (
            <>
              {isLoading && (
                <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
              )}
              <div className="flex flex-col items-start justify-center gap-2 md:flex-row md:items-center md:justify-start md:gap-4 mb-2">
                {/*<div className="text-primary text-[16px] font-gilroy-semibold"> 
                  {L.translate("CommonForm.event_statistics_year")}
                </div>*/}
                <DropdownWithoutLabel
                  placeholder={String(L.translate("Base.year"))}
                  value={currentYear}
                  className="w-[100px]"
                  data={years}
                  onChange={(e: number) => setCurrentYear(e)}
                />
              </div>

              <Chart
                options={{
                  chart: {
                    id: "events-per-month",
                    toolbar: {
                      show: true,
                      tools: {
                        download: true,
                        selection: true,
                        zoom: false,
                        zoomin: true,
                        zoomout: true,
                        pan: false,
                        reset: false,
                        customIcons: [],
                      },
                    },
                  },
                  xaxis: {
                    categories: [
                      String(L.translate("Months.Jan")),
                      String(L.translate("Months.Feb")),
                      String(L.translate("Months.Mar")),
                      String(L.translate("Months.Apr")),
                      String(L.translate("Months.May")),
                      String(L.translate("Months.Jun")),
                      String(L.translate("Months.July")),
                      String(L.translate("Months.Aug")),
                      String(L.translate("Months.Sep")),
                      String(L.translate("Months.Oct")),
                      String(L.translate("Months.Nov")),
                      String(L.translate("Months.Dec")),
                    ],
                  },
                  colors: ["#2970FE", "#518BFF"],
                }}
                series={[
                  {
                    name: `${L.translate("CommonForm.created_event_amount")}`,
                    data: Object.values(eventStatistics),
                  },
                ]}
                type="area"
                height={viewHeight - 410 > 360 ? viewHeight - 410 : 360}
              />
            </>
          )}
        </div>
        </div>

        {/* Reports Sections */}
        <div className="mt-8">
          {/* Events Report */}
          <div className="mb-4">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => toggleSection("events")}
            >
              <h3 className="text-primary font-gilroy-semibold text-[16px] flex items-center">
                {L.translate("CommonForm.event_statistics_month")}
                <span className="ml-2 text-primary">
                  {expandedSections.events ? "↓" : "→"}
                </span>
              </h3>
            </div>
            {expandedSections.events && <ReportEventsPage />}
          </div>
          <br></br>
          {/* Collaborators Report */}
          <div className="mb-4">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => toggleSection("collaborators")}
            >
              <h3 className="text-primary font-gilroy-semibold text-[16px] flex items-center">
                {L.translate("CommonForm.collaborator_statistics_month")}
                <span className="ml-2 text-primary">
                  {expandedSections.collaborators ? "↓" : "→"}
                </span>
              </h3>
            </div>
            {expandedSections.collaborators && <ReportCollaboratorsPage />}
          </div>
          <br></br>
          {/* Clients Report */}
          <div className="mb-4">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => toggleSection("clients")}
            >
              <h3 className="text-primary font-gilroy-semibold text-[16px] flex items-center">
                {L.translate("CommonForm.client_statistics_month")}
                <span className="ml-2 text-primary">
                  {expandedSections.clients ? "↓" : "→"}
                </span>
              </h3>
            </div>
            {expandedSections.clients && <ReportClientsPage />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportsPage;
